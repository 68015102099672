import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { DefaultIcons } from "@/modules/abstract-ui/icon/DefaultIcons";
import { hasValue } from "@/utils/Helpers";
import ObjectIdService from "@/utils/ObjectIdUtils";
import classNames from "classnames";
import { Canvas, Control, FabricImage, Image, Point, Rect, util } from "fabric";
import _ from "lodash";
import {
  getDocument,
  GlobalWorkerOptions,
  PDFDocumentLoadingTask,
  PDFDocumentProxy,
  PDFPageProxy,
  renderTextLayer,
} from "pdfjs-dist";
import { useEffect, useRef, useState } from "react";
import { Loader } from "rsuite";
import Collapse from "rsuite/esm/Animation/Collapse";
import DebugDataComponent from "../../debug/DebugDataComponent";
import Log from "../../debug/Log";
import i18n from "../../i18n";
import BFDropdown from "../../modules/abstract-ui/general/Dropdown/BFDropdown";
import BfIcon from "../../modules/abstract-ui/icon/BfIcon";
import { useTypedSelector } from "../../redux/hooks";
import { DefaultUIConfigs } from "../../redux/reducers/ui-config/UiConfig";
import { AppState } from "../../redux/store";
import DataBus from "../../services/DataBus";
import DataBusDefaults, {
  ScrollGroupOptions,
} from "../../services/DataBusDefaults";
import { DataBusSubKeys } from "../../utils/Constants";
import FileUtils from "../../utils/FileUtils";
import StorageCache from "../../utils/StorageCache";
import "./PDFViewer.scss";
import {
  PDFDocumentHighlight,
  PDFEditRect,
  PDFHightlightState,
  PDFViewerEditState,
  usePdfHighlightState,
  usePdfViewerEditState,
} from "./PDFViewerHooks";

export type PDFHighlight = {
  id: string;
  value: string;
  stroke?: string;
  name?: string;
  top: number;
  left: number;
  width: number;
  height: number;
  page: number;
  padding?: number;
  scrollIntoView?: boolean;
};

interface Props {
  identifier?: string;
  url: string;
  height?: number | string;
  highlights?: PDFHighlight[];
  download?: boolean;
  filename: string;
  scrollGroup?: string;
  scrollIdentifier?: string;
  zoomFactorStorageKey?: string;
  border?: boolean;
  pdfHighlightState?: PDFHightlightState;
  pdfViewerEditState?: PDFViewerEditState;
}
type State = "loading" | "error" | "success";

const PDFViewer = (props: Props) => {
  const pdfHighlightState =
    usePdfHighlightState(props.identifier) || props.pdfHighlightState;
  const pdfViewerEditState =
    usePdfViewerEditState(props.identifier) || props.pdfViewerEditState;

  const [highlightIndex, setHighlightIndex] = useState<number>(0);

  useEffect(() => {
    if (pdfHighlightState?.viewActive) {
      setHighlightIndex(0);
    }
  }, [pdfHighlightState?.viewActive]);

  const refLoadingTask = useRef<PDFDocumentLoadingTask>(null);
  const scrollRef = useRef<HTMLDivElement>();
  const [zoomFactor, setZoomFactor] = useState<number>(
    props.zoomFactorStorageKey
      ? (StorageCache.get(props.zoomFactorStorageKey) as number) || 1
      : 1
  );
  const [state, setState] = useState<State>("loading");
  const [pdfInstance, setPdfInstance] = useState<PDFDocumentProxy>(null);

  const [search, setSearch] = useState<{
    value: string;
    index: number;
    results: HTMLSpanElement[];
  }>({
    value: "",
    index: 0,
    results: [],
  });

  useEffect(() => {
    if (props.zoomFactorStorageKey) {
      StorageCache.save(props.zoomFactorStorageKey, zoomFactor);
    }
  }, [props.zoomFactorStorageKey, zoomFactor]);
  useEffect(() => {
    return () => {
      if (refLoadingTask.current) {
        Log.info("PDFViewer.unmount found refLoadingTask");
        try {
          refLoadingTask.current.destroy().then(() => {
            Log.info("PDFViewer.unmount destroyed refLoadingTask");
          });
        } catch (err) {
          Log.info("PDFViewer.unmount err refLoadingTask", err);
        }
      }
    };
  }, []);
  useEffect(() => {
    return () => {
      Log.info("PDFViewer.unmount check pdfinstance", pdfInstance);
      if (pdfInstance) {
        Log.info("PDFViewer.unmount cleanup pdfinstance");
        pdfInstance.cleanup().finally(() => {
          Log.info("PDFViewer.unmount destroy pdfinstance");
          pdfInstance.destroy();
        });
      }
    };
  }, [pdfInstance]);
  useEffect(() => {
    let subId = null;
    if (props.scrollGroup) {
      subId = DataBus.subscribe(
        DataBusSubKeys.SCROLL_GROUP,
        (data: ScrollGroupOptions) => {
          if (props.scrollGroup === data.groupId) {
            if (props.scrollIdentifier !== data.senderId) {
              scrollRef.current?.scrollTo({
                top: data.top,
                left: data.left,
              });
            }
          }
        }
      );
    }
  }, [props.scrollGroup, props.scrollIdentifier]);
  useEffect(() => {
    if (props.url) {
      loadPdf(props.url);
    } else {
      setError();
    }
  }, [props.url]);

  const setError = async () => {
    if (pdfInstance) {
      await pdfInstance.cleanup();
      await pdfInstance.destroy();
    }

    setState("error");
  };

  const focusSpan = (span: HTMLSpanElement, searchText: string) => {
    scrollRef.current?.querySelectorAll(".search-highlight").forEach((e) => {
      e.remove();
    });
    if (!span) {
      return;
    }
    const text = span.textContent;
    const startIndex = text.toLowerCase().indexOf(searchText.toLowerCase());

    if (startIndex !== -1) {
      const range = document.createRange();
      const textNode = span.firstChild;

      if (textNode) {
        range.setStart(textNode, startIndex);
        range.setEnd(textNode, startIndex + searchText.length);

        const bounds = range.getBoundingClientRect();
        const parentBounds = span.parentElement?.getBoundingClientRect();

        const div = document.createElement("div");
        div.classList.add("search-highlight");
        div.style.position = "absolute";
        div.style.top = `${bounds.top - parentBounds!.top}px`;
        div.style.left = `${bounds.left - parentBounds!.left}px`;
        div.style.width = `${bounds.width}px`;
        div.style.height = `${bounds.height}px`;
        div.style.background = "rgba(38, 123, 197, 0.5)";
        div.style.pointerEvents = "none";

        span.parentElement?.appendChild(div);

        span.scrollIntoView({
          block: "center",
        });

        // const selection = window.getSelection();
        // selection.removeAllRanges(); // Clear any existing selection
        // selection.addRange(range);
      }
    }
  };
  const loadPdf = async (url: string) => {
    try {
      if (pdfInstance) {
        await pdfInstance.cleanup();
        await pdfInstance.destroy();
      }
    } catch (err) {
      Log.info("PDFViewer.loadPdf err pdfInstance", err);
    }

    if (refLoadingTask.current) {
      Log.info("PDFViewer.loadPdf found refLoadingTask");
      try {
        await refLoadingTask.current.destroy();
        Log.info("PDFViewer.loadPdf destroyed refLoadingTask");
      } catch (err) {
        Log.info("PDFViewer.loadPdf err refLoadingTask", err);
      }
    }

    setState("loading");

    GlobalWorkerOptions.workerSrc = "/scripts/pdf.worker.min.js";

    const loadingTask = getDocument(url);
    refLoadingTask.current = loadingTask;
    try {
      const pdf = await loadingTask.promise;
      refLoadingTask.current = null;
      setPdfInstance(pdf);
      setState("success");
      scrollRef.current?.scrollTo({
        top: 0,
      });
    } catch (err) {
      if (err?.message === "Worker was destroyed") {
        Log.info("PDFViewer.loadPdf error destroyed worker", err);
      } else {
        Log.error(`PDFViewer.loadPdf error loading pdf`, err);
        await setError();
      }
    }
  };

  return (
    <div
      className={classNames("pdf-viewer", {
        border: props.border,
      })}
      style={{ height: props.height || "100%" }}
    >
      <DebugDataComponent
        data={{
          props: props,
        }}
      />
      {state === "loading" && (
        <div className="loading">
          <Loader size="md" />
        </div>
      )}
      {state === "error" && (
        <div className="error">
          <div className="error-text">
            {i18n.t(
              "PDFViewer.ErrorAtLoadingPDF",
              "Das PDF konnte nicht geladen werden. Bitte versuchen Sie es erneut, sollte es nicht funktionieren, aktualisieren Sie bitte die Seite."
            )}
          </div>
        </div>
      )}
      {state !== "loading" && state !== "error" && (
        <>
          <div className="controls">
            <div className={`search`}>
              <BfIcon size="xxs" {...DefaultIcons.SEARCH} />
              <input
                placeholder={i18n.t("Global.Labels.Search")}
                value={search.value}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearch({
                      results: search.results,
                      value: search.value,
                      index: (search.index + 1) % search.results.length,
                    });
                    focusSpan(
                      search.results[
                        (search.index + 1) % search.results.length
                      ],
                      search.value
                    );
                  }
                }}
                onChange={(e) => {
                  const searchValue = e.target.value;

                  const allSpans =
                    scrollRef.current.querySelectorAll(".text-layer span");
                  let results: HTMLSpanElement[] = [];
                  allSpans.forEach((e) => {
                    if (
                      e.textContent
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    ) {
                      results.push(e as HTMLSpanElement);
                    }
                  });

                  if (results.length > 0) {
                    focusSpan(results[0], searchValue);
                    e.target.focus();
                  } else {
                    focusSpan(null, searchValue);
                  }

                  setSearch({
                    results: searchValue === "" ? [] : results,
                    value: searchValue,
                    index: 0,
                  });
                }}
              />

              {search.results.length > 0 && (
                <div className={`results`}>
                  <div className={`counter`}>
                    {search.index + 1} / {search.results.length}
                  </div>
                  <div className={`buttons  `}>
                    <button
                      type="button"
                      className="simple-action"
                      onClick={() => {
                        setSearch({
                          results: search.results,
                          value: search.value,
                          index:
                            (search.results.length + search.index - 1) %
                            search.results.length,
                        });
                        focusSpan(
                          search.results[
                            (search.results.length + search.index - 1) %
                              search.results.length
                          ],
                          search.value
                        );
                      }}
                    >
                      <BfIcon type="light" data="arrow-up-1" size="xxs" />
                    </button>
                    <button
                      type="button"
                      className="simple-action"
                      onClick={() => {
                        setSearch({
                          results: search.results,
                          value: search.value,
                          index: (search.index + 1) % search.results.length,
                        });
                        focusSpan(
                          search.results[
                            (search.index + 1) % search.results.length
                          ],
                          search.value
                        );
                      }}
                    >
                      <BfIcon type="light" data="arrow-down-1" size="xxs" />
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="filename">{props.filename}</div>
            <div className="pages">{pdfInstance?.numPages || "-"} Seiten</div>
            <div className="action">
              <BFDropdown
                className="zoom-level"
                label={`${Math.floor(zoomFactor * 100)} %`}
                items={[
                  {
                    type: "button",
                    text: "50%",
                    onSelect: () => setZoomFactor(0.5),
                  },
                  {
                    type: "button",
                    text: "75%",
                    onSelect: () => setZoomFactor(0.75),
                  },
                  {
                    type: "button",
                    text: "100%",
                    onSelect: () => setZoomFactor(1),
                  },
                  {
                    type: "button",
                    text: "125%",
                    onSelect: () => setZoomFactor(1.25),
                  },
                  {
                    type: "button",
                    text: "150%",
                    onSelect: () => setZoomFactor(1.5),
                  },
                ]}
              />
            </div>
            <div className="action">
              <button
                type="button"
                className="simple-action"
                onClick={() =>
                  FileUtils.forceDownloadFile(props.url, props.filename)
                }
              >
                <BfIcon type="light" data="download-bottom" size="xs" />
              </button>
            </div>
          </div>
          {pdfViewerEditState && (
            <Collapse
              in={
                pdfViewerEditState?.editActive &&
                hasValue(pdfViewerEditState?.editHint)
              }
            >
              <div>
                <div className={`editmode-hint`}>
                  <div className={`hint`}>{pdfViewerEditState?.editHint}</div>
                  <div className={`actions`}>
                    <BFButton
                      appearance="outline"
                      onClick={() => {
                        pdfViewerEditState.abort();
                      }}
                    >
                      {i18n.t("Global.Buttons.cancel")}
                    </BFButton>
                    <BFButton
                      appearance="primary"
                      onClick={() => {
                        pdfViewerEditState.save();
                      }}
                    >
                      {i18n.t("Global.Buttons.save")}
                    </BFButton>
                  </div>
                </div>
              </div>
            </Collapse>
          )}
          {pdfHighlightState && (
            <Collapse
              in={
                pdfHighlightState?.viewActive &&
                hasValue(pdfHighlightState?.viewHint) &&
                !pdfViewerEditState?.editActive
              }
            >
              <div>
                <div className={`viewmode-hint`}>
                  <div className={`hint`}>{pdfHighlightState?.viewHint}</div>

                  <div className={`step-through`}>
                    <BFButton
                      size="xs"
                      appearance="clear-on-white"
                      onClick={() => {
                        setHighlightIndex(
                          (highlightIndex -
                            1 +
                            pdfHighlightState?.viewValues?.length) %
                            pdfHighlightState?.viewValues?.length
                        );
                      }}
                    >
                      <BfIcon {...DefaultIcons.BACK} size="xs" />
                    </BFButton>
                    <div className={`count`}>
                      {highlightIndex + 1}/
                      {pdfHighlightState?.viewValues?.length}
                    </div>
                    <BFButton
                      size="xs"
                      appearance="clear-on-white"
                      onClick={() => {
                        setHighlightIndex(
                          (highlightIndex + 1) %
                            pdfHighlightState?.viewValues?.length
                        );
                      }}
                    >
                      <BfIcon {...DefaultIcons.FORWARD} size="xs" />
                    </BFButton>
                  </div>
                  <div>
                    <BFButton
                      appearance="clear-on-white"
                      onClick={() => pdfHighlightState?.stopView()}
                    >
                      <BfIcon {...DefaultIcons.CLOSE} size="xxs" />
                    </BFButton>
                  </div>
                </div>
              </div>
            </Collapse>
          )}
          <div
            className="content"
            ref={scrollRef}
            onScroll={(e) => {
              if (props.scrollGroup) {
                DataBusDefaults.scrollGroup({
                  groupId: props.scrollGroup,
                  senderId: props.scrollIdentifier,
                  top: e.currentTarget.scrollTop,
                  left: e.currentTarget.scrollLeft,
                });
              }
            }}
            onDoubleClick={(ev) => {
              if (zoomFactor === 1) {
                var mouseX =
                  ev.clientX - scrollRef.current.getBoundingClientRect().left;
                var mouseY =
                  ev.clientY - scrollRef.current.getBoundingClientRect().top;

                var newScrollLeft =
                  (mouseX / scrollRef.current.scrollWidth) *
                    (scrollRef.current.scrollWidth * 1.5 -
                      scrollRef.current.clientWidth) +
                  scrollRef.current.scrollLeft;
                var newScrollTop =
                  (mouseY / scrollRef.current.scrollHeight) *
                    (scrollRef.current.scrollHeight * 1.5 -
                      scrollRef.current.clientHeight) +
                  scrollRef.current.scrollTop;

                setTimeout(() => {
                  scrollRef.current.scrollLeft = newScrollLeft;
                  scrollRef.current.scrollTop = newScrollTop;
                }, 20);
                setZoomFactor(1.5);
              } else {
                setZoomFactor(1);
              }
            }}
          >
            {Array(pdfInstance?.numPages)
              .fill(1)
              .map((_e, index) => (
                <PDFPage
                  editMode={
                    pdfViewerEditState?.editActive
                      ? {
                          value:
                            pdfViewerEditState?.value.filter(
                              (e) => e.page === index + 1
                            ) || [],
                          onChange: (value: PDFDocumentHighlight[]) => {
                            pdfViewerEditState?.onChange([
                              ...pdfViewerEditState?.value.filter(
                                (e) => e.page !== index + 1
                              ),
                              ...value,
                            ]);
                          },
                        }
                      : undefined
                  }
                  zoomFactor={zoomFactor}
                  key={index}
                  pageIndex={index + 1}
                  pdfInstance={pdfInstance}
                  highlights={
                    !pdfViewerEditState?.editActive
                      ? hasValue(pdfHighlightState?.viewValues)
                        ? pdfHighlightState?.viewValues
                            ?.map((e, index) => ({
                              ...e,
                              scrollIntoView: index === highlightIndex,
                            }))
                            .filter((e) => e.page === index + 1)
                        : props.highlights?.filter((e) => e.page === index + 1)
                      : undefined
                  }
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PDFViewer;

const renderDeleteIcon = (ctx, left, top, styleOverride, fabricObject) => {
  var size = 24;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(util.degreesToRadians(fabricObject.angle));

  var deleteIcon =
    "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

  var img = document.createElement("img");
  img.src = deleteIcon;
  ctx.drawImage(img, -size / 2, -size / 2, size, size);
  ctx.restore();
};

interface PageProps {
  pageIndex: number;
  pdfInstance: PDFDocumentProxy;
  highlights?: PDFHighlight[];
  zoomFactor: number;
  editMode?: {
    value: PDFEditRect[];
    onChange: (rects: PDFEditRect[]) => void;
  };
}
const boxToScale = (box: PDFEditRect, scaleFactor: number) => {
  return {
    ...box,
    top: box.top * scaleFactor,
    left: box.left * scaleFactor,
    width: box.width * scaleFactor,
    height: box.height * scaleFactor,
  };
};
const boxFromScale = (box: PDFEditRect, scaleFactor: number) => {
  return {
    ...box,
    top: box.top / scaleFactor,
    left: box.left / scaleFactor,
    width: box.width / scaleFactor,
    height: box.height / scaleFactor,
  };
};
const PDFPage = (props: PageProps) => {
  const mouseDownRef = useRef<Point>(null);
  const textLayer = useRef<HTMLDivElement>();
  const [canvId] = useState(_.uniqueId("canv_"));
  const canvRef = useRef<Canvas>(null);
  const scaleFactor = useRef<number>(1);
  const [state, setState] = useState<State>("loading");
  const [page, setPage] = useState<PDFPageProxy>(null);
  const ref = useRef<HTMLCanvasElement>();
  const refContainer = useRef<HTMLDivElement>();
  const viewportWidth = useTypedSelector(
    (state: AppState) => state.uiConfig.general[DefaultUIConfigs.VIEWPORT_WIDTH]
  );
  const [renderFinish, setRenderFinish] = useState(false);

  const editModeRef = useRef<{
    value: PDFEditRect[];
    onChange: (rects: PDFEditRect[]) => void;
  }>(null);

  const boxesRef = useRef<{ [id: string]: Rect }>({});

  const removeRectFromCanvas = (id: string, rect: Rect) => {
    var canvas = rect.canvas;
    canvas?.remove(rect);
    canvas?.requestRenderAll();
    delete boxesRef.current[id];
  };
  const removeAllFromCanvas = () => {
    Object.entries(boxesRef.current).forEach(([id, box]) => {
      removeRectFromCanvas(id, box);
    });
  };
  const addBox = (box: PDFEditRect) => {
    if (!editModeRef.current.value?.find((e) => e.id === box.id)) {
      editModeRef.current.onChange([...editModeRef.current.value, box]);
    }
    const deleteObject = (eventData, transform) => {
      var target = transform.target;
      removeRectFromCanvas(box.id, target);
      editModeRef.current.onChange(
        editModeRef.current.value.filter((e) => e.id !== target.id)
      );

      delete boxesRef.current[box.id];
    };

    const rectToAdd = new Rect({
      ...boxToScale(box, scaleFactor.current),
      fill: "rgba(255, 0, 0, 0.2)",
      lockRotation: true,
      lockSkewingX: true,
      lockSkewingY: true,
      lockScalingFlip: true,
    });
    rectToAdd.on("modified", (options) => {
      Log.info("object-modified", options);
      editModeRef.current.onChange(
        editModeRef.current.value.map((e) =>
          e.id === box.id
            ? boxFromScale(
                {
                  top: options.target.top,
                  left: options.target.left,
                  width: options.target.width * options.target.scaleX,
                  height: options.target.height * options.target.scaleY,
                  id: e.id,
                  page: e.page,
                },
                scaleFactor.current
              )
            : e
        )
      );
    });
    rectToAdd.setControlsVisibility({
      mtr: false,
    });
    rectToAdd.controls.deleteControl = new Control({
      x: 0.5,
      y: -0.5,
      offsetY: 20,
      offsetX: -20,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: renderDeleteIcon,
    });
    boxesRef.current[box.id] = rectToAdd;
    canvRef.current?.add(rectToAdd);
  };

  useEffect(() => {
    const beforeState = editModeRef.current;
    editModeRef.current = props.editMode;
    if (props.editMode && !beforeState) {
      (props.editMode?.value || []).forEach((box) => addBox(box));
    } else if (beforeState && !props.editMode) {
      removeAllFromCanvas();
    }
  }, [props.editMode]);
  // useEffect(() => {
  //   canvRef.current?.setZoom(props.zoomFactor);
  // }, [props.zoomFactor]);
  useEffect(() => {
    removeAllFromCanvas();
    renderPage();
    return () => {
      // canvRef.current?.dispose();
    };
  }, [page, props.zoomFactor, viewportWidth]);
  useEffect(() => {
    return () => {
      if (page) {
        page.cleanup();
      }
      canvRef.current?.dispose();
    };
  }, []);

  useEffect(() => {
    if (props.pdfInstance) {
      setState("loading");
      try {
        props.pdfInstance
          .getPage(props.pageIndex)
          .then((pageProxy) => {
            setPage(pageProxy);
          })
          .catch((err) => {
            setState("error");
          });
      } catch (err) {
        setState("error");
      }
    }
  }, [props.pdfInstance, props.pageIndex]);

  const renderPage = () => {
    if (!page) {
      return;
    }
    let pdfViewport = page.getViewport({ scale: 1 });

    const useScale =
      (refContainer.current.offsetWidth / pdfViewport.width) * props.zoomFactor;
    scaleFactor.current = useScale;
    pdfViewport = page.getViewport({
      scale: useScale,
    });
    // canvas.width = pdfViewport.width;
    // canvas.height = pdfViewport.height;
    if (canvRef.current) {
      canvRef.current?.clear();
      canvRef.current?.setDimensions({
        width: pdfViewport.width,
        height: pdfViewport.height,
      });
      // canvRef.current.setWidth(pdfViewport.width);
      // canvRef.current.height = pdfViewport.height;
    } else {
      canvRef.current = new Canvas(canvId, {
        height: pdfViewport.height,
        width: pdfViewport.width,
        // backgroundColor: 'pink' ,
        selection: true,
        renderOnAddRemove: true,
        // enablePointerEvents: true
      });

      canvRef.current.on("mouse:down", (options) => {
        if (!editModeRef.current) {
          return;
        }
        if (options?.transform?.action) {
          mouseDownRef.current = null;
        } else {
          mouseDownRef.current = options.scenePoint;
        }
      });
      canvRef.current.on("mouse:up", (options) => {
        if (!editModeRef.current) {
          return;
        }
        const up = options.scenePoint;
        const down = mouseDownRef.current;
        if (!up || !down) {
          return;
        }
        const box = {
          top: Math.min(Math.max(0, down.y), Math.max(0, up.y)),
          left: Math.min(Math.max(0, down.x), Math.max(0, up.x)),
          width: Math.abs(
            down.x - Math.max(Math.min(up.x, pdfViewport.width), 0)
          ),
          height: Math.abs(
            down.y - Math.max(Math.min(up.y, pdfViewport.height), 0)
          ),
        };

        if (box.height > 25 && box.width > 25) {
          addBox(
            boxFromScale(
              { ...box, id: ObjectIdService.new(), page: props.pageIndex },
              scaleFactor.current
            )
          );
        }
      });
    }

    if (editModeRef.current) {
      removeAllFromCanvas();
      editModeRef.current?.value.forEach((box) => addBox(box));
    }

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.height = pdfViewport.height;
    canvas.width = pdfViewport.width;

    page
      .render({
        canvasContext: context,
        viewport: pdfViewport,
      })
      .promise.then((a) => {
        const image = new FabricImage(canvas, {
          moveCursor: "default",
          hoverCursor: "default",
          lockMovementX: true,
          lockRotation: true,
          lockScalingX: true,
          lockMovementY: true,
          lockScalingFlip: true,
          lockScalingY: true,
          lockSkewingX: true,
          lockSkewingY: true,
          selectable: false,
          width: pdfViewport.width,
          height: pdfViewport.height,
        });
        canvRef.current.backgroundImage = image;
        canvRef.current.requestRenderAll();

        canvas.remove();
        textLayer.current.innerHTML = "";
        renderTextLayer({
          viewport: pdfViewport,
          container: textLayer.current,
          textContentStream: page.streamTextContent(),
        });
        setRenderFinish(true);
      })
      .catch((err) => {
        setState("error");
        Log.error(`error rendering pdf`, err);
      });
  };

  return (
    <div className={`pdf-page page-${props.pageIndex}`} ref={refContainer}>
      <div className="wrapper">
        <div className={`center-position`}>
          <canvas
            id={canvId}
            onError={(err) => {
              Log.info(`error in canvas`, err);
            }}
            ref={ref}
          ></canvas>
          <div
            style={{
              pointerEvents: props.editMode ? "none" : "all",
            }}
            className={`text-layer`}
            ref={textLayer}
          />
          {renderFinish &&
            props.highlights
              ?.filter((highlight) => {
                //filter out invalid highlight boxes, can occur if extraction module cannot get the dimension properly, boxes are out of bounds then
                if (
                  (highlight.left + highlight.width) * scaleFactor.current <=
                    ref.current?.width &&
                  (highlight.top + highlight.height) * scaleFactor.current <=
                    ref.current?.height
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              .map((highlight) => (
                <PDFHighlightComponent
                  key={highlight.id}
                  {...highlight}
                  scaleFactor={scaleFactor.current}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

interface PDFHighlightComponentPrps extends PDFHighlight {
  scaleFactor: number;
}
const PDFHighlightComponent = (props: PDFHighlightComponentPrps) => {
  const highlightRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (props.scrollIntoView) {
      highlightRef.current?.scrollIntoView({
        // behavior: "smooth",
        inline: "center",
        block: "center",
      });
    }
  }, [props.scrollIntoView]);
  return (
    <div
      ref={highlightRef}
      className={`pdf-highlight-component`}
      style={{
        left:
          props.left * props.scaleFactor -
          (props.padding || 0) * props.scaleFactor,
        top:
          props.top * props.scaleFactor -
          (props.padding || 0) * props.scaleFactor,
        width:
          props.width * props.scaleFactor +
          (props.padding || 0) * 2 * props.scaleFactor,
        height:
          props.height * props.scaleFactor +
          (props.padding || 0) * 2 * props.scaleFactor,
      }}
    ></div>
  );
};
