import BFAssignment, { Assignment, TextOverwrite } from "./BFAssignment";

export type BFAssignmentValue = {
  users: string[];
  teams: string[];
};

interface BFAssignmentDefaultProps {
  label?: string;
  value: BFAssignmentValue;
  onChange: (value: BFAssignmentValue) => void;
  assignmentType: "user" | "team" | "both";
  maxAssignments?: number;
  teamContexts?: string[];
  error?: string | boolean;
  identifier?: string;
  textOverwrite?: TextOverwrite;
  asOverlay?: boolean;
}
const BFAssignmentDefault = (props: BFAssignmentDefaultProps) => {
  return (
    <BFAssignment
      asOverlay={props.asOverlay}
      assignmentType={props.assignmentType}
      maxAssignments={props.maxAssignments}
      teamContexts={props.teamContexts}
      error={props.error}
      identifier={props.identifier}
      textOverwrite={props.textOverwrite}
      label={props.label}
      value={[
        ...(props.value?.users || []).map(
          (user) =>
            ({
              id: user,
              type: "user",
            } as Assignment)
        ),
        ...(props.value?.teams || []).map(
          (team) =>
            ({
              id: team,
              type: "team",
            } as Assignment)
        ),
      ]}
      onChange={(value) => {
        props.onChange({
          users: value.filter((e) => e.type === "user").map((e) => e.id),
          teams: value.filter((e) => e.type === "team").map((e) => e.id),
        });
      }}
    />
  );
};

export default BFAssignmentDefault;
