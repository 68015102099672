import moment from "moment";
import { useEffect, useState } from "react";
import KPIDetailCard from "../../../../../../../../components/KPIDetailCard/KPIDetailCard";
import ChartComponent from "../../../../../../../../configurable/components/ChartComponent/ChartComponent";
import DebugDataComponent from "../../../../../../../../debug/DebugDataComponent";
import i18n from "../../../../../../../../i18n";
import BFButtonToggle from "../../../../../../../../modules/abstract-ui/general/Button/BFButtonToggle";
import { useStatisticQuery } from "../../../../../../../../redux/hooks";
import DataBus from "../../../../../../../../services/DataBus";
import { DataBusSubKeys } from "../../../../../../../../utils/Constants";
import StringUtils from "../../../../../../../../utils/StringUtils";
import { CBStatisticImmoVacancyRate } from "../../../interfaces/CBStatisticQueries";
import "./CBVacancyHistoryCard.scss";
import { getCssVariable } from "@/apps/utils/getCssVariable";

interface VacancyHistoryCardProps {
  objectIds: string[];
  marginBottom?: number;
  referenceId?: string;
}
const CBVacancyHistoryCard = (props: VacancyHistoryCardProps) => {
  const [selector, setSelector] = useState<
    "vacancyRate" | "vacancyArea" | "vacancyRentNet"
  >("vacancyRentNet");
  const queryData = useStatisticQuery<CBStatisticImmoVacancyRate>(
    "IMMO_VACANCY_RATE",
    1,
    {
      fromDate: moment().subtract(6, "month").startOf("month").toISOString(),
      toDate: moment().add(5, "year").startOf("month").toISOString(),
      objectIds: props.objectIds,
    }
  );

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          queryData.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });
  return (
    <KPIDetailCard
      marginBottom={props.marginBottom}
      className="cb-vacancy-history-card"
      title={i18n.t("cb:Vacancy.vacancy-history", "Mieteinheiten Leerstand")} //"Mieteinheiten Leerstand"}
      data={queryData}
      error={queryData.error}
      onReload={queryData.reload}
      headRight={
        <BFButtonToggle
          buttons={[
            {
              text: i18n.t("cb:Vacancy.rent", "Miete"),
              value: "vacancyRentNet",
            },
            {
              text: i18n.t("cb:Vacancy.area", "Fläche"),
              value: "vacancyArea",
            },
            {
              text: i18n.t("cb:Vacancy.count", "Anzahl"),
              value: "vacancyRate",
            },
          ]}
          value={selector}
          onChange={(val: "vacancyRate" | "vacancyArea" | "vacancyRentNet") =>
            setSelector(val)
          }
        />
      }
      childOptions={{
        height: 300,
        render: (queryData) => (
          <>
            <DebugDataComponent data={queryData} />

            <ChartComponent
              renderer="canvas"
              options={cbVacanyHistoryCardOptions(
                queryData.data,
                selector,
                true
              )}
              identifier={`cb-portfolio-dashboard-vacancy`}
            />
          </>
        ),
      }}
      loading={queryData.loading}
    />
  );
};
export default CBVacancyHistoryCard;

export const cbVacanyHistoryCardOptions = (
  queryData: CBStatisticImmoVacancyRate,
  selector: "vacancyRate" | "vacancyArea" | "vacancyRentNet",
  forPdf?: boolean
) => {
  return {
    animation: forPdf ? false : true,
    tooltip: {
      trigger: "axis",
      valueFormatter: (val) =>
        selector === "vacancyArea"
          ? `${StringUtils.formatNumber(val)} ${StringUtils.getAreaUnit()}`
          : selector === "vacancyRentNet"
          ? StringUtils.formatCurrency(val)
          : val,
    },
    legend: {
      left: 10,
      data: [
        i18n.t("cb:Vacancy.vacant", "Leerstand"),
        i18n.t("cb:Vacancy.occupied", "Vermietet"),
      ],
      textStyle: {
        color: getCssVariable("--text-color-strong"),
      },
    },
    //   toolbox: {
    //     feature: {
    //       saveAsImage: {},
    //     },
    //   },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      top: 40,
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: queryData?.map((entry) =>
          moment()
            .month(entry.month - 1)
            .year(entry.year)
            .format("MMM YYYY")
        ),
        axisLabel: {
          textStyle: {
            color: getCssVariable("--chart-axis-label-primary"),
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: (val) =>
            selector === "vacancyArea"
              ? `${StringUtils.formatNumber(val)} ${StringUtils.getAreaUnit()}`
              : selector === "vacancyRentNet"
              ? StringUtils.formatCurrency(val)
              : val,
          textStyle: {
            color: getCssVariable("--chart-axis-label-primary"),
          },
        },
      },
    ],
    series: [
      {
        type: "line",
        markLine: {
          silent: true,
          symbol: ["none", "none"],
          label: {
            formatter: () => i18n.t("Global.Labels.today", "Heute"),
            color: getCssVariable("--text-color-strong"),
          },
          data: [{ xAxis: moment().format("MMM YYYY") }],
        },
      },
      {
        name: i18n.t("cb:Vacancy.occupied", "Vermietet"),
        type: "line",
        stack: i18n.t("Globals.Labels.total", "Gesamt"),
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: getCssVariable("--cb-portfolio-immo-non-vacant"),
        },
        data: queryData?.map((entry) => entry.kpi[selector].totalNonVacant),
      },
      {
        name: i18n.t("cb:Vacancy.vacant", "Leerstand"),
        type: "line",
        stack: i18n.t("Globals.Labels.total", "Total"),
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: getCssVariable("--cb-portfolio-immo-vacant"),
        },
        data: queryData?.map((entry) => entry.kpi[selector].totalVacant),
      },
    ],
  };
};
