import { withPDFBlock } from "../../PDFBlock";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";

import { PDFBlockFooter } from "../../PDFBlockFooter";
import {
  ObjectTenantListConfig,
  ObjectTenantListFields,
} from "@/components/PDFConstructor/services/types/pdfConfigObjectBlockTypes";
import { useState } from "react";
import {
  OBJECT_TENANT_LIST,
  OBJECT_TENANT_LIST_VALUES,
} from "./PDFObjectInputConstants";
import { useBlockChanges } from "../../PDFBlockHooks";
import i18n from "@/i18n";
import { PDFTableFooterInput } from "./Components/PDFTableFooterInput";
import { PDFCommonInput } from "../../Common/PDFCommonInput";
import EZTags from "@/modules/ez-form/form-elements/ez-tags/EZTags";
import LabeledInput from "@/modules/abstract-ui/forms/LabeledInput";

const PDFObjectTenantListTableBlock = withPDFBlock<ObjectTenantListConfig>(
  ({ value, parentId }) => {
    const [{ asset }, { deleteBlock, saveBlock }] = usePDFConstructor();

    const [form, setForm] = useState({ ...value, assetId: asset._id });

    useBlockChanges(value, form);

    return (
      <div>
        <LabeledInput
          label={i18n.t("Component.PDFConstructor.Fields", "Felder")}
        >
          <EZTags
            data={OBJECT_TENANT_LIST.map((option) => ({
              ...option,
              label: option.label(),
            }))}
            value={form.fields}
            onChange={(values) =>
              setForm((form) => {
                const fields = Object.keys(OBJECT_TENANT_LIST_VALUES).filter(
                  (key) => values.includes(key)
                );

                return {
                  ...form,
                  fields: fields as ObjectTenantListFields[],
                };
              })
            }
          />
        </LabeledInput>

        <PDFTableFooterInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
          columnsAmount={OBJECT_TENANT_LIST.length}
        />

        <PDFCommonInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock({ ...value, assetId: asset._id }, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t(
      "Component.PDFConstructor.Block.Inputs.ObjectTenantListTable",
      "Objekt-Mieterliste-Tabelle"
    )
);

export { PDFObjectTenantListTableBlock };
