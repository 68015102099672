import BFCheckbox from "@/modules/abstract-ui/forms/checkbox/BFCheckbox";
import DeviceUtils from "@/utils/Device";
import { isDefined } from "@/utils/Helpers";
import _ from "lodash";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { TableSort } from "../../../model/common/CommonInterfaces";
import ExportDialog, { ExportType } from "../../../modules/export/ExportDialog";
import { TableExcelExportOptions } from "../../../modules/export/export.model";
import InfiniteTable, {
  ExpandKey,
  InfiniteCustomizeParams,
} from "../../../redux/actions/application/application-infinite-table-actions";
import { useDatabus, useTypedSelector } from "../../../redux/hooks";
import { InfineTableCache } from "../../../redux/reducers/application/ApplicationInterface";
import { AppState } from "../../../redux/store";
import { MatchQuery } from "../../../services/DataService";
import { usePrevious } from "../../../utils/Hooks";
import BFVirtualizedTable, {
  BFVirtualizedTablePosition,
  BFVirtualizedTableProps,
  ColumnConfig,
} from "./../../../modules/abstract-ui/data/virtualized-table/BFVirtualizedTable";
import "./VirtualizedTable.scss";

export const DATABUS_OPEN_EXPORT_DIALOG_INFINITE_TABLE =
  "DATABUS_OPEN_EXPORT_DIALOG";

type VirtualizedTableDataUrl = {
  dataUrl: string;
  assetType?: string;
};
type VirtualizedTableAssetType = {
  assetType: string;
  dataUrl?: string;
};

export type SelectionAggregation =
  | {
      type: "date";
      label: string;
      selector: ((node: any) => Date) | string;
      aggregationType: "min" | "max" | "range";
      format?: "date" | ((node: any) => any);
    }
  | {
      type: "number";
      label: string;
      selector: ((node: any) => number) | string;
      aggregationType: "sum" | "avg" | "max" | "min";
      format?:
        | "currency"
        | "string"
        | "number"
        | "percent"
        | ((node: any) => any);
    };

export type VirtualizedTableProps = (
  | VirtualizedTableDataUrl
  | VirtualizedTableAssetType
) & {
  // Props to pass through to virtualizedTable
  selectionAggregations?: SelectionAggregation[];
  hideHeader?: boolean;
  columns: { [columnId: string]: ColumnConfig };
  onRowClick?: (
    node: any,
    index: number,
    ev: MouseEvent<HTMLDivElement>
  ) => void;
  onRowDoubleClick?: (
    node: any,
    index: number,
    ev: MouseEvent<HTMLDivElement>,
    params?: any
  ) => void;
  onRowDrop?: (
    ev: React.DragEvent<HTMLDivElement>,
    node: any,
    params: any
  ) => void;
  convertData?: (node: any) => any;
  params?: any;
  paramsConverter?: (data: any, index: number, params: any) => any;
  calculateSize?: (
    data: any,
    index: number,
    params: any
  ) => number | [number, number] | undefined;
  estimatedSize?: number;
  hover?: boolean;
  overscan?: number;
  scrollingDelay?: number;
  identifierSelector?: string;
  persistCustomizations?: boolean;
  emptyText?: string;
  ignoreRowBorder?: boolean;
  striped?: boolean;
  ignoreColumnBorder?: boolean;
  rowClass?: (
    node: any,
    index: number,
    params?: any,
    position?: BFVirtualizedTablePosition
  ) => string;
  rowOverlay?: (
    position: BFVirtualizedTablePosition,
    node: any,
    index: number,
    params?: any
  ) => React.ReactNode;

  subRowRender?: (
    position: BFVirtualizedTablePosition,
    node: any,
    index: number,
    params?: any
  ) => React.ReactNode;
  selectedIds?: string[];
  exportTypes?: ExportType[];
  exportOptions?: TableExcelExportOptions;
  // props needed for data handling
  selection?: "none" | "single" | "multiple" | "multiple-checkbox";
  identifier: string;
  customizationOverwriteIdentifier?: string;
  limitPerRequest?: number;
  additionalMatchQuery?: MatchQuery;
  keepSelectionOnFilterChange?: boolean;
  loadDelay?: number;
  asPost?: boolean;
  overwriteSkip?: (cache: InfineTableCache) => number;
  reloadOnMount?: boolean;
  cleanupOnUnmount?: boolean;
  //Fixme - what to do here?
  initialVisibleSort?: TableSort;
  hiddenSort?: TableSort[];
  expandKeys?: (ExpandKey | string)[];
  filterDataFc?: (data: any) => boolean;
  deselectOnBlur?: boolean;

  customizeParams?: InfiniteCustomizeParams;
};

type SelectionOpt = {
  lastIndex?: number;
  temporaryIndex?: number;
};
const VirtualizedTable = ({
  identifier,
  dataUrl,
  assetType,
  limitPerRequest,
  additionalMatchQuery,
  keepSelectionOnFilterChange,
  asPost,
  loadDelay,
  initialVisibleSort,
  hiddenSort,
  reloadOnMount,
  overwriteSkip,
  selection,
  cleanupOnUnmount,
  expandKeys,
  exportTypes,
  exportOptions,
  filterDataFc,
  customizeParams,
  convertData,
  deselectOnBlur,
  ...tableProps
}: VirtualizedTableProps) => {
  const [selectionOpt, setSelectionOpt] = useState<SelectionOpt>({
    lastIndex: null,
    temporaryIndex: null,
  });

  const scrollPosition = useRef({ x: 0, y: 0 });
  const dispatch = useDispatch();
  const tableCache = useTypedSelector(
    (state: AppState) => state.application.infiniteTables[identifier]
  );
  const useDataUrl =
    dataUrl || `/api/asset/${asPost ? "list/" : ""}${assetType}`;
  const [exportDialog, setExportDialog] = useState<boolean>(false);

  const [forceRerender, setForceRerender] = useState<boolean>(false);

  useEffect(() => {
    if (forceRerender) {
      setForceRerender(false);
    }
  }, [forceRerender]);

  useDatabus("TABLE_FORCE_RERENDER", (databusProps) => {
    if (identifier === databusProps.identifier) {
      setForceRerender(true);
    }
  });
  useDatabus(DATABUS_OPEN_EXPORT_DIALOG_INFINITE_TABLE, (databusProps) => {
    if (identifier === databusProps.identifier) {
      setExportDialog(true);
    }
  });

  useEffect(() => {
    if (reloadOnMount && tableCache) {
      dispatch(InfiniteTable.reloadData(identifier, true));
    }
    return () => {
      // cleanup
      if (cleanupOnUnmount) {
        dispatch(InfiniteTable.clearTable(identifier));
      } else {
        dispatch(
          InfiniteTable.setTableScroll(
            identifier,
            scrollPosition.current.x,
            scrollPosition.current.y
          )
        );
      }
    };
  }, []);

  useEffect(() => {
    if (!tableCache) {
      dispatch(
        InfiniteTable.initialize(identifier, {
          url: useDataUrl,
          limitPerRequest: limitPerRequest,
          additionalMatchQuery: additionalMatchQuery,
          asPost: asPost,
          loadDelay: loadDelay,
          visibleSort: initialVisibleSort,
          hiddenSort: hiddenSort,
          customizeParams: customizeParams,
          expandKeys: expandKeys?.map((entry) =>
            typeof entry === "string" ? { key: entry } : entry
          ),
        })
      );
    }
  }, [tableCache]);

  useEffect(() => {
    if (tableCache?.reload) {
      // reload requested from other sources - directly reload without delay
      dispatch(InfiniteTable.reloadData(identifier, true));
    }
  }, [tableCache]);

  const previousAdditionalMatchQuery = usePrevious(additionalMatchQuery);

  useEffect(() => {
    if (
      additionalMatchQuery === undefined ||
      !_.isEqual(additionalMatchQuery, previousAdditionalMatchQuery)
    ) {
      // reload with new match query - don't ignore delay
      dispatch(
        InfiniteTable.setMatchQuery(identifier, additionalMatchQuery, false)
      );
      // reset selection on matchquery change
      if (
        !isDefined(keepSelectionOnFilterChange) ||
        !keepSelectionOnFilterChange
      ) {
        dispatch(InfiniteTable.setTableSelection(identifier, []));
      }
    }
  }, [additionalMatchQuery]);

  useEffect(() => {
    if (!_.isEqual(customizeParams, previousAdditionalCustomizeParams)) {
      // reload with new match query - don't ignore delay
      dispatch(
        InfiniteTable.setCustomizeParams(identifier, customizeParams, false)
      );
      if (
        !isDefined(keepSelectionOnFilterChange) ||
        !keepSelectionOnFilterChange
      ) {
        dispatch(InfiniteTable.setTableSelection(identifier, []));
      }
    }
  }, [customizeParams]);

  const previousAdditionalCustomizeParams = usePrevious(customizeParams);

  const onScrollEnd = () => {
    // dispatch action to load next data - action will check itself if there is more data available
    dispatch(
      InfiniteTable.loadNextData(identifier, overwriteSkip?.(tableCache))
    );
  };
  const data = (
    forceRerender
      ? []
      : filterDataFc
      ? tableCache?.data.filter((e) => filterDataFc(e))
      : tableCache?.data || []
  ).map((node) => (convertData ? convertData(node) : node));

  const addProps: Partial<BFVirtualizedTableProps> = {};

  if (selection === "multiple-checkbox") {
    tableProps.params = {
      ...(tableProps.params || {}),
      selection: tableCache?.selection || [],
    };
    tableProps.columns = {
      checkboxSelector: {
        label: "",
        sortable: false,
        resizable: false,
        fixedWidth: 40,
        disableCustomize: true,
        fixed: "left",
        render: (node, _, params) => (
          <BFCheckbox
            className="checkbox-multiple-selection"
            checked={params.selection?.includes(node._id)}
            readOnly
          />
        ),
      },
      ...tableProps.columns,
    };
  }

  if ((selection || "none") !== "none") {
    addProps.selectedIds = tableCache?.selection || [];

    if (selection === "single") {
      addProps.onRowClick = (node, index, ev) => {
        if (tableProps.onRowClick) {
          tableProps.onRowClick(node, index, ev);
        }
        dispatch(InfiniteTable.setTableSelection(identifier, [node._id]));
      };
    } else if (selection === "multiple" || selection === "multiple-checkbox") {
      addProps.onRowClick = (node, index, ev) => {
        const currentSelection = tableCache.selection || [];
        if (tableProps.onRowClick) {
          tableProps.onRowClick(node, index, ev);
        }
        const isSelectionCheckboxClick =
          (ev.target as HTMLInputElement)?.type === "checkbox" &&
          (ev.target as HTMLElement).closest(".checkbox-multiple-selection");
        const cmdModifier =
          (DeviceUtils.isApple() ? ev.metaKey : ev.ctrlKey) ||
          isSelectionCheckboxClick;
        const shiftModifier = ev.shiftKey;

        if (!cmdModifier && !shiftModifier) {
          dispatch(InfiniteTable.setTableSelection(identifier, [node._id]));
          setSelectionOpt({
            lastIndex: index,
            temporaryIndex: null,
          });
        } else {
          if (cmdModifier) {
            const nodeSelected = currentSelection.includes(node._id);
            if (nodeSelected) {
              dispatch(
                InfiniteTable.setTableSelection(
                  identifier,
                  currentSelection.filter((id) => id !== node._id)
                )
              );
            } else {
              dispatch(
                InfiniteTable.setTableSelection(identifier, [
                  ...currentSelection,
                  node._id,
                ])
              );
            }
            setSelectionOpt({
              lastIndex: index,
              temporaryIndex: null,
            });
          } else if (shiftModifier) {
            const hasLastIndex = isDefined(selectionOpt?.lastIndex);
            if (hasLastIndex) {
              let tmpSelections = [...currentSelection];
              const temporaryIndex = selectionOpt?.temporaryIndex;
              if (isDefined(temporaryIndex)) {
                const rangeIndexes = _.range(
                  Math.min(temporaryIndex, selectionOpt.lastIndex),
                  Math.max(temporaryIndex, selectionOpt.lastIndex) + 1
                );
                //deselect all in range
                tmpSelections = tmpSelections.filter(
                  (id, index) =>
                    !rangeIndexes.includes(data.findIndex((e) => e._id === id))
                );
              }

              const rangeIndexes = _.range(
                Math.min(selectionOpt.lastIndex, index),
                Math.max(selectionOpt.lastIndex, index) + 1
              );
              //select all in range
              tmpSelections = _.uniq([
                ...tmpSelections,
                ...rangeIndexes.map(
                  (i) => data.find((e, index) => index === i)?._id
                ),
              ]);

              dispatch(
                InfiniteTable.setTableSelection(identifier, tmpSelections)
              );
              setSelectionOpt({
                lastIndex: selectionOpt.lastIndex,
                temporaryIndex: index,
              });
            } else {
              dispatch(InfiniteTable.setTableSelection(identifier, [node._id]));
              setSelectionOpt({
                lastIndex: index,
                temporaryIndex: null,
              });
            }
          }
        }
      };
      // // TODO - implement
      // throw new Error("not implemented");
    }
  }

  // if (forceRerender) {
  //   return null;
  // }
  return (
    <>
      {exportDialog && (
        <ExportDialog
          onClose={() => setExportDialog(false)}
          onSuccess={() => {
            setExportDialog(false);
          }}
          exportFileName={exportOptions?.filename}
          exportTypes={exportTypes}
          exportOptions={exportOptions}
          exportConfig={Object.entries(tableProps.columns)
            .filter(([key, value]) => value?.export)
            .map(([key, value]) => ({
              id: key,
              ...(typeof value.export === "function"
                ? value.export(
                    tableProps.paramsConverter
                      ? tableProps.paramsConverter({}, null, tableProps.params)
                      : tableProps.params
                  )
                : value.export),
            }))}
          gatherDataFC={async () => {
            const data = (await dispatch(
              InfiniteTable.loadNextDataUntilEnd(
                identifier,
                overwriteSkip?.(tableCache)
              )
            )) as any;
            return data;
          }}
        />
      )}
      <BFVirtualizedTable
        {...tableProps}
        {...addProps}
        onBlur={() => {
          if (deselectOnBlur) {
            dispatch(InfiniteTable.setTableSelection(identifier, []));
          }
        }}
        identifier={identifier}
        insetShadow
        loading={tableCache?.loading}
        data={data}
        onScrollEnd={onScrollEnd}
        sort={tableCache?.visibleSort}
        selectCount={tableCache?.selection?.length}
        initScroll={tableCache?.scroll}
        onScroll={(scrollX, scrollY) => {
          scrollPosition.current = {
            x: scrollX,
            y: scrollY,
          };
        }}
        onKeyEnter={() => {
          if (tableCache?.selection?.length === 1) {
            const selected = tableCache?.selection[0];
            const index = data.findIndex((e) => e._id === selected);
            tableProps.onRowDoubleClick?.(data[index], index, null);
          }
        }}
        onKeyArrowDown={(shift) => {
          if (selection == "none") return;
          if (tableCache?.selection?.length === 1) {
            const selected = tableCache?.selection[0];
            const index = data.findIndex((e) => e._id === selected);
            const newIndex = Math.min(index + 1, data.length - 1);
            dispatch(
              InfiniteTable.setTableSelection(identifier, [data[newIndex]._id])
            );
          } else if (tableCache?.selection?.length === 0) {
            dispatch(
              InfiniteTable.setTableSelection(identifier, [data[0]._id])
            );
          }
        }}
        onKeyArrowUp={(shift) => {
          if (selection == "none") return;
          if (tableCache?.selection?.length === 1) {
            const selected = tableCache?.selection[0];
            const index = data.findIndex((e) => e._id === selected);
            const newIndex = Math.max(index - 1, 0);
            dispatch(
              InfiniteTable.setTableSelection(identifier, [data[newIndex]._id])
            );
          } else {
            dispatch(
              InfiniteTable.setTableSelection(identifier, [
                data[data.length - 1]._id,
              ])
            );
          }
        }}
        onSort={(sort) =>
          dispatch(InfiniteTable.setVisibleSort(identifier, sort))
        }
      />
    </>
  );
};

VirtualizedTable.defaultProps = {
  limitPerRequest: 30,
  persistCustomizations: true,
} as Partial<VirtualizedTableProps>;

export default VirtualizedTable;
