import {
  Content,
  PageOrientation,
  TDocumentDefinitions,
} from "pdfmake/interfaces";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { forwardRef, memo, useImperativeHandle, useState } from "react";
import PDFViewer from "../PDFViewer/PDFViewer";
import { useDebouncedEffect } from "@/utils/Hooks";
import ObjectExportService from "@/apps/tatar/objectsApp/views/export/ObjectExportService";
import { PDFDocumentDescription } from "./services/types/pdfConfigNodeTypes";
import { parseDynamicContent } from "./services/PDFConfigParser";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

type RealTimePDFViewerProps = {
  config: PDFDocumentDescription;
};

const createDocument = async (
  config: TDocumentDefinitions
): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      pdfMake.tableLayouts = ObjectExportService.getPdfTableLayouts();

      pdfMake.createPdf(config).getDataUrl((url) => {
        resolve(url);
      });
    } catch (error) {
      reject(error);
    }
  });
};

const RealTimePDFViewer = memo(
  forwardRef<{ url: string | null }, RealTimePDFViewerProps>(
    ({ config }, ref) => {
      const [url, setUrl] = useState("");

      useDebouncedEffect(() => {
        const { header, footer, content, page, ...props } = config;

        const header_ = config.header
          ? parseDynamicContent(config.header)
          : undefined;
        const footer_ = config.footer
          ? parseDynamicContent(config.footer)
          : undefined;

        const content_ = structuredClone(config.content);
        const page_ = page ? structuredClone(page) : undefined;

        createDocument({
          header: header_,
          content: content_ as Content,
          footer: footer_,
          pageMargins: page_?.margin,
          pageOrientation: page?.pageOrientation as PageOrientation,
          ...props,
        }).then((url) => setUrl(url));
      }, [config]);

      useImperativeHandle(ref, () => ({
        url,
      }));

      return (
        <div style={{ height: "70vh", overflow: "scroll" }}>
          <PDFViewer filename="Preview" url={url} />
        </div>
      );
    }
  )
);

export { RealTimePDFViewer };
