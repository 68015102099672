import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import { withPDFBlock } from "../../PDFBlock";
import { useState } from "react";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";

import { PDFBlockFooter } from "../../PDFBlockFooter";
import { ObjectGalleryConfig } from "@/components/PDFConstructor/services/types/pdfConfigObjectBlockTypes";
import i18n from "@/i18n";

import { PDFObjectGalleryPickList } from "./PDFObjectGalleryPickList/PDFObjectGalleryPickList";
import { useBlockChanges } from "../../PDFBlockHooks";
import { PDFCommonInput } from "../../Common/PDFCommonInput";

const PDFObjectGalleryBlock = withPDFBlock<ObjectGalleryConfig>(
  ({ value, parentId }) => {
    const [{ asset }, { deleteBlock, saveBlock }] = usePDFConstructor();
    const [form, setForm] = useState({
      ...value,
      assetId: asset._id,
    });

    useBlockChanges(value, form);

    return (
      <div>
        <PDFObjectGalleryPickList
          objectId={form.assetId}
          selected={form.images}
          onSelect={(selected) => {
            setForm((value) => ({
              ...value,
              images: selected,
            }));
          }}
        />

        <BFInput
          value={form.columns}
          onChange={(value) =>
            setForm((form) => ({
              ...form,
              columns: Number(value),
            }))
          }
        />

        <PDFCommonInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          // due to debounce logic, fast changes might not be added in the request for saving
          onSave={() => saveBlock({ ...value, ...form }, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t(
      "Component.PDFConstructor.Block.Inputs.ObjectGallery",
      "Objektgalerie"
    )
);

export { PDFObjectGalleryBlock };
