import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";
import { withPDFBlock } from "../../PDFBlock";

import { useState } from "react";

import { PDFBlockFooter } from "../../PDFBlockFooter";

import {
  OBJECT_LOAN_TABLE_FIELDS,
  OBJECT_LOAN_TABLE_FILTERS,
  OBJECT_LOAN_TABLE_VALUES,
} from "./PDFObjectInputConstants";
import { useBlockChanges } from "../../PDFBlockHooks";
import i18n from "@/i18n";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import {
  ObjectLoanTableConfig,
  ObjectLoanTableFields,
  ObjectLoanTableFilter,
} from "@/components/PDFConstructor/services/types/pdfConfigObjectBlockTypes";
import { PDFTableFooterInput } from "./Components/PDFTableFooterInput";
import LabeledInput from "@/modules/abstract-ui/forms/LabeledInput";
import EZTags from "@/modules/ez-form/form-elements/ez-tags/EZTags";

const PDFObjectLoanTableBlock = withPDFBlock<ObjectLoanTableConfig>(
  ({ value, parentId }) => {
    const [{ asset }, { deleteBlock, saveBlock }] = usePDFConstructor();
    const [form, setForm] = useState({
      ...value,
      assetId: asset._id,
    });

    useBlockChanges(value, form);

    return (
      <div>
        <BFSelect
          label={i18n.t("Component.PDFConstructor.Filter", "Filter")}
          data={OBJECT_LOAN_TABLE_FILTERS.map((option) => ({
            ...option,
            label: option.label(),
          }))}
          value={form.filter}
          onChange={(value) =>
            setForm((form) => {
              return {
                ...form,
                filter: value as ObjectLoanTableFilter,
              };
            })
          }
          cleanable={false}
        />

        <LabeledInput
          label={i18n.t("Component.PDFConstructor.Fields", "Felder")}
        >
          <EZTags
            data={OBJECT_LOAN_TABLE_VALUES.map((option) => ({
              ...option,
              label: option.label(),
            }))}
            value={form.fields}
            onChange={(values) =>
              setForm((form) => {
                const fields = Object.keys(OBJECT_LOAN_TABLE_FIELDS).filter(
                  (key) => values.includes(key)
                );

                return {
                  ...form,
                  fields: fields as ObjectLoanTableFields[],
                };
              })
            }
          />
        </LabeledInput>

        <PDFTableFooterInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
          columnsAmount={OBJECT_LOAN_TABLE_VALUES.length}
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock(form, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t(
      "Component.PDFConstructor.Block.Inputs.PortfolioLoanTable",
      "Portfolio-Darlehenstabelle"
    )
);

export { PDFObjectLoanTableBlock };
