import { FC, memo, useCallback, useMemo, useState } from "react";
import { EAppTheme, IAppThemeContext, IAppThemeProviderProps } from "./types";
import { generateThemeClass, initAppTheme } from "./helpers";
import { APP_THEME_STORAGE_KEY } from "./config";
import { AppThemeContext } from "./context";

const AppThemeProvider: FC<IAppThemeProviderProps> = ({ children }) => {
  // ! state
  const [currentTheme, setCurrentTheme] = useState<EAppTheme>(initAppTheme());

  // ! helpers
  const setAppTheme = useCallback(
    (themeValue: EAppTheme) => {
      const newThemeClass = generateThemeClass(themeValue);

      const hasSameTheme = document.body.classList.contains(newThemeClass);
      if (hasSameTheme) return;

      const currentThemeClass = generateThemeClass(currentTheme);

      document.body.classList.remove(currentThemeClass);
      document.body.classList.add(newThemeClass);

      localStorage.setItem(APP_THEME_STORAGE_KEY, themeValue);
      setCurrentTheme(themeValue);
    },
    [currentTheme]
  );

  // ! provider value
  const providerValue = useMemo<IAppThemeContext>(
    () => ({
      currentTheme,
      setAppTheme,
    }),
    [currentTheme, setAppTheme]
  );

  // ! render
  return (
    <AppThemeContext.Provider value={providerValue}>
      {children}
    </AppThemeContext.Provider>
  );
};

export default memo(AppThemeProvider);
