import i18n from "@/i18n";
import DataBus from "@/services/DataBus";
import { useState } from "react";
import { PlacementAll } from "../../common/Placements";
import BFOverlay from "../whisper/BFOverlay";
import BFButton from "./BFButton";
// import "./BFConfirmButton.scss";

interface BFConfirmButtonProps {
  appearance?:
    | "default"
    | "primary"
    | "link"
    | "jump"
    | "clear"
    | "outline"
    | "outline-white"
    | "clear-on-white"
    | "clear-highlight"
    | "transparent-dark"
    | "transparent-light"
    | "debug";
  disabled?: boolean;
  noPadding?: boolean;
  children: JSX.Element;

  placement?: PlacementAll;

  confirmDescription?: string;
  confirmButtonText?: string;
  onConfirm: () => Promise<void> | void;
}
const BFConfirmButton = (props: BFConfirmButtonProps) => {
  const [identifier] = useState(_.uniqueId("confirm-button-"));
  const closeOverlay = () => {
    DataBus.emit("WHISPER", {
      identifier,
      type: "CLOSE",
    });
  };
  return (
    <BFOverlay
      trigger="click"
      identifier={identifier}
      placement={props.placement}
      speaker={
        <div className={`padding-10 bf-confirm-button-overlay`}>
          <div className={`description`}>
            {props.confirmDescription ||
              i18n.t("Global.Confirmation.Description", "Sind Sie sicher?")}
          </div>
          <div className={`__flex __justify-end padding-top-10`}>
            <BFButton
              size="xs"
              appearance="outline"
              onClick={() => {
                closeOverlay();
              }}
            >
              {i18n.t("Global.Buttons.cancel", "Abbrechen")}
            </BFButton>
            <BFButton
              size="xs"
              appearance="primary"
              onClick={async () => {
                await props.onConfirm();
                closeOverlay();
              }}
            >
              {props.confirmButtonText ||
                i18n.t("Global.Buttons.confirm", "Bestätigen")}
            </BFButton>
          </div>
        </div>
      }
    >
      <div>
        <BFButton
          appearance={props.appearance}
          disabled={props.disabled}
          noPadding={props.noPadding}
          // onClick={props.onConfirm}
        >
          {props.children}
        </BFButton>
      </div>
    </BFOverlay>
  );
};

export default BFConfirmButton;
