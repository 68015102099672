import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";
import { withPDFBlock } from "../../PDFBlock";

import {
  ObjectTableActivityFilter,
  ObjectMaintenanceContractsTableConfig,
  ObjectMaintenanceContractsFields,
} from "@/components/PDFConstructor/services/types/pdfConfigObjectBlockTypes";

import { useState } from "react";

import { PDFBlockFooter } from "../../PDFBlockFooter";

import {
  OBJECT_DAMAGE_CLAIM_FILTER,
  OBJECT_MAINTENANCE_CONTRACTS,
  OBJECT_MAINTENANCE_CONTRACTS_FIELDS,
} from "./PDFObjectInputConstants";
import { useBlockChanges } from "../../PDFBlockHooks";
import i18n from "@/i18n";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import { PDFCommonInput } from "../../Common/PDFCommonInput";
import { PDFTableFooterInput } from "./Components/PDFTableFooterInput";
import LabeledInput from "@/modules/abstract-ui/forms/LabeledInput";
import EZTags from "@/modules/ez-form/form-elements/ez-tags/EZTags";

const PDFObjectMaintenanceContractsBlock =
  withPDFBlock<ObjectMaintenanceContractsTableConfig>(
    ({ value, parentId }) => {
      const [{ asset }, { deleteBlock, saveBlock }] = usePDFConstructor();
      const [form, setForm] = useState({ ...value, assetId: asset._id });

      useBlockChanges(value, form);

      return (
        <div>
          <BFSelect
            label={i18n.t("Component.PDFConstructor.Filter", "Filter")}
            data={OBJECT_DAMAGE_CLAIM_FILTER.map((option) => ({
              ...option,
              label: option.label(),
            }))}
            value={form.filter}
            onChange={(value) =>
              setForm((form) => {
                return {
                  ...form,
                  filter: value as ObjectTableActivityFilter,
                };
              })
            }
            cleanable={false}
          />

          <LabeledInput
            label={i18n.t("Component.PDFConstructor.Fields", "Felder")}
          >
            <EZTags
              data={OBJECT_MAINTENANCE_CONTRACTS.map((option) => ({
                ...option,
                label: option.label(),
              }))}
              value={form.fields}
              onChange={(values) =>
                setForm((form) => {
                  const fields = Object.keys(
                    OBJECT_MAINTENANCE_CONTRACTS_FIELDS
                  ).filter((key) => values.includes(key));

                  return {
                    ...form,
                    fields: fields as ObjectMaintenanceContractsFields[],
                  };
                })
              }
            />
          </LabeledInput>

          <PDFCommonInput
            value={form}
            onChange={(key, value) =>
              setForm((form) => ({
                ...form,
                [key]: value,
              }))
            }
          />

          <PDFTableFooterInput
            value={form}
            onChange={(key, value) =>
              setForm((form) => ({
                ...form,
                [key]: value,
              }))
            }
            columnsAmount={OBJECT_MAINTENANCE_CONTRACTS.length}
          />

          <PDFBlockFooter
            isEmpty={value.isEmpty}
            onSave={() => saveBlock(form, parentId)}
            onDelete={() => deleteBlock(value)}
          />
        </div>
      );
    },
    () =>
      i18n.t(
        "Component.PDFConstructor.Block.Inputs.MaintenanceContractsTable",
        "Wartungsverträge-Tabelle"
      )
  );

export { PDFObjectMaintenanceContractsBlock };
