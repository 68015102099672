import CacheService from "@/services/CacheService";
import ServiceUtils from "@/services/ServiceUtils";
import { HTTP } from "@/utils/Http";
import { ReplacementRequest } from "./UserReplacement.interface";

class UserReplacementServiceClass {
  async acceptReplacementRequest(id: string) {
    return ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/api/replacement/${id}/accept`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async declineReplacementRequest(id: string) {
    return ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/api/replacement/${id}/decline`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async cancelReplacementRequest(id: string) {
    return ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/api/replacement/${id}/cancel`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async createReplacementRequest(values: ReplacementRequest) {
    return ServiceUtils.toastError(async () => {
      await HTTP.post({
        url: `/api/replacement/requestReplacement`,
        bodyParams: {
          from: values.from,
          to: values.to,
          message: values.message,
          replacementUser: values.replacementUser,
        },
        target: "EMPTY",
      });
    });
  }
}
const UserReplacementService = new UserReplacementServiceClass();
export default UserReplacementService;
