import { EAppTheme } from "@/context/AppThemeContext";
import i18n from "@/i18n";
import { AppThemesHeaderConfigItem } from "./types";

export const APP_THEMES_HEADER_CONFIG = () =>
  [
    {
      key: EAppTheme.LIGHT,
      // icon: { type: "light", data: "brightness" },
      labelTranslationKey: i18n.t("Header.theme.light", "Hell"),
    },
    {
      key: EAppTheme.DARK,
      // icon: { type: "light", data: "astronomy-moon" },
      labelTranslationKey: i18n.t("Header.theme.dark", "Dunkel"),
    },
  ] as AppThemesHeaderConfigItem[];
