import i18n from "@/i18n";
import { FrameConfig } from "../../services/types/pdfConfigBlockTypes";
import { withPDFBlock } from "../PDFBlock";
import { PDFFrameLayout } from "./Layout/PDFFrameLayout";

const PDFFooterInput = withPDFBlock<FrameConfig>(
  (props) => <PDFFrameLayout {...props} />,
  () => i18n.t("Component.PDFConstructor.Block.Inputs.FooterInput", "Fußzeile")
);

export { PDFFooterInput };
