import ModalManager from "@/components/ModalComponent/ModalManager";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { useTypedSelector } from "@/redux/hooks";
import DataBusDefaults from "@/services/DataBusDefaults";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import { Route, useRouteMatch } from "react-router-dom";
import { Accordion, Loader } from "rsuite";
import { useActivityConstants } from "../../../../ActivityHooks";
import { APActivity } from "../../../../ActivityInterfaces";
import { APOffer, APOfferStatus } from "../APOffer.Interface";
import APOfferEntry from "./APOfferEntry";
import APOfferForm from "./APOfferForm";
import "./APOfferList.scss";

interface APOfferListProps {
  activity: APActivity;
}
const APOfferList = (props: APOfferListProps) => {
  const match = useRouteMatch();
  const constants = useActivityConstants();
  return (
    <Route
      path={`${match.path}/:status?`}
      render={(matchProps) => (
        <div className={classNames(`ap-offer-list`)}>
          <div className={`list-header`}>
            <div className={`fill`} />
            <BFButton
              onClick={() => {
                ModalManager.show({
                  noPadding: true,
                  size: "sm",
                  content: (state, setState, close) => (
                    <APOfferForm
                      constants={constants}
                      activityId={props.activity._id}
                      onClose={close}
                      onSuccess={() => {
                        DataBusDefaults.reload({
                          identifiers: [
                            `new`,
                            "accepted",
                            "declined",
                            "archived",
                          ].map((id) => `offer-list-${id}`),
                        });
                      }}
                    />
                  ),
                });
              }}
            >
              {i18n.t("apTemplate:OfferList.CreateNew", "Anlegen")}
            </BFButton>
          </div>
          <div className={`list-accordion-wrapper __card`}>
            <Accordion
              activeKey={matchProps.match.params.status || "accepted"}
              bordered
              onSelect={(eventKey) => {
                DataBusDefaults.route({
                  route: `${match.url}/${eventKey}`,
                });
              }}
            >
              <APOfferAccordionPanel
                activity={props.activity}
                status={"accepted"}
              />
              <APOfferAccordionPanel activity={props.activity} status={"new"} />

              <APOfferAccordionPanel
                activity={props.activity}
                status={"declined"}
              />
              <APOfferAccordionPanel
                activity={props.activity}
                status={"archived"}
              />
            </Accordion>
          </div>
        </div>
      )}
    />
  );
};

export default APOfferList;

const APOfferAccordionPanel = (props: {
  activity: APActivity;
  status: APOfferStatus;
}) => {
  const constants = useActivityConstants();
  const tableIdentifier = `offer-list-${props.status}`;
  const tableData = useTypedSelector(
    (state) => state.application.infiniteTables[tableIdentifier]
  );

  //   i18n.t(`apTemplate:OfferList.Status.new`, "Neu");
  // i18n.t(`apTemplate:OfferList.Status.accepted`, "Angenommen");
  // i18n.t(`apTemplate:OfferList.Status.declined`, "Abgelehnt");
  // i18n.t(`apTemplate:OfferList.Status.archived`, "Archiviert");

  return (
    <Accordion.Panel
      header={
        <div className={`section-title`}>
          <span>
            {i18n.t(
              `apTemplate:OfferList.Status.${props.status}`,
              props.status
            )}
          </span>
          {(!tableData || tableData?.loading) === "general" ? (
            <Loader size="xs" />
          ) : (
            <span className={`count`}>({tableData?.totalCount})</span>
          )}
        </div>
      }
      eventKey={props.status}
    >
      <ListComponent
        assetType={AssetTypes.ActivityRelated.Offer}
        identifier={tableIdentifier}
        cleanupOnUnmount
        asPost
        additionalMatchQuery={MQ.and(
          MQ.eq("data.linkedActivity.assetId", props.activity._id),
          MQ.eq("data.status", props.status)
        )}
        render={(offer: APOffer) => (
          <div key={offer._id} className={`offer-entry`}>
            <APOfferEntry editable offer={offer} constants={constants} />
          </div>
        )}
      />
    </Accordion.Panel>
  );
};
