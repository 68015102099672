import FormStruct from "@/components/Form/FormStruct/FormStruct";
import { FV } from "@/components/Form/Validation/FormValidators";
import ModalManager from "@/components/ModalComponent/ModalManager";
import {
  PDFDocumentRect,
  PDFEditRect,
} from "@/components/PDFViewer/PDFViewerHooks";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFValueDisplay from "@/modules/abstract-ui/data/value-display/BFValueDisplay";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFDropdown from "@/modules/abstract-ui/general/Dropdown/BFDropdown";
import { DropdownItem } from "@/modules/abstract-ui/general/Dropdown/BFDropdownContent";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import StringUtils from "@/utils/StringUtils";
import { Field } from "react-final-form";
import CBRentalService from "../../../CBRentalService";
import { RentalAgreement } from "../../../TenantsInterfaces";
import {
  showEndAgreementModal,
  showRestartAgreementModal,
} from "./CBAgreementModals";
import "./CBAgreementRentDuration.scss";

export type RentDurationType = {
  moveIn: Date;
  agreementExpiration?: Date;
  moveOut?: Date;
};
interface CBAgreementRentDurationProps {
  marks: PDFEditRect[] | PDFDocumentRect[];
  data: RentDurationType;
  rentalAgreement?: RentalAgreement;
  editable?: boolean;
}
const CBAgreementRentDuration = (props: CBAgreementRentDurationProps) => {
  const object = props.rentalAgreement
    ? OrgaStruct.getObject(props.rentalAgreement.data.objectId)
    : null;
  return (
    <BFPDFMarkerSection
      marginBottom={20}
      readonly
      value={props.marks}
      title={
        <>
          <span className={`padding-right-6`}>
            {i18n.t(
              "cb:RentalAgreement.Form.Fields.rentDuration.title",
              "Vertragszeitraum"
            )}
          </span>
          {props.rentalAgreement && props.editable && (
            <span className={`cb-agreement-rent-duration customize-button`}>
              <BFDropdown
                label={i18n.t("Global.Buttons.Customize", "Anpassen")}
                items={[
                  {
                    type: "button",
                    text: i18n.t(
                      "Global.Buttons.changeAgreementStart",
                      "Mietbeginn ändern"
                    ),
                    onSelect: () => {
                      ModalManager.show({
                        size: "xs",
                        noPadding: true,
                        content: (state, setState, close) => {
                          return (
                            <CBAgreementBeginRentDurationForm
                              onClose={close}
                              rentalAgreement={props.rentalAgreement}
                            />
                          );
                        },
                      });
                    },
                  },
                  {
                    type: "button",
                    text: i18n.t(
                      "Global.Buttons.ChangeRentalExpiration",
                      "Mietvertrag bis ändern"
                    ),
                    onSelect: () => {
                      ModalManager.show({
                        size: "xs",
                        noPadding: true,
                        content: (state, setState, close) => {
                          return (
                            <CBAgreementEndRentDurationForm
                              onClose={close}
                              rentalAgreement={props.rentalAgreement}
                            />
                          );
                        },
                      });
                    },
                  },

                  ...((!props.rentalAgreement.data.moveOut
                    ? [
                        {
                          type: "button",
                          text: i18n.t(
                            "cb:RentalAgreement.Action.EndRentalAgreement",
                            "Mietvertrag beenden"
                          ),
                          disabled: object?.imported,
                          onSelect: () => {
                            showEndAgreementModal(props.rentalAgreement);
                          },
                        },
                      ]
                    : [
                        {
                          type: "button",
                          text: i18n.t(
                            "cb:RentalAgreement.ion.restartAgreement",
                            "Mietvertrag wieder aufnehmen"
                          ),
                          disabled: object?.imported,
                          onSelect: () => {
                            showRestartAgreementModal(props.rentalAgreement);
                          },
                        },
                      ]) as DropdownItem[]),
                ]}
                toggleAs={(toggleProps) => (
                  <BFButton
                    noPadding
                    inline
                    appearance="link"
                    size="sm"
                    {...toggleProps}
                  >
                    {i18n.t("Global.Buttons.Customize", "Anpassen")}
                  </BFButton>
                )}
              />
            </span>
            // <BFButtons
            //   noPadding
            //   inline
            //   appearance="link"
            //   onClick={() => {
            //     ModalManager.show({
            //       size: "xs",
            //       noPadding: true,
            //       content: (state, setState, close) => {
            //         return (
            //           <CBAgreementRentDurationForm
            //             onClose={close}
            //             rentalAgreement={props.rentalAgreement}
            //           />
            //         );
            //       },
            //     });
            //   }}
            //   size="sm"
            // >
            //   {i18n.t("Global.Buttons.Customize", "Anpassen")}
            // </BFButton>
          )}
        </>
      }
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.rentDuration.viewHint",
        "Informationen zum Mietzeitraum"
      )}
      identifier="rentDuration"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      <>
        <div className={`__flex-wrap `}>
          <BFValueDisplay
            label={i18n.t(
              "cb:RentalAgreement.Form.Fields.moveIn",
              "Mietbeginn"
            )}
            value={props.data.moveIn}
            formatter={StringUtils.formatDate}
          />
          <BFValueDisplay
            label={i18n.t(
              "cb:RentalAgreement.Form.Fields.agreementExpiration",
              "Vertrag bis"
            )}
            value={props.data.agreementExpiration}
            formatter={StringUtils.formatDate}
          />
          <BFValueDisplay
            label={i18n.t("cb:RentalAgreement.Form.Fields.moveOut", "Auszug")}
            value={props.data.moveOut}
            formatter={StringUtils.formatDate}
          />
        </div>
      </>
    </BFPDFMarkerSection>
  );
};

export default CBAgreementRentDuration;

const CBAgreementEndRentDurationForm = (props: {
  onClose: () => void;
  rentalAgreement: RentalAgreement;
}) => {
  return (
    <FormStruct
      initialValues={{
        agreementExpiration: props.rentalAgreement.data.agreementExpiration,
      }}
      onSubmit={async (values) => {
        await CBRentalService.submitRentalAgreementEnd(
          props.rentalAgreement._id,
          values.agreementExpiration
        );
        props.onClose();
      }}
      onAbort={props.onClose}
      submitText={i18n.t("Global.Buttons.save")}
      title={i18n.t(
        "cb:RentalAgreement.Form.Fields.rentDuration.title",
        "Vertragszeitraum"
      )}
      render={(formProps) => (
        <>
          <Field name={"agreementExpiration"}>
            {({ input, meta }) => (
              <BFDatefield
                label={i18n.t(
                  "cb:RentalAgreement.Form.Fields.agreementExpiration",
                  "Vertrag bis"
                )}
                {...input}
                {...FV.getValidation(meta)}
              />
            )}
          </Field>
        </>
      )}
    />
  );
};

const CBAgreementBeginRentDurationForm = (props: {
  onClose: () => void;
  rentalAgreement: RentalAgreement;
}) => {
  return (
    <FormStruct
      initialValues={{
        moveIn: props.rentalAgreement.data.moveIn,
      }}
      onSubmit={async (values) => {
        await CBRentalService.submitRentalAgreementBegin(
          props.rentalAgreement._id,
          values.moveIn
        );
        props.onClose();
      }}
      onAbort={props.onClose}
      submitText={i18n.t("Global.Buttons.save")}
      title={i18n.t(
        "cb:RentalAgreement.Form.Fields.rentDuration.title",
        "Vertragszeitraum"
      )}
      render={(formProps) => (
        <>
          <Field name={"moveIn"} validate={FV.required()}>
            {({ input, meta }) => (
              <BFDatefield
                preventNull
                label={i18n.t(
                  "cb:RentalAgreement.Form.Fields.moveIn",
                  "Mietbeginn"
                )}
                {...input}
                {...FV.getValidation(meta)}
              />
            )}
          </Field>
        </>
      )}
    />
  );
};
