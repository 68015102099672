import FileUtils from "@/utils/FileUtils";
import {
  BlockConfig,
  BlockType,
  Config,
  GroupBlockConfig,
} from "../services/types/pdfConfigBlockTypes";
import { PDFConstructorState } from "./PDFConstructorReducer";
import ObjectExportService from "@/apps/tatar/objectsApp/views/export/ObjectExportService";
import * as ECharts from "echarts";
import { generateId } from "../services/PDFConfigParser";
import {
  PDFDocumentDescriptionColumns,
  PDFDocumentDescriptionContent,
  PDFDocumentDescriptionDynamicContent,
  PDFDocumentDescriptionStack,
  PDFDocumentDescriptionStackingPlanConfig,
  PDFDocumentPageConfiguration,
} from "../services/types/pdfConfigNodeTypes";

export const findBlock = (
  id: BlockConfig["id"],
  config: Pick<PDFConstructorState, "content">
): GroupBlockConfig | null => {
  for (const block of config.content) {
    if (block.id === id) {
      return block as GroupBlockConfig;
    }

    if ("content" in block && Array.isArray(block.content)) {
      const target = findBlock(id, {
        content: block.content,
      });

      if (target) {
        return target;
      }
    }
  }

  return null;
};

export const containsBlock = (
  id: BlockConfig["id"],
  config: Pick<PDFConstructorState, "content">
): GroupBlockConfig | null => {
  for (const block of config.content) {
    if (block.id === id) {
      return config as GroupBlockConfig;
    }

    if ("content" in block && Array.isArray(block.content)) {
      const target = containsBlock(id, block as GroupBlockConfig);

      if (target) {
        return target;
      }
    }
  }

  return null;
};

export type ContentArray =
  | PDFDocumentDescriptionColumns["columns"]
  | PDFDocumentDescriptionStack["stack"];

export const extractContentArray = (
  block: PDFDocumentDescriptionContent,
  mutateCallback?: (value: ContentArray) => ContentArray
): ContentArray => {
  if ("columns" in block) {
    if (mutateCallback) {
      block.columns = mutateCallback(
        block.columns
      ) as PDFDocumentDescriptionColumns["columns"];
    }

    return block.columns;
  }

  if ("stack" in block) {
    if (mutateCallback) {
      block.stack = mutateCallback(
        block.stack
      ) as PDFDocumentDescriptionStack["stack"];
    }

    return block.stack;
  }

  if ("content" in block) {
    if (mutateCallback) {
      block.content = mutateCallback(
        block.stack
      ) as PDFDocumentDescriptionContent[];
    }

    return block.stack;
  }

  return [];
};

export const findContentNode = (
  id: number,
  contentArray:
    | PDFDocumentDescriptionContent[]
    | PDFDocumentDescriptionDynamicContent[]
): PDFDocumentDescriptionContent | null => {
  for (const contentNode of contentArray) {
    if (typeof contentNode !== "object") {
      continue;
    }

    if (Array.isArray(contentNode)) {
      return findContentNode(id, contentNode);
    }

    // contentNode.id might also exist in Anchors, but it is a string there
    if (
      "id" in contentNode &&
      typeof contentNode.id === "number" &&
      contentNode.id === id
    ) {
      return contentNode;
    }

    if ("stack" in contentNode && Array.isArray(contentNode.stack)) {
      const found = findContentNode(id, contentNode.stack);
      if (found) return found;
    }

    if ("columns" in contentNode && Array.isArray(contentNode.columns)) {
      for (const column of contentNode.columns) {
        if ("stack" in column && Array.isArray(column.stack)) {
          const found = findContentNode(id, column.stack);
          if (found) return found;
        }

        if ("id" in column && column.id === id) {
          return column;
        }
      }
    }

    if ("content" in contentNode && Array.isArray(contentNode.content)) {
      const found = findContentNode(id, contentNode.content);
      if (found) return found;
    }
  }

  return null;
};

export const findParentNode = (
  id: number,
  contentArray: PDFDocumentDescriptionContent[],
  parentNode?: PDFDocumentDescriptionContent
): PDFDocumentDescriptionContent | null => {
  for (const contentNode of contentArray) {
    if (typeof contentNode !== "object") {
      continue;
    }

    if (Array.isArray(contentNode)) {
      return findParentNode(id, contentNode, parentNode);
    }

    // contentNode.id might also exist in Anchors, but it is a string there
    if (
      "id" in contentNode &&
      typeof contentNode.id === "number" &&
      contentNode.id === id
    ) {
      return parentNode;
    }

    if ("stack" in contentNode && Array.isArray(contentNode.stack)) {
      const found = findParentNode(id, contentNode.stack, contentNode);
      if (found) return found;
    }

    if ("columns" in contentNode && Array.isArray(contentNode.columns)) {
      for (const column of contentNode.columns) {
        if ("stack" in column && Array.isArray(column.stack)) {
          const found = findParentNode(id, column.stack, column);
          if (found) return found;
        }

        if ("id" in column && column.id === id) {
          return contentNode;
        }
      }
    }
  }

  return null;
};

export const findTopLevelNodeByProperty = <T>(
  property: string,
  value: T,
  contentArray: PDFDocumentDescriptionContent[]
): PDFDocumentDescriptionContent | null => {
  for (const contentNode of contentArray) {
    if (property in contentNode && contentNode[property] === value) {
      return contentNode;
    }
  }

  return null;
};

export const isFrameNode = (
  type: BlockType,
  payload: PDFDocumentDescriptionContent | PDFDocumentDescriptionDynamicContent
): payload is PDFDocumentDescriptionDynamicContent => {
  return (type === "footer" || type === "header") && "content" in payload;
};

export const isPageConfigurationNode = (
  type: BlockType,
  payload:
    | PDFDocumentDescriptionContent
    | PDFDocumentDescriptionDynamicContent
    | PDFDocumentPageConfiguration
): payload is PDFDocumentPageConfiguration => {
  return (
    type === "page" &&
    "margin" in payload &&
    "pageOrientation" in payload &&
    Array.isArray(payload.margin)
  );
};

export const swapElements = <T extends { id?: number }[]>(
  array: T,
  elementId1: number,
  elementId2: number
): T => {
  const dragIndex = array.findIndex((block) => block.id === elementId1);
  const hoverIndex = array.findIndex((block) => block.id === elementId2);

  if (dragIndex === -1 || hoverIndex === -1) {
    return array;
  }

  const [movedCard] = array.splice(dragIndex, 1);
  array.splice(hoverIndex, 0, movedCard);

  return array;
};

const isURL = (value: string) => {
  try {
    new URL(value);
    return true;
  } catch {
    return false;
  }
};

export const loadImages = async (node: PDFDocumentDescriptionContent) => {
  if (node.columns) {
    return {
      ...node,
      columns: await Promise.all(node.columns.map((node) => loadImages(node))),
    };
  }

  if (node.stack) {
    return {
      ...node,
      stack: await Promise.all(node.stack.map((node) => loadImages(node))),
    };
  }

  if (node.table?.body) {
    return {
      ...node,
      table: {
        ...node.table,
        body: await Promise.all(
          node.table.body.map((row) => {
            return Promise.all(row.map((node) => loadImages(node)));
          })
        ),
      },
    };
  }

  if (node.image === undefined) {
    return node;
  }

  if (node.image.length === 0) {
    // pdfmake doesn't allow empty strings for an image (neither it supports undefined nor null). Text node comes higher in priority for pdfmake and gets recognised as text node instead of image node.
    // table rows have to be the same length, in cases when gallery block has unequal amount of images in a row, the empty cells get replaced with empty text
    node.text = "";

    return node;
  }

  if (!isURL(node.image)) {
    return node;
  }

  try {
    const result = await FileUtils.getBase64ImageFromUrl(node.image);
    node.image = result;
    return node;
  } catch (error) {
    // This represents a 1x1 PNG image and is very lightweight. You can use this as a default whenever an image fails to load.
    node.image =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjOHPmzH8ACDADZKt3GNsAAAAASUVORK5CYII=";
    return node;
  }
};

export const processStackingPlan = async (
  node: PDFDocumentDescriptionStackingPlanConfig
): Promise<PDFDocumentDescriptionStack> => {
  return {
    id: node.id,
    stack: (
      await ObjectExportService.getStackingPlanImage(
        node.config.mapId,
        node.config.stackingPlan,
        node.config.chart as ECharts.EChartsOption
      )
    ).map((image) => ({
      svg: image,
      width: node.config.width,
    })),
  };
};

export const assignPropertiesToConfigElements = (
  config: Omit<BlockConfig, "id">[],
  assetId: string
): Config => {
  return config.map((block) => {
    if ("content" in block && Array.isArray(block.content)) {
      return {
        ...block,
        id: generateId(),
        isTemplate: true,
        content: assignPropertiesToConfigElements(block.content, assetId),
      } as BlockConfig;
    }

    if ("assetId" in block) {
      return {
        ...block,
        id: generateId(),
        isTemplate: true,
        assetId,
      } as BlockConfig;
    }

    return {
      ...block,
      id: generateId(),
      isTemplate: true,
    } as BlockConfig;
  });
};
