import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { PDFTemplatesManager } from "./PDFTemplatesManager";
import ModalManager from "@/components/ModalComponent/ModalManager";
import { PDFTemplatesSaver } from "./PDFTemplatesSaver";
import { usePDFConstructor } from "../../context/PDFConstructorContext";
import i18n from "@/i18n";
import { useHTTPRequest } from "../../PDFConstructorHooks";
import { useCallback } from "react";
import { saveTemplate } from "../../actions/templateActions";

type PDFTemplatesTabProps = {
  refresh: () => void;
};

const PDFTemplatesTab: React.FC<PDFTemplatesTabProps> = ({ refresh }) => {
  const [{ state, language }] = usePDFConstructor();
  const { data, refresh: refreshTemplatesList } = useHTTPRequest<{
    templates: {
      _id: string;
      displayName: string;
    }[];
  }>({
    url: "/pdfConstructor/template",
  });

  const refreshData = useCallback(async () => {
    await Promise.all([refresh(), refreshTemplatesList()]);
  }, []);

  return (
    <div className="pdf-constructor--template-menu">
      <PDFTemplatesManager data={data} onChange={refreshData} />

      <BFButton
        style={{ flexGrow: 1 }}
        onClick={async () => {
          ModalManager.show({
            size: "fluid",
            noPadding: true,
            closable: true,
            content: (_, __, close) => (
              <PDFTemplatesSaver
                onSave={async (content: string) => {
                  await saveTemplate(content, state.content, language);
                  await refreshData();
                  close();
                }}
              />
            ),
          });
        }}
      >
        {i18n.t(
          "Component.PDFConstructor.Template.SaveCurrentPDFState",
          "Neue Vorlage aus aktuellem PDF erstellen"
        )}
      </BFButton>
    </div>
  );
};

export { PDFTemplatesTab };
