import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import {
  resolveTreeDataToEndNodes,
  TreeData,
} from "@/modules/abstract-ui/forms/checktree/BFCheckTree";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import { reloadStructData } from "@/redux/actions/struct/struct-actions";
import { openOrDownloadDocument } from "@/redux/actions/ui-config/ui-config-actions";
import { store } from "@/redux/store";
import CacheService from "@/services/CacheService";
import CDNService from "@/services/CDNService";
import LanguageService, { TranslatedLabel } from "@/services/LanguageService";
import ServiceUtils from "@/services/ServiceUtils";
import ArrayUtils from "@/utils/ArrayUtils";
import FileUtils from "@/utils/FileUtils";
import { hasValue } from "@/utils/Helpers";
import { HTTP } from "@/utils/Http";
import StringUtils from "@/utils/StringUtils";
import _ from "lodash";
import { AccountingData } from "./AccountingLoader";
import {
  AccountingBooking,
  AccountingBookingFormValue,
  AccountingBookingHistory,
  AccountingBookingType,
  AccountingExportDialogValue,
  AccountingIDMapping,
  AccountingSettingsFormValue,
  AccountType,
} from "./interfaces/account.interface";
import { getAccounting } from "./useAccounting";

export type AccountingSheetFormValue = {
  displayName: TranslatedLabel;
  entity: string;
  id: string;
  objectId: string | null;
  exportId: number | null;
};

export type AccountBookingEntry = {
  value: {
    amount: number;
    currency: string;
  };

  description: string;

  contraAccount: string;
  bookingType: "S" | "H";

  linkedCountraBooking: string | null;

  note: string | null;
};
export type AccountingBookingFormValueOld = {
  groupDisplayName: string;
  entity: string;
  date: Date;
  account: string;
  objectId: string | null;
  bookings: AccountBookingEntry[];

  // account: string; // x
  // contraAccount: string; // x
  // // entity: string; // x
  // bookingDate: Date;
  // performanceDate: Date | null;
  // invoiceDate: Date | null;
  // description: string;
  // bookingType: "S" | "H";
  // BU: number;

  // invoiceField1?: string;
  // invoiceField2?: string;
  // costCenter1?: string;
  // costCenter2?: string;
  // note: string;
};

class AccountingServiceClass {
  switchAccountingBookingType(accountingBookingType: AccountingBookingType) {
    return accountingBookingType === AccountingBookingType.HABEN
      ? AccountingBookingType.SOLL
      : AccountingBookingType.HABEN;
  }

  getTreenodesForAccountings(accountings: AccountingData[]) {
    const treeData: TreeData[] = [];

    Object.values(AccountType).forEach((accountingType) => {
      const accountingTypeTree: TreeData = {
        value: `${accountingType}`,
        label: this.getAccountTypeLabel(accountingType),
        children: [],
      };

      accountings.map((accounting) => {
        const entityId = accounting.accounting.data.entity;
        const entity = OrgaStruct.getEntity(entityId);

        if (entity) {
          const entityTreeData: TreeData = {
            value: `${accountingType}_${entityId}`,
            label: entity.displayName,
          };
          // accounting.accounting.data.accounts
          //   .filter((e) => e.accountType === accountingType)
          //   .forEach((account) => {
          //     const accountEntry: TreeData = {
          //       value: account.id,
          //       label: `${
          //         account.internalId
          //       } - ${LanguageService.translateLabel(account.displayName)}`,
          //     };

          //     entityTreeData.children.push(accountEntry);
          //   });

          accountingTypeTree.children.push(entityTreeData);
        }
      });
      treeData.push(accountingTypeTree);
      //   accounting.accounting.data.accounts
      //     .filter((e) => e.accountType === accountingType)
      //     .forEach((account) => {
      //       const accountEntry: TreeData = {
      //         value: account.id,
      //         label: `${account.internalId} - ${LanguageService.translateLabel(
      //           account.displayName
      //         )}`,
      //       };

      //       accountingTypeTree.children.push(accountEntry);
      //     });

      //   treeData.push(accountingTypeTree);

      //   const entityId = accounting.accounting.data.entity;
      //   const entity = OrgaStruct.getEntity(entityId);
      //   if (entity) {
      //     const entityTreeData: TreeData = {
      //       value: entityId,
      //       label: entity.displayName,
      //       children: [],
      //     };

      //     Object.values(AccountType).forEach((accountingType) => {
      //       const accountingTypeTree: TreeData = {
      //         value: `${accountingType}_${entityId}`,
      //         label: this.getAccountTypeLabel(accountingType),
      //         children: [],
      //       };

      //       accounting.accounting.data.accounts
      //         .filter((e) => e.accountType === accountingType)
      //         .forEach((account) => {
      //           const accountEntry: TreeData = {
      //             value: account.id,
      //             label: `${
      //               account.internalId
      //             } - ${LanguageService.translateLabel(account.displayName)}`,
      //           };
      //           accountingTypeTree.children.push(accountEntry);
      //         });

      //       entityTreeData.children.push(accountingTypeTree);
      //     });

      //     treeData.push(entityTreeData);
      //   }
    });

    return treeData;
  }

  async exportAccountingData(
    value: AccountingExportDialogValue,
    openAccountSettingsModal: (accounts: {
      [entityId: string]: string[];
    }) => void
  ) {
    return await ServiceUtils.toastError(
      async () => {
        let accounts = [];
        if (!value.useAllAccounts) {
          const accountings = value.entityIds.map((e) => getAccounting(e));
          const treenodes = this.getTreenodesForAccountings(
            accountings.map((e) => ({
              accounting: e.data,
              reload: null,
            }))
          );

          accounts = resolveTreeDataToEndNodes(value.accounts, treenodes).map(
            (e) => ({
              entity: e.split("_")[1],
              accountingType: e.split("_")[0],
            })
          );
        }

        const result = (await HTTP.post({
          target: "EMPTY",
          url: "/api/accounting/exportData",
          bodyParams: {
            entityIds: value.entityIds,
            useAllAccounts: value.useAllAccounts,
            accounts: accounts,
            fromBookingDate: value.fromBookingDate,
            toBookingDate: value.toBookingDate,
            exportType: value.exportType,
          },
        })) as AccountingBookingHistory;
        if (result?.data) {
          await this.downloadHistoryZip(result);
        } else {
          throw new Error("no result");
        }
      },
      async (err) => {
        if (err?.message === "no result") {
          return i18n.t(
            "Accounting.Form.Errors.NO_RESULT",
            "Es wurden keine Buchungen gefunden, die exportiert werden können."
          );
        }
        const specialHandlingCodes = ["EXTERNAL_ID_MISSING_FOR_ACCOUNTING"];

        if (specialHandlingCodes.includes(err.response?.data?.code)) {
          const errData = err.response.data;

          if (errData.code === "EXTERNAL_ID_MISSING_FOR_ACCOUNTING") {
            //todo show dialog with form
            const messageResult = errData.message;
            const entities = _.uniq(messageResult.map((e) => e.entity));
            const accounts = Object.fromEntries(
              entities.map((entity) => {
                return [
                  entity,
                  messageResult
                    .filter((e) => e.entity === entity)
                    .map((e) => e.id),
                ];
              })
            );

            openAccountSettingsModal(accounts);
            return `${i18n.t(
              "Accounting.Form.Errors.EXTERNAL_ID_MISSING_FOR_ACCOUNTING",
              "Es fehlen noch Fremdsystem Kontonummern für Konten die für den Export relevant sind. Bitte füllen Sie diese aus und starten Sie den Export erneut."
            )}`;
          }
        }
        return null;
      }
    );
  }

  async downloadHistoryZip(entry: AccountingBookingHistory) {
    const url = await CDNService.fetchCDNLink({
      assetType: AssetTypes.Accounting.ExportHistory,
      assetId: entry._id,
      assetField: "data.file",
      cdnId: "",
      hasFolderReadPermissions: true,
      fileKey: entry.data.file.key,
    });

    store.dispatch(
      openOrDownloadDocument(
        url,
        FileUtils.mimeToExt(entry.data.file.content_type),
        `${entry.data.exportType}_${StringUtils.formatDate(
          entry.data.dateFrom,
          "YYYY-MM-DD"
        )}_${StringUtils.formatDate(entry.data.dateTo, "YYYY-MM-DD")}.zip`
      )
    );
  }

  async createBooking(
    value: AccountingBookingFormValue,
    lqBookingId?: string,
    isManualSollstellung?: boolean
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        target: "EMPTY",
        url: lqBookingId
          ? `/api/accounting/createBookingFromBank`
          : `/api/accounting/createBooking`,
        bodyParams: {
          useCorrection: Boolean(isManualSollstellung),
          lqBookingId: lqBookingId,
          groupDisplayName: value.groupDisplayName,
          entity: value.entity,
          date: value.date,
          account: value.account,
          note: value.note || "",
          linkedAsset: value.linkedAsset || [],
          bookings: value.frames
            .map((frame) =>
              frame.bookings
                .filter(
                  (e) =>
                    e.value.amount !== 0 ||
                    (hasValue(e.counterBooking?.value?.amount)
                      ? e.counterBooking?.value?.amount !== 0
                      : false)
                )
                .map(({ costAccount, ...booking }) => ({
                  ...booking,
                  objectId: frame.objectId,
                  contraAccount: frame.contraAccount,
                  bookingType: booking.bookingType === "S" ? "H" : "S",
                }))
            )
            .flat(),
        },
      });
    });
  }

  // getAccountByObjectAndType(
  //   accounting: AccountingData,
  //   objectId: string,
  //   type: AccountType,
  //   additionalFilter?: (e: AccountingAccountData) => boolean
  // ) {
  //   return accounting.accounting.data.accounts.find(
  //     (e) =>
  //       e.objectId === objectId &&
  //       e.accountType === type &&
  //       (additionalFilter ? additionalFilter(e) : true)
  //   );
  // }
  // getAccountByType(
  //   accounting: AccountingData,
  //   type: AccountType,
  //   additionalFilter?: (e: AccountingAccountData) => boolean
  // ) {
  //   return accounting.accounting.data.accounts.find(
  //     (e) =>
  //       e.accountType === type &&
  //       (additionalFilter ? additionalFilter(e) : true)
  //   );
  // }

  submitBooking = async (booking: AccountingBookingFormValueOld) => {
    const result = (await HTTP.post({
      url: `/api/accounting/createBooking`,
      target: "EMPTY",
      bodyParams: {
        ...booking,
      },
    })) as AccountingBooking;

    CacheService.update(result);

    return result;
  };
  getCorrectedBooking = (forAccount: string, booking: AccountingBooking) => {
    // if (booking.data.account === forAccount) return booking;
    if (booking.data.contraAccount === forAccount) {
      return {
        ...booking,
        data: {
          ...booking.data,
          account: booking.data.contraAccount,
          contraAccount: booking.data.account,
          bookingType:
            booking.data.bookingType === AccountingBookingType.SOLL
              ? AccountingBookingType.HABEN
              : AccountingBookingType.SOLL,
        },
      };
    }
    return booking;
  };
  getAccountTypeLabel = (accountType: AccountType) => {
    switch (accountType) {
      case AccountType.debitor_deposit:
        return i18n.t("acc:Nav.AccountSheetDeposit", "Kaution");
      case AccountType.debitor_dunning:
        return i18n.t("acc:Nav.AccountDebitorDunning", "Mahnung");
      case AccountType.debitor_loss:
        return i18n.t("acc:Nav.AccountSheetLoss", "Umsatzausfall");
      case AccountType.debitor_rent_loss:
        return i18n.t("acc:Nav.debitor_rent_loss", "Mietausfall");
      case AccountType.debitor_rent_reduction:
        return i18n.t("acc:Nav.debitor_rent_reduction", "Mietreduzierung");
      case AccountType.debitor_rentalagreement:
        return i18n.t("acc:Nav.AccountSheetRentalAgreements", "Mieter");
      case AccountType.debitor_rentalposition:
        return i18n.t("acc:Nav.AccountSheetRentalPosition", "Sollstellung");
      case AccountType.debitor_other:
        return i18n.t("acc:Nav.AccountSheetOther", "Weitere");
      case AccountType.bank:
        return i18n.t("acc:Nav.AccountBank", "Bank");
      default:
        return (accountType as any).toString();
    }
  };
  // getAccountsOfEntityGrouped = (
  //   accounting: AccountingData,
  //   ignoreAccounts?: string[],
  //   filterToTypes?: AccountType[],
  //   filterToObjects?: string[]
  // ) => {
  //   return ArrayUtils.sortData(
  //     accounting.accounting.data.accounts
  //       .filter((e) => !(ignoreAccounts || []).includes(e.id))
  //       .filter((e) =>
  //         filterToTypes ? filterToTypes.includes(e.accountType) : true
  //       )
  //       .filter(
  //         (e) => !filterToObjects || filterToObjects.includes(e.objectId)
  //       ),
  //     {
  //       dir: "asc",
  //       key: "internalId",
  //     },
  //     {
  //       internalId: (a, b) => [
  //         hasValue(a.internalId) ? Number(a.internalId) : 0,
  //         hasValue(b.internalId) ? Number(b.internalId) : 0,
  //       ],
  //     }
  //   ).map(
  //     (account) =>
  //       ({
  //         label: LanguageService.translateLabel(account.displayName),
  //         subLabel: account.internalId,
  //         value: account.id,
  //         group: this.getAccountTypeLabel(account.accountType),
  //       } as BFChooserOption)
  //   );
  // };

  getEntitySelectOptions = (unitTypes: string[], usesAccounting: boolean) =>
    ArrayUtils.sortData(
      OrgaStruct.getEntities(unitTypes).filter(
        (entity) => entity.usesAccounting === usesAccounting
      ),
      [
        {
          dir: "asc",
          key: "id",
        },
        {
          dir: "asc",
          key: "displayName",
        },
      ],
      {
        displayName: (a, b) => [
          LanguageService.translateLabel(a.displayName),
          LanguageService.translateLabel(b.displayName),
        ],
      }
    ).map((entity) => ({
      label: LanguageService.translateLabel(entity.displayName),
      value: entity._id,
      group: UnitStruct.getUnitLabel(entity.type),
      subLabel:
        entity.objects?.length > 0
          ? entity.objects
              ?.map((obj) => LanguageService.translateLabel(obj.displayName))
              .join(", ")
          : undefined,
    }));

  async createAccountForEntity(
    entity: string,
    startOfAccounting: Date,
    currency: string
  ) {
    const result = await HTTP.post({
      url: `/api/accounting/createAccountingForEntity`,
      target: "EMPTY",
      bodyParams: {
        entity,
        startOfAccounting,
        currency,
      },
    });
    await store.dispatch(reloadStructData("orga"));
  }

  async submitAccountingSheet(
    form: AccountingSheetFormValue,
    accountType: AccountType,
    accounting: AccountingData
  ) {
    return await ServiceUtils.toastError(async () => {
      let url;
      if (accountType === AccountType.debitor_other) {
        url = "/api/accounting/createDebitorAccountOther";
      }

      const result = await HTTP.post({
        url: url || "/api/accounting/createDebitorAccount",
        target: "EMPTY",
        bodyParams: {
          ...form,
          entity: accounting.accounting.data.entity,
          exportId: form.exportId ? Number(form.exportId) : null,
          objectId: form.objectId || null,
        },
      });

      return result;
    });
  }

  updateAccountingIdMapping = async (
    entityId: string,
    accountingIdMapping: AccountingIDMapping[]
  ) => {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/api/accounting/${entityId}/updateAccounting`,
        target: "EMPTY",
        bodyParams: {
          changes: accountingIdMapping,
        },
      });
    });
  };

  updateAccountingExportSettings = async (
    entityId: string,
    value: AccountingSettingsFormValue
  ) => {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/api/accounting/${entityId}/updateAccountingExport`,
        target: "EMPTY",
        bodyParams: {
          ...value,
        },
      });

      return result;
    });
  };
}
const AccountingService = new AccountingServiceClass();
export default AccountingService;
