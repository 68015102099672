import i18n from "@/i18n";

import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";

import React from "react";

type BlockFooterProps = {
  isEmpty: boolean;
  onSave: () => void;
  onDelete: () => void;
  children?: React.ReactElement | React.ReactElement[];
  className?: string;
};

const PDFBlockFooter: React.FC<BlockFooterProps> = ({
  isEmpty,
  onSave,
  onDelete,
  className,
  children,
}) => (
  <footer className={className}>
    {children}

    {isEmpty && (
      <BFButton onClick={onSave}>
        {i18n.t("Component.PDFConstructor.Confirm", "Bestätigen")}
      </BFButton>
    )}

    <BFButton appearance="jump" onClick={onDelete}>
      <BfIcon type="light" data="bin-2" size="xs" />
    </BFButton>
  </footer>
);

export { PDFBlockFooter };
