import { micromark } from "micromark";
import React, { useEffect } from "react";
import "./BFMarkdown.scss";

interface BFMarkdownProps {
  content: string;
}
const BFMarkdown = (props: BFMarkdownProps) => {
  const [formatted, setFormatted] = React.useState<string>(
    props.content ? micromark(props.content) : ""
  );
  useEffect(() => {
    if (props.content) {
      setFormatted(micromark(props.content));
    } else {
      setFormatted("");
    }
  }, [props.content]);
  return (
    <div
      className={`bf-markdown`}
      dangerouslySetInnerHTML={{
        __html: formatted,
      }}
    />
  );
};

export default BFMarkdown;
