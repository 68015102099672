import { RealTimePDFViewer } from "./RealTimePDFViewer";
import { PDFBlockList } from "./components/PDFBlockList";

import { PDFImageInput } from "./components/Blocks/ImageInput/PDFImageInput";
import { PDFLineInput } from "./components/Blocks/PDFLineInput";
import { PDFPageBreak } from "./components/Blocks/PDFPageBreak";
import { PDFLinkInput } from "./components/Blocks/TextInput/PDFLinkInput";
import { PDFTextInput } from "./components/Blocks/TextInput/PDFTextInput";
import { PDFBlockSelect } from "./components/PDFBlockSelect";

import i18n from "@/i18n";
import "./PDFConstructor.scss";
import { usePDFConstructor } from "./context/PDFConstructorContext";

import { PDFConstructorProvider } from "./context/PDFConstructorContext";

import { PDFColumnGroupInput } from "./components/Blocks/Layout/PDFColumnGroupInput";
import { useAvailableBlockList } from "./PDFConstructorHooks";
import { PDFObjectDetailsBlock } from "./components/Blocks/Object/PDFObjectDetailsBlock";
import { PDFObjectGalleryBlock } from "./components/Blocks/Object/PDFObjectGalleryBlock";
import { OAObject } from "@/apps/tatar/objectsApp/types/object.interface";

import { PDFAssetInformation } from "./components/PDFAssetInformation";
import StructLoader from "../StructLoader/StructLoader";
import { useTypedSelector } from "@/redux/hooks";
import { PDFObjectStackingPlanBlock } from "./components/Blocks/Object/PDFObjectStackingPlanBlock";
import { PDFObjectVacancyChartBlock } from "./components/Blocks/Object/PDFObjectVacancyChart";
import { PDFUpcomingVacancyBlock } from "./components/Blocks/Object/PDFObjectUpcomingVacancyBlock";
import { PDFObjectRentalUnitDistributionChartBlock } from "./components/Blocks/Object/PDFObjectRentalUnitDistributionChart";
import { PDFObjectStackingPlanTableBlock } from "./components/Blocks/Object/PDFObjectStackingPlanTableBlock";
import { PDFObjectTenantListTableBlock } from "./components/Blocks/Object/PDFObjectTenantListTableBlock";
import { PDFObjectVacancyActivityBlock } from "./components/Blocks/Object/PDFObjectVacancyActivityBlock";
import { PDFObjectDamageClaimsTableBlock } from "./components/Blocks/Object/PDFObjectDamageClaimsTableBlock";
import { PDFObjectRentalIncomeChartBlock } from "./components/Blocks/Object/PDFObjectRentalIncomeChartBlock";
import { BlockType } from "./services/types/pdfConfigBlockTypes";
import { PDFObjectRiskManagementBlock } from "./components/Blocks/Object/PDFObjectRiskManagementChartBlock";
import { PDFObjectMaintenanceContractsBlock } from "./components/Blocks/Object/PDFObjectMaintenanceContractsTableBlock";
import { PDFObjectPortfolioLoanChartBlock } from "./components/Blocks/Object/PDFObjectPortfolioLoanChartBlock";
import { PDFObjectLoanTableBlock } from "./components/Blocks/Object/PDFObjectLoanTableBlock";
import BFTabs from "@/modules/abstract-ui/data/tabs/BFTabs";
import { PDFConfiguration } from "./components/PDFConfiguration";
import { PDFTemplatesTab } from "./components/Templates/PDFTemplatesTab";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { useRef } from "react";
import FileUtils from "@/utils/FileUtils";

type PDFConstructorProps = {
  close: () => void;
};

const PDFConstructor: React.FC<PDFConstructorProps> = ({ close }) => {
  const [{ state, document, asset }, { addBlock, addTemplate }] =
    usePDFConstructor();

  const { data, refresh } = useAvailableBlockList();
  const unitTypes = useTypedSelector(
    (state) => state?.uiConfig?.activeApplication?.constants?.businessUnits
  ) as string[];

  const pdfFileRef = useRef({ url: null });

  return (
    <div className="pdf-constructor">
      <div className="form-title">PDF Constructor</div>

      <div className="pdf-constructor--wrapper">
        <div className="pdf-constructor--elements">
          <BFTabs
            tabs={[
              {
                id: "asset-data",
                label: "Asset Data",
                content: () => (
                  <StructLoader
                    block
                    structTypes={["orga", "objectKind", "unit"]}
                    unitTypes={unitTypes}
                    render={() => <PDFAssetInformation />}
                  ></StructLoader>
                ),
              },
              {
                id: "pdf-config",
                label: i18n.t(
                  "Component.PDFConstructor.PDFConfiguration.Title",
                  "PDF-Konfiguration"
                ),
                content: () => <PDFConfiguration />,
              },
              {
                id: "pdf-blocks",
                label: i18n.t(
                  "Component.PDFConstructor.BlockList.Title",
                  "Blöcke"
                ),
                content: () => (
                  <>
                    <PDFBlockList
                      config={state.content}
                      parentId={0}
                      elements={{
                        link: PDFLinkInput,
                        text: PDFTextInput,
                        image: PDFImageInput,
                        line: PDFLineInput,
                        break: PDFPageBreak,
                        "column-group": PDFColumnGroupInput,
                        "object-details": PDFObjectDetailsBlock,
                        "object-gallery": PDFObjectGalleryBlock,
                        "object-stacking-plan": PDFObjectStackingPlanBlock,
                        "vacancy-chart": PDFObjectVacancyChartBlock,
                        "upcoming-vacancy-table": PDFUpcomingVacancyBlock,
                        "rental-unit-distribution-chart":
                          PDFObjectRentalUnitDistributionChartBlock,
                        "object-stacking-plan-table":
                          PDFObjectStackingPlanTableBlock,
                        "object-tenant-list": PDFObjectTenantListTableBlock,
                        "object-vacancy-activity":
                          PDFObjectVacancyActivityBlock,
                        "object-damage-claims-table":
                          PDFObjectDamageClaimsTableBlock,
                        "object-rental-income-chart":
                          PDFObjectRentalIncomeChartBlock,
                        "object-risk-management": PDFObjectRiskManagementBlock,
                        "object-maintenance-contracts-table":
                          PDFObjectMaintenanceContractsBlock,
                        "object-loan-chart": PDFObjectPortfolioLoanChartBlock,
                        "object-loan-table": PDFObjectLoanTableBlock,
                      }}
                    />
                    <PDFBlockSelect
                      addValue={(block) => addBlock(block, 0)}
                      addTemplate={(value) => addTemplate(value, asset._id)}
                      additionalValues={
                        data?.templates?.map((template) => ({
                          label: () => template.displayName,
                          value: template._id,
                        })) ?? []
                      }
                      show={[
                        "break",
                        "column-group",
                        "image",
                        "line",
                        "link",
                        "text",
                        ...(data?.blocks ?? []),
                        ...((data?.templates?.map(
                          (template) => template.displayName
                        ) as BlockType[]) ?? []),
                      ]}
                    />
                  </>
                ),
              },
              {
                id: "pdf-templates",
                label: i18n.t(
                  "Component.PDFConstructor.Template.Templates",
                  "Vorlagen"
                ),
                content: () => <PDFTemplatesTab refresh={refresh} />,
              },
            ]}
          />
        </div>

        <div className="pdf-constructor--preview">
          <RealTimePDFViewer config={document} ref={pdfFileRef} />
        </div>
      </div>

      <div className="form-action-row">
        <BFButton
          className="margin-left-10"
          appearance="Outline"
          onClick={close}
        >
          Cancel
        </BFButton>
        <BFButton
          className="margin-left-10"
          type="submit"
          appearance="primary"
          onClick={() => {
            if (pdfFileRef.current?.url) {
              FileUtils.forceDownloadFile(pdfFileRef.current.url, "Preview");
            }
          }}
        >
          Download
        </BFButton>
      </div>
    </div>
  );
};

type PDFConstructorWrapperProps = {
  asset: OAObject;
  close: () => void;
};

const PDFConstructorWrapper: React.FC<PDFConstructorWrapperProps> = ({
  asset,
  close,
}) => (
  <PDFConstructorProvider asset={asset}>
    <PDFConstructor close={close} />
  </PDFConstructorProvider>
);

export { PDFConstructorWrapper as PDFConstructor };
