import Log from "@/debug/Log";
import BaseAsset from "@/model/general-assets/BaseAsset";
import { AttachmentValue } from "@/modules/customfields/fields/CFAttachment";
import { handleError } from "@/utils/ErrorCodes";
import { hasValue } from "@/utils/Helpers";
import CDNService from "./CDNService";
import DataBusDefaults from "./DataBusDefaults";

class ServiceUtilsClass {
  async toastError(
    promise: () => Promise<any>,
    errorMsg?: string | ((err: any) => Promise<string>)
  ) {
    try {
      return await promise();
    } catch (error) {
      let msg;
      if (typeof errorMsg === "function") {
        try {
          msg = await errorMsg(error);
        } catch (err) {
          msg = null;
        }
      } else {
        msg = errorMsg;
      }
      if (!msg) {
        handleError(error);
      } else {
        DataBusDefaults.toast({
          type: "error",
          text: msg,
          duration: msg.length > 200 ? 10000 : 5000,
        });
      }
      throw error;
    }
  }

  async handleCDNFiles(
    asset: BaseAsset,
    assetType: string,
    fieldName: string,
    values: { old?: AttachmentValue[]; new: AttachmentValue[] },
    additionalMetaData?: Object
  ) {
    let result = asset;
    const attachmentsToDelete = [];
    if (hasValue(values.old)) {
      values.old.forEach((attachment) => {
        if (
          !values.new.find(
            (newAttachment) =>
              newAttachment.type === "cdn" &&
              attachment.type === "cdn" &&
              newAttachment.linkToCdn === attachment.linkToCdn
          )
        ) {
          attachmentsToDelete.push(attachment);
        }
      });
    }
    const attachmentsToAdd = [];

    if (hasValue(values.new)) {
      values.new
        .filter((e) => e.type === "new")
        .forEach((e) => attachmentsToAdd.push(e));
    }

    if (attachmentsToDelete.length > 0) {
      // await asset.removeAttachments(deletedAttachments);

      for (const attachment of attachmentsToDelete) {
        Log.info("delete new attachment", attachment, "bot implamenebt");
        //   await CDNService.deleteAttachment(attachment.id);
        result = await CDNService.deleteDocument(
          assetType,
          result._id,
          attachment.linkToCdn,
          fieldName,
          attachment
        );
      }
    }

    for (const attachment of attachmentsToAdd) {
      Log.info("upload new attachment", attachment);
      result = (await CDNService.uploadAttachment(
        result._id,
        {
          file: attachment.file,
          name: attachment.file.name,
          ...(additionalMetaData ? additionalMetaData : {}),
        },
        assetType,
        fieldName,
        attachment.id,
        (progres: number) => {}
      )) as BaseAsset;
    }

    return result;
  }
}
const ServiceUtils = new ServiceUtilsClass();
export default ServiceUtils;
