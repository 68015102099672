import * as echarts from "echarts";
import i18n from "@/i18n";
import { HistoryRentRecord } from "./types";
import { getCssVariable } from "@/apps/utils/getCssVariable";

// ! HELPERS
// Function for formatting dates in a form convenient for display
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  const currentLanguage = i18n.language || "en";

  return new Intl.DateTimeFormat(currentLanguage, {
    year: "numeric",
    month: "short",
  }).format(date); // Output in "MMM yyyy" format
};

// get main graph data (dates, minData, maxData, medianData, candlestickData)
const processMarketRentHistory = (historyData: HistoryRentRecord[]) => {
  const dates: string[] = [];
  const minData: number[] = [];
  const maxData: number[] = [];
  const medianData: number[] = [];
  const candlestickData: number[][] = [];

  historyData.forEach((historyItem) => {
    // Format the date for the X axis
    dates.push(formatDate(historyItem.data.valuationMonth));

    // Adding data for candles
    candlestickData.push([
      historyItem.data.rentalInfo.percentile25, // open
      historyItem.data.rentalInfo.percentile75, // close
      historyItem.data.rentalInfo.minimum, // low
      historyItem.data.rentalInfo.maximum, // high
    ]);

    // Adding data for lines
    medianData.push(historyItem.data.rentalInfo.median);
    minData.push(historyItem.data.rentalInfo.minimum);
    maxData.push(historyItem.data.rentalInfo.maximum);
  });

  return { dates, minData, maxData, medianData, candlestickData };
};

// ! MAIN FUNCTION FOR GENERATING CHART OPTIONS
export const getChartOptions = (
  data: HistoryRentRecord[]
): echarts.EChartsOption => {
  const { dates, maxData, minData, medianData, candlestickData } =
    processMarketRentHistory(data);

  const series: echarts.EChartsOption["series"] = [
    {
      name: i18n.t<string>(
        "cb:RentDataGraph.labels.series.candlestick",
        "Mietpreis (25 %-75 %)"
      ),
      type: "candlestick",
      data: candlestickData,
      itemStyle: {
        color: getCssVariable("--chart-rent-data-candlestick-up"),
        color0: getCssVariable("--chart-rent-data-candlestick-down"),
        borderColor: getCssVariable("--chart-rent-data-candlestick-up"),
        borderColor0: getCssVariable("--chart-rent-data-candlestick-down"),
      },
    },
    {
      name: i18n.t<string>("cb:RentDataGraph.labels.series.median", "Median"),
      type: "line",
      data: medianData,
      smooth: true,
      lineStyle: {
        color: getCssVariable("--chart-rent-data-median"),
        opacity: 0.5,
      },
    },
    {
      name: i18n.t<string>("cb:RentDataGraph.labels.series.min", "Min"),
      type: "line",
      data: minData,
      smooth: true,
      lineStyle: {
        color: getCssVariable("--chart-rent-data-min"),
        opacity: 0.5,
      },
    },
    {
      name: i18n.t<string>("cb:RentDataGraph.labels.series.max", "Max"),
      type: "line",
      data: maxData,
      smooth: true,
      lineStyle: {
        color: getCssVariable("--chart-rent-data-max"),
        opacity: 0.5,
      },
    },
  ];

  return {
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "cross" },
      appendTo: "body",
    },
    legend: {
      data: series.map<string>((s) => `${s.name}`),
      selectedMode: "multiple",
      textStyle: {
        color: getCssVariable("--text-color-strong"),
      },
    },
    grid: {
      containLabel: true,
      left: "auto",
      right: "auto",
      bottom: 41,
    },
    xAxis: {
      type: "category",
      data: dates,
      boundaryGap: true,
      axisLine: { onZero: false },
      axisLabel: {
        textStyle: {
          color: getCssVariable("--chart-axis-label-primary"),
        },
      },
    } as echarts.EChartsOption["xAxis"],
    yAxis: [
      {
        id: "historyYAxis",
        position: "left",
        scale: true,
        splitArea: { show: true },
        axisLine: { show: true },
        axisLabel: {
          textStyle: {
            color: getCssVariable("--chart-axis-label-primary"),
          },
        },
      },
    ] as echarts.EChartsOption["yAxis"],
    series,
  };
};
