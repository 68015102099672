import classNames from "classnames";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import AssetLoader from "../../../../../../components/AssetLoader/AssetLoader";
import ModalManager from "../../../../../../components/ModalComponent/ModalManager";
import StructLoader from "../../../../../../components/StructLoader/StructLoader";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import BFButtonToggle from "../../../../../../modules/abstract-ui/general/Button/BFButtonToggle";
import DataBusDefaults from "../../../../../../services/DataBusDefaults";
import { hasValue } from "../../../../../../utils/Helpers";
import MQ from "../../../../../../utils/MatchQueryUtils";
import { APStatusTags } from "../../../../../AppConfigInterfaces";
import { useActivityConstants } from "../../../ActivityHooks";
import { APActivity } from "../../../ActivityInterfaces";
import { useActivityStruct } from "../../../ActivityStructContext";
import { APBudget } from "./APBudgetInterfaces";
import APBudgetPage from "./APBudgetPage";
import "./APBudgetView.scss";
import APCreateBudgetForm from "./APCreateBudgetForm";

interface APBudgetViewProps {
  activity: APActivity;
}

const APBudgetView = (props: APBudgetViewProps) => {
  const constants = useActivityConstants();
  const match = useRouteMatch();
  const baseUrl = match.url;

  return (
    <StructLoader
      structTypes={["category"]}
      unitType={props.activity.data.type}
      render={() => (
        <AssetLoader
          ignoreDelay
          clearOnUnmount
          assetType={AssetTypes.ActivityRelated.Budget}
          query={MQ.and(
            MQ.eq("data.status", "draft"),
            MQ.eq("data.linkedAsset.assetId", props.activity._id)
          )}
          render={(budget: APBudget, selector, reload) => {
            const budgetAvailable = hasValue(budget);
            const activeAvailable = hasValue(
              props.activity.data.currentActiveBudgetId
            );

            if (!budgetAvailable && !activeAvailable) {
              return (
                <div className={`ap-budget-view-no-budget-yet`}>
                  <div className={`box`}>
                    <div className={`description`}>
                      {i18n.t(
                        "apTemplate:BudgetView.NoBudgetYet",
                        "Bisher wurde noch keine Budget-Planung angelegt. Klicken Sie auf 'Budget erstellen' um ein neuen Plan zu erstellen. Sie können die Daten jederzeit bis zur Budgetgenehmigung anpassen."
                      )}
                    </div>
                    <div className={`budget-create`}>
                      <BFButton
                        className={`create-budget-button`}
                        appearance="primary"
                        onClick={() => {
                          ModalManager.show({
                            size: "xs",
                            noPadding: true,
                            content: (state, setState, closeModal) => (
                              <APCreateBudgetForm
                                planType={
                                  constants.fields?.budgetFeatureType ||
                                  "timespan"
                                }
                                activity={props.activity}
                                onClose={closeModal}
                                onSubmit={() => {
                                  reload();
                                  DataBusDefaults.route({
                                    route: `${match.url}/draft`,
                                  });
                                }}
                              />
                            ),
                          });
                        }}
                      >
                        {i18n.t(
                          "apTemplate:BudgetView.CreateBudget",
                          "Budget erstellen"
                        )}
                      </BFButton>
                    </div>
                  </div>
                </div>
              );
            }

            return (
              <Switch>
                <Route path={`${match.path}/:tab`}>
                  <div className={classNames(`ap-budget-view`)}>
                    <BudgetHeader
                      activity={props.activity}
                      baseUrl={baseUrl}
                      activeAvailable={activeAvailable}
                      budgetAvailable={budgetAvailable}
                      budget={budget}
                      reloadBudget={() => reload(true)}
                    />
                    <div className={`ap-budget-content`}>
                      <Route
                        path={`${match.path}/:tab`}
                        render={(routeProps) => (
                          <APBudgetPage
                            budgetSelector={routeProps.match.params["tab"]}
                            activity={props.activity}
                          />
                        )}
                      />
                    </div>
                    {/* <APBudgetForm /> */}
                  </div>
                </Route>
                <Route
                  path={match.path}
                  render={() => (
                    <Redirect
                      to={`${match.url}/${
                        props.activity?.data.currentActiveBudgetId
                          ? "active"
                          : "draft"
                      }`}
                    />
                  )}
                />
              </Switch>
            );
          }}
        />
      )}
    />
  );
};

export default APBudgetView;

const BudgetHeader = (props: {
  baseUrl: string;
  activity: APActivity;
  budget?: APBudget;
  activeAvailable?: boolean;
  budgetAvailable?: boolean;
  reloadBudget?: () => void;
}) => {
  const activityStruct = useActivityStruct();
  const constants = useActivityConstants();
  const match = useRouteMatch();
  const location = useLocation();

  const tab = match.params["tab"];

  if (!tab) {
    return null;
  }
  const activeTab =
    tab === "active" ? "active" : tab === "draft" ? "draft" : "history";

  const finished = activityStruct
    .getStatus(props.activity.data.type, props.activity.data.status)
    ?.tags.includes(APStatusTags.FINISHED_STATUS);

  const isInApproval = activityStruct
    .getStatus(props.activity.data.type, props.activity.data.status)
    ?.tags.includes(APStatusTags.BUDGET_IN_APPROVAL);

  return (
    <div className={`ap-budget-view-header`}>
      {props.activeAvailable && props.budgetAvailable && (
        <BFButtonToggle
          value={activeTab}
          onChange={(route) => {
            DataBusDefaults.route({
              route: `${props.baseUrl}/${route}`,
              stayInApp: false,
            });
          }}
          buttons={[
            ...(props.activeAvailable
              ? [
                  {
                    text: i18n.t(
                      "apTemplate:Activity.Budget.ApprovedBudget",
                      "Genehmigtes Budget",
                      {
                        ns: ["apTemplate"],
                      }
                    ),
                    value: "active",
                  },
                ]
              : []),
            ...(props.budgetAvailable
              ? [
                  {
                    text: i18n.t(
                      "apTemplate:Activity.Budget.BudgetInPlanning",
                      "Neuer Budgetplan",
                      {
                        ns: ["apTemplate"],
                      }
                    ),
                    value: "draft",
                  },
                ]
              : []),
            // {
            //   text: i18n.t(
            //     "apTemplate:Activity.Budget.HistoricalBudgets",
            //     "Historische Budgets",
            //     {
            //       ns: ["apTemplate"],
            //     }
            //   ),
            //   value: "history",d
            // },
          ]}
        />
      )}
      <div className={`fill`} />

      {!finished && !props.budgetAvailable && !isInApproval && (
        <BFButton
          onClick={() => {
            ModalManager.show({
              size: "xs",
              noPadding: true,
              content: (state, setState, closeModal) => (
                <APCreateBudgetForm
                  planType={constants.fields?.budgetFeatureType || "timespan"}
                  activity={props.activity}
                  onClose={closeModal}
                  onSubmit={() => {
                    props.reloadBudget?.();
                    if (match.params["tab"]) {
                      DataBusDefaults.route({
                        route: match.url.replace(
                          `/${match.params["tab"]}`,
                          "/draft"
                        ),
                      });
                    } else {
                      DataBusDefaults.route({
                        route: `${match.url}/draft`,
                      });
                    }
                  }}
                />
              ),
            });

            // ModalManager.confirm({
            //   title: i18n.t(
            //     "apTemplate:BudgetView.CreateBudget",
            //     "Budget erstellen"
            //   ),
            //   confirmButtonText: i18n.t(
            //     "apTemplate:BudgetView.CreateBudget",
            //     "Budget erstellen"
            //   ),
            //   message: i18n.t(
            //     "apTemplate:BudgetView.CreateBudgetConfirm",
            //     "Sind Sie sicher, dass Sie ein Budget erstellen mochten?"
            //   ),
            //   onConfirm: async () => {
            //     await ActivityService.saveBudgetDraft(
            //       constants.serviceUrl,
            //       props.activity._id,
            //       [],
            //       new Date().getFullYear(),
            //       1
            //     );

            //     props.reloadBudget?.();
            //     if (match.params["tab"]) {
            //       DataBusDefaults.route({
            //         route: match.url.replace(
            //           `/${match.params["tab"]}`,
            //           "/draft"
            //         ),
            //       });
            //     } else {
            //       DataBusDefaults.route({
            //         route: `${match.url}/draft`,
            //       });
            //     }
            //   },
            // });

            // ModalManager.show({
            //   size: "md",
            //   title: i18n.t(
            //     "apTemplate:BudgetView.CreateNewBudget",
            //     "Neues Budget planen"
            //   ),
            //   content: (state, setState, closeModal) => (
            //     <APCreateBudgetForm
            //       activity={props.activity}
            //       onClose={closeModal}
            //       onSubmit={() => {
            //         props.reloadBudget?.();
            //         if (match.params["tab"]) {
            //           DataBusDefaults.route({
            //             route: match.url.replace(
            //               `/${match.params["tab"]}`,
            //               "/draft"
            //             ),
            //           });
            //         } else {
            //           DataBusDefaults.route({
            //             route: `${match.url}/draft`,
            //           });
            //         }
            //       }}
            //     />
            //   ),
            // });
          }}
        >
          {i18n.t(
            "apTemplate:BudgetView.CreateNewBudget",
            "Neues Budget planen"
          )}
        </BFButton>
      )}
    </div>
  );
};
