import _ from "lodash";
import moment from "moment";
import AssetLoader from "../../../../../../components/AssetLoader/AssetLoader";
import FormStruct from "../../../../../../components/Form/FormStruct/FormStruct";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import BFButton from "../../../../../../modules/abstract-ui/general/Button/BFButton";
import { PlanTableTimespanFields } from "../../../../../../modules/plan-table/PlanTableTimespanManage";
import { hasValue } from "../../../../../../utils/Helpers";
import { useActivityConstants } from "../../../ActivityHooks";
import { APActivity } from "../../../ActivityInterfaces";
import ActivityService from "../../../ActivityService";
import { APBudget } from "./APBudgetInterfaces";
import "./APCreateBudgetForm.scss";

interface APCreateBudgetFormProps {
  onClose: () => void;
  planType: "fixYearPlan" | "timespan";
  activity: APActivity;
  onSubmit: () => void;
}
const APCreateBudgetForm = (props: APCreateBudgetFormProps) => {
  const constants = useActivityConstants();
  const hasCurrentBudget = hasValue(props.activity.data.currentActiveBudgetId);
  // if hasCurrentBudget -> load before form rendering to prevent position deletion & date corruption

  const renderForm = (budget: APBudget) => {
    if (props.planType === "fixYearPlan") {
      return (
        <div className={`fix-year-plan-form`}>
          <div className={`title`}>
            {i18n.t("apTemplate:BudgetView.CreateBudget", "Budget erstellen")}
          </div>
          <div>
            {i18n.t(
              "apTemplate:BudgetView.CreateBudgetConfirm",
              "Sind Sie sicher, dass Sie ein Budget erstellen mochten?"
            )}
          </div>
          <div className={`actions`}>
            <BFButton appearance="outline" onClick={props.onClose}>
              {i18n.t("Global.Buttons.cancel")}
            </BFButton>
            <BFButton
              appearance="primary"
              onClick={async () => {
                await ActivityService.saveBudgetDraft(
                  constants.serviceUrl,
                  props.activity._id,
                  [],
                  new Date().getFullYear(),
                  1
                );
                props.onSubmit();
                props.onClose();
              }}
            >
              {i18n.t("apTemplate:BudgetView.CreateBudget", "Budget erstellen")}
            </BFButton>
          </div>
        </div>
      );
    } else {
      const datesWithValues = budget?.data.entries
        .filter((e) => e.values.filter((a) => a.value != 0).length > 0)
        .map((e) => e.date);
      const minDate = _.min(datesWithValues);
      const maxDate = _.max(datesWithValues);
      return (
        <FormStruct
          // noPadding
          title={i18n.t(
            "apTemplate:BudgetView.CreateBudget",
            "Budget erstellen"
          )}
          initialValues={{
            from: budget ? minDate : moment().toDate(),
            to: budget ? maxDate : moment().add(1, "year").toDate(),
          }}
          className={`ap-create-budget-form`}
          onAbort={() => props.onClose()}
          onSubmit={async (values) => {
            await ActivityService.saveBudgetDraftTimespan(
              constants.serviceUrl,
              props.activity._id,
              [],
              values.from,
              values.to
            );
            props.onSubmit?.();

            props.onClose();
          }}
          render={(form) => (
            <div>
              <PlanTableTimespanFields
              // minDate={props.minDate}
              // maxDate={props.maxDate}
              // warnMinDate={props.warnMinDate}
              // warnMaxDate={props.warnMaxDate}
              />
            </div>
          )}
        />
        // <PlanTableCreate
        //   onClose={() => props.onClose()}
        //   onSubmit={async (values) => {
        //     const start = moment(values.from);
        //     const end = moment(values.to);
        //     const entriesToSave =
        //       budget?.data.entries.filter((e) =>
        //         moment(e.date).isBetween(start, end)
        //       ) || [];

        //     await ActivityService.saveBudgetDraftTimespan(
        //       constants.serviceUrl,
        //       props.activity._id,
        //       entriesToSave,
        //       values.from,
        //       values.to
        //     );
        //     props.onSubmit?.();
        //     props.onClose();
        //   }}
        //   from={budget?.data.fromDate}
        //   to={budget?.data.toDate}
        //   warnMaxDate={maxDate}
        //   warnMinDate={minDate}
        // />
      );
    }
  };
  if (hasCurrentBudget) {
    return (
      <AssetLoader
        assetType={AssetTypes.ActivityRelated.Budget}
        id={props.activity.data.currentActiveBudgetId}
        render={(budget: APBudget) => renderForm(budget)}
      />
    );
  } else {
    return renderForm(null);
  }
};

export default APCreateBudgetForm;
