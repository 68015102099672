import i18n from "@/i18n";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFMessageBox from "@/modules/abstract-ui/general/Message/BFMessageBox";
import BfIcon from "@/modules/abstract-ui/icon/BfIcon";
import DSService from "@/modules/document-store/DSService";
import {
  ConvertedAttachment,
  EmailContact,
  EmailFile,
  useEmailFile,
  useEmailFileProps,
} from "@/modules/document-store/hooks/useEmailFile";
import classNames from "classnames";
import moment from "moment";
import { nanoid } from "nanoid";
import React, { useCallback, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import Collapse from "rsuite/esm/Animation/Collapse";
import WrappedText from "../WrappedText/WrappedText";
import "./MailViewer.scss";

export type MailViewerProps = {
  document: {
    data: string | EmailFile;
    fileName?: string;
    fileType?: string;
    viewerType?: string;
  };
};

export const MailViewer = (props: MailViewerProps) => {
  const { email, loading, fileType } = useEmailFile({
    ...(props.document as useEmailFileProps),
  });

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const handlePrint = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      const iframeWindow: Window = iframe.contentWindow;

      // Ensure we have access to the iframe's window
      if (iframeWindow) {
        iframeWindow.focus(); // Focus on the iframe content
        iframeWindow.print(); // Trigger the print dialog for the iframe content
      } else {
        console.error("Failed to print: Unable to access iframe's window.");
      }
    }
  };

  if (!loading && email) {
    const html = `
      <style>
        html {
          font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
        }
        img {
          max-width: 100%;
        }
        .print{
          display: none;
          flex-direction: column;
        }
          .print > div{
            flex-grow: 1;
          }
        @media print {
            .print{
              margin-bottom: var(--m-5);
              padding-bottom: var(--p-5);
              border-bottom: 2px solid var(--border-color-secondary);
              display: flex;
            }
        }
      </style>
      ${
        renderToString(
          <MailViewerHeader email={email} fileType={fileType} print={true} />
        ) + email?.html
      }
      `;
    return (
      <div className="mail-viewer-container">
        <BFButton
          onClick={handlePrint}
          appearance="link"
          className="print-button"
        >
          <BfIcon type="light" data="printer" size="xs" />
          &nbsp; {i18n.t("Global.Buttons.Print", "Drucken")}
        </BFButton>
        <MailViewerHeader email={email} fileType={fileType} />
        {email.html !== undefined && email.html !== "" ? (
          <iframe
            srcDoc={html}
            ref={iframeRef}
            className="htmlBody"
            sandbox={"allow-same-origin allow-modals"}
          />
        ) : (
          <div className="htmlBody">
            <WrappedText text={email.text} />
          </div>
        )}
        <MailViewerAttachments
          attachments={email.attachments}
          showHidden={false}
        />
      </div>
    );
  }
};

type MailViewerHeaderProps = {
  email: EmailFile;
  fileType?: string;
  print?: boolean;
};

const MailViewerHeader = (props: MailViewerHeaderProps) => {
  const baseStyles: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    paddingLeft: 10,
    paddingRight: 10,
    columnGap: 15,
    fontSize: 14,
  };
  return (
    <div className={classNames("header", props.print ? "print" : "")}>
      {props.email.from && (
        <div className="sender" style={props.print ? baseStyles : {}}>
          <MailViewerRecipients
            key={"sender"}
            recipients={props.email.from}
            label={i18n.t("Mailviewer.Sender", "Absender")}
          />
        </div>
      )}
      {props.email.to && props.email.to.length > 0 && (
        <div className="recipients" style={baseStyles}>
          <MailViewerRecipients
            key={"to"}
            recipients={props.email.to}
            label={i18n.t("Mailviewer.Recipient", "Empfänger")}
          />
        </div>
      )}
      {props.email.cc && props.email.cc.length > 0 && (
        <div className="cc" style={baseStyles}>
          <MailViewerRecipients
            key={"cc"}
            recipients={props.email.cc}
            label={i18n.t("Mailviewer.CC", "CC")}
          />
        </div>
      )}
      {props.email.bcc && props.email.bcc.length > 0 && (
        <div className="bcc" style={baseStyles}>
          <MailViewerRecipients
            key={"bcc"}
            recipients={props.email.bcc}
            label={i18n.t("Mailviewer.BCC", "BCC")}
          />
        </div>
      )}
      <div className="date" style={baseStyles}>
        <div className="label">Date</div>
        <div className="value">
          {moment(props.email.date).format(i18n.t("Formats.dateFormat"))}
        </div>
      </div>
      {props.email.subject && (
        <div className="subject" style={baseStyles}>
          <div className="label">Subject</div>
          <div className="value">{props.email.subject}</div>
        </div>
      )}
      {props.fileType === "msg" && !props.print && (
        <BFMessageBox type="info">
          {i18n.t(
            "Mailviewer.HTML.Disclaimer",
            "Die Formatierung in der Vorschau ist nicht immer korrekt. Für das beste Ergebnis bitte Datei herunterladen und in Outlook öffnen."
          )}
        </BFMessageBox>
      )}
    </div>
  );
};

const MailViewerRecipients = (props: {
  recipients: EmailContact[] | EmailContact;
  label: string;
}) => {
  if (Array.isArray(props.recipients)) {
    return (
      <>
        <div className="label">{props.label}</div>
        <div className="value">
          {props.recipients.map((r) => (
            <React.Fragment key={r.address}>
              <div className="value bf-status">
                {r.name && <span className={`name`}>{r.name}</span>}
                {r.address && (
                  <span className={`address`}>
                    {"<"}
                    {r.address}
                    {">"}
                  </span>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="label">{props.label}</div>
        <div
          className="value bf-status"
          key={props.recipients.address + "_recipient"}
        >
          {props.recipients.name && (
            <span className={`name`}>{props.recipients.name}</span>
          )}
          {props.recipients.address && (
            <span className={`address`}>
              {"<"}
              {props.recipients.address}
              {">"}
            </span>
          )}
        </div>
      </>
    );
  }
};

const MailViewerAttachments = (props: {
  attachments: ConvertedAttachment[];
  showHidden?: boolean;
}) => {
  const [collapsedAttachments, setCollapsedAttachments] = useState(false);
  const attachments = props.showHidden
    ? props.attachments || []
    : props.attachments?.filter((a) => !a.contentId);

  const onClickAttachment = useCallback(() => {
    setCollapsedAttachments(!collapsedAttachments);
  }, [collapsedAttachments]);

  const hasAttachments = attachments && attachments.length > 0;

  return (
    <div className="attachments">
      <BFButton
        onClick={onClickAttachment}
        appearance="link"
        className={classNames(`attachment-button`, {
          collapsed: collapsedAttachments,
        })}
      >
        <BfIcon
          data="attachment"
          type="light"
          size="xs"
          className={`attachment-icon`}
        />
        <span>
          {attachments?.length || 0}{" "}
          {attachments?.length !== 1
            ? i18n.t("Mailviewer.AttachmentsMultiple", "Anhänge")
            : i18n.t("Mailviewer.AttachmentsSingle", "Anhang")}
        </span>
        {hasAttachments && (
          <BfIcon
            data={collapsedAttachments ? "arrow-up-1" : "arrow-down-1"}
            type="light"
            size="xs"
            className={`dropdown-ident`}
          />
        )}
      </BFButton>
      <div className="fill"></div>

      {hasAttachments && (
        <div className={classNames(`attachments-list`)}>
          <Collapse in={collapsedAttachments}>
            <div>
              {attachments.map((a: ConvertedAttachment) => (
                <div className="attachment-entry" key={a.fileName + nanoid()}>
                  <BfIcon
                    type="color"
                    data={DSService.getIconFromContentType(a.mimeType)}
                    size="sm"
                  />
                  <a
                    className="attachment-link"
                    download={a.fileName}
                    href={a.base64Data}
                  >
                    {a.fileName}
                  </a>
                </div>
              ))}
            </div>
          </Collapse>
        </div>
      )}
    </div>
  );
};
