import { PageCountConfig } from "@/components/PDFConstructor/services/types/pdfConfigBlockTypes";
import { withPDFBlock } from "../../PDFBlock";
import { PDFBlockFooter } from "../../PDFBlockFooter";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";
import { useState } from "react";
import { useBlockChanges } from "../../PDFBlockHooks";

import { SelectPicker } from "rsuite";
import { TEXT_ALIGNMENTS } from "@/components/PDFConstructor/common/InputConstants";
import i18n from "@/i18n";
import { PDFCommonInput } from "../../Common/PDFCommonInput";

const PDFPageCountInput = withPDFBlock<PageCountConfig>(
  ({ value, parentId }) => {
    const [_, { deleteBlock, saveBlock }] = usePDFConstructor();
    const [form, setForm] = useState(value);

    useBlockChanges(value, form);

    return (
      <div>
        <SelectPicker
          label={i18n.t(
            "Component.PDFConstructor.Block.Alignment",
            "Ausrichtung auswählen"
          )}
          data={TEXT_ALIGNMENTS.map((option) => ({
            ...option,
            label: option.label(),
          }))}
          cleanable={false}
          value={form.alignment}
          onChange={(value) =>
            setForm((form) => ({
              ...form,
              alignment: value as PageCountConfig["alignment"],
            }))
          }
          searchable={false}
          className="col-span-2"
        />

        <PDFCommonInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock(value, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t(
      "Component.PDFConstructor.Block.Inputs.PageCountInput",
      "Seiteneingabe"
    )
);

export { PDFPageCountInput };
