import i18n from "@/i18n";
import StorageUtils from "@/utils/StorageUtils";
import { ChangelogEntries } from "./changelog-entries";

export const LOCALSTORAGE_KEY_LAST_CHANGELOG_ENTRY = "lastChangelogEntry";

export const hasNewChangelogEntry = () => {
  const lastChangelogEntry = StorageUtils.LocalStorage.get(
    LOCALSTORAGE_KEY_LAST_CHANGELOG_ENTRY
  ) as string;

  if (!lastChangelogEntry) {
    return true;
  }

  const mostRecentEntry = ChangelogEntries[0].date;

  return new Date(lastChangelogEntry) < new Date(mostRecentEntry);
};

export const fetchChangelogFile = async (
  date: string,
  language = i18n.language
) => {
  return fetch(`/changelogs/${date}/${language}.md`)
    .then((response) => response.text())
    .catch((err) => {
      if (language !== "de") {
        return fetch(`/changelogs/de/${date}.md`).then((response) =>
          response.text()
        );
      } else {
        throw err;
      }
    });
};

export const updateLastChangelogEntry = () => {
  const mostRecentEntry = ChangelogEntries[0].date;
  StorageUtils.LocalStorage.save(
    LOCALSTORAGE_KEY_LAST_CHANGELOG_ENTRY,
    mostRecentEntry
  );
};
