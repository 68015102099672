import ArrayUtils from "@/utils/ArrayUtils";
import { ChangelogEntry } from "./changelog.interface";

export const ChangelogEntries: ChangelogEntry[] = ArrayUtils.sortData(
  [
    {
      title: {
        de: "Vertretungsregelung",
        en: "Delegation Rules",
      },
      date: "2024-12-20",
    },
    {
      title: {
        de: "Update Notes",
        en: "Update Notes",
      },
      date: "2024-12-19",
    },
    {
      title: {
        de: "Darkmode",
        en: "Darkmode",
      },
      date: "2025-01-20",
    },
  ],
  {
    key: "date",
    dir: "desc",
  }
);
