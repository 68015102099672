import { useRef, useState } from "react";

import { USABLE_PAGE_WIDTH } from "@/components/PDFConstructor/services/PDFConfigParser";
import { withPDFBlock } from "../../PDFBlock";
import { PDFBlockFooter } from "../../PDFBlockFooter";
import { PDFImagePreview } from "./PDFImagePreview";
import { ImageConfig } from "@/components/PDFConstructor/services/types/pdfConfigBlockTypes";
import BFNumbericSlider from "@/modules/abstract-ui/forms/slider/BFNumbericSlider";
import { useDebouncedEffect } from "@/utils/Hooks";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import i18n from "@/i18n";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";
import { PDFCommonInput } from "../../Common/PDFCommonInput";
import { PDFNumericSlider } from "../../Common/PDFNumericSlider";

const PDFImageInput = withPDFBlock<ImageConfig>(
  ({ value, parentId }) => {
    const [_, { editBlock, deleteBlock, saveBlock }] = usePDFConstructor();

    const [file, setFile] = useState<File | string | null>(value.content);
    const [width, setWidth] = useState(100);
    const imageReference = useRef<HTMLImageElement>();
    const fileReference = useRef<HTMLInputElement>();

    useDebouncedEffect(() => {
      if (file === "") {
        return;
      }

      const naturalWidth = imageReference.current.naturalWidth;
      const naturalHeight = imageReference.current.naturalHeight;

      const ratio = naturalWidth / naturalHeight;

      const insertedImageWidth = (USABLE_PAGE_WIDTH * width) / 100;
      const insertedImageHeight = insertedImageWidth / ratio;

      editBlock({
        ...value,
        content: imageReference.current.src,
        width: insertedImageWidth,
        height: insertedImageHeight,
      });
    }, [file, width]);

    return (
      <div>
        <input
          ref={fileReference}
          style={{
            visibility: "hidden",
            position: "absolute",
          }}
          type="file"
          onChange={(event) => {
            setFile(event.target.files[0] ?? null);
          }}
        />

        <BFButton onClick={() => fileReference.current.click()}>
          {i18n.t("Component.PDFConstructor.Upload", "Hochladen")}
        </BFButton>

        <PDFImagePreview
          file={file}
          imageRef={imageReference}
          width={`${width}%`}
          onLoad={() => {
            if (value.content === "") {
              return;
            }

            const percentage = (value.width / USABLE_PAGE_WIDTH) * 100;
            setWidth(percentage);
          }}
        />

        <PDFNumericSlider
          step={1}
          max={100}
          min={1}
          value={width}
          onChange={(value) => {
            setWidth(value);
          }}
        />

        <PDFCommonInput
          value={value}
          onChange={(key, v) =>
            editBlock({
              ...value,
              [key]: v,
            })
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock(value, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t("Component.PDFConstructor.Block.Inputs.ImageInput", "Bildeingabe")
);

export { PDFImageInput };
