import { ColumnGroupConfig } from "@/components/PDFConstructor/services/types/pdfConfigBlockTypes";
import i18n from "@/i18n";
import { withPDFBlock } from "../../PDFBlock";
import { PDFBlockList } from "../../PDFBlockList";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";
import { PDFColumnInput } from "./PDFColumnInput";
import { PDFBlockFooter } from "../../PDFBlockFooter";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { generateDefault } from "@/components/PDFConstructor/services/PDFConfigParser";
import { PDFCommonInput } from "../../Common/PDFCommonInput";

const PDFColumnGroupInput = withPDFBlock<ColumnGroupConfig>(
  ({ value, id, parentId }) => {
    const [_, { addBlock, deleteBlock, editBlock, saveBlock }] =
      usePDFConstructor();

    return (
      <div>
        <PDFBlockList
          parentId={value.id}
          config={value.content}
          elements={{
            column: PDFColumnInput,
          }}
        />

        <BFButton onClick={() => addBlock(generateDefault("column"), id)}>
          {i18n.t(
            "Component.PDFConstructor.BlockSelect.ModalAddButton",
            "Hinzufügen"
          )}
        </BFButton>

        <PDFCommonInput
          value={value}
          onChange={(key, v) =>
            editBlock({
              ...value,
              [key]: v,
            })
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock(value, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t(
      "Component.PDFConstructor.Block.Inputs.ColumnGroupInput",
      "Spaltengruppe"
    )
);

export { PDFColumnGroupInput };
