import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import LoadPage from "../../../../../../../../components/LoadPage/LoadPage";
import ChartComponent from "../../../../../../../../configurable/components/ChartComponent/ChartComponent";
import i18n from "../../../../../../../../i18n";
import BFButtonToggle from "../../../../../../../../modules/abstract-ui/general/Button/BFButtonToggle";
import { useStatisticQuery } from "../../../../../../../../redux/hooks";
import { hasValue } from "../../../../../../../../utils/Helpers";
import StringUtils from "../../../../../../../../utils/StringUtils";
import "./RiskScenarioChart.scss";
import {
  RiskObjectData,
  RiskScenarioLoanRiskResponse,
} from "./RiskScenarioInterfaces";
import RiskScenarioService from "./RiskScenarioService";
import { getCssVariable } from "@/apps/utils/getCssVariable";
import { useAppTheme } from "@/context/AppThemeContext";

interface RiskScenarioChartProps {
  objectData: RiskObjectData[];
  chartIdentifier?: string;
}
const RiskScenarioChart = (props: RiskScenarioChartProps) => {
  const [options, setOptions] = useState<any>(null);
  const timoutRef = React.useRef<NodeJS.Timeout>(null);
  const [mode, setMode] = useState<"plan" | "is">("plan");
  const appTheme = useAppTheme();

  const loanRiskData = useStatisticQuery<RiskScenarioLoanRiskResponse>(
    "LOAN_RISK",
    null,
    {
      objectIds: props.objectData.map((obj) => obj.objectId),
    }
  );
  const useChartIdentfiier = `${
    props.chartIdentifier || "risk-scenario-chart"
  }-${props.objectData.map((obj) => obj.objectId).join("-")}`;

  useEffect(() => {
    if (timoutRef.current) {
      clearTimeout(timoutRef.current);
    }
    timoutRef.current = setTimeout(() => {
      setOptions(
        riskScenarioCalculateData(
          loanRiskData.data,
          props.objectData,
          mode === "is"
        )
      );
    }, 400);
  }, [loanRiskData?.data, props.objectData, mode, appTheme.currentTheme]);

  return (
    <div className={classNames(`risk-scenario-chart`)}>
      <div className={`header`}>
        <div className={`title`}>
          {i18n.t("cb:RiskScenario.Chart.Title", "Simulation der Finanzierung")}
        </div>
        <BFButtonToggle
          value={mode}
          onChange={setMode}
          buttons={[
            {
              value: "plan",
              text: i18n.t("cb:RiskScenario.Chart.Plan", "Planmiete"),
            },
            {
              value: "is",
              text: i18n.t("cb:RiskScenario.Chart.IS", "Aktuelle Miete"),
            },
          ]}
        />
      </div>
      <div className={`chart-main`}>
        {loanRiskData.loading && <LoadPage />}
        {loanRiskData.error && <div className="error">Error</div>}
        {loanRiskData.data && (
          <>
            <ChartComponent
              loading={options === null}
              options={options || {}}
              identifier={useChartIdentfiier}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RiskScenarioChart;

export const riskScenarioCalculateData = (
  loanData: RiskScenarioLoanRiskResponse,
  objectData: RiskObjectData[],
  useCurrentData: boolean,
  forPdf?: boolean
) => {
  const relevantObjectIds = loanData?.objects.map((e) => e.objectId);
  const relevantObjects = objectData.filter((e) =>
    relevantObjectIds.includes(e.objectId)
  );

  const loanDataOfObjects = objectData
    .filter((e) => relevantObjectIds.includes(e.objectId))
    .map((obj) => {
      return obj.loanData.map((loan) => {
        return {
          ...loan,
          ...loanData.loans.find((e) => e.loanId === loan.simulationForLoan),
          objectId: obj.objectId,
          loanData: RiskScenarioService.calculateLoan(loan),
        };
      });
    })
    .flat(1);

  const startDateCurrentLoans = hasValue(loanData.loanData)
    ? RiskScenarioService.monthDateToDate(loanData.loanData[0]?.date)
    : new Date();
  const endDateCurrentLoans = hasValue(loanData.loanData)
    ? RiskScenarioService.monthDateToDate(
        loanData.loanData[loanData.loanData.length - 1]?.date
      )
    : new Date();

  const endDateOfFollowLoans = RiskScenarioService.monthDateToDate(
    (loanDataOfObjects || [])
      .filter((e) => e.loanData && e.loanData.length > 0)
      .map((e) => e.loanData[e.loanData.length - 1]?.date)
      .sort((a, b) => b.year - a.year || b.month - a.month)[0]
  );

  const endDate =
    endDateOfFollowLoans > endDateCurrentLoans
      ? endDateOfFollowLoans
      : endDateCurrentLoans;

  const balanceCurrentLoans = RiskScenarioService.createArrayWithData(
    startDateCurrentLoans,
    endDate,
    loanData.loanData,
    (e) => e.openAmount,
    true,
    "setZero",
    true
  );

  const interestCurrentLoans = RiskScenarioService.createArrayWithData(
    startDateCurrentLoans,
    endDate,
    loanData.loanData,
    (e) => e.interest,
    true,
    "setZero"
  );
  const repaymentCurrentLoans = RiskScenarioService.createArrayWithData(
    startDateCurrentLoans,
    endDate,
    loanData.loanData,
    (e) => e.repayment,
    true,
    "setZero"
  );

  const estimatedRentIncomes = relevantObjects.map((obj) => ({
    objectId: obj.objectId,
    data: RiskScenarioService.createArrayWithIncome(
      startDateCurrentLoans,
      endDate,
      loanData.objects.find((e) => e.objectId === obj.objectId)?.[
        useCurrentData ? "rentNet" : "rentNetPlan"
      ] / 12,
      obj.estimatedRentNetGrowth,
      obj.estimatedVacancyRate,
      obj.estimatedCostFactor
    ),
  }));

  const aggregatedPlanRentIncomes = RiskScenarioService.aggregateData(
    estimatedRentIncomes.map((e) => e.data.plan)
  );
  const aggregatedRentIncomes = RiskScenarioService.aggregateData(
    estimatedRentIncomes.map((e) => e.data.income)
  );

  const aggregatedCosts = RiskScenarioService.aggregateData(
    estimatedRentIncomes.map((e) => e.data.costs)
  );

  const aggregatedVacancyLoss = RiskScenarioService.aggregateData(
    estimatedRentIncomes.map((e) => e.data.vacancy)
  );

  const aggregatedLoanDataObj = RiskScenarioService.aggregateLoanDataEntries(
    loanDataOfObjects.map((e) => e.loanData)
  );

  const repaymentData = RiskScenarioService.aggregateData([
    repaymentCurrentLoans,
    RiskScenarioService.createArrayWithData(
      startDateCurrentLoans,
      endDate,
      aggregatedLoanDataObj,
      (e) => e.repayment,
      false,
      "setZero"
    ),
  ]);
  const interestData = RiskScenarioService.aggregateData([
    interestCurrentLoans,
    RiskScenarioService.createArrayWithData(
      startDateCurrentLoans,
      endDate,
      aggregatedLoanDataObj,
      (e) => e.interest,
      false,
      "setZero"
    ),
  ]);

  const interestAccumulated = interestData.reduce((prev, curr, index) => {
    if (prev.length === 0) {
      return [curr];
    } else {
      return [...prev, [curr[0], (prev[index - 1][1] || 0) + (curr[1] || 0)]];
    }
  }, []);

  const allCosts = RiskScenarioService.aggregateData([
    aggregatedCosts,
    repaymentData,
    interestData,
  ]);

  const regionsWhereRentIsLowerThanCosts = RiskScenarioService.extractRegions(
    aggregatedRentIncomes,
    allCosts,
    (a, b) => a < b,
    (a, b) => a - b
  );

  const plannedBalance = RiskScenarioService.createArrayWithData(
    startDateCurrentLoans,
    endDate,
    aggregatedLoanDataObj,
    (e) => e.openAmount,
    false,
    "setZero",
    true
  );

  // loanData.loanData.map((e) => [
  //   monthDateToDate(e.date),
  //   e.openAmount / 100,
  // ]);

  return {
    animation: forPdf ? false : true,
    tooltip: {
      trigger: "axis",
      // axisPointer: {
      //   type: "cross",
      //   label: {
      //     backgroundColor: "#6a7985",
      //   },
      // },
      valueFormatter: (value: number) =>
        StringUtils.formatCurrency(value, true),

      formatter: (params, ticket, callback) => {
        const data = Object.fromEntries(params.map((e) => [e.seriesId, e]));

        const surplus =
          (data["rentalPlan"]?.data[1] || 0) -
          (data["vacancyLoss"]?.data[1] || 0) -
          (data["otherCost"]?.data[1] || 0) -
          (data["repayment"]?.data[1] || 0) -
          (data["interest"]?.data[1] || 0);
        return `
          <div class="risk-scenario-tooltip">
            <div class="selected-date">
              ${moment(data["currentBalance"]?.data[0])
                .utc(true)
                .format("MMMM YYYY")}
            </div>
            <div class="section">
            <div class="section-header">
            <div class="section-title">
            ${i18n.t("cb:RiskScenario.Chart.Balance", "Darlehenstand")}
            </div>
            <div class="section-value">
              ${StringUtils.formatCurrency(
                (data["currentBalance"]?.data[1] || 0) +
                  (data["plannedBalance"]?.data[1] || 0)
              )}
            </div>
            </div>
              <div class="values">
              <div class="sub-value">
                <div class="label">
                  ${i18n.t(
                    "cb:RiskScenario.Chart.CurrentLoans",
                    "Laufender Darlehenstand"
                  )}
                </div>
                <div class="value">
                <div class="color-indicator" style="background:${
                  data["currentBalance"]?.color
                }" >
                </div>
                  ${StringUtils.formatCurrency(
                    data["currentBalance"]?.data[1] || 0,
                    true
                  )}
                </div>
              </div>
              <div class="sub-value">
                <div class="label">
                  ${i18n.t(
                    "cb:RiskScenario.Chart.FollowLoan",
                    "Geplanter Darlehenstand"
                  )}
                </div>
                <div class="value">
                <div class="color-indicator" style="background:${
                  data["plannedBalance"]?.color
                }" >
                </div>
                  ${StringUtils.formatCurrency(
                    data["plannedBalance"]?.data[1] || 0,
                    true
                  )}
                </div>
              </div>
              </div>
            </div>


            <div class="section">
            <div class="section-header">
            <div class="section-title">
            ${i18n.t(
              "cb:RiskScenario.Chart.AccumulatedInterest",
              "Angesammelte Zinsen"
            )}
            </div>
            <div class="section-value">
            <div class="color-indicator" style="background:${
              data["accumulatedInterest"]?.color
            }" >
            </div>

              ${StringUtils.formatCurrency(
                data["accumulatedInterest"]?.data[1] || 0
              )}
            </div>
            </div>
            </div>


            <div class="section">
              <div class="section-header">
                <div class="section-title">
                ${i18n.t(
                  "cb:RiskScenario.Chart.RentalIncomeShould",
                  "NKM Soll"
                )}
                </div>
                <div class="section-value">

                <div class="color-indicator" style="background:${
                  data["rentalPlan"]?.color
                }" >
                </div>
                    ${StringUtils.formatCurrency(
                      data["rentalPlan"]?.data[1] || 0
                    )}
                </div>
              </div>

              <div class="values">
              <div class="sub-value">
                <div class="label">
                  ${i18n.t(
                    "cb:RiskScenario.Chart.RentalIncome",
                    "Mieteinnahmen"
                  )}
                </div>
                <div class="value">
                  ${StringUtils.formatCurrency(
                    (data["rentalPlan"]?.data[1] || 0) -
                      (data["vacancyLoss"]?.data[1] || 0),
                    true
                  )}
                </div>
              </div>
              <div class="sub-value">
                <div class="label">
                  ${i18n.t(
                    "cb:RiskScenario.Chart.Vacancy",
                    "Leerstand"
                  )} ${StringUtils.formatPercent(
          data["vacancyLoss"]?.data[1] / data["rentalPlan"]?.data[1],
          true
        )}
                </div>
                <div class="value">
                <div class="color-indicator" style="background:${
                  data["vacancyLoss"]?.color
                }" >
                </div>
                  ${StringUtils.formatCurrency(
                    data["vacancyLoss"]?.data[1] || 0,
                    true
                  )}
                </div>
              </div>

            </div>

            <div class="section">
            <div class="section-header">
            <div class="section-title">
            ${i18n.t("cb:RiskScenario.Chart.Annuity", "Annuität")}
            </div>
            <div class="section-value">
              ${StringUtils.formatCurrency(
                (data["interest"]?.data[1] || 0) +
                  (data["repayment"]?.data[1] || 0)
              )}
            </div>
            </div>
              <div class="values">
              <div class="sub-value">
                <div class="label">
                  ${i18n.t("cb:RiskScenario.Chart.Interest", "Zinsen")}
                </div>
                <div class="value">
                <div class="color-indicator" style="background:${
                  data["interest"]?.color
                }" >
                </div>
                  ${StringUtils.formatCurrency(
                    data["interest"]?.data[1] || 0,
                    true
                  )}
                </div>
              </div>
              <div class="sub-value">
                <div class="label">
                  ${i18n.t("cb:RiskScenario.Chart.Repayment", "Tilgung")}
                </div>
                <div class="value">
                <div class="color-indicator" style="background:${
                  data["repayment"]?.color
                }" >
                </div>
                  ${StringUtils.formatCurrency(
                    data["repayment"]?.data[1] || 0,
                    true
                  )}
                </div>
              </div>
              </div>
            </div>


            <div class="section">
              <div class="section-header">
                <div class="section-title">
                ${i18n.t("cb:RiskScenario.Chart.OtherCosts", "Sonstige Kosten")}
                </div>
                <div class="section-value">
                  <div class="color-indicator" style="background:${
                    data["otherCost"]?.color
                  }" >
                  </div>

                    ${StringUtils.formatCurrency(
                      data["otherCost"]?.data[1] || 0
                    )}
                </div>
              </div>
            </div>


            ${
              moment(data["currentBalance"]?.data[0])
                .utc(true)
                .isSameOrAfter(moment().utc(true), "month")
                ? `
              <div class="section">
                <div class="section-header">
                  <div class="section-title">
                  ${i18n.t(
                    "cb:RiskScenario.Chart.Surplus",
                    "Überschuss/Verlust"
                  )}
                  </div>
                  <div class="section-value ${
                    surplus <= 0 ? "negative" : "positive"
                  }">

                    ${StringUtils.formatCurrency(surplus)}
                  </div>
                </div>
              </div>
`
                : ""
            }
          </div>
        `;
      },
    },

    legend: [
      {
        selectedMode: false,
        data: [
          i18n.t(
            "cb:RiskScenario.Chart.CurrentLoans",
            "Laufender Darlehenstand"
          ),
          i18n.t("cb:RiskScenario.Chart.FollowLoan", "Geplanter Darlehenstand"),
          i18n.t(
            "cb:RiskScenario.Chart.AccumulatedInterest",
            "Angesammelte Zinsen"
          ),
        ],
        textStyle: {
          color: getCssVariable("--text-color-strong"),
        },
      },
      {
        selectedMode: false,
        data: [
          i18n.t("cb:RiskScenario.Chart.RentalPlan", "Miete SOLL"),
          i18n.t("cb:RiskScenario.Chart.VacancyPotential", "Leerstandsverlust"),
          i18n.t("cb:RiskScenario.Chart.OtherCosts", "Sonstige Kosten"),
          i18n.t("cb:RiskScenario.Chart.Repayment", "Tilgung"),
          i18n.t("cb:RiskScenario.Chart.Interest", "Zinsen"),
        ],
        xAxisIndex: 1,
        yAxisIndex: 1,
        bottom: 0,
        textStyle: {
          color: getCssVariable("--text-color-strong"),
        },
      },
    ],
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    axisPointer: {
      link: [
        {
          xAxisIndex: [0, 1],
        },
        {
          xAxisIndex: [1, 0],
        },
      ],
    },
    dataZoom: forPdf
      ? undefined
      : [
          {
            brushSelect: false,
            type: "slider",
            show: true,
            top: "46%",
            realtime: true,
            // start: 0,
            startValue: startDateCurrentLoans,
            // end: 100,
            endValue: endDateOfFollowLoans,
            xAxisIndex: [0, 1],
            zoomOnMouseWheel: true,
            moveOnMouseWheel: false,
            minValueSpan: 3600 * 24 * 1000 * 30 * 12,
          },
          {
            brushSelect: false,
            type: "inside",
            realtime: true,
            // start: 0,
            startValue: startDateCurrentLoans,
            // end: 100,
            endValue: endDateOfFollowLoans,
            xAxisIndex: [0, 1],
            zoomOnMouseWheel: true,
            moveOnMouseWheel: false,
            minValueSpan: 3600 * 24 * 1000 * 30 * 12,
          },
        ],

    grid: [
      {
        top: 40,
        left: 80,
        right: 80,
        height: "32%",
      },
      {
        left: 80,
        right: 80,
        top: "55%",
        height: "35%",
      },
    ],
    xAxis: [
      {
        type: "time",
        splitNumber: 12,
        boundaryGap: false,
        // boundaryGap: false /,
        axisLabel: {
          textStyle: {
            color: getCssVariable("--chart-axis-label-primary"),
          },
        },
      },
      {
        gridIndex: 1,
        type: "time",
        splitNumber: 12,
        boundaryGap: false,
        // boundaryGap: false /,
        axisLabel: {
          textStyle: {
            color: getCssVariable("--chart-axis-label-primary"),
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: (value: number) =>
            StringUtils.formatCurrency(
              value,
              undefined,
              undefined,
              undefined,
              0
            ),
          textStyle: {
            color: getCssVariable("--chart-axis-label-primary"),
          },
        },
      },
      {
        gridIndex: 1,
        type: "value",
        axisLabel: {
          formatter: (value: number) =>
            StringUtils.formatCurrency(
              value,
              undefined,
              undefined,
              undefined,
              0
            ),
          textStyle: {
            color: getCssVariable("--chart-axis-label-primary"),
          },
        },
      },
    ],
    // visualMap: regionsWhereRentIsLowerThanCosts.length > 0 && {
    //   show: true,
    //   dimension: 0,
    //   seriesIndex: 5,
    //   pieces: [
    //     regionsWhereRentIsLowerThanCosts.map((region) => ({
    //       gt: region.from,
    //       lte: region.to,
    //       color: "rgba(255, 0, 0, 1)",
    //     })),
    //   ],
    // },
    series: [
      // CHART 1
      {
        id: "currentBalance",
        name: i18n.t(
          "cb:RiskScenario.Chart.CurrentLoans",
          "Laufender Darlehenstand"
        ),
        type: "line",
        stack: "balance",
        symbolSize: 0,
        emphasis: {
          focus: "series",
        },
        areaStyle: {
          opacity: 1,
          color: getCssVariable("--chart-risk-scenario-current-balance-area"),
        },
        color: getCssVariable("--chart-risk-scenario-current-balance-color"),

        data: balanceCurrentLoans,

        markLine: {
          silent: true,
          symbol: ["none", "none"],
          lineStyle: {
            color: getCssVariable(
              "--chart-risk-scenario-current-balance-line-color"
            ),
          },
          label: {
            formatter: () => i18n.t("Global.Labels.Today", "Heute"),
            color: getCssVariable("--text-color-strong"),
          },
          data: [{ xAxis: new Date() }],
        },
      },
      {
        id: "plannedBalance",
        name: i18n.t(
          "cb:RiskScenario.Chart.FollowLoan",
          "Geplanter Darlehenstand"
        ),
        type: "line",
        stack: "balance",
        areaStyle: {
          opacity: 1,
          color: getCssVariable("--chart-risk-scenario-planned-balance-area"),
        },
        color: getCssVariable("--chart-risk-scenario-planned-balance-color"),
        symbolSize: 0,
        emphasis: {
          focus: "series",
        },
        data: plannedBalance,
      },
      {
        id: "accumulatedInterest",
        name: i18n.t(
          "cb:RiskScenario.Chart.AccumulatedInterest",
          "Angesammelte Zinsen"
        ),
        type: "line",
        stack: "interestAccumulated",
        color: getCssVariable(
          "--chart-risk-scenario-accumulated-interest-color"
        ),
        lineStyle: {
          width: 2,
          type: "dashed",
          dashOffset: 2,
        },
        symbolSize: 0,
        emphasis: {
          focus: "series",
        },
        data: interestAccumulated,
      },

      // CHART 2

      // {
      //   id: "rentalIncome",
      //   name: i18n.t("cb:RiskScenario.Chart.RentIncome", "Mieteinnahmen"),
      //   type: "line",
      //   stack: "income",
      //   xAxisIndex: 1,
      //   yAxisIndex: 1,
      //   color: "#0052de",
      //   lineStyle: {
      //     width: 3,
      //     type: "solid",
      //     // dashOffset: 2,
      //   },
      //   areaStyle: {
      //     opacity: 1,
      //     color: "rgba(63, 35, 203, 0.15)",
      //   },
      //   symbolSize: 0,
      //   emphasis: {
      //     focus: "series",
      //   },
      //   data: aggregatedRentIncomes,

      //   markArea: regionsWhereRentIsLowerThanCosts.length > 0 && {
      //     label: {
      //       offset: [0, 20],
      //       color: "#9c0000ff",
      //     },
      //     itemStyle: {
      //       color: "rgba(255, 0, 0, 0.174)",
      //     },
      //     data: regionsWhereRentIsLowerThanCosts.map((region) => [
      //       {
      //         name: StringUtils.formatCurrency(region.sum),
      //         xAxis: region.from,
      //       },
      //       {
      //         xAxis: region.to,
      //       },
      //     ]),
      //   },
      // },

      {
        id: "interest",
        name: i18n.t("cb:RiskScenario.Chart.Interest", "Zinsen"),
        type: "line",
        stack: "costs",
        areaStyle: {
          // opacity: forPdf ? 0.1 : 1,
          color: getCssVariable("--chart-risk-scenario-interest-area"),
        },
        symbolSize: 0,
        xAxisIndex: 1,
        yAxisIndex: 1,

        markLine: {
          silent: true,
          symbol: ["none", "none"],
          lineStyle: {
            color: getCssVariable("--chart-risk-scenario-interest-line-color"),
          },
          label: {
            show: false,
          },
          data: [{ xAxis: new Date() }],
        },
        color: getCssVariable("--chart-risk-scenario-interest-color"),
        emphasis: {
          focus: "series",
        },
        data: interestData,
      },
      {
        id: "repayment",
        name: i18n.t("cb:RiskScenario.Chart.Repayment", "Tilgung"),
        type: "line",
        stack: "costs",
        symbolSize: 0,
        xAxisIndex: 1,
        yAxisIndex: 1,
        color: getCssVariable("--chart-risk-scenario-repayment-color"),
        areaStyle: {
          // opacity: forPdf ? 0.1 : 1,
          color: getCssVariable("--chart-risk-scenario-repayment-area"),
          // color: "rgba(54, 136, 51, 0.495)",
        },
        emphasis: {
          focus: "series",
        },
        data: repaymentData,
      },
      {
        id: "otherCost",
        name: i18n.t("cb:RiskScenario.Chart.OtherCosts", "Sonstige Kosten"),
        type: "line",
        stack: "costs",
        xAxisIndex: 1,
        yAxisIndex: 1,
        color: getCssVariable("--chart-risk-scenario-other-cost-color"),
        areaStyle: {
          // opacity: forPdf ? 0.1 : 1,
          // color: "rgba(195, 34, 34, 0.426)",
          color: getCssVariable("--chart-risk-scenario-other-cost-area"),
        },
        symbolSize: 0,
        emphasis: {
          focus: "series",
        },
        data: aggregatedCosts,
      },

      {
        id: "vacancyLoss",
        name: i18n.t(
          "cb:RiskScenario.Chart.VacancyPotential",
          "Leerstandsverlust"
        ),
        type: "line",
        stack: "costs",
        xAxisIndex: 1,
        yAxisIndex: 1,
        color: getCssVariable("--chart-risk-scenario-vacancy-loss-color"),
        lineStyle: {
          width: 1,
          type: "solid",
        },
        areaStyle: {
          // opacity: forPdf ? 0.1 : 1,
          color: getCssVariable("--chart-risk-scenario-vacancy-loss-area"),
        },
        symbolSize: 0,
        emphasis: {
          focus: "series",
        },
        data: aggregatedVacancyLoss,
      },

      {
        id: "rentalPlan",
        name: i18n.t("cb:RiskScenario.Chart.RentalPlan", "Miete SOLL"),
        type: "line",
        stack: "none",
        xAxisIndex: 1,
        yAxisIndex: 1,
        color: getCssVariable("--chart-risk-scenario-rental-plan-color"),
        lineStyle: {
          width: 3,
          type: "solid",
          // dashOffset: 2,
        },
        // areaStyle: {
        //   opacity: 1,
        //   color: "rgba(63, 35, 203, 0.15)",
        // },
        symbolSize: 0,
        emphasis: {
          focus: "series",
        },
        data: aggregatedPlanRentIncomes,

        markArea: regionsWhereRentIsLowerThanCosts.length > 0 && {
          label: {
            offset: [0, 20],
            color: getCssVariable("--chart-risk-scenario-rental-plan-label"),
            overflow: "truncate",
            fontSize: 9,
          },
          itemStyle: {
            color: getCssVariable(
              "--chart-risk-scenario-rental-plan-item-color"
            ),
            borderWidth: 1,
            borderColor: getCssVariable(
              "--chart-risk-scenario-rental-plan-item-border"
            ),
          },
          data: regionsWhereRentIsLowerThanCosts.map((region, index) => [
            {
              name: StringUtils.formatCurrency(region.sum),
              label: {
                distance: 5 - (index % 4) * 10,
              },
              xAxis: region.from,
            },
            {
              xAxis: region.to,
            },
          ]),
        },
      },
    ],
  };
};

const DECAL_DEFAULT_DATA = {
  show: true,
  dashArrayX: [1.4, 0],
  dashArrayY: [1.4, 2.8],
  symbolSize: 1,
  rotation: 45,
  symbol: "rect",
};
