import { AssetTypes } from "@/model/AssetTypes";
import CacheService from "@/services/CacheService";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ModalManager from "../../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../../i18n";
import BFAssignment, {
  Assignment,
} from "../../../../../modules/abstract-ui/forms/assignment/BFAssignment";
import AutosizeTextarea from "../../../../../modules/abstract-ui/forms/autosize-textarea/AutosizeTextarea";
import BFCheckbox from "../../../../../modules/abstract-ui/forms/checkbox/BFCheckbox";
import BFSelect from "../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import DataBusDefaults from "../../../../../services/DataBusDefaults";
import InvoiceService, { DeclineType } from "../../InvoiceService";
import { RAInvoiceProcessHistoryById } from "../InvoiceProcessHistory/RAInvoiceProcessHistory";
import "./RADeclineInvoice.scss";

const DeclineHelperTags = () => ({
  correction: [
    i18n.t("ra:DeclineInvoice.CorrectionReasons.WrongCompany"),
    i18n.t("ra:DeclineInvoice.CorrectionReasons.WrongAmount"),
    i18n.t("ra:DeclineInvoice.CorrectionReasons.WrongData"),
  ],
  decline: [
    i18n.t("ra:DeclineInvoice.DeclineReasons.WillNotBePaid"),
    i18n.t("ra:DeclineInvoice.DeclineReasons.DuplicateInvoice"),
    i18n.t("ra:DeclineInvoice.DeclineReasons.NotAnInvoice"),
    i18n.t("ra:DeclineInvoice.DeclineReasons.FraudAttempt"),
  ],
});

interface DeclineOverlayProps {
  comment?: string;
  onCommentChange?: (comment: string) => void;
  onClose: () => void;
  selected?: boolean;
  invoiceId: string;
  task?: boolean;
  onFinish?: () => void;
  showProcess?: boolean;
}
const RADeclineInvoice = (props: DeclineOverlayProps) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState<DeclineType | null>("correction");
  const [loading, setLoading] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [comment, setComment] = useState<string>(props.comment || "");

  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [assignmentError, setAssignmentError] = useState(false);
  const [needNewInvoice, setNeedNewInvoice] = useState(false);
  const [needStorno, setNeedStorno] = useState(false);

  useEffect(() => {
    if (props.comment !== comment) {
      setComment(props.comment || "");
    }
  }, [props.comment]);
  useEffect(() => {
    if (props.selected === false) {
      close();
    }
  }, [props.selected]);

  const assignmentChange = (value: Assignment[]) => {
    setAssignments(value);
    setAssignmentError(value.length === 0);
  };
  const reasonChange = (value: DeclineType) => {
    setReason(value);
  };
  const commentChange = (value: string) => {
    setComment(value);
    props.onCommentChange && props.onCommentChange(value);
    if (value === "") {
      setCommentError(true);
    } else {
      setCommentError(false);
    }
  };
  const close = () => {
    props.onClose();
    setCommentError(false);
    setAssignments([]);
    setReason("correction");
    setNeedStorno(false);
    setNeedNewInvoice(false);
  };
  const decline = async () => {
    if (!comment || (reason === "correction" && assignments.length === 0)) {
      if (!comment) {
        setCommentError(true);
      }
      if (assignments.length === 0) {
        setAssignmentError(true);
      }
      return;
    }
    const doDecline = async (force: boolean) => {
      await InvoiceService.declineInvoiceTask(
        props.invoiceId,
        reason === "decline"
          ? {
              declineType: "decline",
              comment,
            }
          : {
              declineType: "correction",
              comment,
              needNewInvoice,
              needStorno,
              userAssignee: assignments
                .filter((e) => e.type === "user")
                .map((e) => e.id),
              teamAssignee: assignments
                .filter((e) => e.type === "team")
                .map((e) => e.id),
            },
        force
      );

      CacheService.getData({
        oType: "asset",
        id: props.invoiceId,
        assetType: AssetTypes.Invoice,
        forceReload: true,
        silentReload: true,
      });
    };
    try {
      setLoading(true);

      await doDecline(false);
      props.onFinish && props.onFinish();
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 300) {
        ModalManager.confirm({
          message: i18n.t(
            "ra:DeclineInvoice.declineWhileInPayment",
            "Die Rechnung befindet sich gerade in Zahlung. Mit Ablehnen der Rechnung wird die Zahlung abgebrochen. Wollen Sie die Rechnung ablehnen?"
          ),
          confirmButtonText: i18n.t(
            "ra:DeclineInvoice.forceDecline",
            "Rechnung ablehnen"
          ),
          onConfirm: async () => {
            try {
              await doDecline(true);
              props.onFinish && props.onFinish();
              setLoading(false);
            } catch (err) {
              DataBusDefaults.toast({
                type: "error",
                text: i18n.t("ra:DeclineInvoice.errorSubmit"),
              });
              setLoading(false);
            }
          },
          onAbort: () => {
            setLoading(false);
          },
        });
      } else {
        DataBusDefaults.toast({
          type: "error",
          text: i18n.t("ra:DeclineInvoice.errorSubmit"),
        });
        setLoading(false);
      }
    }
  };
  return (
    <div className={`ra-decline-invoice`}>
      <div className={`decline-content`}>
        {props.showProcess && (
          <div className={`process`}>
            <RAInvoiceProcessHistoryById
              invoiceId={props.invoiceId}
              maxHistoryHeight={450}
            />
          </div>
        )}
        <div className="decline-form">
          <div className="title">{i18n.t("ra:DeclineInvoice.title")}</div>
          <div className="reason">
            <BFSelect
              label={`${i18n.t("ra:DeclineInvoice.reason.title")}*`}
              value={reason}
              cleanable={false}
              onChange={reasonChange}
              data={[
                {
                  value: "correction",
                  label: i18n.t("ra:DeclineInvoice.reason.correction"),
                },
                {
                  value: "decline",
                  label: i18n.t("ra:DeclineInvoice.reason.decline"), //"Ablehnen",
                },
              ]}
            />
          </div>

          {reason === "correction" && (
            <div className="additional-form">
              <BFCheckbox
                checked={needNewInvoice}
                onChange={(_v, checked) => setNeedNewInvoice(checked)}
              >
                {i18n.t("ra:DeclineInvoice.newInvoiceNeeded")}
              </BFCheckbox>
              <BFCheckbox
                checked={needStorno}
                onChange={(_v, checked) => setNeedStorno(checked)}
              >
                {i18n.t("ra:DeclineInvoice.stornoNeeded")}
              </BFCheckbox>
              <div className={`assignment-wrapper`}>
                <BFAssignment
                  identifier={`bf-assignment-${props.invoiceId}}`}
                  error={assignmentError}
                  assignmentType="both"
                  asOverlay
                  label={`${i18n.t("ra:DeclineInvoice.assignment")}*`}
                  onChange={assignmentChange}
                  value={assignments}
                />
              </div>
            </div>
          )}

          <div className={`comment `}>
            <AutosizeTextarea
              error={commentError}
              value={comment}
              onChange={commentChange}
              label={`${i18n.t("ra:DeclineInvoice.comment")}*`}
              minHeight={75}
              maxHeight={130}
              placeholder={i18n.t("ra:DeclineInvoice.commentPlaceholder")}
            />
          </div>
          {reason && (
            <div className="helper-tags">
              {DeclineHelperTags()
                [reason] // .filter((e) => !ignore.find((ignoreEntry) => ignoreEntry === e))
                .map((tag) => (
                  <BFButton
                    disabled={
                      comment.toLowerCase().indexOf(tag.toLowerCase()) !== -1
                    }
                    appearance="outline"
                    className="tag"
                    size="xs"
                    onClick={() => {
                      // setIgnore([...ignore, tag]);
                      commentChange(
                        comment + (comment.length > 0 ? " " : "") + tag
                      );
                    }}
                  >
                    {tag}
                  </BFButton>
                ))}
            </div>
          )}
        </div>
      </div>

      <div className="actions">
        <BFButton appearance="outline" onClick={close}>
          {i18n.t("Global.Buttons.cancel")}
        </BFButton>
        <BFButton loading={loading} appearance="primary" onClick={decline}>
          {i18n.t("Global.Buttons.decline")}
        </BFButton>
      </div>
    </div>
  );
};
export default RADeclineInvoice;
