export enum CSS_CLASSES {
  COLOR_NEGATIVE = "__color-negative",
  COLOR_POSTIIVE = "__color-positive",
  COLOR_NEUTRAL = "__color-neutral",
}
class CSSUtilsClass {
  getColorClassForNumber(val: number) {
    if (val > 0) {
      return CSS_CLASSES.COLOR_POSTIIVE;
    } else if (val < 0) {
      return CSS_CLASSES.COLOR_NEGATIVE;
    } else {
      return CSS_CLASSES.COLOR_NEUTRAL;
    }
  }
  getColorClassForNumberPercent(val: number) {
    if (val > 1) {
      return CSS_CLASSES.COLOR_POSTIIVE;
    } else if (val < 1) {
      return CSS_CLASSES.COLOR_NEGATIVE;
    } else {
      return CSS_CLASSES.COLOR_NEUTRAL;
    }
  }
  invertColor(value: string) {
    const padZero = (v: string) => {
      return v.length === 2 ? v : `0${v}`;
    };

    if (value.indexOf("#") === 0) {
      value = value.slice(1);
    }
    // convert 3-digit value to 6-digits.
    if (value.length === 3) {
      value = value[0] + value[0] + value[1] + value[1] + value[2] + value[2];
    }
    if (value.length !== 6) {
      throw new Error("Invalid VALUE color.");
    }
    // invert color components
    const r = (255 - parseInt(value.slice(0, 2), 16)).toString(16);
    const g = (255 - parseInt(value.slice(2, 4), 16)).toString(16);
    const b = (255 - parseInt(value.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
  }
}
const CSSUtils = new CSSUtilsClass();
export default CSSUtils;
