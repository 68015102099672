import { BlockConfig, Config } from "../services/types/pdfConfigBlockTypes";
import { containsBlock, findBlock } from "./PDFReducerUtils";

export type PDFConstructorState = {
  content: Config;
};

export enum PDFConstructorActions {
  ADD = "add",
  SAVE = "save",
  EDIT = "edit",
  DELETE = "delete",
  SWAP = "swap",
  REVERT_SWAP = "revert-swap",
  TEMPLATE = "template",
}

type Action =
  | {
      type: PDFConstructorActions.SAVE;
      payload: {
        id: BlockConfig["id"];
      };
    }
  | {
      type: PDFConstructorActions.EDIT | PDFConstructorActions.DELETE;
      payload: BlockConfig;
    }
  | {
      type: PDFConstructorActions.ADD;
      payload: {
        block: BlockConfig;
        parentId: BlockConfig["id"];
      };
    }
  | {
      type: PDFConstructorActions.SWAP;
      payload: {
        dragId: BlockConfig["id"];
        hoverId: BlockConfig["id"];
        parentId: BlockConfig["id"];
      };
    }
  | {
      type: PDFConstructorActions.TEMPLATE;
      payload: {
        config: Config;
      };
    }
  | {
      type: PDFConstructorActions.REVERT_SWAP;
      payload: {
        blockId: BlockConfig["id"];
        originalIndex: number;
        parentId: BlockConfig["id"];
      };
    };

export const pdfConstructorReducer = (
  state: PDFConstructorState,
  action: Action
) => {
  switch (action.type) {
    case PDFConstructorActions.ADD: {
      if (action.payload.parentId === 0) {
        state.content.push(action.payload.block);
        break;
      }

      const parent = findBlock(action.payload.parentId, state);
      if (parent) {
        parent.content.push(action.payload.block as any);
      }
      break;
    }
    case PDFConstructorActions.EDIT: {
      const block = findBlock(action.payload.id, state);
      if (block) {
        for (const key of Object.keys(action.payload)) {
          block[key] = action.payload[key];
        }
      }
      break;
    }
    case PDFConstructorActions.DELETE: {
      const parentBlock = containsBlock(action.payload.id, state);

      if (parentBlock && "content" in parentBlock) {
        parentBlock.content = parentBlock.content.filter(
          (block) => block.id !== action.payload.id
        );
      }
      break;
    }
    case PDFConstructorActions.SWAP: {
      const parent = findBlock(action.payload.parentId, state) ?? state;

      if (!parent || !("content" in parent)) {
        break;
      }

      const dragIndex = parent.content.findIndex(
        (block) => block.id === action.payload.dragId
      );
      const hoverIndex = parent.content.findIndex(
        (block) => block.id === action.payload.hoverId
      );

      if (dragIndex === -1 || hoverIndex === -1) {
        break;
      }

      const [movedCard] = parent.content.splice(dragIndex, 1);
      parent.content.splice(hoverIndex, 0, movedCard);
      break;
    }
    case PDFConstructorActions.REVERT_SWAP: {
      const parent = findBlock(action.payload.parentId, state) ?? state;

      if (!parent || !("content" in parent)) {
        break;
      }

      const dragIndex = parent.content.findIndex(
        (block) => block.id === action.payload.blockId
      );

      if (dragIndex === -1) {
        break;
      }

      const [movedCard] = parent.content.splice(dragIndex, 1);
      parent.content.splice(action.payload.originalIndex, 0, movedCard);
      break;
    }
    case PDFConstructorActions.SAVE: {
      const block = findBlock(action.payload.id, state);
      if (block) {
        block.isEmpty = false;
      }
      break;
    }
    case PDFConstructorActions.TEMPLATE: {
      const parent = state.content;

      parent.push(...action.payload.config);
      break;
    }
  }
};
