import classNames from "classnames";
import { useDispatch } from "react-redux";
import { CDNImagePropsWithContent } from "../../../../../../components/CDNImageGallery/CDNImageGallery";
import FilePreviewComponent from "../../../../../../components/FilePreviewComponent/FilePreviewComponent";
import WrappedText from "../../../../../../components/WrappedText/WrappedText";
import ListComponent from "../../../../../../configurable/components/ListComponent/ListComponent";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import BFOverlay from "../../../../../../modules/abstract-ui/general/whisper/BFOverlay";
import BfIcon from "../../../../../../modules/abstract-ui/icon/BfIcon";
import { openGallery } from "../../../../../../redux/actions/ui-config/ui-config-actions";
import MQ from "../../../../../../utils/MatchQueryUtils";
import StringUtils from "../../../../../../utils/StringUtils";
import { APProjectConstructionDiary } from "./APConstructionDiariesInterfaces";
import "./APTaskConstructionDiariesEntries.scss";

interface APTaskConstructionDiariesEntriesProps {
  groupIds: string[];
  projectId: string;
  onClose: () => void;
}
const APTaskConstructionDiariesEntries = (
  props: APTaskConstructionDiariesEntriesProps
) => {
  const dispatch = useDispatch();

  return (
    <div className={classNames(`ap-task-construction-diaries-entries`)}>
      <div className={`image-list-container`}>
        <ListComponent
          identifier={`task-construction-diaries-entries-${props.groupIds.join(
            "-"
          )}`}
          initialVisibleSort={{
            key: "data.entryDate",
            dir: "desc",
          }}
          asPost
          cleanupOnUnmount
          assetType={AssetTypes.ActivityRelated.ConstructionDiary}
          additionalMatchQuery={MQ.and(
            MQ.eq("data.projectId", props.projectId),
            MQ.in("data.entries.group.linkedId", props.groupIds)
          )}
          render={(node: APProjectConstructionDiary) => {
            const images = [];

            node.data.entries
              .filter(
                (e) =>
                  e.group.type === "group" &&
                  props.groupIds.includes(e.group.linkedId)
              )
              .filter((e) => e.status === "active")
              .forEach((entry) => {
                entry.imageCdnLinks
                  .map((cdnLink) =>
                    node.data.images.find((i) => i.linkToCdn === cdnLink)
                  )
                  .filter((e) => e && e.status === "active")
                  .map((image) => ({
                    cdn: node.cdn?.find((e) => e._id === image.linkToCdn),
                    image: node.data?.images?.find(
                      (e) => e.linkToCdn === image.linkToCdn
                    ),
                  }))
                  .map(
                    (imgEntry) =>
                      ({
                        assetField: "data.images",
                        assetId: node._id,
                        assetType: AssetTypes.ActivityRelated.ConstructionDiary,
                        hasFolderReadPermissions: true,
                        cdnId: imgEntry.image.linkToCdn,
                        fileKey: imgEntry.cdn.key,
                        renderContent: imgEntry.image.note ? (
                          <WrappedText text={imgEntry.image.note} />
                        ) : null,
                      } as CDNImagePropsWithContent)
                  )
                  .forEach((image) => images.push(image));
              });

            if (images.length === 0) {
              return null;
            }

            return (
              <div className={`cb-entry`}>
                <div className={`title`}>
                  <div className={`date`}>
                    {StringUtils.formatDate(node.data.entryDate)}
                  </div>
                  {/* TODO add details for construciton diary */}
                  {/* <BFButton
                    appearance="link"
                    onClick={() => {}}
                    size="xs"
                    className={`margin-left-20`}
                    noPadding
                  >
                    {i18n.t(
                      "apTemplate:ConstructionDiary.showConstructionDiary",
                      "Bautagebuch anzeigen"
                    )}
                  </BFButton> */}
                </div>
                <div className={`images`}>
                  {images.map((image, index) => {
                    const cmp = (showInfo: boolean) => (
                      <div className={`image`} key={image.cdnId}>
                        <FilePreviewComponent
                          type="cdn"
                          asset={node}
                          cdnID={image.cdnId}
                          assetField={image.assetField}
                          assetType={image.assetType}
                          hasFolderPermissions
                          height={60}
                          width={60}
                          onClick={() => {
                            dispatch(
                              openGallery({
                                id: `ap-activity-details-gallery-${node._id}`,
                                images: images,
                                initialActiveIndex: index,
                              })
                            );
                          }}
                        />
                        {showInfo && (
                          <div className={`info-indicator`}>
                            <BfIcon
                              type="bold"
                              data="messages-bubble"
                              size="xxs"
                            />
                          </div>
                        )}
                      </div>
                    );

                    if (!image.renderContent) return cmp(false);
                    else
                      return (
                        <BFOverlay
                          speaker={
                            <div className={`padding-10`}>
                              {image.renderContent}
                            </div>
                          }
                        >
                          {cmp(true)}
                        </BFOverlay>
                      );
                  })}
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default APTaskConstructionDiariesEntries;
