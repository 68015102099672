import { Fragment, useEffect, useState } from "react";
import AssetLoader from "../../../../../components/AssetLoader/AssetLoader";
import UserAvatar from "../../../../../components/AvatarComponent/UserAvatar";
import { TaskAsset } from "../../../../../model/general-assets/TaskAsset";
import BFOverlay from "../../../../../modules/abstract-ui/general/whisper/BFOverlay";
import BfIcon from "../../../../../modules/abstract-ui/icon/BfIcon";
import StringUtils from "../../../../../utils/StringUtils";
import { RAInvoice } from "../../RAInterfaces";
import { RAInvoiceProcessHistoryContent } from "./RAInvoiceProcessHistory";
import "./RAInvoiceProcessHistoryInline.scss";
import {
  CurrentProcessTypeFormatter,
  PastProcessTypeFormatter,
  ProcessEntryType,
} from "./RAInvoiceProcessHistoryUtils";

interface RAInvoiceProcessHistoryInlineProps {
  invoice: RAInvoice;
}
const RAInvoiceProcessHistoryInline = (
  props: RAInvoiceProcessHistoryInlineProps
) => {
  return props.invoice?.intern?.invoiceTaskId ? (
    <AssetLoader
      assetType="task"
      id={props.invoice?.intern.invoiceTaskId}
      // renderError={() => (
      //   <RAInvoiceProcessHistoryContent invoice={props.invoice} />
      //   // <div>error</div>
      // )}
      render={(task) => {
        return (
          <RAInvoiceProcessHistoryInlineContent
            invoice={props.invoice}
            task={task}
          />
        );
      }}
    />
  ) : (
    <RAInvoiceProcessHistoryInlineContent invoice={props.invoice} />
  );
};

export default RAInvoiceProcessHistoryInline;

const RAInvoiceProcessHistoryInlineContent = (props: {
  invoice: RAInvoice;
  task?: TaskAsset;
}) => {
  const [processEntries, setProcessEntries] = useState<ProcessEntryType[]>([]);

  useEffect(() => {
    setProcessEntries([
      ...(props.invoice?.data.history
        .sort((a, b) => Number(new Date(a.date)) - Number(new Date(b.date)))
        .map((e) => PastProcessTypeFormatter(e, props.invoice))
        .filter((e) => e !== null) || []),
      ...CurrentProcessTypeFormatter(props.invoice, props.task),
    ]);
  }, [props.invoice, props.task]);
  return (
    <BFOverlay
      enterable
      speaker={
        <div className={`ra-invoice-process-history-inline-overlay`}>
          <RAInvoiceProcessHistoryContent
            maxHistoryHeight={400}
            invoice={props.invoice}
            task={props.task}
          />
        </div>
      }
    >
      <div className={`ra-invoice-process-history-inline`}>
        {processEntries.map((entry, i) => (
          <Fragment key={i}>
            <div key={i} className={`ra-invoice-process-history-inline-entry`}>
              {entry.type === "past" && (
                <>
                  <div className={`status-wrapper`}>
                    <div className={`status-icon`}>
                      {entry.user === undefined ? (
                        <div className={`system-avatar`}>
                          <BfIcon
                            type="light"
                            data="cash-payment-bills-1"
                            size={"xs"}
                          />
                        </div>
                      ) : (
                        <UserAvatar size={24} id={entry.user} />
                      )}
                    </div>
                    {i === processEntries.length - 1 && (
                      <div className={`label`}>
                        <div className={`status`}>{entry.statusText}</div>
                        <div className={`date`}>
                          {StringUtils.formatDate(entry.date)}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {entry.type === "current" && (
                <>
                  <div className={`status-wrapper`}>
                    <div className={`status-icon`}>
                      <div className={`icon-container`}>
                        <BfIcon type="light" data={entry.icon} />
                      </div>
                    </div>

                    <div className={`label`}>
                      <div className={`status`}>{entry.statusText}</div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {i < processEntries.length - 1 && <div className={`line`} />}
          </Fragment>
        ))}
      </div>
    </BFOverlay>
  );
};
