import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import { PageConfig } from "../../services/types/pdfConfigBlockTypes";
import { withPDFBlock } from "../PDFBlock";
import { useState } from "react";

import i18n from "@/i18n";
import { PDFBlockFooter } from "../PDFBlockFooter";
import { usePDFConstructor } from "../../context/PDFConstructorContext";
import { useBlockChanges } from "../PDFBlockHooks";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";

const orientation = [
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.PageOrientation.Landscape",
        "Querformat"
      ),
    value: "landscape",
  },
  {
    label: () =>
      i18n.t(
        "Component.PDFConstructor.Block.PageOrientation.Portrait",
        "Hochformat"
      ),
    value: "portrait",
  },
];

const PDFPageInput = withPDFBlock<PageConfig>(
  ({ value, parentId }) => {
    const [form, setForm] = useState(value);
    const [_, { deleteBlock, editBlock, saveBlock }] = usePDFConstructor();

    useBlockChanges(value, form);

    return (
      <div className="grid-col-2">
        <BFSelect
          label="Page Orientation"
          data={orientation.map((option) => ({
            ...option,
            label: option.label(),
          }))}
          value={value.pageOrientation}
          onChange={(orientation) =>
            editBlock({
              ...value,
              pageOrientation: orientation,
            })
          }
        />

        <BFInput
          label={i18n.t(
            "Component.PDFConstructor.Block.Margins.MarginTop",
            "Oberer Rand"
          )}
          placeholder="40"
          value={form.marginTop}
          type="number"
          onChange={(value) =>
            setForm((form) => ({
              ...form,
              marginTop: Number(value),
            }))
          }
        />
        <BFInput
          label={i18n.t(
            "Component.PDFConstructor.Block.Margins.MarginRight",
            "Rechter Rand"
          )}
          placeholder="40"
          value={form.marginRight}
          type="number"
          onChange={(value) =>
            setForm((form) => ({
              ...form,
              marginRight: Number(value),
            }))
          }
        />
        <BFInput
          label={i18n.t(
            "Component.PDFConstructor.Block.Margins.MarginBottom",
            "Unterer Rand"
          )}
          placeholder="40"
          value={form.marginBottom}
          type="number"
          onChange={(value) =>
            setForm((form) => ({
              ...form,
              marginBottom: Number(value),
            }))
          }
        />
        <BFInput
          label={i18n.t(
            "Component.PDFConstructor.Block.Margins.MarginLeft",
            "Linker Rand"
          )}
          placeholder="40"
          value={form.marginLeft}
          type="number"
          onChange={(value) =>
            setForm((form) => ({
              ...form,
              marginLeft: Number(value),
            }))
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          className="col-span-2"
          onSave={() => saveBlock(value, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t(
      "Component.PDFConstructor.Block.Inputs.PageInput",
      "Seiteneinstellungen"
    )
);

export { PDFPageInput };
