import { APActivity } from "@/apps/tatar/activityApp/ActivityInterfaces";
import UserAvatar from "@/components/AvatarComponent/UserAvatar";
import Username from "@/components/AvatarComponent/Username";
import ModalManager from "@/components/ModalComponent/ModalManager";
import DebugDataComponent from "@/debug/DebugDataComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import { ApproveDamangeClaimTaskAssetUserSelection } from "@/model/general-assets/TaskAsset";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import BFOverlay from "@/modules/abstract-ui/general/whisper/BFOverlay";
import DisplayCustomFields from "@/modules/customfields/DisplayCustomFields";
import DataBusDefaults from "@/services/DataBusDefaults";
import StringUtils from "@/utils/StringUtils";
import classNames from "classnames";
import { ActivityApplicationConstants } from "../../../../ActivityHooks";
import { APOffer } from "../APOffer.Interface";
import "./APOfferEntry.scss";
import APOfferForm from "./APOfferForm";

interface APOfferEntryProps {
  editable?: boolean;
  offer: APOffer;
  activity?: APActivity;
  constants: ActivityApplicationConstants;
  userSelections?: ApproveDamangeClaimTaskAssetUserSelection[];
}
const APOfferEntry = (props: APOfferEntryProps) => {
  const fields = props.constants?.fields?.assetApproval?.fields || [];

  return (
    <div className={classNames(`ap-offer-entry`)}>
      <DebugDataComponent data={props.offer} />
      {props.editable && (
        <div className={`action-row`}>
          <BFButton
            appearance="link"
            noPadding
            onClick={() => {
              ModalManager.show({
                noPadding: true,
                size: "sm",
                content: (state, setState, close) => (
                  <APOfferForm
                    constants={props.constants}
                    activityId={props.offer.data.linkedActivity.assetId}
                    onClose={close}
                    asset={props.offer}
                    onSuccess={(asset) => {
                      DataBusDefaults.reload({
                        identifiers: [
                          `new`,
                          "accepted",
                          "declined",
                          "archived",
                        ].map((id) => `offer-list-${id}`),
                      });
                    }}
                  />
                ),
              });
            }}
          >
            {i18n.t("Global.Buttons.edit")}
          </BFButton>
        </div>
      )}
      <div className={`main-row`}>
        <div className={`name`}>{props.offer.data.displayName}</div>

        <div className={`value`}>
          {StringUtils.formatCurrency(props.offer.data.value)}
        </div>
      </div>
      <DisplayCustomFields
        prefix="meta"
        asset={props.offer}
        assetType={AssetTypes.ActivityRelated.Offer}
        fields={fields}
        values={props.offer.data.meta}
      />

      {props.userSelections && props.userSelections?.length > 0 && (
        <div className={`user-selections`}>
          <div className={`description`}>
            {i18n.t(
              "apTemplate:ActivitiesOffers.UserSelectionLabel",
              "Auswahl",
              {
                ns: [props.constants?.assetType, "apTemplate"],
              }
            )}
            :
          </div>
          {props.userSelections.map((select) => (
            <div className={`selection-entry`} key={select.id}>
              <BFOverlay
                marginTop={5}
                placement="bottomEnd"
                trigger="hover"
                speaker={
                  <div className={`ap-activity-user-select`}>
                    <div className={`description`}>
                      {i18n.t(
                        "apTemplate:ActivitiesOffers.UserSelectionDescription",
                        "Ausgewählt bei Genehmigung",
                        {
                          ns: [props.constants?.assetType, "apTemplate"],
                        }
                      )}
                    </div>
                    <div className={`user-name`}>
                      <Username id={select.user} />
                    </div>
                    <div className={`date`}>
                      {StringUtils.formatDate(select.date)}
                    </div>
                  </div>
                }
              >
                <div>
                  <UserAvatar id={select.user} size={20} />
                </div>
              </BFOverlay>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default APOfferEntry;
