import { useRef, useState } from "react";

type PDFObjectGalleryItemProps = {
  url: string;
  isSelected: boolean;
  onSelectChange: (value: string) => void;
};

const PDFObjectGalleryItem: React.FC<PDFObjectGalleryItemProps> = ({
  url,
  isSelected,
  onSelectChange,
}) => {
  const reference = useRef<HTMLImageElement>();
  const [isError, setIsError] = useState(false);

  if (isError) {
    return (
      <div className="object-gallery--item object-gallery--item__error">
        Failed to load an image
      </div>
    );
  }

  return (
    <button
      className="object-gallery--item"
      type="button"
      data-selected={isSelected}
      onClick={async () => {
        onSelectChange(url);
      }}
    >
      <img
        src={url}
        alt={url}
        ref={reference}
        crossOrigin="anonymous"
        onError={(error) => {
          console.log(error);
          setIsError(true);
        }}
      />
    </button>
  );
};

export { PDFObjectGalleryItem };
