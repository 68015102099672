export const FileMimeType = {
  "application/eml": "eml",
  "application/vnd.ms-outlook": "msg",
  "message/rfc822": "eml",

  "audio/x-mpeg": "mpega",
  "application/postscript": "ps",
  "audio/x-aiff": "aiff",
  "application/x-aim": "aim",
  "image/x-jg": "art",
  "video/x-ms-asf": "asx",
  "audio/basic": "ulw",
  "video/x-msvideo": "avi",
  "video/x-rad-screenplay": "avx",
  "application/x-bcpio": "bcpio",
  "application/octet-stream": "exe",
  "image/bmp": "dib",
  "text/html": "html",
  "application/x-cdf": "cdf",
  "application/pkix-cert": "cer",
  "application/java": "class",
  "application/x-cpio": "cpio",
  "application/x-csh": "csh",
  "text/css": "css",
  "application/msword": "doc",
  "application/xml-dtd": "dtd",
  "video/x-dv": "dv",
  "application/x-dvi": "dvi",
  "application/vnd.ms-fontobject": "eot",
  "text/x-setext": "etx",
  "image/gif": "gif",
  "application/x-gtar": "gtar",
  "application/x-gzip": "gz",
  "application/x-hdf": "hdf",
  "application/mac-binhex40": "hqx",
  "text/x-component": "htc",
  "image/ief": "ief",
  "text/vnd.sun.j2me.app-descriptor": "jad",
  "application/java-archive": "jar",
  "text/x-java-source": "java",
  "application/x-java-jnlp-file": "jnlp",
  "image/jpeg": "jpeg",
  "image/webp": "webp",
  "image/heic": "heic",
  "application/javascript": "js",
  "text/plain": "txt",
  "application/json": "json",
  "audio/midi": "midi",
  "application/x-latex": "latex",
  "audio/x-mpegurl": "m3u",
  "image/x-macpaint": "pnt",
  "text/troff": "tr",
  "application/mathml+xml": "mathml",
  "application/x-mif": "mif",
  "video/quicktime": "qt",
  "video/x-sgi-movie": "movie",
  "audio/mpeg": "mpa",
  "video/mp4": "mp4",
  "video/mpeg": "mpg",
  "video/mpeg2": "mpv2",
  "application/x-wais-source": "src",
  "application/x-netcdf": "nc",
  "application/oda": "oda",
  "application/vnd.oasis.opendocument.database": "odb",
  "application/vnd.oasis.opendocument.chart": "odc",
  "application/vnd.oasis.opendocument.formula": "odf",
  "application/vnd.oasis.opendocument.graphics": "odg",
  "application/vnd.oasis.opendocument.image": "odi",
  "application/vnd.oasis.opendocument.text-master": "odm",
  "application/vnd.oasis.opendocument.presentation": "odp",
  "application/vnd.oasis.opendocument.spreadsheet": "ods",
  "application/vnd.oasis.opendocument.text": "odt",
  "application/vnd.oasis.opendocument.graphics-template": "otg",
  "application/vnd.oasis.opendocument.text-web": "oth",
  "application/vnd.oasis.opendocument.presentation-template": "otp",
  "application/vnd.oasis.opendocument.spreadsheet-template": "ots",
  "application/vnd.oasis.opendocument.text-template": "ott",
  "application/ogg": "ogx",
  "video/ogg": "ogv",
  "audio/ogg": "spx",
  "application/x-font-opentype": "otf",
  "audio/flac": "flac",
  "application/annodex": "anx",
  "audio/annodex": "axa",
  "video/annodex": "axv",
  "application/xspf+xml": "xspf",
  "image/x-portable-bitmap": "pbm",
  "image/pict": "pict",
  "application/pdf": "pdf",
  "image/x-portable-graymap": "pgm",
  "audio/x-scpls": "pls",
  "image/png": "png",
  "image/x-portable-anymap": "pnm",
  "image/x-portable-pixmap": "ppm",
  "application/vnd.ms-powerpoint": "pps",
  "image/vnd.adobe.photoshop": "psd",
  "image/x-quicktime": "qtif",
  "image/x-cmu-raster": "ras",
  "application/rdf+xml": "rdf",
  "image/x-rgb": "rgb",
  "application/vnd.rn-realmedia": "rm",
  "application/rtf": "rtf",
  "text/richtext": "rtx",
  "application/font-sfnt": "sfnt",
  "application/x-sh": "sh",
  "application/x-shar": "shar",
  "application/x-stuffit": "sit",
  "application/x-sv4cpio": "sv4cpio",
  "application/x-sv4crc": "sv4crc",
  "image/svg+xml": "svgz",
  "application/x-shockwave-flash": "swf",
  "application/x-tar": "tar",
  "application/x-tcl": "tcl",
  "application/x-tex": "tex",
  "application/x-texinfo": "texinfo",
  "image/tiff": "tiff",
  "text/tab-separated-values": "tsv",
  "application/x-font-ttf": "ttf",
  "application/x-ustar": "ustar",
  "application/voicexml+xml": "vxml",
  "image/x-xbitmap": "xbm",
  "application/xhtml+xml": "xhtml",
  "application/vnd.ms-excel": "xls",
  "application/xml": "xsl",
  "image/x-xpixmap": "xpm",
  "application/xslt+xml": "xslt",
  "application/vnd.mozilla.xul+xml": "xul",
  "image/x-xwindowdump": "xwd",
  "application/vnd.visio": "vsd",
  "audio/x-wav": "wav",
  "image/vnd.wap.wbmp": "wbmp",
  "text/vnd.wap.wml": "wml",
  "application/vnd.wap.wmlc": "wmlc",
  "text/vnd.wap.wmlsc": "wmls",
  "application/vnd.wap.wmlscriptc": "wmlscriptc",
  "video/x-ms-wmv": "wmv",
  "application/font-woff": "woff",
  "application/font-woff2": "woff2",
  "model/vrml": "wrl",
  "application/wspolicy+xml": "wspolicy",
  "application/x-compress": "z",
  "application/zip": "zip",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "pptx",
};

class FileUtilsClass {
  mimeToExt(mime: string) {
    return FileMimeType[mime] || null;
  }
  extToMime(ext: string) {
    return (
      Object.entries(FileMimeType).find(([, value]) => value === ext)?.[0] ||
      null
    );
  }
  extractMimetypeOfBase64(base64: string) {
    try {
      const mime = base64
        .split(",")[0]
        .split(";")[0]
        .split(":")[1]
        .split("/")[1];
      return mime;
    } catch (err) {
      return null;
    }
  }
  blobToFile(blob: Blob, filename: string) {
    return new File([blob], filename, { type: blob.type });
  }
  fileToObjectUrl(file: File) {
    return URL.createObjectURL(file);
  }

  extractExtensionOfBase64(base64: string) {
    const mime = this.extractExtensionOfBase64(base64);
    return this.mimeToExt(mime);
  }

  getFilenameOfDisposition(disposition: string) {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    let fileName: string = disposition;
    if (utf8FilenameRegex.test(disposition)) {
      fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
    } else {
      // prevent ReDos attacks by anchoring the ascii regex to string start and
      //  slicing off everything before 'filename='
      const filenameStart = disposition.toLowerCase().indexOf("filename=");
      if (filenameStart >= 0) {
        const partialDisposition = disposition.slice(filenameStart);
        const matches = asciiFilenameRegex.exec(partialDisposition);
        if (matches != null && matches[2]) {
          fileName = matches[2];
        }
      }
    }
    return fileName;
  }
  toBase64(file: File | Blob) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });
  }

  getFiletype(filename: string) {
    const splitted = filename.split(".");
    const filetype = splitted.length >= 2 ? splitted[splitted.length - 1] : "";

    return filetype.toLocaleLowerCase();
  }

  replaceFileExtension(filename: string, newExtension: string) {
    const splitted = filename.split(".");
    splitted.pop();
    splitted.push(newExtension);
    return splitted.join(".");
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl, {
      mode: "cors",
    });
    var blob = await res.blob();
    return new Promise<string>((resolve, reject) => {
      var reader = new FileReader();

      reader.onloadend = () => {
        if (!res.ok || !res.headers.get("content-type")?.startsWith("image/")) {
          return reject(this);
        }

        resolve(reader.result as string);
      };

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  toDataURL(url) {
    return fetch(url, {})
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }
  toDataUrlAsync(url): Promise<string> {
    return new Promise((resolve, reject) => {
      this.toDataURL(url)
        .then((data: string) => {
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  }

  async downloadFile(data: string, filename: string, fetchData = false) {
    var a = document.createElement("a");
    a.href = data;
    if (fetchData) {
      if (data.indexOf("data") === 0) {
        a.href = data;
      } else {
        const response = await fetch(data, { mode: "cors" });
        const blob = await response.blob();
        a.href = URL.createObjectURL(blob);
        // a.href = await this.getBase64ImageFromUrl(data);
      }
    }
    a.target = "_blank";
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async downloadFileBlob(file: File, filename?: string) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        const base64data = reader.result as string;
        this.downloadFile(base64data, file.name || filename)
          .then(() => resolve(null))
          .catch(() => reject());
      };
    });
  }

  forceDownloadFile = (fileURL: string, fileName: string) => {
    // for non-IE
    if (!(window as any).ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";

      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        (document as any).location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!(window as any).ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  };
}

const FileUtils = new FileUtilsClass();
export default FileUtils;
