import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";
import { withPDFBlock } from "../../PDFBlock";

import { ObjectRiskManagementConfig } from "@/components/PDFConstructor/services/types/pdfConfigObjectBlockTypes";
import { useState } from "react";

import { PDFBlockFooter } from "../../PDFBlockFooter";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";

import { useBlockChanges } from "../../PDFBlockHooks";
import i18n from "@/i18n";
import { OBJECT_RISK_MANAGEMENT_CHART_TYPES } from "./PDFObjectInputConstants";

import { PDFCommonInput } from "../../Common/PDFCommonInput";
import { PDFChartFooterInput } from "./Components/PDFChartFooterInput";

const PDFObjectRiskManagementBlock = withPDFBlock<ObjectRiskManagementConfig>(
  ({ value, parentId }) => {
    const [{ asset }, { deleteBlock, saveBlock }] = usePDFConstructor();
    const [form, setForm] = useState({ ...value, assetId: asset._id });

    useBlockChanges(value, form);

    return (
      <div>
        <BFSelect
          data={OBJECT_RISK_MANAGEMENT_CHART_TYPES.map((option) => ({
            ...option,
            label: option.label(),
          }))}
          value={form.chartType}
          onSelect={(value) =>
            setForm((form) => ({
              ...form,
              chartType: value,
            }))
          }
          cleanable={false}
        />

        <PDFCommonInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
        />

        <PDFChartFooterInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock(value, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t(
      "Component.PDFConstructor.Block.Inputs.ObjectRiskManagementChart",
      "Risiko-Management-Diagramm"
    )
);

export { PDFObjectRiskManagementBlock };
