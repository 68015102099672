import _ from "lodash";
import { ACPortfolioObject } from "../apps/AppConfigInterfaces";
import { useTypedSelector } from "../redux/hooks";
import { AppState, store } from "../redux/store";

export type Restrictions = {
  units: string[];
  entities: string[];
  objects: string[];
  lqObjects: string[];
  bankAccounts: string[];
  dependencies: {
    units: string[];
    entities: string[];
  };
};
class RestrictionServiceClass {}
const RestrictionService = new RestrictionServiceClass();
export default RestrictionService;

export const useRestrictions = () => {
  const restrictions = useTypedSelector((state) => selectRestrictions(state));
  return restrictions;
};
export const getRestrictions = () => {
  const restrictions: Restrictions = selectRestrictions(store.getState());
  return restrictions;
};

export const selectRestrictions = (state: AppState) => {
  const restrictions = state.global.user.restrictions;

  if (!restrictions) {
    return null;
  } else {
    const objects = (window as any).OrgaStruct.filterObjectsBy(
      restrictions.units,
      restrictions.entities,
      restrictions.objects
    ).map((object) =>
      (window as any).OrgaStruct.getObject(object)
    ) as (ACPortfolioObject & { entityId: string; type: string })[];
    const lqObjects = _.uniq(objects.map((e) => e.lqObject));
    const bankAccounts = lqObjects.map(
      (e) => (window as any).OrgaStruct.getObject(e)?.bankAccount as string
    );

    restrictions.units?.forEach((unit) =>
      (window as any).OrgaStruct.getAllBankAccounts([unit])?.forEach(
        (bankAccount) => bankAccounts.push(bankAccount._id)
      )
    );

    restrictions.entities?.forEach((entity) =>
      (window as any).OrgaStruct.getBankAccountsOfEntity(entity)?.forEach(
        (bankAccount) => bankAccounts.push(bankAccount._id)
      )
    );

    const dependencies = {
      units: _.uniq(objects.map((e) => e.type)),
      entities: _.uniq(objects.map((e) => e.entityId)),
    };
    const restrictionsObj: Restrictions = {
      bankAccounts: _.uniq(bankAccounts).filter((e) => e),
      lqObjects: _.uniq(objects.map((e) => e.lqObject)).filter((e) => e),
      objects: objects.map((e) => e._id).filter((e) => e),
      entities: (window as any).OrgaStruct.filterEntitiesBy(
        restrictions.units,
        restrictions.entities
      ).filter((e) => e) as string[],
      units: restrictions.units.filter((e) => e),
      dependencies,
    };
    return restrictionsObj;
  }
};
