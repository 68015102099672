import React, { FC, useEffect, useState } from "react";
import i18n from "@/i18n";
import axios from "axios";
import {
  HistoryRentRecord,
  RentDataGraphContentProps,
  RentValues,
} from "./types";
import Log from "@/debug/Log";
import { HTTP } from "@/utils/Http";
import { getChartOptions } from "./helpers";
import { RentDataGraphDetails } from "./RentDataGraphDetails";
import { ErrorContentComponent } from "@/components/ErrorContentComponent";
import { EmptyContentComponent } from "@/components/EmptyContentComponent";
import ChartComponent from "@/configurable/components/ChartComponent/ChartComponent";
import styles from "./RentDataGraphContent.module.scss";
import BFButtonToggle from "@/modules/abstract-ui/general/Button/BFButtonToggle";

const RentDataGraphContent: FC<RentDataGraphContentProps> = ({ objectId }) => {
  // ! state
  const [isEmpty, setIsEmpty] = useState(false);
  const [isError, setIsError] = useState(false);
  const [rentValues, setRentValues] = useState<RentValues>({
    median: 0,
    currentRent: 0,
    plannedRent: 0,
  });
  const [selector, setSelector] = useState<"residential" | "commercial">(
    "residential"
  );
  const [historyInfo, setHistoryInfo] = useState<HistoryRentRecord[]>([]);

  // ! effects
  useEffect(() => {
    const historyCancelSource = axios.CancelToken.source();

    const fetchHistoryData = async () => {
      try {
        const historyInfo: HistoryRentRecord[] = await HTTP.get({
          url: `/portfolioObject/${objectId}/getGeoMapHistory/${selector}`,
          cancelToken: historyCancelSource.token,
        });

        const rentValues = await HTTP.get({
          url: `/portfolioObject/${objectId}/getRentValues/${selector}`,
          cancelToken: historyCancelSource.token,
        });
        setRentValues(rentValues);

        if (!historyInfo) {
          setIsError(true);
          return;
        }
        if (!historyInfo.length) {
          setIsEmpty(true);
        } else {
          setIsEmpty(false);
          setHistoryInfo(historyInfo);
        }
      } catch (error) {
        if (axios.isCancel(error)) return;

        Log.error("Fetching rent object history error", error);
        setIsError(true);
      }
    };

    void fetchHistoryData();

    return () => {
      historyCancelSource.cancel();
    };
  }, [objectId, selector]);

  // ! render
  if (isError) {
    return (
      <ErrorContentComponent
        description={i18n.t(
          "cb:RentDataGraph.messages.errors.errorGettingRentHistory",
          "Fehler beim Abrufen der Mietgeschichte"
        )}
      />
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <div className={styles.buttonsContainer}>
        <BFButtonToggle
          buttons={[
            {
              text: i18n.t("cb:RentDataGraph.buttons.private", "Privat"),
              value: "residential",
            },
            {
              text: i18n.t("cb:RentDataGraph.buttons.commercial", "Gewerbe"),
              value: "commercial",
            },
          ]}
          value={selector}
          onChange={(val: "residential" | "commercial") => setSelector(val)}
        />
      </div>
      <RentDataGraphDetails
        lastMedian={rentValues.median}
        rentalIncome={rentValues.currentRent}
        rentalIncomePlan={rentValues.plannedRent}
      />
      {isEmpty || !historyInfo.length ? (
        <div className="__field">
          <EmptyContentComponent
            hideAnimation
            descriptionFontSize={14}
            description={i18n.t(
              "cb:RentDataGraph.messages.info.rentDataEmpty",
              "Für dieses Objekt liegt kein Mietpreisverlauf vor."
            )}
          />
        </div>
      ) : (
        <div className={styles.container}>
          <ChartComponent options={getChartOptions(historyInfo)} />
        </div>
      )}
    </div>
  );
};

export default RentDataGraphContent;
