import {
  ObjectKind,
  ObjectKindFeature_IMMO,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import {
  PDFDocumentRect,
  PDFEditRect,
} from "@/components/PDFViewer/PDFViewerHooks";
import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import { hasNoValue } from "@/utils/Helpers";
import MQ from "@/utils/MatchQueryUtils";
import StringUtils from "@/utils/StringUtils";
import {
  GraduatedRent,
  RentalAgreement,
  RentalAgreementTimePeriod,
} from "../../../TenantsInterfaces";
import { PaymentPositionsData } from "./CBAgreementPaymentInfo";
// import "./CBAgreementGraduatedRent.scss";

export type GraduatedRentType = {
  graduatedRent: null | GraduatedRent[];
};
interface CBAgreementGraduatedRentProps {
  data?: GraduatedRentType;
  marks: PDFEditRect[] | PDFDocumentRect[];
  hideIfEmpty?: boolean;
  taxable: boolean;
  objectId: string;
  rentalAgreement?: RentalAgreement;
  kind: ObjectKind;
}
const CBAgreementGraduatedRent = (props: CBAgreementGraduatedRentProps) => {
  if (props.hideIfEmpty && hasNoValue(props.data?.graduatedRent)) {
    return null;
  }
  const reasonId = (
    props.kind?.data.features.find(
      (e) => e.type === "immo"
    ) as ObjectKindFeature_IMMO
  )?.graduatedRentReason;

  return (
    <BFPDFMarkerSection
      marginBottom={20}
      readonly
      value={props.marks}
      title={i18n.t(
        "cb:RentalAgreement.Form.SectionTitle.graduatedRent.title",
        "Staffelmiete"
      )}
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.graduatedRent.viewHint",
        "Informationen zur Staffelmeite"
      )}
      identifier="graduatedRent"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      {props.rentalAgreement ? (
        <div>
          <ListComponent
            assetType={AssetTypes.Rental.RentalAgreementPeriod}
            identifier="graduatedRent"
            cleanupOnUnmount
            additionalMatchQuery={MQ.and(
              MQ.eq("data.status", "active"),
              MQ.eq("data.rentalAgreement", props.rentalAgreement._id),
              MQ.when(reasonId, MQ.eq("data.reason", reasonId))
            )}
            render={(period: RentalAgreementTimePeriod) => (
              <div className={`__flex padding-bottom-6`}>
                <div>
                  {i18n.t("Global.Labels.fromDate")}{" "}
                  {StringUtils.formatDate(period.data.from)}
                </div>
                <div>
                  {props.rentalAgreement.data.taxable ? (
                    <span>
                      {StringUtils.formatCurrency(period.data.rentNet)}{" "}
                      {i18n.t(
                        "cb:RentalAgreement.Labels.RentNet",
                        "Miete Netto"
                      )}{" "}
                      /{" "}
                      {StringUtils.formatCurrency(period.data.operatingCostNet)}{" "}
                      {i18n.t(
                        "cb:RentalAgreement.Labels.OperatingCostNet",
                        "Nebenkosten Netto"
                      )}
                    </span>
                  ) : (
                    <span>
                      {StringUtils.formatCurrency(period.data.rentGross)}{" "}
                      {i18n.t(
                        "cb:RentalAgreement.Labels.RentGross",
                        "Miete Brutto"
                      )}{" "}
                      /{" "}
                      {StringUtils.formatCurrency(
                        period.data.operatingCostGross
                      )}{" "}
                      {i18n.t(
                        "cb:RentalAgreement.Labels.OperatingCostGross",
                        "Nebenkosten Brutto"
                      )}
                    </span>
                  )}
                </div>
              </div>
            )}
          />
        </div>
      ) : (
        <div>
          {props.data.graduatedRent.map((graduatedRent, index) => (
            <div>
              <div className={` __h4 margin-top-10`}>
                {i18n.t("Global.Labels.fromDate")}{" "}
                {StringUtils.formatDate(graduatedRent.fromDate)}
              </div>
              <div className={`__flex-wrap`}>
                <PaymentPositionsData
                  objectId={props.objectId}
                  paymentPositions={graduatedRent.paymentPositions}
                  taxable={props.taxable}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </BFPDFMarkerSection>
  );
};

export default CBAgreementGraduatedRent;
