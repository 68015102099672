import { TypedBaseAsset } from "@/model/general-assets/BaseAsset";

export enum REPLACEMENT_STATUS {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  CANCELLED = "CANCELLED",
}

export type ReplacementData = {
  replacedUser: string;
  replacementUser: string;
  status: REPLACEMENT_STATUS;
  from: Date;
  to: Date;
  acceptedAt: Date;
  message: string;
};
export type ReplacementRequest = {
  replacementUser: string;
  from: Date;
  to: Date;
  message: string;
};

export type Replacement = TypedBaseAsset<ReplacementData>;
