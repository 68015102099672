import {
  BaseConfig,
  ChartConfig,
  TableConfig,
  TableStyle,
} from "./pdfConfigUtilTypes";

export type ObjectGalleryType = "object-gallery";

export type ObjectGalleryConfig = BaseConfig<
  ObjectGalleryType,
  {
    assetId: string;
    columns: number;
    images: {
      key: string;
      url: string;
      bucket: string;
    }[];
  }
>;

export type ObjectDetailsType = "object-details";

export type ObjectDetailsConfig = BaseConfig<
  ObjectDetailsType,
  {
    assetId: string;
    viewType: "list" | "full";
  }
>;

export type ObjectStackingPlanType = "object-stacking-plan";

export type ObjectStackingPlanConfig = ChartConfig<
  ObjectStackingPlanType,
  {
    assetId: string;
  }
>;
export type VacancyChartType = "vacancy-chart";

export enum VacancyChart {
  RENT_NET = "vacancyRentNet",
  AREA = "vacancyArea",
  RATE = "vacancyRate",
}

export type VacancyChartConfig = ChartConfig<
  VacancyChartType,
  {
    assetId: string;
    chartType: VacancyChart;
  }
>;

export type RentalUnitDistributionChartType = "rental-unit-distribution-chart";

export type RentalUnitDistributionChartConfig = ChartConfig<
  RentalUnitDistributionChartType,
  {
    assetId: string;
    chartType: VacancyChart;
  }
>;

export type ObjectStackingPlanTableType = "object-stacking-plan-table";

export enum ObjectStackingPlanTableFields {
  ID = "id",
  NAME = "name",
  STATUS = "status",
  USAGE_TYPE = "usageType",
  UNIT_TYPE = "unitType",
  FLOOR = "floor",
  SURFACE_AREA = "surfaceArea",
  PITCHERS = "pitchers",
  NKM_TARGET = "nkmTarget",
  GROSS_RENT = "grossRent",
  GROSS_PLANNED = "grossPlanned",
  MANAGED_BY = "managedBy",
  MANAGED_UNTIL = "managedUntil",
  NKM_ACTUAL = "nkmActual",
  IST = "ist",
  SOLL = "soll",
  NKM_DIFFERENCE = "nkmDifference",
  UGLY_IST = "uglyIst",
  GROSS_RENT_DIFFERENCE = "grossRentDifference",
}

export type ObjectStackingPlanTableConfig = TableConfig<
  ObjectStackingPlanTableType,
  ObjectStackingPlanTableFields,
  {
    assetId: string;
  }
>;

export type ObjectTenantListType = "object-tenant-list";

export enum ObjectTenantListFields {
  RENTAL_AGREEMENT = "rental-agreement",
  TENANT = "tenant",
  SURFACE_AREA = "surface-area",
  NUMBER_PITCHES = "number-pitches",
  NKM_ACTUAL = "nkm-actual",
  NKM_PER_SQUARE = "nkm-per-square",
  NKM_TARGET = "nkm-target",
  NKM_TARGET_PER_SQUARE = "nkm-target-per-square",
  NKM_DIFF = "nkm-diff",
  RENT_GROSS = "rent-gross",
  RENT_GROSS_PER_SQUARE = "rent-gross-per-square",
  PLAN_RENT_GROSS = "plan-rent-gross",
  PLAN_RENT_GROSS_PER_SQUARE = "plan-rent-gross-per-square",
  RENT_GROSS_DIFF = "rent-gross-diff",
}

export type ObjectTenantListConfig = TableConfig<
  ObjectTenantListType,
  ObjectTenantListFields,
  {
    assetId: string;
  }
>;

export enum UpcomingVacancyFields {
  ID = "id",
  NAME = "name",
  TENANT_NAME = "tenant-name",
  CONTRACT_TYPE = "contract-type",
  UNIT_TYPE = "unit-type",
  USAGE_TYPE = "usage-type",
  ACCOUNT_BALANCE = "account-balance",
  RENT_NET = "rent-net",
  RENT_GROSS = "rent-gross",
  COST_NET = "operating-cost-net",
  COST_GROSS = "operating-cost-gross",
  DEPOSIT = "deposit",
  UNIT_AREA = "unit-area",
  MOVE_IN = "move-in",
  MOVE_OUT = "move-out",
  AGREEMENT_EXPIRATION = "agreement-expiration",
  AUTOMATIC_PULL_OUT = "automatic-pull-out",
  AUTOMATIC_PULL_OUT_EXTENSION = "automatic-pull-out-extension",
  STAFF_RENT = "staff-rent",
  STATUS = "status",
  RENT_ADJUSTMENT_FACTOR = "rent-adjustment-factor",
  LAST_PRICE_INDEX_CHANGE = "last-price-index-change",
  LAST_PRICE_INDEX = "last-price-index",
  STITCH_MONTH = "stitch-month",
  INDEX_FACTOR = "index-factor",
  INDEX_TYPE = "index-type",
  CURRENT_DUNNING_ACTIVITY = "current-dunning-activity",
  STOP_DUNNING_RUN = "stop-dunning-run",
}

export type UpcomingVacancyTableType = "upcoming-vacancy-table";

export type UpcomingVacancyTableConfig = TableConfig<
  UpcomingVacancyTableType,
  UpcomingVacancyFields,
  {
    assetId: string;
    month: number;
  }
>;

export type ObjectDamageClaimsTableType = "object-damage-claims-table";

export enum ObjectTableActivityFilter {
  TOTAL = "total",
  ACTIVE = "active",
  INACTIVE = "inactive",
  ON_HOLD = "on-hold",
}

export enum ObjectDamageClaimsFields {
  TICKET_NUMBER = "ticket-number",
  NAME = "name",
  ASSIGNMENTS = "assignments",
  STATUS = "status",
  PRIORITY = "priority",
  CREATED_AT = "created-at",
  DAMAGE_DATE = "damage-date",
  DUE_DATE = "due-date",
  ESTIMATED_COST = "estimated-cost",
  SUPPLY_COST = "supply-cost",
  TAGS = "tags",
  DESCRIPTION = "description",
  ASSIGNED_TO = "assigned-to",
  RESPONSIBLE = "responsible",
  INSURANCE = "insurance",
}

export type ObjectDamageClaimsTableConfig = TableConfig<
  ObjectDamageClaimsTableType,
  ObjectDamageClaimsFields,
  {
    assetId: string;
    filter: ObjectTableActivityFilter;
  }
>;

export type ObjectVacancyActivityType = "object-vacancy-activity";

export enum ObjectVacancyActivityFields {
  TICKET_NUMBER = "ticket-number",
  NAME = "name",
  ASSIGNMENTS = "assignments",
  STATUS = "status",
  PRIORITY = "priority",
  RECORD = "record",
  PLANNED_RENT = "planned-rent",
  BEFORE_RENT = "before-rent",
  AREA = "area",
  SQUARE_METER_RENT = "square-meter-rent",
  MARKET_RENT = "market-rent",
  HIGHEST_RENT_BY_NOW = "highest-rent-by-now",
  TAGS = "tags",
  DESCRIPTION = "description",
  ASSIGNED_TO = "assigned-to",
  RESPONSIBLE = "responsible",
  EVALUATION = "evaluation",
}

export type ObjectVacancyActivityConfig = BaseConfig<
  ObjectVacancyActivityType,
  {
    assetId: string;
    fields: {
      path: string;
      label: string;
      format: string;
      aggregation?: string;
    }[];
  } & TableStyle
>;

export type ObjectRentalIncomeChartType = "object-rental-income-chart";

export type ObjectRentalIncomeChartConfig = ChartConfig<
  ObjectRentalIncomeChartType,
  {
    assetId: string;
    months: number;
  }
>;

export type ObjectRiskManagementType = "object-risk-management";

export enum ObjectRiskManagementChart {
  PLANNED = "planned",
  CURRENT = "current",
}

export type ObjectRiskManagementConfig = ChartConfig<
  ObjectRiskManagementType,
  {
    assetId: string;
    chartType: ObjectRiskManagementChart;
  }
>;

export type ObjectMaintenanceContractsTableType =
  "object-maintenance-contracts-table";

export enum ObjectMaintenanceContractsFields {
  TICKET_NUMBER = "ticket-number",
  NAME = "name",
  ASSIGNMENTS = "assignments",
  STATUS = "status",
  PRIORITY = "priority",
  CREATED_AT = "created-at",
  CONTRACT_CATEGORY = "contract-category",
  CONTRACT_ACCOUNT = "contract-account",
  CONTRACT_PARTNER = "contract-partner",
  DUE_DATE = "due-date",
  CONTRACT_TYPE = "contract-type",
  CONTRACT_UNTIL = "contract-until",
  NOTICE_PERIOD = "notice-period",
  TERMINATION_BY = "termination-by",
  RENEWAL = "renewal",
  CONTRACT_COST = "contract-cost",
  REALLOCABLE = "reallocable",
  ALLOCABLE_COSTS = "allocable-costs",
  OWN_COST = "own-cost",
  TAGS = "tags",
  DESCRIPTION = "description",
  ASSIGNED_TO = "assigned-to",
}

export type ObjectMaintenanceContractsTableConfig = TableConfig<
  ObjectMaintenanceContractsTableType,
  ObjectMaintenanceContractsFields,
  {
    assetId: string;
    filter: ObjectTableActivityFilter;
  }
>;

export type ObjectLoanChartType = "object-loan-chart";

export enum ObjectLoanChart {
  TOTAL = "total",
  CURRENT = "current",
  FINISHED = "finished",
  FUTURE = "future",
}

export type ObjectLoanChartConfig = ChartConfig<
  ObjectLoanChartType,
  {
    assetId: string;
    chartType: ObjectLoanChart;
  }
>;

export type ObjectLoanTableType = "object-loan-table";

export enum ObjectLoanTableFields {
  DISPLAY_NAME = "display-name",
  LOAN_AMOUNT = "loan-amount",
  INTEREST_RATE = "interest-rate",
  FINAL_AMOUNT = "final-amount",
  START_DATE = "start-date",
  END_DATE = "end-date",
}

export enum ObjectLoanTableFilter {
  TOTAL = "total",
  ONGOING = "ongoing",
  FUTURE = "future",
  COMPLETED = "completed",
}

export type ObjectLoanTableConfig = TableConfig<
  ObjectLoanTableType,
  ObjectLoanTableFields,
  {
    assetId: string;
    filter: ObjectLoanTableFilter;
  }
>;
