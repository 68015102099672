import {
  BlockType,
  ColumnConfig,
} from "@/components/PDFConstructor/services/types/pdfConfigBlockTypes";

import { withPDFBlock } from "../../PDFBlock";
import { PDFBlockFooter } from "../../PDFBlockFooter";
import { PDFBlockList } from "../../PDFBlockList";
import { PDFBlockSelect } from "../../PDFBlockSelect";
import { PDFLinkInput } from "../TextInput/PDFLinkInput";
import { PDFTextInput } from "../TextInput/PDFTextInput";
import { PDFImageInput } from "../ImageInput/PDFImageInput";
import { PDFLineInput } from "../PDFLineInput";
import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import i18n from "@/i18n";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";
import { PDFPageBreak } from "../PDFPageBreak";
import { PDFObjectDetailsBlock } from "../Object/PDFObjectDetailsBlock";
import { PDFObjectGalleryBlock } from "../Object/PDFObjectGalleryBlock";
import { PDFObjectStackingPlanBlock } from "../Object/PDFObjectStackingPlanBlock";
import { PDFObjectVacancyChartBlock } from "../Object/PDFObjectVacancyChart";
import { PDFUpcomingVacancyBlock } from "../Object/PDFObjectUpcomingVacancyBlock";
import { PDFObjectRentalUnitDistributionChartBlock } from "../Object/PDFObjectRentalUnitDistributionChart";
import { PDFObjectStackingPlanTableBlock } from "../Object/PDFObjectStackingPlanTableBlock";
import { PDFObjectTenantListTableBlock } from "../Object/PDFObjectTenantListTableBlock";
import { PDFObjectVacancyActivityBlock } from "../Object/PDFObjectVacancyActivityBlock";
import { PDFObjectDamageClaimsTableBlock } from "../Object/PDFObjectDamageClaimsTableBlock";
import { PDFObjectRentalIncomeChartBlock } from "../Object/PDFObjectRentalIncomeChartBlock";
import { PDFObjectRiskManagementBlock } from "../Object/PDFObjectRiskManagementChartBlock";
import { PDFObjectMaintenanceContractsBlock } from "../Object/PDFObjectMaintenanceContractsTableBlock";
import { PDFObjectPortfolioLoanChartBlock } from "../Object/PDFObjectPortfolioLoanChartBlock";
import { useAvailableBlockList } from "@/components/PDFConstructor/PDFConstructorHooks";
import { useState } from "react";
import { useDebouncedEffect } from "@/utils/Hooks";

const PDFColumnInput = withPDFBlock<ColumnConfig>(
  ({ value, id, parentId }) => {
    const [_, { addBlock, editBlock, deleteBlock, saveBlock }] =
      usePDFConstructor();
    const { data } = useAvailableBlockList();

    const [form, setForm] = useState({
      width: value.width,
    });

    useDebouncedEffect(() => {
      editBlock({
        ...value,
        width: form.width,
      });
    }, [form]);

    return (
      <div>
        <PDFBlockList
          parentId={value.id}
          config={value.content}
          elements={{
            link: PDFLinkInput,
            text: PDFTextInput,
            image: PDFImageInput,
            line: PDFLineInput,
            break: PDFPageBreak,
            "object-details": PDFObjectDetailsBlock,
            "object-gallery": PDFObjectGalleryBlock,
            "object-stacking-plan": PDFObjectStackingPlanBlock,
            "vacancy-chart": PDFObjectVacancyChartBlock,
            "upcoming-vacancy-table": PDFUpcomingVacancyBlock,
            "rental-unit-distribution-chart":
              PDFObjectRentalUnitDistributionChartBlock,
            "object-stacking-plan-table": PDFObjectStackingPlanTableBlock,
            "object-tenant-list": PDFObjectTenantListTableBlock,
            "object-vacancy-activity": PDFObjectVacancyActivityBlock,
            "object-damage-claims-table": PDFObjectDamageClaimsTableBlock,
            "object-rental-income-chart": PDFObjectRentalIncomeChartBlock,
            "object-risk-management": PDFObjectRiskManagementBlock,
            "object-maintenance-contracts-table":
              PDFObjectMaintenanceContractsBlock,
            "object-loan-chart": PDFObjectPortfolioLoanChartBlock,
          }}
        />

        <PDFBlockSelect
          addValue={(block) => addBlock(block, id)}
          show={[
            "text",
            "link",
            "image",
            "line",
            ...(data?.blocks ?? []),
            ...((data?.templates?.map(
              (template) => template.displayName
            ) as BlockType[]) ?? []),
          ]}
        />

        <BFInput
          label={i18n.t("Component.PDFConstructor.Block.Width", "Breite")}
          value={form.width}
          onChange={(width) => {
            if (
              typeof width === "string" &&
              (width === "*" || width.endsWith("%"))
            ) {
              setForm((form) => ({
                ...form,
                width,
              }));
              return;
            }

            if (!Number.isNaN(width)) {
              setForm((form) => ({
                ...form,
                width: Number(width),
              }));
              return;
            }
          }}
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock(value, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t(
      "Component.PDFConstructor.Block.Inputs.ColumnInput",
      "Spalteneringabe"
    )
);

export { PDFColumnInput };
