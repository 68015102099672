import { BFColorPickerPopover } from "@/modules/abstract-ui/data/color-picker/BFColorPickerPopover";
import "@/modules/abstract-ui/data/color-picker/BFColorPicker.scss";
import BFOverlay from "@/modules/abstract-ui/general/whisper/BFOverlay";
import classNames from "classnames";
import { useState } from "react";

interface BFColorPickerProps {
  value?: string;
  onChange?: (colorHex: string) => void;
  container?: HTMLElement | (() => HTMLElement);
  children: (
    selectedColor: string
  ) => React.ReactElement | React.ReactElement[];
}

const COLOR_PICKER_OVERLAY = "COLOR_PICKER_OVERLAY";

const PDFColorPicker = ({
  value = "#FFF",
  onChange,
  container,
  children,
}: BFColorPickerProps) => {
  const [color, setColor] = useState(value);
  const [oldColor, setOldColor] = useState(value);

  return (
    <div className={classNames("bf-color-picker")}>
      <BFOverlay
        className="bf-color-picker-overlay-container"
        identifier={COLOR_PICKER_OVERLAY}
        placement="left"
        trigger="click"
        zIndex={1200}
        speaker={
          <BFColorPickerPopover
            color={color}
            oldColor={oldColor}
            setColor={setColor}
            setOldColor={setOldColor}
            commitColor={onChange}
          />
        }
        onOpen={() => {
          setOldColor(color);
        }}
        onExit={() => {
          setColor(oldColor);
        }}
        container={container}
      >
        <div
          onClick={() => {
            onChange(color);
            setOldColor(color);
          }}
        >
          {children(value)}
        </div>
      </BFOverlay>
    </div>
  );
};

export { PDFColorPicker };
