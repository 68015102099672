import {
  ObjectKind,
  ObjectKindFeature_IMMO,
} from "@/apps/tatar/objectsApp/types/objectKind.interface";
import i18n from "@/i18n";
import { NewAttachmentValue } from "@/modules/customfields/fields/CFAttachment";
import CDNService from "@/services/CDNService";
import ServiceUtils from "@/services/ServiceUtils";
import _ from "lodash";
import moment from "moment";
import { AssetTypes } from "../../../../../model/AssetTypes";
import { Contact } from "../../../../../model/db/Contact";
import CacheService from "../../../../../services/CacheService";
import ContactService from "../../../../../services/ContactService";
import DataBus from "../../../../../services/DataBus";
import SubmitService from "../../../../../services/SubmitService";
import { DataBusSubKeys } from "../../../../../utils/Constants";
import { HTTP } from "../../../../../utils/Http";
import {
  AgreementOption,
  ContactOption,
  DepositPosition,
  IndexOption,
  PaymentPosition,
  RentalAgreement,
  RentalAgreementAutomatic,
  RentalAgreementCopayment,
  RentalAgreementMarks,
  RentalAgreementTimePeriod,
  RentalAgreementUnit,
  RentalUnit,
  RentalUnitFormValue,
  RentPaymentTimingOption,
  SubmitObjectPlanData,
} from "./TenantsInterfaces";
import { RentalOpos } from "./components/rental-opos/OposInterfaces";

class CBRentalServiceClass {
  async addTimePeriodToAgreement(
    agreementId: string,
    data: {
      from: Date;
      to?: Date;
      paymentPositions: PaymentPosition[];
      reason: string;
      comment?: string;
      rentalUnits: string[];
      taxable: "taxable" | "notTaxable";
      rentPaymentTimingOptions: RentPaymentTimingOption;
    }
  ) {
    return ServiceUtils.toastError(
      async () => {
        const result = await HTTP.post({
          url: `/rental/${agreementId}/insertTimePeriod`,
          bodyParams: {
            rentalUnits: data.rentalUnits,
            from: moment(data.from).toISOString(),
            to: data.to ? moment(data.to).toISOString() : null,
            paymentPositions: data.paymentPositions || [],
            reason: data.reason,
            note: data.comment || "",
            taxable: data.taxable === "taxable",
            rentPaymentTimingOptions: data.rentPaymentTimingOptions,
          },
          target: "EMPTY",
        });
        return result;
      },
      async (err) => {
        const specialHandlingCodes = [
          "RENTAL_UNIT_OCCUPIED",
          "RENTAL_TIME_PERIOD_GAP",
        ];
        if (specialHandlingCodes.includes(err.response?.data?.code)) {
          const errData = err.response.data;

          if (errData.code === "RENTAL_UNIT_OCCUPIED") {
            const periodIds = errData.message;
            const timePeriods: RentalAgreementTimePeriod[] = await Promise.all(
              periodIds.map((periodId) =>
                CacheService.getData({
                  oType: "asset",
                  assetType: AssetTypes.Rental.RentalAgreementPeriod,
                  id: periodId,
                })
              )
            );

            const affectedTimeperiods = timePeriods
              .map((period) => period.data.rentalUnitIds)
              .flat();
            const overlappingRentalUnitIds = _.intersection(
              affectedTimeperiods,
              data.rentalUnits
            );

            const units: RentalUnit[] = await Promise.all(
              overlappingRentalUnitIds.map((unitId) =>
                CacheService.getData({
                  oType: "asset",
                  assetType: AssetTypes.Rental.RentalUnit,
                  id: unitId,
                })
              )
            );
            return `${i18n.t(
              "cb:RentalAgreement.Form.Errors.RENTAL_UNIT_OCCUPIED",
              "Folgende Mieteinheiten sind bereits vermietet:"
            )}\n${units
              .map((unit) => `${unit.data.id} - ${unit.data.displayName}`)
              .join("\n")}`;
          }
          if (errData.code === "RENTAL_TIME_PERIOD_GAP") {
            return `${i18n.t(
              "cb:RentalAgreement.Form.Errors.RENTAL_TIME_PERIOD_GAP",
              "Zeitraumlücke zwischen den Mietzeiträumen"
            )}`;
          }
        }
        return null;
      }
    );
  }

  async updateRentOfAgreeement(
    agreementId,
    data: {
      rentNet: number;
      rentGross: number;
      operatingCostNet: number;
      operatingCostGross: number;
      from: Date;
      to: Date;
      reason: string;
      comment: string;
    }
  ) {
    const result = await HTTP.post({
      url: `/rental/agreement/${agreementId}/changeRent`,
      bodyParams: {
        rentNet: data.rentNet,
        rentGross: data.rentGross,
        operatingCostNet: data.operatingCostNet,
        operatingCostGross: data.operatingCostGross,
        from: moment(data.from).toISOString(),
        to: data.to ? moment(data.to).toISOString() : undefined,
        // reason: data.reason,
        // comment: data.comment,
      },
      target: "EMPTY",
    });
    CacheService.updateDataInCaches(result._id, result);
    return result;
  }

  async updateRentalUnitsOfAgreement(
    agreementId: string,
    rentalUnits: RentalAgreementUnit[]
  ) {
    const result = await HTTP.post({
      url: `/rental/agreement/${agreementId}/changeRentalUnit`,
      bodyParams: {
        rentalUnitsInfo: rentalUnits.map((e) => ({
          rentalUnitId: e.rentalUnitId,
          from: moment(e.from).toISOString(),
          to: e.to ? moment(e.to).toISOString() : undefined,
        })),
      },
      target: "EMPTY",
    });
    CacheService.updateDataInCaches(result._id, result);
    return result;
  }

  async submitRentalAgreement(
    kind: ObjectKind,
    values: any,
    additionalAttachmentsMetadata?: any,
    additionalDepositAttachmentsMetadata?: any
  ) {
    return await ServiceUtils.toastError(
      async () => {
        const deposit = (values.deposit || []).map(
          ({ document, ...depositData }) => ({
            ...depositData,
          })
        );

        const depositAttachments = (values.deposit || [])
          .map((e) => e.document)
          .filter((e) => e)
          .flat();

        const rentalAgreementFiles =
          values.rentalAgreement as NewAttachmentValue[];

        const agreementUpload = await Promise.all(
          rentalAgreementFiles.map((rentalAgreementFile) =>
            CDNService.uploadAttachmentBeforeAssetCreation(
              {
                file: rentalAgreementFile.file,
                name: rentalAgreementFile.file.name,
                ...additionalAttachmentsMetadata,
              },
              AssetTypes.Rental.RentalAgreement,
              "data.attachments",
              rentalAgreementFile.id,
              (progress: number) => {}
            )
          )
        );
        const agreementCdnIds: string[] = agreementUpload.map((e) => e.cdnID);

        const marks: RentalAgreementMarks = values.marks || {};
        const marksSanitzied = Object.fromEntries(
          Object.entries(marks).map(([key, value]) => [
            key,
            value.map((val) => ({
              ...val,
              cdnID: agreementCdnIds[val.index],
            })),
          ])
        );

        const data = {
          marks: marksSanitzied,
          type: values.type,
          rentalUnits: values.rentalUnits.map((e) => e.assetId),
          tenant: values.tenant,
          id: values.id,
          copayment: values.copayment,
          contractType: values.contractType,
          displayName: values.displayName,
          moveIn: moment(values.moveIn).toISOString(),
          moveOut: values.moveOut ? moment(values.moveOut).toISOString() : null,
          agreementExpiration: values.agreementExpiration
            ? moment(values.agreementExpiration).toISOString()
            : null,
          paymentPositions:
            values.paymentPositions?.map((position) => ({
              id: position.id,
              net: position.net,
              gross: values.taxable ? position.gross : 0,
            })) || [],
          rentPaymentTimingOptions: values.rentPaymentTimingOptions,
          taxable: values.taxable === "taxable" ? true : false,
          deposit: deposit,
          note: values.note,
          option: values.option || null,
          contactOption: values.contactOption || null,
          graduatedRent: values.graduatedRent?.length > 0,
          index: values.index || null,
          automatic: values.automatic || null,
        };

        let result;
        result = await HTTP.post({
          url: `/rental/${values.objectId}/createRentalAgreement`,
          target: "EMPTY",
          bodyParams: data,
        });

        for (const agreementUploadEntry of agreementUpload) {
          await agreementUploadEntry.link(result._id);
        }

        for (const graduatedRent of values.graduatedRent || []) {
          await CBRentalService.addTimePeriodToAgreement(result._id, {
            from: graduatedRent.fromDate,
            to: null,
            reason: (
              kind.data.features.find(
                (e) => e.type === "immo"
              ) as ObjectKindFeature_IMMO
            )?.graduatedRentReason,
            comment: i18n.t(
              "cb:RentalAgreement.Form.graduatedRentDefaultText",
              "Geplante Mietpreisanpassung laut Vertrag"
            ),
            paymentPositions:
              graduatedRent.paymentPositions?.map((position) => ({
                id: position.id,
                net: position.net,
                gross: values.taxable ? position.gross : 0,
              })) || [],
            taxable: values.taxable,
            rentalUnits: values.rentalUnits.map((e) => e.assetId),
            rentPaymentTimingOptions: values.rentPaymentTimingOptions,
          });
        }

        if (depositAttachments) {
          await ServiceUtils.handleCDNFiles(
            result,
            AssetTypes.Rental.RentalAgreement,
            "data.attachments",
            {
              new: depositAttachments,
            },
            additionalDepositAttachmentsMetadata
          );
        }

        DataBus.emit(DataBusSubKeys.STACKING_PLAN_RELOAD, {
          objectId: result.data.objectId,
        });

        return result;
      },
      async (err) => {
        const specialHandlingCodes = [
          "RENTAL_UNIT_OCCUPIED",
          "RENTAL_AGREEMENT_ID_ALREADY_EXISTS",
        ];
        if (specialHandlingCodes.includes(err.response?.data?.code)) {
          const errData = err.response.data;

          if (errData.code === "RENTAL_UNIT_OCCUPIED") {
            const periodIds = errData.message;
            const timePeriods: RentalAgreementTimePeriod[] = await Promise.all(
              periodIds.map((periodId) =>
                CacheService.getData({
                  oType: "asset",
                  assetType: AssetTypes.Rental.RentalAgreementPeriod,
                  id: periodId,
                })
              )
            );

            const affectedTimeperiods = timePeriods
              .map((period) => period.data.rentalUnitIds)
              .flat();
            const overlappingRentalUnitIds = _.intersection(
              affectedTimeperiods,
              values.rentalUnits
            );

            const units: RentalUnit[] = await Promise.all(
              overlappingRentalUnitIds.map((unitId) =>
                CacheService.getData({
                  oType: "asset",
                  assetType: AssetTypes.Rental.RentalUnit,
                  id: unitId,
                })
              )
            );
            return `${i18n.t(
              "cb:RentalAgreement.Form.Errors.RENTAL_UNIT_OCCUPIED",
              "Folgende Mieteinheiten sind bereits vermietet:"
            )}\n${units
              .map((unit) => `${unit.data.id} - ${unit.data.displayName}`)
              .join("\n")}`;
          }
          if (errData.code === "RENTAL_AGREEMENT_ID_ALREADY_EXISTS") {
            return `${i18n.t(
              "cb:RentalAgreement.Form.Errors.RENTAL_AGREEMENT_ID_ALREADY_EXISTS",
              "Mietvertrag mit der ID bereits vorhanden"
            )}`;
          }
        }
        return null;
      }
    );
  }
  async submitTenant(values: any, tenant?: Contact) {
    const { helpers, ...formValues } = values;
    return await ContactService.submitContact(
      { contactType: ["TENANT"], ...formValues },
      tenant?._id
    );
  }
  async submitRentalUnit(values: RentalUnitFormValue, rentalUnit?: RentalUnit) {
    return await ServiceUtils.toastError(
      async () => {
        const { unitGroup, ...unit } = values;
        const unitCleared = {
          ...unit,
          building: unit.building || "",
        };

        const result = await HTTP.post({
          url: `/rental/${rentalUnit.data.objectId}/${rentalUnit._id}/updateRentalUnit`,
          target: "EMPTY",
          bodyParams: unitCleared,
        });
        CacheService.update(result);

        DataBus.emit(DataBusSubKeys.STACKING_PLAN_RELOAD, {
          objectId: rentalUnit.data.objectId,
        });
        return result;
      },
      async (err) => {
        const specialHandlingCodes = [
          "RENTAL_UNIT_ADMINISTRATION_RANGE_NOT_ALLOWED",
        ];
        if (specialHandlingCodes.includes(err.response?.data?.code)) {
          const errData = err.response.data;

          if (errData.code === "RENTAL_UNIT_ADMINISTRATION_RANGE_NOT_ALLOWED") {
            return i18n.t(
              "cb:RentalAgreement.Form.Errors.RENTAL_UNIT_ADMINISTRATION_RANGE_NOT_ALLOWED",
              "Administrationsbereich überschneidet sich mit laufenden Verträgen zu dieser Mieteinheit"
            );
          }
        }
        return null;
      }
    );
  }

  async submitRentalOpos(values: any, rentalOpos: RentalOpos) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalOpos,
      data: {
        _id: rentalOpos?._id,
        data: {
          ...values,
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalOpos;

    return result;
  }
  async closeRentalOpos(id: string) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalOpos,
      data: {
        _id: id,
        data: {
          status: "archived",
          closed: moment().toISOString(),
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalOpos;

    return result;
  }
  async reopenRentalOpos(id: string) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Rental.RentalOpos,
      data: {
        _id: id,
        data: {
          status: "active",
          closed: null,
        },
      },
      pushToCache: true,
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as RentalOpos;

    return result;
  }

  async submitRentalUnitPlanData(
    data: SubmitObjectPlanData[],
    reload?: { assetType: string; id: string }
  ) {
    const result = await HTTP.post({
      url: `/rental/changeMultipleRentalUnits`,
      bodyParams: {
        data: data,
      },
      target: "EMPTY",
    });

    if (reload) {
      CacheService.getData({
        assetType: reload.assetType,
        oType: "asset",
        id: reload.id,
        forceReload: true,
        ignoreDelay: true,
      });
    }
  }

  async submitRentalUnits(
    objectId: string,
    rentalUnits: RentalUnitFormValue[]
  ) {
    return await ServiceUtils.toastError(async () => {
      const unitsCleared = rentalUnits.map(({ unitGroup, ...unit }) => ({
        ...unit,
        building: unit.building || "",
      }));
      const result = await HTTP.post({
        url: `/rental/${objectId}/createNewRentalUnits`,
        bodyParams: {
          rentalUnits: unitsCleared,
        },
        target: "EMPTY",
      });

      return result;
    });
  }

  sanitizeMarks(marks: Partial<RentalAgreementMarks>) {
    if (!marks) return {};
    return Object.fromEntries(
      Object.entries(marks).map(([k, v]) => [
        k,
        (v || []).map(({ index, ...v }) => v),
      ])
    );
  }

  async submitRentalAgreementDeposit(
    rentalAgreementId: string,
    form: (DepositPosition & { document: any })[],
    marks: Partial<RentalAgreementMarks>,
    additionalDepositAttachmentsMetadata?: any
  ) {
    return await ServiceUtils.toastError(async () => {
      const deposit = (form || []).map(({ document, ...depositData }) => ({
        ...depositData,
      }));

      const depositAttachments = (form || [])
        .map((e) => e.document)
        .filter((e) => e)
        .flat();

      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/updateRentalAgreementDeposit`,
        bodyParams: {
          deposit: deposit,
          marks: this.sanitizeMarks(marks),
        },
        target: "EMPTY",
      });

      if (depositAttachments) {
        await ServiceUtils.handleCDNFiles(
          result,
          AssetTypes.Rental.RentalAgreement,
          "data.attachments",
          {
            new: depositAttachments,
          },
          additionalDepositAttachmentsMetadata
        );
      }

      CacheService.update(result);
    });
  }
  async submitRentalAgreementIndex(
    rentalAgreementId: string,
    form: IndexOption,
    marks: Partial<RentalAgreementMarks>
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/updateRentalAgreementIndex`,
        bodyParams: {
          index: form,
          marks: this.sanitizeMarks(marks),
        },
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async submitRentalAgreementAutomatic(
    rentalAgreementId: string,
    form: RentalAgreementAutomatic,
    marks: Partial<RentalAgreementMarks>
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/updateRentalAgreementAutomatic`,
        bodyParams: {
          automatic: form,
          marks: this.sanitizeMarks(marks),
        },
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async submitRentalAgreementCopayment(
    rentalAgreementId: string,
    form: RentalAgreementCopayment,
    marks: Partial<RentalAgreementMarks>
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/updateRentalAgreementCopayment`,
        bodyParams: {
          copayment: form,
          marks: this.sanitizeMarks(marks),
        },
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async submitRentalAgreementOptions(
    rentalAgreementId: string,
    form: AgreementOption,
    marks: Partial<RentalAgreementMarks>
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/updateRentalAgreementOptions`,
        bodyParams: { option: form, marks: this.sanitizeMarks(marks) },
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async submitRentalAgreementEnd(
    rentalAgreementId: string,
    agreementExpiration: Date
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/changeAgreementEnd`,
        bodyParams: { agreementExpiration },
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async submitRentalAgreementBegin(rentalAgreementId: string, moveIn: Date) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/changeAgreementStart`,
        bodyParams: { moveIn },
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async submitRentalAgreementContact(
    rentalAgreementId: string,
    form: ContactOption,
    marks: Partial<RentalAgreementMarks>
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/updateRentalAgreementContactOption`,
        bodyParams: {
          tenant: form,
          marks: this.sanitizeMarks(marks),
        },
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }

  async removeRentalAgreementIndex(rentalAgreementId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/removeRentalAgreementIndex`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async removeRentalAgreementDeposit(rentalAgreementId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/removeRentalAgreementDeposit`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async removeRentalAgreementOptions(rentalAgreementId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/removeRentalAgreementOptions`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async removeRentalAgreementAutomatic(rentalAgreementId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/removeRentalAgreementAutomatic`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }
  async removeRentalAgreementCopayment(rentalAgreementId: string) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${rentalAgreementId}/removeRentalAgreementCopayment`,
        target: "EMPTY",
      });
      CacheService.update(result);
    });
  }

  async endRentalAgreement(agreementId: string, moveOut: Date) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${agreementId}/endAgreement`,
        bodyParams: {
          moveOut: moment(moveOut).toISOString(),
        },
        target: "EMPTY",
      });
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }

  async restartRentalAgreement(agreement: RentalAgreement) {
    return await ServiceUtils.toastError(
      async () => {
        const result = await HTTP.post({
          url: `/rental/${agreement._id}/restartAgreement`,
          target: "EMPTY",
        });
        CacheService.updateDataInCaches(result._id, result);
        return result;
      },
      async (err) => {
        const specialHandlingCodes = [
          "RENTAL_UNIT_OCCUPIED",
          "RENTAL_TIME_PERIOD_GAP",
        ];
        if (specialHandlingCodes.includes(err.response?.data?.code)) {
          const errData = err.response.data;

          if (errData.code === "RENTAL_UNIT_OCCUPIED") {
            const periodIds = errData.message;
            const timePeriods: RentalAgreementTimePeriod[] = await Promise.all(
              periodIds.map((periodId) =>
                CacheService.getData({
                  oType: "asset",
                  assetType: AssetTypes.Rental.RentalAgreementPeriod,
                  id: periodId,
                })
              )
            );

            const affectedTimeperiods = timePeriods
              .map((period) => period.data.rentalUnitIds)
              .flat();
            const overlappingRentalUnitIds = _.intersection(
              affectedTimeperiods,
              agreement.data.rentalUnits
            );

            const units: RentalUnit[] = await Promise.all(
              overlappingRentalUnitIds.map((unitId) =>
                CacheService.getData({
                  oType: "asset",
                  assetType: AssetTypes.Rental.RentalUnit,
                  id: unitId,
                })
              )
            );
            return `${i18n.t(
              "cb:RentalAgreement.Form.Errors.RENTAL_UNIT_OCCUPIED",
              "Folgende Mieteinheiten sind bereits vermietet:"
            )}\n${units
              .map((unit) => `${unit.data.id} - ${unit.data.displayName}`)
              .join("\n")}`;
          }
          if (errData.code === "RENTAL_TIME_PERIOD_GAP") {
            return `${i18n.t(
              "cb:RentalAgreement.Form.Errors.RENTAL_TIME_PERIOD_GAP",
              "Zeitraumlücke zwischen den Mietzeiträumen"
            )}`;
          }
        }
        return null;
      }
    );
  }

  async setRentalAgreementDunningStop(
    agreementId: string,
    dunningStop: boolean
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${agreementId}/setDunningStop`,
        bodyParams: {
          dunningStop,
        },
        target: "EMPTY",
      });
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }

  async setRentalAgreementDebitRunStop(
    agreementId: string,
    stopDebitRun: boolean
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${agreementId}/setDebitRunStop`,
        bodyParams: {
          stopDebitRun,
        },
        target: "EMPTY",
      });
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }

  async mergeRentalAgreements(
    mainAgreementId: string,
    mergeAgreementIds: string[]
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/rental/${mainAgreementId}/mergeAgreements`,
        bodyParams: {
          agreementIds: mergeAgreementIds,
        },
        target: "EMPTY",
      });
      CacheService.updateDataInCaches(result._id, result);
      return result;
    });
  }

  async updateRentalAgreementBaseData(
    agreementId: string,
    id: string,
    displayName: string,
    note: string,
    contractType: string
  ) {
    return await ServiceUtils.toastError(
      async () => {
        const result = await HTTP.post({
          url: `/rental/${agreementId}/updateRentalAgreementBaseData`,
          bodyParams: {
            id,
            displayName,
            note,
            contractType,
          },
          target: "EMPTY",
        });
        CacheService.update(result);
        return result;
      },
      async (err) => {
        const specialHandlingCodes = ["RENTAL_AGREEMENT_ID_ALREADY_EXISTS"];
        if (specialHandlingCodes.includes(err.response?.data?.code)) {
          const errData = err.response.data;

          if (errData.code === "RENTAL_AGREEMENT_ID_ALREADY_EXISTS") {
            return `${i18n.t(
              "cb:RentalAgreement.Form.Errors.RENTAL_AGREEMENT_ID_ALREADY_EXISTS",
              "Mietvertrag mit der ID bereits vorhanden"
            )}`;
          }
        }
        return null;
      }
    );
  }
}
const CBRentalService = new CBRentalServiceClass();
export default CBRentalService;
