import { isDefined } from "@/utils/Helpers";
import classNames from "classnames";
import React from "react";
import i18n from "../../../../i18n";
import BFButton from "../../general/Button/BFButton";
import ValidationPopover, {
  ValidatorPopoverStyle,
} from "../../general/ValidationPopover/ValidationPopover";
import BFSelect from "../select/BFSelect";
import "./BFAssignment.scss";
import {
  AssignmentEntry,
  AssignmentEntryProps,
  BFAssignmentSearch,
} from "./BFAssignmentSearch";

export type UserAssignment = {
  id: string;
  role?: string;
  type: "user";
};
export type TeamAssignment = {
  id: string;
  role?: string;
  type: "team";
};
export type Assignment = UserAssignment | TeamAssignment;

export interface TextOverwrite {
  assignAUser?: string;
  assignFurtherUsers?: string;
  assignATeam?: string;
  assignFurtherTeams?: string;
  assignAUserOrTeam?: string;
  assignFurtherUsersOrTeams?: string;
}
interface BFAssignmentProps {
  label?: string;
  value: Assignment[];
  onChange: (value: Assignment[]) => void;
  assignmentType: "user" | "team" | "both";
  maxAssignments?: number;
  teamContexts?: string[];
  error?: string | boolean;
  identifier?: string;
  textOverwrite?: TextOverwrite;
  asOverlay?: boolean;
  roles?: { value: string; label: string }[];

  appearance?: "bf" | "ez";

  validation?: {
    message: string;
    level: "error" | "warning";
  };
  validatorStyle?: ValidatorPopoverStyle;
}

const assignmentText = (
  type: "user" | "team" | "both",
  count: number,
  textOverwrite?: TextOverwrite
) => {
  switch (type) {
    case "user":
      if (count === 0) {
        return (
          textOverwrite?.assignAUser ||
          i18n.t("BFAssignment.assignAUser", "Benutzer zuweisen")
        );
      } else {
        return (
          textOverwrite?.assignFurtherUsers ||
          i18n.t("BFAssignment.assignFurtherUsers", "weitere Benutzer zuweisen")
        );
      }
    case "team":
      if (count === 0) {
        return (
          textOverwrite?.assignATeam ||
          i18n.t("BFAssignment.assignATeam", "Team zuweisen")
        );
      } else {
        return (
          textOverwrite?.assignFurtherTeams ||
          i18n.t("BFAssignment.assignFurtherTeams", "weitere Teams zuweisen")
        );
      }
    case "both":
      if (count === 0) {
        return (
          textOverwrite?.assignAUserOrTeam ||
          i18n.t(
            "BFAssignment.assignAUserOrTeam",
            "Benutzer oder Team zuweisen"
          )
        );
      } else {
        return (
          textOverwrite?.assignFurtherUsersOrTeams ||
          i18n.t(
            "BFAssignment.assignFurtherUsersOrTeams",
            "weitere Benutzer oder Teams zuweisen"
          )
        );
      }
  }
};
const BFAssignment = (props: BFAssignmentProps) => {
  const [focused, setFocused] = React.useState(false);

  return (
    <ValidationPopover
      validatorStyle={props.validatorStyle}
      level={props.validation ? props.validation.level : "error"}
      message={props.validation ? props.validation.message : null}
      marginTop={0}
    >
      <div
        className={classNames(
          `bf-assignment`,
          `apperance-${props.appearance || "bf"}`,
          {
            focus: focused,
            error: !!props.error,
          }
        )}
      >
        {props.label && (
          <div className={`bf-assignment__label`}>{props.label}</div>
        )}
        <div className={`bf-assignment__content`}>
          <div className={`bf-assignment__assignments`}>
            {props.value.map((assignment) => (
              <AssignmentEntryValueElement
                key={assignment.id}
                roles={props.roles}
                onRoleChange={(role) => {
                  props.onChange(
                    props.value.map((e) =>
                      e.id === assignment.id
                        ? {
                            ...e,
                            role,
                          }
                        : e
                    )
                  );
                }}
                onDelete={() =>
                  props.onChange(props.value.filter((e) => e !== assignment))
                }
                assignment={assignment}
              />
            ))}
          </div>
          {props.value.length <
            (props.maxAssignments || Number.MAX_SAFE_INTEGER) && (
            <BFAssignmentSearch
              asOverlay={props.asOverlay}
              identifier={props.identifier}
              teamContexts={props.teamContexts}
              text={assignmentText(
                props.assignmentType,
                props.value.length,
                props.textOverwrite
              )}
              type={props.assignmentType}
              onSelect={(assignment) =>
                props.onChange([
                  ...props.value,
                  {
                    ...assignment,
                    role: isDefined(props.roles)
                      ? props.roles[0].value
                      : undefined,
                  },
                ])
              }
              value={props.value}
              renderToggle={(toggleProps, ref) => (
                <BFButton
                  className="bf-assignment__add-assignment"
                  appearance="link"
                  {...toggleProps}
                >
                  {assignmentText(
                    props.assignmentType,
                    props.value.length,
                    props.textOverwrite
                  )}
                </BFButton>
              )}
            />
          )}
        </div>
      </div>
    </ValidationPopover>
  );
};

export default BFAssignment;

interface AssignmentEntryValueProps extends AssignmentEntryProps {
  onDelete: () => void;
  roles?: { value: string; label: string }[];
  onRoleChange: (role: string) => void;
}
const AssignmentEntryValueElement = (props: AssignmentEntryValueProps) => {
  return (
    <div className={classNames("value-element")}>
      <AssignmentEntry assignment={props.assignment} />
      {props.roles && (
        <div className={`role`}>
          <BFSelect
            cleanable={false}
            data={props.roles}
            value={props.assignment.role}
            onChange={props.onRoleChange}
          />
        </div>
      )}
      <BFButton appearance="link" onClick={props.onDelete} size="xs">
        {i18n.t("Global.Buttoms.remove", "Entfernen")}
      </BFButton>
    </div>
  );
};
