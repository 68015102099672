import i18n from "@/i18n";
import { REPLACEMENT_STATUS } from "./UserReplacement.interface";

export const getUserReplacementStatus = (status: REPLACEMENT_STATUS) => {
  switch (status) {
    case REPLACEMENT_STATUS.PENDING:
      return {
        label: i18n.t(
          "UserProfile.Replacement.Status.Pending",
          "Warten auf Bestätigung"
        ),
        color: "#8bdafa",
      };
    case REPLACEMENT_STATUS.ACCEPTED:
      return {
        label: i18n.t("UserProfile.Replacement.Status.Accepted", "Akzeptiert"),
        color: "#74b76a",
      };
    case REPLACEMENT_STATUS.DECLINED:
      return {
        label: i18n.t("UserProfile.Replacement.Status.Declined", "Abgelehnt"),
        color: "#b76a6a",
      };
    case REPLACEMENT_STATUS.CANCELLED:
      return {
        label: i18n.t(
          "UserProfile.Replacement.Status.Cancelled",
          "Abgebrochen"
        ),
        color: "#a7a7a7",
      };
  }
};
