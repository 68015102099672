import { nanoid } from "nanoid";
import { Field } from "react-final-form";
import AssetLoader from "../../../../components/AssetLoader/AssetLoader";
import FormStruct from "../../../../components/Form/FormStruct/FormStruct";
import ModalManager from "../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import {
  Textblock,
  UserConfigAsset,
} from "../../../../model/db/UserConfigAsset";
import { useTypedSelector } from "../../../../redux/hooks";
import CacheService from "../../../../services/CacheService";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import SubmitService from "../../../../services/SubmitService";
import StringUtils from "../../../../utils/StringUtils";
import BFInput from "../../../abstract-ui/forms/input/BFInput";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import TextEditor from "../../../ckeditor/TextEditor";
import "./UserTextblocksConfig.scss";

interface UserTextblocksConfigProps {}
const UserTextblocksConfig = (props: UserTextblocksConfigProps) => {
  const userId = useTypedSelector((state) => state.global.user._id);
  const hasUserConfigAsset = useTypedSelector((state) =>
    state.global.user.permissions
      .reduce((prev, current) => {
        if (!current.ASSET) {
          return prev;
        }
        return [...prev, ...current.ASSET.map((e) => e.AssetID)];
      }, [])
      ?.includes(AssetTypes.UserDependend.UserConfig)
  );

  if (!hasUserConfigAsset) {
    return null;
  }
  return (
    <AssetLoader
      assetType={AssetTypes.UserDependend.UserConfig}
      query={{
        type: "op",
        name: "data.user",
        op: "eq",
        value: userId,
      }}
      render={(userConfig: UserConfigAsset, selector, reload) => {
        const textblocks = userConfig?.data?.textblocks || [];

        const onDelete = (index: number) => {
          ModalManager.confirm({
            title: i18n.t(
              "UserProfile.Profile.Textblock.deleteTextblock",
              "Textbausteine löschen"
            ),
            message: i18n.t(
              "UserProfile.Profile.Textblock.deleteTextblockText",
              "Möchten Sie den Textbaustein wirklich löschen?"
            ),
            onConfirm: async () => {
              try {
                const data = await SubmitService.submitDataAsync({
                  type: "asset",
                  assetType: AssetTypes.UserDependend.UserConfig,
                  data: {
                    _id: userConfig._id,
                    data: {
                      ...userConfig.data,
                      textblocks: textblocks.filter((_, i) => i !== index),
                    },
                  },
                  pushToCache: true,
                  ignorePropChecks: true,
                  ignoreSubmitValidation: true,
                });
                if (!userConfig) {
                  reload();
                } else {
                  CacheService.updateDataInCaches(selector, data);
                }
                DataBusDefaults.toast({
                  type: "success",
                  text: i18n.t(
                    "UserProfile.Profile.Textblock.successDeleteTextblock",
                    "Textbaustein wurde erfolgreich gelöscht"
                  ),
                });
              } catch (err) {
                DataBusDefaults.toast({
                  type: "error",
                  text: i18n.t(
                    "UserProfile.Profile.Textblock.errorDeleteTextblock",
                    "Textbaustein konnte nicht gelöscht werden"
                  ),
                });
              }
            },
            confirmButtonText: i18n.t(
              "UserProfile.Profile.Textblock.deleteTextblockConfirm",
              "Textbaustein löschen"
            ),
          });
        };
        const openTextblockForm = (textblock?: Textblock, index?: number) => {
          ModalManager.show({
            backdrop: "static",
            noPadding: true,
            size: "lg",
            content: (states, setStates, closeModal) => (
              <TextblockFormModal
                textblock={textblock}
                onClose={closeModal}
                onError={(error) => {
                  DataBusDefaults.toast({
                    type: "error",
                    text: i18n.t(
                      "UserProfile.Profile.Textblock.saveTextblockError",
                      "Fehler beim Speichern des Textbaustein"
                    ),
                  });
                }}
                onSuccess={async (value) => {
                  const asset = {
                    _id: userConfig?._id,
                    data: {
                      user: userId,
                      ...(userConfig?.data || {}),
                      textblocks: !textblock
                        ? [
                            ...(userConfig?.data?.textblocks || []),
                            {
                              ...value,
                              id: nanoid(),
                            },
                          ]
                        : userConfig.data.textblocks.map((s, i) => {
                            if (i === index) {
                              return { ...s, ...value };
                            }
                            return s;
                          }),
                    },
                  };
                  const data = await SubmitService.submitDataAsync({
                    type: "asset",
                    assetType: AssetTypes.UserDependend.UserConfig,
                    data: asset,
                    pushToCache: true,
                    ignorePropChecks: true,
                    ignoreSubmitValidation: true,
                  });
                  if (!userConfig) {
                    reload();
                  } else {
                    CacheService.updateDataInCaches(selector, data);
                  }
                  DataBusDefaults.toast({
                    type: "success",
                    text: i18n.t(
                      "UserProfile.Profile.Textblock.saveTextblockSuccess",
                      "Textbaustein erfolgreich gespeichert"
                    ),
                  });
                }}
              />
            ),
          });
        };

        return (
          <div className={`user-textblocks-config`}>
            <div className={`user-textblocks-config__header `}>
              <div className={`title __h1`}>
                {i18n.t("UserProfile.Profile.Textblock.Title", "Textbausteine")}
              </div>
              <BFButton appearance="link" onClick={() => openTextblockForm()}>
                {i18n.t(
                  "UserProfile.Profile.Textblock.Add",
                  "Neue Textbaustein hinzufügen"
                )}
              </BFButton>
            </div>

            <div className={`user-textblocks-config__description`}>
              {i18n.t(
                "UserProfile.Profile.Textblock.Description",
                "Textbausteine können Sie in der erweiterten Texteingaben (Emails & Kommentare) nutzen. Sie können Textbausteine für verschiedene Zwecke anlegen. Zum Beispiel für die Begrüßung von Kunden oder für die Verabschiedung von Kunden. Sie finden die Textbausteine in der Toolleiste des Texteditors."
              )}
            </div>
            <div className={`user-textblocks-config__content`}>
              {textblocks.length === 0 && (
                <div className={`user-textblocks-config__content__empty`}>
                  {i18n.t(
                    "UserProfile.Profile.Textblock.Empty",
                    "Keine Textbaustein vorhanden"
                  )}
                </div>
              )}
              {textblocks.map((textblock, index) => {
                return (
                  <TextblockEntry
                    textblock={textblock}
                    key={index}
                    onDelete={() => onDelete(index)}
                    onEdit={() => {
                      openTextblockForm(textblock, index);
                    }}
                  />
                );
              })}
            </div>
          </div>
        );
      }}
    />
  );
};

export default UserTextblocksConfig;

const TextblockEntry = (props: {
  textblock: Textblock;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  return (
    <div className={`textblock-entry`}>
      <div className={`textblock-entry__header`}>
        <div className={`textblock-entry__header__title`}>
          {props.textblock.title}
        </div>
        <div className={`textblock-entry__header__actions`}>
          <BFButton appearance="link" onClick={props.onEdit}>
            {i18n.t("Global.Buttons.edit")}
          </BFButton>
          <BFButton appearance="link" onClick={props.onDelete}>
            {i18n.t("Global.Buttons.delete")}
          </BFButton>
        </div>
      </div>
      <div className={`textblock-entry__content`}>
        <div
          className={`textblock-entry__content__text`}
          dangerouslySetInnerHTML={{
            __html: StringUtils.sanitizeHtml(props.textblock.textblock),
          }}
        />
      </div>
    </div>
  );
};
const TextblockFormModal = (props: {
  textblock?: Textblock;
  onClose: () => void;
  onSuccess: (data: Textblock) => Promise<any>;
  onError?: (error: any) => void;
}) => {
  return (
    <FormStruct
      className={"user-textblock-form"}
      initialValues={{
        title: props.textblock?.title || "",
        textblock: props.textblock?.textblock || "",
      }}
      title={
        props.textblock
          ? i18n.t(
              "UserProfile.Profile.Textblock.Form.TitleEdit",
              "Textbaustein bearbeiten"
            )
          : i18n.t(
              "UserProfile.Profile.Textblock.Form.TitleCreate",
              "Textbaustein erstellen"
            )
      }
      description={
        props.textblock ? (
          <div className={`user-textblock-form__description`}>
            {i18n.t(
              "UserProfile.Profile.Textblock.Form.DescriptionEdit",
              "Bearbeiten Sie die ausgewählte Textbaustein. Beim Erstellen von Kommentaren, kann diese Textbaustein in die Eingabemaske hinzugefügt werden."
            )}
          </div>
        ) : (
          <div className={`user-textblock-form__description`}>
            {i18n.t(
              "UserProfile.Profile.Textblock.Form.DescriptionCreate",
              "Erstellen Sie eine neue Textbaustein. Beim Erstellen von Kommentaren, kann diese Textbaustein in die Eingabemaske hinzugefügt werden."
            )}
          </div>
        )
      }
      submitText={
        props.textblock
          ? i18n.t("Global.Buttons.save")
          : i18n.t("Global.Buttons.create")
      }
      onAbort={props.onClose}
      onSubmit={async (values) => {
        try {
          await props.onSuccess(values);
          props.onClose();
        } catch (err) {
          props.onError?.(err);
        }
      }}
      validate={(values) => {
        const errors: any = {};
        if (!values.title) {
          errors.title = i18n.t(
            "UserProfile.Profile.Textblock.Form.Error.title",
            "Bitte geben Sie einen Namen ein"
          );
        }
        if (!values.textblock) {
          errors.textblock = i18n.t(
            "UserProfile.Profile.Textblock.Form.Error.Textblock",
            "Bitte geben Sie eine Textbaustein ein"
          );
        }
        return errors;
      }}
      render={({ form }) => (
        <>
          <div className={`__field`}>
            <Field name="title">
              {({ input, meta }) => (
                <BFInput
                  {...input}
                  label={`${i18n.t(
                    "UserProfile.Profile.Textblock.Form.Label.title",
                    "Name"
                  )}*`}
                  validation={
                    meta.error && meta.touched
                      ? {
                          level: "error",
                          message: meta.error,
                        }
                      : undefined
                  }
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="textblock">
              {({ input, meta }) => (
                <>
                  <TextEditor
                    placeholder={i18n.t(
                      "UserProfile.Profile.Textblock.Form.Placeholder.Textblock",
                      "Erstellen Sie Ihre Textbaustein hier..."
                    )}
                    value={input.value}
                    onChange={input.onChange}
                  />
                  {meta.error && meta.touched && (
                    <div className={`__error margin-top-10`}>{meta.error}</div>
                  )}
                </>
              )}
            </Field>
          </div>
        </>
      )}
    />
  );
};
