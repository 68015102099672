import SuccessAnimation from "@/components/LottieAnimations/Success/SuccessAnimation";
import OrgaStruct from "@/redux/actions/struct/implemented/OrgaStruct";
import UnitStruct from "@/redux/actions/struct/implemented/UnitStruct";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AssetLoader from "../../../../../components/AssetLoader/AssetLoader";
import { checkListViewSplitView } from "../../../../../components/ListViewPDF/OldListViewPDF";
import DeleteAnimation from "../../../../../components/LottieAnimations/Delete/DeleteAnimation";
import ModalManager from "../../../../../components/ModalComponent/ModalManager";
import DebugDataComponent from "../../../../../debug/DebugDataComponent";
import i18n from "../../../../../i18n";
import { ApproveInvoiceTaskAsset } from "../../../../../model/general-assets/TaskAsset";
import BFFormSection from "../../../../../modules/abstract-ui/data/form-section/BFFormSection";
import AutosizeTextarea from "../../../../../modules/abstract-ui/forms/autosize-textarea/AutosizeTextarea";
import BFSelect from "../../../../../modules/abstract-ui/forms/select/BFSelect";
import BFButton from "../../../../../modules/abstract-ui/general/Button/BFButton";
import BFMessage from "../../../../../modules/abstract-ui/general/Message/BFMessage";
import { DefaultIcons } from "../../../../../modules/abstract-ui/icon/DefaultIcons";
import { patchAssetData } from "../../../../../redux/actions/application/application-actions";
import {
  useTypedSelector,
  useUserIds,
  useViewportSelector,
} from "../../../../../redux/hooks";
import DataBus from "../../../../../services/DataBus";
import DataBusDefaults from "../../../../../services/DataBusDefaults";
import LanguageService from "../../../../../services/LanguageService";
import PermissionService from "../../../../../services/PermissionService";
import ArrayUtils from "../../../../../utils/ArrayUtils";
import { DataBusSubKeys } from "../../../../../utils/Constants";
import ACInvoiceUtils from "../../../../utils/ACInvoiceUtils";
import InvoiceService from "../../InvoiceService";
import { RAIncomingInvoice, RAInvoice } from "../../RAInterfaces";
import {
  RA_APPROVAL_LIST_TABLE_IDENTIFIER,
  RA_INVOICE_MAIN_FIELDS,
} from "../../RAUtils";
import RADeclineInvoice from "../DeclineInvoice/RADeclineInvoice";
import RACostCenterView from "../UpdateInvoice/RACostCenterView";
import RARelationsView from "../UpdateInvoice/RARelationsView";
import { RAInvoiceChangeTags } from "../UpdateInvoice/RAUpdateInvoiceEntry";
import RAInvoiceEntryView from "./../RAInvoiceEntryView";
import "./RAApproveInvoiceEntry.scss";

interface RAApproveInvoiceEntryProps {
  asset: ApproveInvoiceTaskAsset;
  selected: boolean;
  onOpenDrawer?: (state: "comments" | "history") => void;
  selectFC?: (
    asset: ApproveInvoiceTaskAsset,
    invoice: RAInvoice,
    force?: boolean
  ) => void;
  taskViewOnly?: boolean;
}

const RAApproveInvoiceEntry = (props: RAApproveInvoiceEntryProps) => {
  return (
    <div className="ra-approve-invoice-entry">
      <AssetLoader
        ignoreViewportDelay
        silentReload
        assetType={props.asset?.data.linkedAsset.assetType}
        id={props.asset?.data.linkedAsset.assetId}
        render={(invoice: RAInvoice) => (
          <ApproveInvoiceEntry {...props} invoice={invoice} />
        )}
      />
    </div>
  );
};
export default React.memo(RAApproveInvoiceEntry, (prev, next) => {
  // fix memo issue with functions - we should consider some general solution for this issue
  return _.isEqualWith(prev, next, (a, b) => {
    if (typeof a === "function" && typeof b === "function") {
      return true;
    }
    return undefined;
  });
});

const ApproveInvoiceEntry = (
  props: RAApproveInvoiceEntryProps & { invoice: RAInvoice }
) => {
  const dispatch = useDispatch();
  const [declineModalOpen, setDeclineModalOpen] = useState<boolean>(false);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [costCenterFormActive, setCostCenterFormActive] =
    useState<boolean>(false);
  const viewport = useViewportSelector();

  const me = useTypedSelector((state) => state.global.user);
  const userCheck = useUserIds();
  useEffect(() => {
    if (props.selected) {
      props.selectFC?.(props.asset, props.invoice, true);
    }
  }, [props.invoice]);

  const disableEdit = props.asset?._declined || props.asset?._approved;
  const approve = async () => {
    try {
      setApproveLoading(true);
      const result = await InvoiceService.approveInvoiceTask(
        props.invoice,
        props.asset,
        comment
      );
      setApproveLoading(false);

      dispatch(
        patchAssetData(props.asset?._id, {
          _approved: true,
        })
      );

      DataBus.emit(DataBusSubKeys.SELECT_NEXT, {
        tableIdentifier: RA_APPROVAL_LIST_TABLE_IDENTIFIER,
        smooth: true,
      });
    } catch (err) {
      DataBus.emit(DataBusSubKeys.TOAST, {
        type: "error",
        text: i18n.t("ra:ApproveList.Entry.ErrorAtApproving"),
      });
      setApproveLoading(false);
    }
  };

  const splitView = checkListViewSplitView(viewport);
  const isReplacement = userCheck.canReplace(
    props.asset?.data.restrictTo.users
  );
  const isNotAssignedToYou =
    !(props.asset?.data.restrictTo.users || []).includes(me._id) &&
    _.intersection(props.asset?.data.restrictTo.teams || [], me.teams || [])
      .length === 0;

  const canEditAnyWay = isNotAssignedToYou
    ? (
        PermissionService.hasBusinessUnitRole("inv_overwriteApprovals", true) ||
        []
      ).includes(props.asset?.data.type) || isReplacement
    : false;

  const onInvoiceChainChange = () => {
    return new Promise((resolve, reject) => {
      ModalManager.custom({
        title: i18n.t(
          "ra:ApproveList.Entry.ChangeInvoiceChain.Title",
          "Zuweisung ändern"
        ),
        message: i18n.t(
          "ra:ApproveList.Entry.ChangeInvoiceChain.Description",
          "Um die Zuweisung der Rechnung zu ändern, korrigieren Sie bitte folgende Rechnungsdaten. Die Zuweisung wird automatisch vom System ermittelt, anhand der eingegebenen Rechnungsdaten."
        ),
        confirmButtonText: i18n.t(
          "ra:ApproveList.Entry.ChangeInvoiceChain.ConfirmButton",
          "Rechnungsdaten korrigieren"
        ),
        initialState: {
          invoiceType: props.invoice?.data.invoice.invoiceType,
          objectId: props.invoice?.data.objectId,
          entity: props.invoice?.data.entity,
        },
        elements: (state, setState) => {
          const selectedInvoiceType = ACInvoiceUtils.getInvoiceType(
            state.invoiceType
          );
          const selectedEntity = OrgaStruct.getEntity(state.entity);

          return (
            <>
              <div style={{ marginBottom: 10 }}>
                <BFSelect
                  onChange={(value) =>
                    setState({ ...state, invoiceType: value })
                  }
                  label={RA_INVOICE_MAIN_FIELDS().invoiceType.label}
                  cleanable={false}
                  value={state.invoiceType}
                  data={ArrayUtils.sortData(
                    ACInvoiceUtils.getInvoiceTypes(
                      props.invoice?.data?.direction,
                      props.invoice?.data.type
                    ).map((type) => ({
                      value: type._id,
                      label: LanguageService.translateLabel(
                        type.data.displayName
                      ),
                    })),
                    { key: "label", dir: "asc" }
                  )}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <BFSelect
                  onChange={(value) =>
                    setState({ ...state, entity: value, objectId: null })
                  }
                  label={RA_INVOICE_MAIN_FIELDS().entity.label}
                  cleanable={false}
                  value={state.entity}
                  data={ArrayUtils.sortData(
                    OrgaStruct.getEntities(props.invoice?.data.type).map(
                      (type) => ({
                        value: type._id,
                        label: LanguageService.translateLabel(type.displayName),
                      })
                    ),
                    { key: "label", dir: "asc" }
                  )}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <BFSelect
                  onChange={(value) => setState({ ...state, objectId: value })}
                  label={RA_INVOICE_MAIN_FIELDS().objectId.label}
                  cleanable={false}
                  value={state.objectId}
                  data={ArrayUtils.sortData(
                    selectedEntity?.objects.map((type) => ({
                      value: type._id,
                      label: type.displayName,
                    })),
                    { key: "label", dir: "asc" }
                  )}
                />
              </div>
            </>
          );
        },
        onHide: () => {
          resolve(null);
        },
        canConfirm: (state) => true,

        onConfirm: (state) => {
          if (!state.objectId) {
            state.objectId = null;
          }
          InvoiceService.changeInvoiceAssignment(props.invoice, state)
            .then((result) => {
              DataBus.emit(DataBusSubKeys.SELECT_NEXT, {
                tableIdentifier: RA_APPROVAL_LIST_TABLE_IDENTIFIER,
                smooth: true,
              });
              resolve(result);
            })
            .catch((err) => {
              DataBusDefaults.toast({
                type: "error",
                text: i18n.t(
                  "ra:ApproveList.Entry.ChangeInvoiceChain.Error",
                  "Fehler beim Ändern der Rechnungsdaten"
                ),
              });
              reject(err);
            });
        },
        onAbort: () => {
          resolve(null);
        },
      });
    });
  };
  const disableEditActions = isNotAssignedToYou ? !canEditAnyWay : false;
  return (
    <div
      className="entry-content"
      onClick={() => props.selectFC?.(props.asset, props.invoice)}
    >
      {props.invoice && (
        <>
          <DebugDataComponent data={props.invoice} />
          {!props.taskViewOnly && (
            <div className="header">
              <div className="id">
                {props.invoice?.id} -{" "}
                {UnitStruct.getUnitLabel(props.invoice?.data.type)}
              </div>
              <div className="fill" />
              <div className="actions">
                <BFButton
                  tooltip={{
                    tooltip: i18n.t("Global.Buttons.edit"),
                  }}
                  appearance={"clear-on-white"}
                  icon={{
                    ...DefaultIcons.EDIT,
                    size: "xs",
                  }}
                  onClick={() =>
                    DataBusDefaults.route({
                      append: true,
                      route: `__edit/${props.invoice?._id}`,
                    })
                  }
                />
                {props.onOpenDrawer && (
                  <>
                    <BFButton
                      tooltip={{
                        tooltip: i18n.t("ra:Actions.Comment"),
                      }}
                      appearance={"clear-on-white"}
                      icon={{
                        type: "light",
                        data: "conversation-chat-1",
                        size: "xs",
                      }}
                      badge={{
                        content:
                          props.invoice.communication?.numComments || undefined,
                        color: "blue",
                      }}
                      onClick={() => props.onOpenDrawer("comments")}
                    />
                    <BFButton
                      tooltip={{
                        tooltip: i18n.t("ra:Actions.History"),
                      }}
                      appearance={"clear-on-white"}
                      icon={{
                        type: "light",
                        data: "synchronize-arrow-clock",
                        size: "xs",
                      }}
                      onClick={() => props.onOpenDrawer("history")}
                    />
                  </>
                )}

                {!splitView && (
                  <>
                    <div className="divider" />

                    <BFButton
                      tooltip={{
                        tooltip: "PDF anzeigen",
                      }}
                      appearance={"clear-on-white"}
                      icon={{ type: "light", data: "file-pdf", size: "xs" }}
                      onClick={() =>
                        DataBus.emit(DataBusSubKeys.OPEN_PDF_VIEWER, {})
                      }
                    />
                    {/* <BFButton
                tooltip={{
                  tooltip: props.pinned
                    ? i18n.t("ra:Actions.Unpin")
                    : i18n.t("ra:Actions.Pin"),
                }}
                appearance={props.pinned ? "link" : "clear-on-white"}
                icon={{ type: "light", data: "pin-4", size: "xs" }}
                onClick={() => props.setPinned(!props.pinned)}
              /> */}
                    {/* <BFButton
                tooltip={{
                  tooltip: i18n.t("ra:Actions.Unselect"),
                }}
                appearance="clear-on-white"
                icon={{ type: "light", data: "close", size: "xs" }}
                onClick={props.onClearSelection}
              /> */}
                  </>
                )}
              </div>
            </div>
          )}
          <div className="content">
            {props.invoice?.data.correction?.isCorrection && (
              <div className="correction">
                <BFMessage
                  type="info"
                  text={
                    <>
                      <div className={`text`}>
                        {i18n.t("ra:ApproveList.Entry.IsCorrectionInvoice")}
                      </div>
                      <BFButton
                        appearance="link"
                        size="xs"
                        onClick={() =>
                          DataBusDefaults.route({
                            route: `__details/${props.invoice?.data.correction.linkedInvoice}`,
                            append: true,
                          })
                        }
                      >
                        {i18n.t("ra:ApproveList.Entry.showOriginalInvoice")}
                      </BFButton>
                    </>
                  }
                ></BFMessage>

                {/* <BFButton appearance="outline" size="xs" onClick={() => {}}>
            Korrektur-Rechnung - Original anzeigen
          </BFButton> */}
              </div>
            )}
            {!props.taskViewOnly && (
              <>
                <RAInvoiceEntryView
                  invoice={props.invoice}
                  maxHistoryHeight={350}
                  hideCostCenter
                  hideInfoFields
                  hideRelations
                  hideTags
                />

                <RAInvoiceChangeTags asset={props.invoice} />

                {props.invoice.data.direction === "INCOMING" && (
                  <div className="costcenter-container">
                    <BFFormSection
                      title={i18n.t("ra:ApproveList.Entry.CostCenterSection")}
                      initialOpen={true}
                      marginBottom={15}
                    >
                      <RACostCenterView
                        onCostCenterActiveChange={setCostCenterFormActive}
                        initialCollapseActive={true}
                        disabled={disableEdit}
                        invoice={props.invoice as RAIncomingInvoice}
                        selected={props.selected}
                      />
                    </BFFormSection>
                  </div>
                )}
                <div className={`relations-container`}>
                  <BFFormSection
                    title={i18n.t(
                      "ra:UpdateInvoice.Section.Relations",
                      "Zugeordnete Aktivitäten"
                    )}
                    initialOpen={true}
                    marginBottom={15}
                    marginTop={15}
                  >
                    <RARelationsView invoice={props.invoice} />
                  </BFFormSection>
                </div>
              </>
            )}
            <div className="approve-form">
              {isNotAssignedToYou && (
                <>
                  {canEditAnyWay &&
                    (isReplacement ? (
                      <BFMessage
                        type="info"
                        text={i18n.t(
                          "ra:ApproveList.Entry.NotAssginedButYouAreReplacement",
                          "Sie sind als Vertreter für den Bearbeiter eingetragen und dürfen somit die Genehmigung durchführen."
                        )}
                      />
                    ) : (
                      <BFMessage
                        type="info"
                        text={i18n.t(
                          "ra:ApproveList.Entry.NotAssginedButYouCanEdit",
                          "Diese Genehmigung ist nicht Ihnen zugeordnet, Sie haben aber die Berechtigung diese Genehmigung durchzuführen."
                        )}
                      />
                    ))}
                  {!canEditAnyWay && (
                    <div className="disable-hint">
                      {i18n.t(
                        "ra:ApproveList.Entry.NotAssginedAndYouCannotEdit",
                        "Diese Genehmigung ist nicht Ihnen zugeordnet. Sie können diese nicht genehmigen."
                      )}
                    </div>
                  )}
                </>
              )}
              <div className="comment">
                <AutosizeTextarea
                  disabled={disableEdit || costCenterFormActive}
                  value={comment}
                  onChange={setComment}
                  minHeight={48}
                  maxHeight={130}
                  placeholder={i18n.t(
                    "ra:ApproveList.Entry.commentPlaceholder"
                  )}
                />
              </div>
              <div className="actions">
                <BFButton
                  disabled={
                    disableEditActions || disableEdit || costCenterFormActive
                  }
                  className={"change-user"}
                  appearance="outline"
                  onClick={onInvoiceChainChange}
                >
                  {i18n.t(
                    "ra:ApproveList.Entry.changeInvoiceChain",
                    "Zuweisung ändern"
                  )}
                </BFButton>
                <div className="fill" />
                <BFButton
                  disabled={disableEdit || costCenterFormActive}
                  className={"decline"}
                  appearance="outline"
                  onClick={() => setDeclineModalOpen(true)}
                >
                  {i18n.t("Global.Buttons.decline")}
                </BFButton>
                <BFButton
                  disabled={
                    disableEditActions || disableEdit || costCenterFormActive
                  }
                  className={"approve"}
                  appearance="primary"
                  loading={approveLoading}
                  onClick={approve}
                >
                  {i18n.t("Global.Buttons.approve")}
                </BFButton>
              </div>
            </div>

            <div
              className={`decline-overlay ${declineModalOpen ? "active" : ""}`}
            >
              <RADeclineInvoice
                invoiceId={props.invoice?._id}
                task={!!props.asset}
                comment={comment}
                onCommentChange={setComment}
                onClose={() => setDeclineModalOpen(false)}
                selected={props.selected}
                onFinish={() => {
                  dispatch(
                    patchAssetData(props.asset?._id, {
                      _declined: true,
                    })
                  );
                  setDeclineModalOpen(false);

                  DataBus.emit(DataBusSubKeys.SELECT_NEXT, {
                    tableIdentifier: RA_APPROVAL_LIST_TABLE_IDENTIFIER,
                    smooth: true,
                  });
                }}
              />
            </div>
          </div>
        </>
      )}
      {!props.invoice && (
        <div>
          <BFMessage
            type="warning"
            text={i18n.t(
              "ra:ApproveList.Entry.noPermissionToViewInvoice",
              "Sie haben keine Berechtigung diese Rechnung zu sehen."
            )}
          />
        </div>
      )}
      {props.asset?._declined && (
        <div className="overlay">
          <div className="overlay-content">
            <DeleteAnimation style={{ width: 80, height: 80 }} />
            {/* <BfIcon data="bin-2" type="light" size="sm" /> Rechnung gelöscht */}
          </div>
        </div>
      )}
      {props.asset?._approved && (
        <div className="overlay">
          <div className="overlay-content">
            <SuccessAnimation style={{ width: 100, height: 100 }} />
            {/* <BfIcon
              data="singe-netrual-actions-process"
              type="light"
              size="sm"
            />{" "}
            In Genehmigung */}
          </div>
        </div>
      )}
      {!UnitStruct.hasUnitIdentifier(props.invoice?.data.type) && (
        <div className="overlay">
          <div className="overlay-content">
            <div className={`invalid-type-info`}>
              {i18n.t(
                "ra:ApproveList.Entry.InvalidType",
                "Um diesen Eintrag zu bearbeiten wechseln Sie bitte in die andere App"
              )}
            </div>
            {/* <BfIcon
              data="singe-netrual-actions-process"
              type="light"
              size="sm"
            />{" "}
            In Genehmigung */}
          </div>
        </div>
      )}
    </div>
  );
};
