import Log from "../debug/Log";
import i18n from "../i18n";
import { SET_USER_DATA } from "../redux/actions/global/global-actions-types";
import { store } from "../redux/store";
import DataBus from "../services/DataBus";
import { BFNotification } from "./BFNotification";
import { BFToast } from "./BFToast";

interface ErrorEntry {
  localizeTitleKey?: string;
  localizeMsg: string;
  showNotification: boolean;
  showAlert: boolean;
  duration?: number;
}

export function handleError(errorObj, context = "default") {
  const errorCodes = ErrorCodes();
  const t = (window as any).translate;
  Log.error(errorObj);
  if (errorObj && errorObj.response) {
    const data = errorObj.response.data;
    if (data && data.code) {
      const error =
        errorCodes[data.code] && errorCodes[data.code][context]
          ? errorCodes[data.code][context]
          : errorCodes["UNKNOWN_ERROR"]["default"];
      if (error) {
        if (error.showAlert) {
          BFToast.open({
            type: "error", //duration: 4000000,
            duration:
              error.duration || error.localizeMsg.length > 100 ? 4000 : 2000,
            content: (
              <>
                {typeof error.localizeTitleKey === "string" && (
                  <h5>{t(error.localizeTitleKey)}</h5>
                )}
                <p>{t(error.localizeMsg)}</p>
              </>
            ),
          });
        }

        if (error.showNotification) {
          BFNotification.open({
            type: "error",
            duration:
              error.duration || error.localizeMsg.length > 100 ? 4000 : 2000,
            title: error.localizeTitleKey ? t(error.localizeTitleKey) : null,
            description: t(error.localizeMsg),
          });
        }
      }
    } else {
      //TODO not a api error, handle status codes
      if (errorObj.response.status === 401) {
        const error = errorCodes["UNAUTHORIZED"][context];
        BFToast.open({
          type: "error", //duration: 4000000,
          duration:
            error.duration || error.localizeMsg.length > 100 ? 4000 : 2000,
          content: (
            <>
              {typeof error.localizeTitleKey === "string" && (
                <h5>{t(error.localizeTitleKey)}</h5>
              )}
              <p>{t(error.localizeMsg)}</p>
            </>
          ),
        });
        store.dispatch({
          type: SET_USER_DATA,
          user: null,
        });
        DataBus.emit("ROUTE", {
          route: "/login",
        });
      } else {
        const error = errorCodes["UNKNOWN_ERROR"][context];
        BFToast.open({
          type: "error", //duration: 4000000,
          duration:
            error.duration || error.localizeMsg.length > 100 ? 4000 : 2000,
          content: (
            <>
              {typeof error.localizeTitleKey === "string" && (
                <h5>{t(error.localizeTitleKey)}</h5>
              )}
              <p>{t(error.localizeMsg)}</p>
            </>
          ),
        });
      }
    }
  }
}
export function getErrorLocalized(errorObj, context = "default") {
  const errorCodes = ErrorCodes();
  const t = (window as any).translate;
  if (errorObj && errorObj.response) {
    const data = errorObj.response.data;
    if (data && data.code) {
      const error =
        errorCodes[data.code] && errorCodes[data.code][context]
          ? errorCodes[data.code][context]
          : errorCodes["UNKNOWN_ERROR"]["default"];
      return t(error.localizeMsg);
    } else {
      const error = errorCodes["UNKNOWN_ERROR"]["default"];
      return t(error.localizeMsg);
    }
  }
  return null;
}

export const ErrorCodes: () => {
  [key: string]: { [context: string]: ErrorEntry };
} = () => ({
  USER_ALREADY_EXISTS: {
    default: {
      localizeMsg: i18n.t("errorCodes.createUser.userAlreadyExists.msg"),
      localizeTitleKey: i18n.t("errorCodes.createUser.userAlreadyExists.title"),
      showNotification: false,
      showAlert: true,
    },
  },
  WRONG_OLD_PASSWORD: {
    default: {
      localizeMsg: i18n.t(
        "errorCodes.login.wrongOldPassword.msg",
        "Das aktuelle Passwort ist nicht korrekt. Bitte versuchen Sie es erneut."
      ),
      localizeTitleKey: i18n.t(
        "errorCodes.login.wrongOldPassword.title",
        "Änderung fehlgeschlagen"
      ),
      showNotification: false,
      showAlert: true,
    },
  },
  NOT_ALLOWED: {
    default: {
      localizeMsg: i18n.t(
        "errorCodes.common.notAllowed.msg",
        "Sie haben keine Berechtigung für diese Aktion."
      ),
      localizeTitleKey: i18n.t(
        "errorCodes.common.notAllowed.title",
        "Keine Berechtigung"
      ),
      showNotification: false,
      showAlert: true,
    },
  },
  WRONG_CRED: {
    default: {
      localizeMsg: i18n.t("errorCodes.login.wrongCreds.msg"),
      localizeTitleKey: i18n.t("errorCodes.login.wrongCreds.title"),
      showNotification: false,
      showAlert: true,
    },
  },
  REQUEST_INVALID_PARAM: {
    default: {
      localizeMsg: i18n.t(
        "errorCodes.common.invalidParam.msg",
        "Die Anfrage ist fehlerhaft aufgebaut. Bitte überprüfen Sie Ihre Eingaben."
      ),
      localizeTitleKey: i18n.t(
        "errorCodes.common.invalidParam.title",
        "Fehlerhafte Anfrage"
      ),
      showNotification: false,
      showAlert: true,
    },
  },
  MANDATOR_DEACTIVATED: {
    default: {
      localizeMsg: i18n.t("errorCodes.login.mandatorDeactivated.msg"),
      localizeTitleKey: i18n.t("errorCodes.login.mandatorDeactivated.title"),
      showNotification: false,
      showAlert: true,
    },
  },
  ACCOUNT_DEACTIVATED: {
    default: {
      localizeMsg: i18n.t("errorCodes.login.accountDeactivated.msg"),
      localizeTitleKey: i18n.t("errorCodes.login.accountDeactivated.title"),
      showNotification: false,
      showAlert: true,
    },
  },
  ACCOUNT_INACTIVE: {
    default: {
      localizeMsg: i18n.t("errorCodes.login.accountInactive.msg"),
      localizeTitleKey: i18n.t("errorCodes.login.accountInactive.title"),
      showNotification: false,
      showAlert: true,
    },
  },
  ACCOUNT_LOCKED: {
    default: {
      localizeMsg: i18n.t("errorCodes.login.accountLocked.msg"),
      localizeTitleKey: i18n.t("errorCodes.login.accountLocked.title"),
      showNotification: false,
      showAlert: true,
    },
  },
  ACCOUNT_WAITINGFORACTIVATION: {
    default: {
      localizeMsg: i18n.t("errorCodes.login.accountWaitingForActivation.msg"),
      localizeTitleKey: i18n.t(
        "errorCodes.login.accountWaitingForActivation.title"
      ),
      showNotification: false,
      showAlert: true,
    },
  },
  UNKNOWN_USER_STATUS: {
    default: {
      localizeMsg: i18n.t("errorCodes.login.unknownUserStatus.msg"),
      localizeTitleKey: i18n.t("errorCodes.login.unknownUserStatus.title"),
      showNotification: false,
      showAlert: true,
    },
  },

  UNAUTHORIZED: {
    default: {
      localizeMsg: i18n.t("errorCodes.unauthorized.msg"),
      localizeTitleKey: i18n.t("errorCodes.unauthorized.title"),
      showNotification: false,
      showAlert: true,
    },
  },

  UNKNOWN_ERROR: {
    default: {
      localizeMsg: i18n.t("errorCodes.unknownError.msg"),
      localizeTitleKey: i18n.t("errorCodes.unknownError.title"),
      showNotification: false,
      showAlert: true,
    },
  },
  SILENT_UNKNOWN_ERROR: {
    default: {
      localizeMsg: i18n.t("errorCodes.unknownError.msg"),
      localizeTitleKey: i18n.t("errorCodes.unknownError.title"),
      showNotification: false,
      showAlert: false,
    },
  },

  NO_PERMISSION_TO_DELETE: {
    default: {
      localizeMsg: i18n.t("errorCodes.permissionMissing.delete"),
      localizeTitleKey: i18n.t("common.actions.deleteObject"),
      showNotification: true,
      showAlert: false,
    },
  },
  NO_PERMISSION_TO_UPDATE: {
    default: {
      localizeMsg: i18n.t("errorCodes.permissionMissing.update"),
      localizeTitleKey: i18n.t("common.actions.updateObject"),
      showNotification: true,
      showAlert: false,
    },
  },
  NO_PERMISSION_TO_INSERT: {
    default: {
      localizeMsg: i18n.t("errorCodes.permissionMissing.insert"),
      localizeTitleKey: i18n.t("common.actions.insertObject"),
      showNotification: true,
      showAlert: false,
    },
  },
  NO_PERM_VIEW: {
    default: {
      localizeMsg: i18n.t("errorCodes.permissionMissing.view"),
      localizeTitleKey: i18n.t("common.actions.getObject"),
      showNotification: true,
      showAlert: false,
    },
  },
  ID_NOT_FOUND: {
    default: {
      localizeMsg: i18n.t("errorCodes.idNotFound.msg"),
      showNotification: false,
      showAlert: true,
      duration: 4000,
    },
    completeRegistration: {
      localizeMsg: i18n.t("errorCodes.idNotFound.completeRegistration.msg"),
      showNotification: false,
      showAlert: true,
      duration: 4000,
    },
  },
  REPLACEMENT_EXISTS: {
    default: {
      localizeMsg: i18n.t(
        "errorCodes.replacement.replacementExist.msg",
        "Die Vertretung existiert bereits"
      ),
      showNotification: false,
      showAlert: true,
    },
  },
  REPLACEMENT_IS_REPLACED: {
    default: {
      localizeMsg: i18n.t(
        "errorCodes.replacement.replacedIsReplaced.msg",
        "Vertretung in dem Zeitraum bereits beantragt"
      ),
      showNotification: false,
      showAlert: true,
    },
  },
  REPLACED_IS_REPLACEMENT: {
    default: {
      localizeMsg: i18n.t(
        "errorCodes.replacement.replacedIsReplacement.msg",
        "Vertretende Person hat für den Zeitraum bereits selbst Vertretung beantragt"
      ),
      showNotification: false,
      showAlert: true,
    },
  },
  REPLACEMENT_ACCEPTED: {
    default: {
      localizeMsg: i18n.t(
        "errorCodes.replacement.REPLACEMENT_ACCEPTED.msg",
        "Vertretung wurde bereits angenommen"
      ),
      showNotification: false,
      showAlert: true,
    },
  },
  REPLACEMENT_DECLINED: {
    default: {
      localizeMsg: i18n.t(
        "errorCodes.replacement.REPLACEMENT_DECLINED.msg",
        "Vertretung wurde bereits abgelehnt"
      ),
      showNotification: false,
      showAlert: true,
    },
  },

  ASSET_TYPE_INVALID: {
    default: {
      localizeMsg: i18n.t("errorCodes.assets.assetTypeInvalid.msg"),
      localizeTitleKey: i18n.t("errorCodes.assets.assetTypeInvalid.title"),
      showNotification: false,
      showAlert: true,
    },
    mismatch: {
      localizeMsg: i18n.t("errorCodes.assets.assetTypeInvalid.mismatch.msg"),
      localizeTitleKey: i18n.t("errorCodes.assets.assetTypeInvalid.title"),
      showNotification: false,
      showAlert: true,
    },
  },
  ASSET_INACTIVE: {
    default: {
      localizeMsg: i18n.t("errorCodes.assets.assetInactive.msg"),
      localizeTitleKey: i18n.t("errorCodes.assets.assetInactive.title"),
      showNotification: false,
      showAlert: true,
    },
    target: {
      localizeMsg: i18n.t("errorCodes.assets.assetInactive.target.msg"),
      localizeTitleKey: i18n.t("errorCodes.assets.assetInactive.title"),
      showNotification: false,
      showAlert: true,
    },
    source: {
      localizeMsg: i18n.t("errorCodes.assets.assetInactive.source.msg"),
      localizeTitleKey: i18n.t("errorCodes.assets.assetInactive.title"),
      showNotification: false,
      showAlert: true,
    },
  },
});
