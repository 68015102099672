import { HTTP, HttpInterface } from "@/utils/Http";
import axios, { CancelTokenSource } from "axios";
import { useCallback, useEffect, useState } from "react";
import { BlockType } from "./services/types/pdfConfigBlockTypes";

export const useHTTPRequest = <Response,>(http: HttpInterface) => {
  const [data, setData] = useState<Response>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);

  const fetch = useCallback(
    async (cancelToken: CancelTokenSource | undefined) => {
      setIsLoading(true);
      try {
        if (cancelToken) {
          http.cancelToken = cancelToken.token;
        }

        const response = (await HTTP.get(http)) as Response;

        setData(response);
      } catch (err) {
        if (!axios.isCancel(err)) {
          setIsError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [http]
  );

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    fetch(cancelToken);

    // Cleanup function to abort fetch if component unmounts
    return () => cancelToken.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = useCallback(() => {
    return fetch(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, isLoading, isError, refresh };
};

export const useAvailableBlockList = () => {
  const { data, isLoading, isError, refresh } = useHTTPRequest<{
    templates: { _id: string; displayName: string }[];
    blocks: BlockType[];
  }>({
    url: "/pdfConstructor",
  });

  return { data, isLoading, isError, refresh };
};
