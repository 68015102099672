import classNames from "classnames";
import AssetLoader from "../../../../../../components/AssetLoader/AssetLoader";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import MQ from "../../../../../../utils/MatchQueryUtils";
import { useActivityConstants } from "../../../ActivityHooks";
import { APActivity } from "../../../ActivityInterfaces";
import APBudgetForm from "./APBudgetForm";
import { APBudget } from "./APBudgetInterfaces";
// import "./APBudgetPage.scss";

interface APBudgetPageProps {
  budgetSelector: string;
  activity: APActivity;
}
const APBudgetPage = (props: APBudgetPageProps) => {
  const constants = useActivityConstants();

  const activeId = props.activity?.data.currentActiveBudgetId;
  return (
    <div className={classNames(`ap-budget-page`)}>
      <AssetLoader
        assetType={AssetTypes.ActivityRelated.Budget}
        id={
          props.budgetSelector === "active"
            ? activeId
            : !["active", "draft"].includes(props.budgetSelector)
            ? props.budgetSelector
            : undefined
        }
        query={
          ["draft"].includes(props.budgetSelector)
            ? MQ.and(
                MQ.eq("data.status", "draft"),
                MQ.eq("data.linkedAsset.assetId", props.activity._id)
              )
            : undefined
        }
        render={(
          budget: APBudget,
          selector: string,
          reload: (silent?: boolean) => void
        ) => {
          return (
            <>
              {/* {props.budgetSelector === "active" && (
                <APActiveBudgetCharts
                  activity={props.activity}
                  budget={budget}
                />
              )} */}
              {props.budgetSelector === "draft" && activeId ? (
                <AssetLoader
                  assetType={AssetTypes.ActivityRelated.Budget}
                  id={activeId}
                  render={(currentBudget: APBudget) => (
                    <APBudgetForm
                      onSubmit={async (result) => {
                        reload(true);
                      }}
                      currentBudget={currentBudget}
                      showActualData={false}
                      // showActualData={props.budgetSelector === "active"}
                      activity={props.activity}
                      readonly={props.budgetSelector !== "draft"}
                      budget={budget}
                    />
                  )}
                />
              ) : (
                <APBudgetForm
                  onSubmit={async (result) => {
                    reload(true);
                  }}
                  showActualData={false}
                  // showActualData={props.budgetSelector === "active"}
                  activity={props.activity}
                  readonly={props.budgetSelector !== "draft"}
                  budget={budget}
                />
              )}
            </>
            // <div>
            //   APBudgetPage {props.budgetSelector} {budget?._id || "no budget"}
            // </div>
          );
        }}
      />
    </div>
  );
};

export default APBudgetPage;
