import React from "react";
import BFGanttChart from "../../../../modules/gantt-chart/BFGanttChart";
import { GanttTask } from "../../../../modules/gantt-chart/BFGanttChart.interface";
import { convertTasksToGantTasks as convertTasksToGanttTasks } from "../../../../modules/gantt-chart/BFGanttChart.utils";
import { Task, ViewMode } from "../../../../modules/gantt-chart/lib";
import APScheduleTaskProgress, {
  APSchduleTaskProgressHeader,
} from "../../../tatar/activityApp/views/details/project-budget/APScheduleTaskProgress";
import "./KSGanttDemo.scss";

export function initTasks() {
  const currentDate = new Date();
  const tasks: Task[] = [
    {
      start: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
        0,
        0
      ),
      end: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        15,
        0,
        0
      ),
      name: "Some Project",
      id: "ProjectSample",
      progress: 25,
      type: "project",
      hideChildren: false,
      displayOrder: 1,
    },
    {
      start: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
        0,
        0
      ),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 0, 0),
      name: "Idea",
      id: "Task 0",
      progress: 45,
      type: "task",
      project: "ProjectSample",
      displayOrder: 2,
    },
    {
      start: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        2,
        0,
        0
      ),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
      name: "Research",
      id: "Task 1",
      progress: 25,
      type: "task",
      project: "ProjectSample",
      displayOrder: 3,
    },
    {
      start: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        4,
        0,
        0
      ),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
      name: "Discussion with team",
      id: "Task 2",
      progress: 10,
      type: "task",
      project: "ProjectSample",
      displayOrder: 4,
    },
    {
      start: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        8,
        0,
        0
      ),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
      name: "Developing",
      id: "Task 3",
      progress: 2,
      type: "task",
      project: "ProjectSample",
      displayOrder: 5,
    },
    {
      start: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        8,
        0,
        0
      ),
      end: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        10,
        0,
        0
      ),
      name: "Review",
      id: "Task 4",
      type: "task",
      progress: 70,
      project: "ProjectSample",
      displayOrder: 6,
    },
    {
      start: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        15,
        0,
        0
      ),
      end: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        16,
        0,
        0
      ),
      name: "Release",
      id: "Task 6",
      progress: currentDate.getMonth(),
      type: "milestone",
      project: "ProjectSample",
      displayOrder: 7,
    },
    {
      start: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        18,
        0,
        0
      ),
      end: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        19,
        0,
        0
      ),
      name: "Party Time",
      id: "Task 9",
      progress: 0,
      isDisabled: true,
      type: "task",
    },
  ];
  return tasks;
}

export function getStartEndDateForProject(tasks: Task[], projectId: string) {
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}

interface KSGanttDemoProps {}
const KSGanttDemo = (props: KSGanttDemoProps) => {
  const [view, setView] = React.useState<ViewMode>(ViewMode.Day);
  const [tasks, setTasks] = React.useState<Task[]>(initTasks());
  const [gantTasks, setGantTasks] = React.useState<GanttTask[]>(
    convertTasksToGanttTasks(initTasks())
  );
  const [isChecked, setIsChecked] = React.useState(true);
  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskChange = (task: Task) => {
    console.log("On date change Id:" + task.id);
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task: Task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleClick = (task: Task) => {
    console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };

  return (
    <div className="Wrapper">
      <BFGanttChart
        value={gantTasks}
        onChange={setGantTasks}
        progressChange={"manual"}
        additionalColumn={{
          renderHeader: () => <APSchduleTaskProgressHeader />,
          renderColumn: (task: Task) => (
            <APScheduleTaskProgress
              activityId=""
              task={task}
              // scheduleProgressEntry={{
              //   date: moment().startOf("day").toDate(),
              //   daysMissed: 4,
              //   groupId: task.id,
              //   userId: myUser,
              //   comment: "Dies ist ein Kommentar",
              // }}
            />
          ),
        }}
      />
    </div>
  );
};

export default KSGanttDemo;
