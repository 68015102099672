import moment, { Moment } from "moment";
import i18n from "../../i18n";
import { AssetTypes } from "../../model/AssetTypes";
import { store } from "../../redux/store";
import CacheService from "../../services/CacheService";
import DataBus from "../../services/DataBus";
import DataBusDefaults from "../../services/DataBusDefaults";
import { MatchQuery } from "../../services/DataService";
import SubmitService from "../../services/SubmitService";
import {
  DataBusSubKeys,
  PriorityColorCodes,
  PriorityIcons,
} from "../../utils/Constants";
import MQ from "../../utils/MatchQueryUtils";
import { RSDatabusEvents, RSListIdentifiers } from "./RSConstants";
import {
  RSAssigneeFilterOption,
  RSDateTagStatus,
  Resubmission,
  ResubmissionStatus,
} from "./RSInterfaces";

class RSServiceClass {
  /**
   * Returns the default matchQueries for Resubmissions.
   * Take an optional params object, which can have an array of status values to filter for
   * and an assetType to filter for linkedAsset.
   *
   * If no params object is present the matchQuery will only filter for open Resubmissions
   *
   * @param params
   * {
   * assetType?: string;
   * status?: [];
   * }
   */
  getDefaultMatchQuery(params?: {
    assetType?: string;
    assetId?: string;
    status?: string[];
  }): MatchQuery {
    const statusValues = params?.status || ["open"];
    const statusMatchQuery: MatchQuery = {
      type: "op",
      op: "in",
      name: "data.status",
      value: statusValues,
    };

    let assetIdQuery: MatchQuery | undefined = undefined;
    if (params && params.assetId) {
      assetIdQuery = {
        type: "op",
        op: "eq",
        name: "data.linkedAsset.assetId",
        value: params.assetId,
      };
    }

    let assetTypeQuery: MatchQuery | undefined = undefined;
    if (params && params.assetType) {
      assetTypeQuery = {
        type: "op",
        op: "eq",
        name: "data.linkedAsset.assetType",
        value: params.assetType,
      };
    }

    if (assetIdQuery || assetTypeQuery) {
      return MQ.combine("and", [
        statusMatchQuery,
        assetIdQuery,
        assetTypeQuery,
      ]);
    }

    return statusMatchQuery;
  }

  /**
   * Returns the match query given to the assignee filter
   *
   * @param assigneeFilter
   * @returns
   */
  getAssigneeMatchQuery(
    assigneeFilter: RSAssigneeFilterOption,
    assignmentField?: string
  ) {
    const field = assignmentField || "data.assignees";

    const user = store.getState().global.user;
    let matchQuery = undefined;
    switch (assigneeFilter) {
      case "all":
        matchQuery = undefined;
        break;
      case "own":
        matchQuery = {
          type: "op",
          op: "in",
          name: `${field}.users`,
          value: [
            user._id,
            ...(user?.replacementFor?.map((e) => e.data.replacedUser) || []),
          ],
        };
        break;
      case "team":
        // TODO SJ - implement team match query
        matchQuery = undefined;
        break;
    }

    return matchQuery;
  }

  async submitResubmissionAsync(values: any, identifierToUpdate: string) {
    const result = (await SubmitService.submitDataAsync({
      type: "asset",
      assetType: AssetTypes.Resubmission,
      data: {
        _id: values.id,
        data: {
          type: values.type,
          title: values.title,
          description: values.description,
          isTodo: values.isTodo,
          priority: values.priority,
          assignees: values.assignees,
          dueDate: moment(values.dueDate)
            .startOf("day")
            .utc(true)
            .toISOString(),
          remindDate: values.remindDate,
          resubmissionType: values.resubmissionType,
          interval: values.interval,
          linkedAsset: values.linkedAsset,
          status: values.status,
          category: values.category,
          feedbackRecipients: values.feedbackRecipients,
        },
      },
      ignorePropChecks: true,
      ignoreSubmitValidation: true,
    })) as Resubmission;
    CacheService.updateDataInCaches(result._id, result);

    DataBus.emit(DataBusSubKeys.RESUBMISSION_COUNT_UPDATE, "");
    DataBusDefaults.reload({
      identifiers: [
        RSListIdentifiers.GlobalDashboard,
        RSListIdentifiers.AppBarButton,
      ],
    });

    DataBus.emit(RSDatabusEvents.UpdateResubmissions, "");
    if (identifierToUpdate) {
      DataBusDefaults.reload({
        identifiers: [identifierToUpdate],
      });
    }

    DataBusDefaults.toast({
      type: "success",
      text: i18n.t(
        "Resubmission.Toast.Save",
        "Die Wiedervorlage wurde gespeichert."
      ),
    });
  }

  async submitResubmissionDoneAsync(values: any, identifierToUpdate: string) {
    try {
      const result = await SubmitService.submitDataAsync({
        type: "asset",
        assetType: AssetTypes.Resubmission,
        data: {
          _id: values.id,
          data: {
            status: "done",
            doneAt: moment().toISOString(),
            doneBy: store.getState().global.user._id,
            feedbackComment: values.feedbackComment,
          },
        },
        ignorePropChecks: true,
        ignoreSubmitValidation: true,
      });

      const resubmissionResult = result as Resubmission;
      CacheService.updateDataInCaches(resubmissionResult._id, result);

      DataBus.emit(DataBusSubKeys.RESUBMISSION_COUNT_UPDATE, "");
      DataBusDefaults.reload({
        identifiers: [
          RSListIdentifiers.GlobalDashboard,
          RSListIdentifiers.AppBarButton,
          RSListIdentifiers.KpiOwn,
          RSListIdentifiers.KpiFeedback,
        ],
      });
      DataBus.emit(RSDatabusEvents.UpdateResubmissions, "");
      if (identifierToUpdate) {
        DataBusDefaults.reload({
          identifiers: [identifierToUpdate],
        });
      }
      DataBusDefaults.toast({
        type: "success",
        text: i18n.t(
          "Resubmission.Toast.Done",
          "Die Wiedervorlage wurde abgeschlossen."
        ),
      });

      return true;
    } catch (error) {
      DataBusDefaults.toast({
        type: "error",
        text: i18n.t(
          "Resubmission.Error.Toast.Done",
          "Beim Schließen der Wiedervorlage ist ein Fehler aufgetreten."
        ),
      });
      return false;
    }
  }

  getResubmissionTypeOptions() {
    return [
      {
        value: "single",
        label: i18n.t("Resubmission.TypeOption.Single", "Einmalig"),
      },
      {
        value: "interval",
        label: i18n.t("Resubmission.TypeOption.Interval", "Intervall"),
      },
      // {
      //   value: "series",
      //   label: i18n.t("Resubmission.TypeOption.Series", "Serie"),
      // },
    ];
  }

  getCategoryOptions() {
    return [
      {
        value: "todo",
        label: i18n.t("Resubmission.CategoryOption.Todo", "Aufgabe"),
        icon: { data: "task-checklist", type: "light" },
      },
      {
        value: "reminder",
        label: i18n.t("Resubmission.CategoryOption.Reminder", "Erinnerung"),
        icon: { data: "alarm-clock", type: "light" },
      },
    ];
  }

  getPriorityOptions() {
    return [
      {
        icon: PriorityIcons.Light.Low,
        color: PriorityColorCodes.Low,
        value: "low",
        label: i18n.t("Resubmission.PriorityOption.Low", "Niedrig"),
      },
      {
        icon: PriorityIcons.Light.Medium,
        color: PriorityColorCodes.Medium,
        value: "medium",
        label: i18n.t("Resubmission.PriorityOption.Medium", "Mittel"),
      },
      {
        icon: PriorityIcons.Light.High,
        color: PriorityColorCodes.High,
        value: "high",
        label: i18n.t("Resubmission.PriorityOption.High", "Hoch"),
      },
      {
        icon: PriorityIcons.Light.Critical,
        color: PriorityColorCodes.Critical,
        value: "critical",
        label: i18n.t("Resubmission.PriorityOption.Critical", "Kritisch"),
      },
    ];
  }

  getIntervalOptions() {
    return [
      {
        value: "daily",
        label: i18n.t("Resubmission.IntervalOption.Daily", "Täglich"),
      },
      {
        value: "weekly",
        label: i18n.t("Resubmission.IntervalOption.Weekly", "Wöchentlich"),
      },
      {
        value: "monthly",
        label: i18n.t("Resubmission.IntervalOption.Monthly", "Monatlich"),
      },
      {
        value: "yearly",
        label: i18n.t("Resubmission.IntervalOption.Yearly", "Jährlich"),
      },
    ];
  }

  getIntervalDayOptions() {
    return [
      {
        value: "monday",
        label: i18n.t("Resubmission.IntervalDayOption.Monday", "Montag"),
      },
      {
        value: "tuesday",
        label: i18n.t("Resubmission.IntervalDayOption.Tuesday", "Dienstag"),
      },
      {
        value: "wednesday",
        label: i18n.t("Resubmission.IntervalDayOption.Wednesday", "Mittwoch"),
      },
      {
        value: "thursday",
        label: i18n.t("Resubmission.IntervalDayOption.Thursday", "Donnerstag"),
      },
      {
        value: "friday",
        label: i18n.t("Resubmission.IntervalDayOption.Friday", "Freitag"),
      },
      {
        value: "saturday",
        label: i18n.t("Resubmission.IntervalDayOption.Saturday", "Samstag"),
      },
      {
        value: "sunday",
        label: i18n.t("Resubmission.IntervalDayOption.Sunday", "Sonntag"),
      },
    ];
  }

  getWeekdayLabel(day: string) {
    const options = this.getIntervalDayOptions();
    const option = options.find((option) => option.value === day);
    return option.label || "";
  }

  getIntervalDayDefaultValues() {
    return this.getIntervalDayOptions().map((option) => option.value);
  }

  getResubmissionStatusLabel(status: ResubmissionStatus): string {
    switch (status) {
      case "open":
        return i18n.t("Resubmission.Status.Open", "Offen");
      case "done":
        return i18n.t("Resubmission.Status.Done", "Erledigt");
      case "archived":
        return i18n.t("Resubmission.Status.Archived", "Archiviert");
      case "deleted":
        return i18n.t("Resubmission.Status.Deleted", "Gelöscht");
      case "rejected":
        return i18n.t("Resubmission.Status.Rejected", "Abgebrochen");
      default:
        return "";
    }
  }

  /**
   * Returns the due status of a task based on its due date.
   * @param {Date} dueDate - The due date of the task.
   * @returns {RSDateTagStatus} - The due status of the task ("planned", "due").
   */
  getDueStatus = (dueDate: Date): RSDateTagStatus => {
    // Set the hours, minutes, and seconds to 0 for both dates
    const today = moment().startOf("day");
    const compareDate = moment(dueDate).startOf("day");

    // Compare the dates using Moment.js
    if (today.isBefore(compareDate)) {
      return "planned";
    } else if (today.isAfter(compareDate)) {
      return "overdue";
    } else if (today.isSame(compareDate)) {
      return "due";
    } else {
      return "planned";
    }
  };

  formatDueDate = (dueDate: Date): string => {
    return this.formatDueDateMoment(moment(dueDate));
  };

  formatDueDateMoment = (dueDate: Moment): string => {
    const today = moment().startOf("day");
    if (today.isSame(dueDate.startOf("day"))) {
      return i18n.t("Resubmission.DueDate.Today", "Heute");
    }

    return dueDate.format("DD MMM");
  };
}
const RSService = new RSServiceClass();
export default RSService;
