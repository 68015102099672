import { nanoid } from "nanoid";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import CDNService from "../../../../../../services/CDNService";
import CacheService from "../../../../../../services/CacheService";
import ServiceUtils from "../../../../../../services/ServiceUtils";
import { when } from "../../../../../../utils/Helpers";
import { HTTP } from "../../../../../../utils/Http";
import { ActivityApplicationConstants } from "../../../ActivityHooks";
import {
  APContractActivity,
  APMaintenanceAppointment,
  APNoticePeriodLimited,
  APNoticePeriodRule,
  AddMaintenanceScheduleDto,
  FinishAppointmentDto,
} from "./APActivityMainteanceInterfaces";

class APActivityMaintenanceServiceClass {
  async submitSchedulingData(
    activityId: string,
    form: AddMaintenanceScheduleDto
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/maintenance/${activityId}/addMaintenanceSchedule`,
        target: "EMPTY",
        bodyParams: form,
      });
      CacheService.update(result);
      return result;
    });
  }
  async updateSchedulingData(
    activity: APContractActivity,
    appointment: APMaintenanceAppointment,
    form: AddMaintenanceScheduleDto
  ) {
    return await ServiceUtils.toastError(async () => {
      const { appointmentStartDate, ...formData } = form;
      const result = await HTTP.post({
        url: `/maintenance/${activity._id}/${appointment.id}/updateAppointment`,
        target: "EMPTY",
        bodyParams: {
          dueDate: appointmentStartDate,
          note: form.note || "",
          appointmentDate: appointment.appointmentDate || null,
          schedule: formData,
        },
      });
      CacheService.update(result);
      return result;
    });
  }
  async submitContractData(
    serviceUrl: string,
    activityId: string,
    form: Partial<
      APNoticePeriodRule & {
        contact?: string;
        contractStartDate: Date;
        allocatableCost: number;
        contractId: string;
        budgetLqCategories: string[];
        workPrice?: number;
        fixPrice?: number;
        priceNote?: string;
        consumption?: number;
        consumptionPeriodStart?: Date;
        consumptionPeriodEnd?: Date;
        insuranceValue?: number;
        insuranceValue14?: number;
        insuranceList?: { id: string; value: number }[];
      }
    >,
    constants: ActivityApplicationConstants
  ) {
    return await ServiceUtils.toastError(async () => {
      const result = await HTTP.post({
        url: `/${serviceUrl}/${activityId}/updateContractData`,
        target: "EMPTY",
        bodyParams: {
          type: form.type,
          contact: form.contact,
          contractId: form.contractId,
          contractStartDate: form.contractStartDate,
          budgetLqCategories: form.budgetLqCategories,
          allocatableCost: (form.allocatableCost || 0) / 100,
          ...((form as APNoticePeriodLimited).typeData || []),
          earliestCancelationDate: undefined,

          ...when(
            constants.fields?.supplyContractFeature,
            {
              priceNote: form.priceNote || "",
              workPrice: form.workPrice || 0,
              fixPrice: form.fixPrice || 0,
              consumption: form.consumption,
            },
            {}
          ),

          ...when(
            constants.fields?.insuranceFeature,
            {
              insuranceValue: form.insuranceValue || 0,
              insuranceValue14: form.insuranceValue14 || 0,
              insuranceList: form.insuranceList || [],
            },
            {}
          ),
        },
      });
      CacheService.update(result);

      return result;
    });
  }

  async setAppointmentDate(
    activity: APContractActivity,
    appointment: APMaintenanceAppointment,
    date: Date
  ) {
    return await ServiceUtils.toastError(async () => {
      const schedule = activity.data.schedulingRules.find(
        (e) => e.id === appointment.scheduleRuleId
      );
      const result = await HTTP.post({
        url: `/maintenance/${activity._id}/${appointment.id}/updateAppointment`,
        target: "EMPTY",
        bodyParams: {
          appointmentDate: date,
          note: appointment.note || "",
          dueDate: appointment.dueDate,
          schedule: {
            type: schedule.type,
            affectedTechnicalUnits: schedule.affectedTechnicalUnits,
            automaticAppointment: schedule.automaticAppointment || false,
            reminderBeforeInDays: schedule.reminderBeforeInDays,
            numberOfMonths:
              schedule.type === "regular"
                ? schedule.typeData.numberOfMonths
                : null,
            values: schedule.type === "fixed" ? schedule.typeData : [],
          },
        },
      });
      CacheService.update(result);
      return result;
    });
  }
  async cancelAppointment(
    activityId: string,
    appointmentId: string,
    values: Partial<FinishAppointmentDto>
  ) {
    return ServiceUtils.toastError(async () => {
      const { file, ...formData } = values;

      const result = await HTTP.post({
        url: `/maintenance/${activityId}/${appointmentId}/finishAppointment`,
        target: "EMPTY",
        bodyParams: {
          ...formData,
          cdnId: null,
        },
      });
      CacheService.update(result);
      return result;
    });
  }
  async finishAppointment(
    activityId: string,
    appointmentId: string,
    form: FinishAppointmentDto
  ) {
    const { file, ...formData } = form;
    return await ServiceUtils.toastError(async () => {
      const tempID = nanoid();
      const { cdnID } = await CDNService.uploadFile({
        file: file,
        assetField: "data.file",
        assetType: AssetTypes.ActivityRelated.MaintenanceProtocol,
        filename: file.name,
        tempID,
      });

      const result = await HTTP.post({
        url: `/maintenance/${activityId}/${appointmentId}/finishAppointment`,
        target: "EMPTY",
        bodyParams: {
          ...formData,
          cdnId: cdnID,
        },
      });
      CacheService.update(result);
      return result;
    });
  }
}
const APActivityMaintenanceService = new APActivityMaintenanceServiceClass();
export default APActivityMaintenanceService;
