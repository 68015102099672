import { SelectionAggregation } from "@/configurable/data/VirtualizedTable/VirtualizedTable";
import { Virtualizer } from "@tanstack/react-virtual";
import classNames from "classnames";
import { LexoRank } from "lexorank";
import _ from "lodash";
import { nanoid } from "nanoid";
import {
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Animation, Loader } from "rsuite";
import i18n from "../../../../i18n";
import { TableSort } from "../../../../model/common/CommonInterfaces";
import { useTypedSelector } from "../../../../redux/hooks";
import { TableColumnCustomization } from "../../../../redux/reducers/customization/CustomizationInterface";
import { AppState } from "../../../../redux/store";
import { isDefined } from "../../../../utils/Helpers";
import { ExportColumnConfig } from "../../../export/ExportDialog";
import BFVirtualized from "../virtualized/BFVirtualized";
import BFDataAggregationOverlay from "./BFDataAggregationOverlay";
import "./BFVirtualizedTable.scss";
import BFVirtualizedTableContent from "./BFVirtualizedTableContent";
import BFVirtualizedTableFooter from "./BFVirtualizedTableFooter";
import BFVirtualizedTableHeader from "./BFVirtualizedTableHeader";
import {
  CustomField,
  CustomFieldEditType,
} from "@/modules/customfields/CustomField.interface";

export type BFVirtualizedTablePosition = "center" | "left" | "right";
const SCROLL_BOTTOM_OFFSET = 100;
type RenderColumn = (
  node: any,
  index: number,
  params?: any,
  rowRef?: React.RefObject<HTMLDivElement>
) => React.ReactNode;
type RenderLabel = (params?: any) => React.ReactNode;

export type ColumnConfig = {
  resizable?: boolean;
  resizableOptions?: {
    min: number;
    max: number;
  };
  fieldType?: CustomFieldEditType | string;
  disableCustomize?: boolean;
  alignment?: "center" | "left" | "right";
  label: string | React.ReactNode | RenderLabel;
  fixedWidth?: number;
  flexWidth?: number;
  render: RenderColumn;
  renderFooter?: (params?: any) => React.ReactNode;
  ignoreRowClick?: boolean;
  className?: string;
  classNameFC?: (node: any, index: number, params?: any) => string;
  sortable?: boolean;
  noPadding?: boolean;
  columnStyles?: (
    node: any,
    index: number,
    params?: any
  ) => React.CSSProperties;
  fixed?: "left" | "right";
  disabledFixed?: boolean;
  hidden?: boolean;
  defaultOrderIndex?: number;
  export?: ExportColumnConfig | ((params: any) => ExportColumnConfig);
};
type EnrichedColumnConfig = ColumnConfig & {
  orderIndex?: string;
};

export type ColumnConfigWithIdentifier = EnrichedColumnConfig & {
  identifier: string;
};

const syncScrollTop = (source, targets: HTMLDivElement[]) => {
  targets.forEach((target) => {
    if (target) {
      // target.style.scrollBehavior = "smooth";
      target.scrollTo({
        top: source.scrollTop,
        // @ts-ignore
        behavior: "instant",
      });
      // target.style.scrollBehavior = null;
    }
  });
};
//   2,
//   {
//     trailing: true,
//   }
// );
export type BFVirtualizedTableRef = {
  mainRef: React.MutableRefObject<Virtualizer<HTMLDivElement, Element>>;
  leftRef: React.MutableRefObject<Virtualizer<HTMLDivElement, Element>>;
  rightRef: React.MutableRefObject<Virtualizer<HTMLDivElement, Element>>;
};
export type BFVirtualizedTableProps = {
  initScroll?: {
    x: number;
    y: number;
  };
  customizationOverwriteIdentifier?: string;
  tableRef?: React.MutableRefObject<BFVirtualizedTableRef>;
  className?: string;
  hideHeader?: boolean;
  selectedIds?: string[];
  emptyText?: string;
  identifier?: string;
  insetShadow?: boolean;
  ignoreStriped?: boolean;

  onRowDrop?: (
    ev: React.DragEvent<HTMLDivElement>,
    node: any,
    params: any
  ) => void;
  data: any[];
  selectionAggregations?: SelectionAggregation[];
  rowOverlay?: (
    position: BFVirtualizedTablePosition,
    node: any,
    index: number,
    params?: any
  ) => React.ReactNode;
  subRowRender?: (
    position: BFVirtualizedTablePosition,
    node: any,
    index: number,
    params?: any
  ) => React.ReactNode;
  columns: { [columnId: string]: ColumnConfig };
  loading?: "general" | "append";
  params?: any;
  paramsConverter?: (data: any, index: number, params: any) => any;
  overscan?: number;
  scrollingDelay?: number;
  hover?: boolean;
  estimatedSize?: number;
  calculateSize?: (
    data: any,
    index: number,
    params: any
  ) => number | [number, number];
  rowClass?: (
    node: any,
    index: number,
    params?: any,
    position?: BFVirtualizedTablePosition
  ) => string;

  identifierSelector?: string;
  // controlled sortstate
  sort?: TableSort;
  onSort?: (sort: TableSort) => void;
  selectCount: number;
  onBlur?: () => void;
  onRowDoubleClick?: (
    node: any,
    index: number,
    ev: MouseEvent<HTMLDivElement>,
    params: any
  ) => void;
  onRowClick?: (
    node: any,
    index: number,
    ev: MouseEvent<HTMLDivElement>
  ) => void;
  onScrollEnd?: () => void;
  onScroll?: (scrollX: number, scrollY: number) => void;

  ignoreRowBorder?: boolean;
  ignoreColumnBorder?: boolean;
  persistCustomizations?: boolean;
  onKeyArrowUp?: (shift?: boolean) => void;
  onKeyArrowDown?: (shift?: boolean) => void;
  onKeyEnter?: () => void;
};

const convertColumnsToArray = (
  columns: {
    [columnId: string]: ColumnConfig;
  },
  type: "left" | "right" | "main",
  customizations?: { [columnIdentifier: string]: TableColumnCustomization }
) => {
  let rank = LexoRank.middle();
  const columnsWithSortindex: { [key: string]: EnrichedColumnConfig } =
    Object.fromEntries(
      Object.entries(columns)
        .filter(([identifier, column]) => column)
        .map(
          ([identifier, column], index) =>
            [
              identifier,
              {
                ...column,
                defaultOrderIndex: isDefined(column.defaultOrderIndex)
                  ? column.defaultOrderIndex
                  : index,
              },
            ] as [string, ColumnConfig]
        )
        .sort((a, b) => a[1].defaultOrderIndex - b[1].defaultOrderIndex)
        .map(([identifier, column]) => {
          rank = rank.genNext();
          return [
            identifier,
            {
              ...column,
              orderIndex: rank.toString(),
            },
          ];
        })
    );

  let columnsToEvaluate = customizations
    ? _.merge({}, columnsWithSortindex, customizations)
    : columnsWithSortindex;
  columnsToEvaluate = Object.fromEntries(
    Object.entries(columnsToEvaluate).filter(([key, e]) => !!e.render)
  );
  // do merge stuff here
  return Object.entries(columnsToEvaluate)
    .filter(([_identifier, column]) =>
      type === "main" ? !column.fixed : column.fixed === type
    )
    .map(([identifier, column]) => ({
      ...column,
      identifier,
    }))
    .sort((a, b) => {
      return LexoRank.parse(a.orderIndex).compareTo(
        LexoRank.parse(b.orderIndex)
      );
    }) as ColumnConfigWithIdentifier[];
};

const TIMEOUT_SCROLL_SYNC = 0;
const BFVirtualizedTable = (props: BFVirtualizedTableProps) => {
  const scrolling = useRef<"left" | "main" | "right">(null);
  // generate a random identifier, if none is specified, so that the customize-store can be used properly
  const [tableIdentifier] = useState(props.identifier || nanoid());
  const customizations = useTypedSelector(
    (state: AppState) =>
      state.customizations.infiniteTable[
        props.customizationOverwriteIdentifier || props.identifier
      ]
  );

  const [focusedRow, setFocusedRow] = useState<number>(null);

  const [mainColumns, setMainColumns] = useState<ColumnConfigWithIdentifier[]>(
    convertColumnsToArray(props.columns, "main", customizations)
  );

  const [fixedLeftColumns, setFixedLeftColumns] = useState<
    ColumnConfigWithIdentifier[]
  >(convertColumnsToArray(props.columns, "left", customizations));

  const [fixedRightColumns, setFixedRightColumns] = useState<
    ColumnConfigWithIdentifier[]
  >(convertColumnsToArray(props.columns, "right", customizations));

  useEffect(() => {
    setMainColumns(
      convertColumnsToArray(props.columns, "main", customizations)
    );
    setFixedLeftColumns(
      convertColumnsToArray(props.columns, "left", customizations)
    );
    setFixedRightColumns(
      convertColumnsToArray(props.columns, "right", customizations)
    );
  }, [props.columns, customizations]);

  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const fixedLeftScrollRef = useRef<HTMLDivElement>(null);
  const mainScrollRef = useRef<HTMLDivElement>(null);
  const fixedRightScrollRef = useRef<HTMLDivElement>(null);
  const [hoverIndex, setHoverIndex] = useState<number>(null);

  const leftRef = useRef<Virtualizer<HTMLDivElement, Element>>(null);
  const mainRef = useRef<Virtualizer<HTMLDivElement, Element>>(null);
  const rightRef = useRef<Virtualizer<HTMLDivElement, Element>>(null);

  if (props.tableRef) {
    props.tableRef.current = {
      leftRef,
      mainRef,
      rightRef,
    };
  }
  const hasFooter = mainColumns.some((column) => column.renderFooter);

  const hiddenColumns = [
    ...mainColumns.filter((e) => e.hidden),
    ...fixedLeftColumns.filter((e) => e.hidden),
    ...fixedRightColumns.filter((e) => e.hidden),
  ];
  useEffect(() => {
    if (props.data.length > 0) {
      // checks on new data if there is room for more data to be load in the viewport
      checkInfiniteScroll(mainScrollRef.current);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.initScroll) {
      if (mainScrollRef.current) {
        mainScrollRef.current.scrollTop = props.initScroll.y;
        mainScrollRef.current.scrollLeft = props.initScroll.x;
      }
      if (fixedRightScrollRef.current) {
        fixedRightScrollRef.current.scrollTop = props.initScroll.y;
      }
      if (fixedLeftScrollRef.current) {
        fixedRightScrollRef.current.scrollTop = props.initScroll.y;
      }
    }
  }, [props.initScroll]);

  const calcSize = useCallback(
    (index: number) => {
      const data = props.calculateSize(props.data[index], index, props.params);
      if (Array.isArray(data)) {
        return data[0] + data[1];
      } else {
        return data;
      }
    },
    [props.calculateSize, props.data, props.params]
  );

  const hasLeftColumns =
    fixedLeftColumns?.filter((e) => !e.hidden).length !== 0;
  const hasRightolumns =
    fixedRightColumns?.filter((e) => !e.hidden).length !== 0;
  const checkInfiniteScroll = (target: HTMLDivElement) => {
    // Log.info(
    //   "#checkInfiniteScroll",
    //   target,
    //   target.scrollTop,
    //   target.clientHeight,
    //   target.scrollHeight,
    //   target.offsetHeight
    // );
    if (target && props.onScrollEnd) {
      if (
        target.scrollTop + target.clientHeight + SCROLL_BOTTOM_OFFSET >=
        target.scrollHeight
      ) {
        props.onScrollEnd();
      }
    }
  };

  return (
    <div
      className={classNames(`bf-virtualized-table`, props.className, {
        "ignore-column-border": props.ignoreColumnBorder,
        "ignore-row-border": props.ignoreRowBorder,
        "hide-header": props.hideHeader,
        "ignore-striped": props.ignoreStriped,
      })}
    >
      <div className="bf-virtualized-table-header">
        {fixedLeftColumns?.filter((e) => !e.hidden) && (
          <div
            className="left-table-header-container"
            style={{
              width: fixedLeftColumns
                ?.filter((e) => !e.hidden)
                .reduce((acc, column) => acc + (column.fixedWidth || 0), 0),
            }}
          >
            <div
              className="table-header"
              //   style={{ transform: `translateX(${-scrollX}px)` }}
            >
              {fixedLeftColumns
                .filter((e) => !e.hidden)
                .map((column, index) => (
                  <BFVirtualizedTableHeader
                    customizationOverwriteIdentifier={
                      props.customizationOverwriteIdentifier
                    }
                    key={column.identifier}
                    hiddenColumns={hiddenColumns}
                    column={column}
                    index={index}
                    sort={props.sort}
                    onSort={props.onSort}
                    params={props.params}
                    ignoreResizable
                    tableIdentifier={tableIdentifier}
                    persistCustomizations={props.persistCustomizations}
                    allDependingColumns={fixedLeftColumns}
                  />
                ))}
            </div>
          </div>
        )}

        <div className="main-table-header-container">
          <div
            ref={headerRef}
            className="table-header"
            //   style={{ transform: `translateX(${-scrollX}px)` }}
          >
            {mainColumns
              .filter((e) => !e.hidden)
              .map((column, index) => (
                <BFVirtualizedTableHeader
                  customizationOverwriteIdentifier={
                    props.customizationOverwriteIdentifier
                  }
                  key={column.identifier}
                  hiddenColumns={hiddenColumns}
                  column={column}
                  index={index}
                  sort={props.sort}
                  onSort={props.onSort}
                  params={props.params}
                  tableIdentifier={tableIdentifier}
                  persistCustomizations={props.persistCustomizations}
                  allDependingColumns={mainColumns}
                />
              ))}
          </div>
        </div>

        {fixedRightColumns?.filter((e) => !e.hidden) && (
          <div
            className="right-table-header-container"
            style={{
              width: fixedRightColumns
                ?.filter((e) => !e.hidden)
                .reduce((acc, column) => acc + (column.fixedWidth || 0), 0),
            }}
          >
            <div
              className="table-header"
              //   style={{ transform: `translateX(${-scrollX}px)` }}
            >
              {fixedRightColumns
                .filter((e) => !e.hidden)
                .map((column, index) => (
                  <BFVirtualizedTableHeader
                    customizationOverwriteIdentifier={
                      props.customizationOverwriteIdentifier
                    }
                    key={column.identifier}
                    hiddenColumns={hiddenColumns}
                    column={column}
                    index={index}
                    ignoreResizable
                    sort={props.sort}
                    onSort={props.onSort}
                    params={props.params}
                    tableIdentifier={tableIdentifier}
                    persistCustomizations={props.persistCustomizations}
                    allDependingColumns={fixedRightColumns}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
      <div
        className="table-content"
        tabIndex={0} // Make the div focusable
        onKeyDown={(ev) => {
          if (ev.key === "ArrowUp") {
            props.onKeyArrowUp?.(ev.shiftKey);
          } else if (ev.key === "ArrowDown") {
            props.onKeyArrowDown?.(ev.shiftKey);
          } else if (ev.key === "Enter") {
            props.onKeyEnter?.();
          }
        }}
        onBlur={props.onBlur}
      >
        {fixedLeftColumns?.filter((e) => !e.hidden) && (
          <BFVirtualized
            emptyText=" "
            virtualizedRef={leftRef}
            getStyles={(_data, index) => ({
              zIndex: index === focusedRow ? 1 : 0,
            })}
            calculateSize={props.calculateSize ? calcSize : undefined}
            estimatedSize={props.estimatedSize}
            scrollRef={fixedLeftScrollRef}
            className="left"
            paramsConverter={(data, index, params) => ({
              hover: params.hover,
              hovered: params.hoverIndex === index,
              setHoverIndex: params.setHoverIndex,
              ...(props.paramsConverter
                ? props.paramsConverter(data, index, params)
                : params),
            })}
            overscan={props.overscan}
            scrollingDelay={props.scrollingDelay}
            onScroll={(ev) => {
              if (scrolling.current && scrolling.current !== "left") {
                return;
              }
              scrolling.current = "left";
              props.onScroll?.(
                (ev.target as HTMLDivElement).scrollLeft,
                (ev.target as HTMLDivElement).scrollTop
              );

              syncScrollTop(ev.target, [
                mainScrollRef.current,
                fixedRightScrollRef.current,
              ]);
              // if (!scrolling.current) {
              //   if (syncTimeout.current) {
              //     clearTimeout(syncTimeout.current);
              //   }
              //   syncTimeout.current = setTimeout(() => {
              //     scrolling.current = true;
              //     if (mainScrollRef.current) {
              //       mainScrollRef.current.scrollTop = (
              //         ev.target as HTMLDivElement
              //       ).scrollTop;
              //     }
              //     if (fixedRightScrollRef.current) {
              //       fixedRightScrollRef.current.scrollTop = (
              //         ev.target as HTMLDivElement
              //       ).scrollTop;
              //     }
              //     scrolling.current = false;
              //     syncTimeout.current = null;
              //   }, TIMEOUT_SCROLL_SYNC);
              // }

              checkInfiniteScroll(ev.target as HTMLDivElement);
              setTimeout(() => {
                scrolling.current = null;
              });
            }}
            data={props.data}
            params={{
              hover: props.hover,
              hoverIndex,
              setHoverIndex,
              ...(props.params || {}),
            }}
            width={fixedLeftColumns
              ?.filter((e) => !e.hidden)
              .reduce((acc, column) => acc + (column.fixedWidth || 0), 0)}
            renderElement={(data, rowIndex, allData, params) => (
              <BFVirtualizedTableRow
                size={
                  props.calculateSize
                    ? props.calculateSize(data, rowIndex, props.params)
                    : undefined
                }
                subRowRender={props.subRowRender}
                rowClass={props.rowClass}
                selected={(props.selectedIds || []).includes(
                  data[props.identifierSelector || "_id"]
                )}
                rowOverlay={props.rowOverlay}
                position="left"
                identifierSelector={props.identifierSelector}
                focusedRow={rowIndex === focusedRow}
                setFocusedRow={setFocusedRow}
                data={data}
                rowIndex={rowIndex}
                params={params}
                hover={params.hover}
                onRowClick={props.onRowClick}
                onRowDoubleClick={props.onRowDoubleClick}
                render={(ref) => (
                  <>
                    {fixedLeftColumns
                      ?.filter((e) => !e.hidden)
                      .map((column, columnIndex) => {
                        return (
                          <BFVirtualizedTableContent
                            key={column.identifier}
                            rowRef={ref}
                            data={data}
                            rowIndex={rowIndex}
                            allData={allData}
                            params={params}
                            column={column}
                            columnIndex={columnIndex}
                          />
                        );
                      })}
                  </>
                )}
              />
            )}
          />
        )}

        <BFVirtualized
          virtualizedRef={mainRef}
          emptyText={
            props.emptyText ||
            i18n.t("BFList.emptyText", "Keine Daten vorhanden")
          }
          getStyles={(_data, index) => ({
            zIndex: index === focusedRow ? 1 : 0,
          })}
          scrollRef={mainScrollRef}
          className="main"
          paramsConverter={(data, index, params) => ({
            hover: params.hover,
            hovered: params.hoverIndex === index,
            setHoverIndex: params.setHoverIndex,
            ...(props.paramsConverter
              ? props.paramsConverter(data, index, params)
              : params),
          })}
          calculateSize={
            props.calculateSize
              ? (index: number) => {
                  const data = props.calculateSize(
                    props.data[index],
                    index,
                    props.params
                  );
                  if (Array.isArray(data)) {
                    return data[0] + data[1];
                  } else {
                    return data;
                  }
                }
              : undefined
          }
          estimatedSize={props.estimatedSize}
          overscan={props.overscan}
          scrollingDelay={props.scrollingDelay}
          onScroll={(ev) => {
            if (scrolling.current && scrolling.current !== "main") {
              return;
            }
            scrolling.current = "main";
            props.onScroll?.(
              (ev.target as HTMLDivElement).scrollLeft,
              (ev.target as HTMLDivElement).scrollTop
            );

            if (hasLeftColumns || hasRightolumns) {
              syncScrollTop(ev.target, [
                fixedRightScrollRef.current,
                fixedLeftScrollRef.current,
              ]);

              // // if (!scrolling.current) {
              // if (syncTimeout.current) {
              //   clearTimeout(syncTimeout.current);
              // }
              // // syncTimeout.current = setTimeout(() => {
              // scrolling.current = true;
              // if (fixedRightScrollRef.current) {
              //   fixedRightScrollRef.current.scrollTop = (
              //     ev.target as HTMLDivElement
              //   ).scrollTop;
              // }
              // if (fixedLeftScrollRef.current) {
              //   fixedLeftScrollRef.current.scrollTop = (
              //     ev.target as HTMLDivElement
              //   ).scrollTop;
              // }
              // // scrolling.current = false;
              // syncTimeout.current = null;
              // // }, TIMEOUT_SCROLL_SYNC);
            }
            // }

            if (headerRef.current) {
              headerRef.current.style.transform = `translateX(${-(
                ev.target as HTMLDivElement
              ).scrollLeft}px)`;
            }
            if (footerRef.current) {
              footerRef.current.style.transform = `translateX(${-(
                ev.target as HTMLDivElement
              ).scrollLeft}px)`;
            }
            checkInfiniteScroll(ev.target as HTMLDivElement);
            setTimeout(() => {
              scrolling.current = null;
            });
          }}
          data={props.data}
          params={{
            hover: props.hover,
            hoverIndex,
            setHoverIndex,
            ...(props.params || {}),
          }}
          renderElement={(data, rowIndex, allData, params) => (
            <BFVirtualizedTableRow
              size={
                props.calculateSize
                  ? props.calculateSize(data, rowIndex, props.params)
                  : undefined
              }
              subRowRender={props.subRowRender}
              rowClass={props.rowClass}
              selected={(props.selectedIds || []).includes(
                data[props.identifierSelector || "_id"]
              )}
              onRowDrop={props.onRowDrop}
              rowOverlay={props.rowOverlay}
              position="center"
              identifierSelector={props.identifierSelector}
              focusedRow={rowIndex === focusedRow}
              setFocusedRow={setFocusedRow}
              data={data}
              rowIndex={rowIndex}
              params={params}
              hover={params.hover}
              onRowClick={props.onRowClick}
              onRowDoubleClick={props.onRowDoubleClick}
              render={(rowRef) => (
                <>
                  {mainColumns
                    ?.filter((e) => !e.hidden)
                    .map((column, columnIndex) => {
                      return (
                        <BFVirtualizedTableContent
                          key={column.identifier}
                          rowRef={rowRef}
                          data={data}
                          rowIndex={rowIndex}
                          allData={allData}
                          params={params}
                          column={column}
                          columnIndex={columnIndex}
                        />
                      );
                    })}
                </>
              )}
            />
          )}
        />

        {fixedRightColumns?.filter((e) => !e.hidden) && (
          <BFVirtualized
            getStyles={(_data, index) => ({
              zIndex: index === focusedRow ? 1 : 0,
            })}
            emptyText=" "
            virtualizedRef={rightRef}
            calculateSize={
              props.calculateSize
                ? (index: number) => {
                    const data = props.calculateSize(
                      props.data[index],
                      index,
                      props.params
                    );
                    if (Array.isArray(data)) {
                      return data[0] + data[1];
                    } else {
                      return data;
                    }
                  }
                : undefined
            }
            estimatedSize={props.estimatedSize}
            scrollRef={fixedRightScrollRef}
            className="right"
            paramsConverter={(data, index, params) => ({
              hover: params.hover,
              hovered: params.hoverIndex === index,
              setHoverIndex: params.setHoverIndex,
              ...(props.paramsConverter
                ? props.paramsConverter(data, index, params)
                : {}),
            })}
            overscan={props.overscan}
            scrollingDelay={props.scrollingDelay}
            onScroll={(ev) => {
              if (scrolling.current && scrolling.current !== "right") {
                return;
              }
              scrolling.current = "right";
              props.onScroll?.(
                (ev.target as HTMLDivElement).scrollLeft,
                (ev.target as HTMLDivElement).scrollTop
              );

              syncScrollTop(ev.target, [
                mainScrollRef.current,
                fixedLeftScrollRef.current,
              ]);

              // if (!scrolling.current) {
              //   if (syncTimeout.current) {
              //     clearTimeout(syncTimeout.current);
              //   }
              //   syncTimeout.current = setTimeout(() => {
              //     scrolling.current = true;
              //     if (mainScrollRef.current) {
              //       mainScrollRef.current.scrollTop = (
              //         ev.target as HTMLDivElement
              //       ).scrollTop;

              //       // .scrollTop = (
              //       //   ev.target as HTMLDivElement
              //       // ).scrollTop;
              //     }
              //     if (fixedLeftScrollRef.current) {
              //       fixedLeftScrollRef.current.scrollTop = (
              //         ev.target as HTMLDivElement
              //       ).scrollTop;
              //       // fixedLeftScrollRef.current.scrollTop = (
              //       //   ev.target as HTMLDivElement
              //       // ).scrollTop;
              //     }
              //     scrolling.current = false;
              //     syncTimeout.current = null;
              //   }, TIMEOUT_SCROLL_SYNC);
              // }

              checkInfiniteScroll(ev.target as HTMLDivElement);
              setTimeout(() => {
                scrolling.current = null;
              });
            }}
            data={props.data}
            params={{
              hover: props.hover,
              hoverIndex,
              setHoverIndex,
              ...(props.params || {}),
            }}
            width={fixedRightColumns
              ?.filter((e) => !e.hidden)
              .reduce((acc, column) => acc + (column.fixedWidth || 0), 0)}
            renderElement={(data, rowIndex, allData, params) => (
              <BFVirtualizedTableRow
                size={
                  props.calculateSize
                    ? props.calculateSize(data, rowIndex, props.params)
                    : undefined
                }
                subRowRender={props.subRowRender}
                rowClass={props.rowClass}
                selected={(props.selectedIds || []).includes(
                  data[props.identifierSelector || "_id"]
                )}
                rowOverlay={props.rowOverlay}
                position="right"
                identifierSelector={props.identifierSelector}
                focusedRow={rowIndex === focusedRow}
                setFocusedRow={setFocusedRow}
                data={data}
                rowIndex={rowIndex}
                params={params}
                hover={params.hover}
                onRowClick={props.onRowClick}
                onRowDoubleClick={props.onRowDoubleClick}
                render={(rowRef) => (
                  <>
                    {fixedRightColumns
                      ?.filter((e) => !e.hidden)
                      .map((column, columnIndex) => {
                        return (
                          <BFVirtualizedTableContent
                            key={column.identifier}
                            rowRef={rowRef}
                            data={data}
                            rowIndex={rowIndex}
                            allData={allData}
                            params={params}
                            column={column}
                            columnIndex={columnIndex}
                          />
                        );
                      })}
                  </>
                )}
              />
            )}
          />
        )}

        <Animation.Fade in={props.loading === "general"} unmountOnExit>
          <div className={`loading-overlay`}>
            <Loader size="lg" />
          </div>
        </Animation.Fade>

        <Animation.Bounce in={props.loading === "append"} unmountOnExit>
          <div className={`loading-bottom-container`}>
            <div className={`loading-bottom`}>
              <Loader size="sm" />
            </div>
          </div>
        </Animation.Bounce>

        <Animation.Bounce
          in={isDefined(props.selectionAggregations) && props.selectCount > 1}
          unmountOnExit
        >
          <BFDataAggregationOverlay
            data={props.data.filter((e) =>
              (props.selectedIds || []).includes(e._id)
            )}
            selectionAggregations={props.selectionAggregations}
          />
        </Animation.Bounce>

        {props.insetShadow && <div className="inset-shadow" />}
      </div>
      {hasFooter && (
        <div className={`bf-virtualized-table-footer`}>
          {fixedLeftColumns?.filter((e) => !e.hidden) && (
            <div
              className="left-table-footer-container"
              style={{
                width: fixedLeftColumns
                  ?.filter((e) => !e.hidden)
                  .reduce((acc, column) => acc + (column.fixedWidth || 0), 0),
              }}
            >
              <div
                className="table-footer"
                //   style={{ transform: `translateX(${-scrollX}px)` }}
              >
                {fixedLeftColumns
                  ?.filter((e) => !e.hidden)
                  .map((column, index) => (
                    <BFVirtualizedTableFooter
                      key={column.identifier}
                      column={column}
                      index={index}
                      sort={props.sort}
                      onSort={props.onSort}
                      params={props.params}
                      tableIdentifier={tableIdentifier}
                      persistCustomizations={props.persistCustomizations}
                    />
                  ))}
              </div>
            </div>
          )}

          <div className="main-table-footer-container">
            <div
              ref={footerRef}
              className="table-footer"
              //   style={{ transform: `translateX(${-scrollX}px)` }}
            >
              {mainColumns
                ?.filter((e) => !e.hidden)
                .map((column, index) => (
                  <BFVirtualizedTableFooter
                    key={column.identifier}
                    column={column}
                    index={index}
                    sort={props.sort}
                    onSort={props.onSort}
                    params={props.params}
                    tableIdentifier={tableIdentifier}
                    persistCustomizations={props.persistCustomizations}
                  />
                ))}
            </div>
          </div>

          {fixedRightColumns?.filter((e) => !e.hidden) && (
            <div
              className="right-table-footer-container"
              style={{
                width: fixedRightColumns
                  ?.filter((e) => !e.hidden)
                  .reduce((acc, column) => acc + (column.fixedWidth || 0), 0),
              }}
            >
              <div
                className="table-footer"
                //   style={{ transform: `translateX(${-scrollX}px)` }}
              >
                {fixedRightColumns
                  ?.filter((e) => !e.hidden)
                  .map((column, index) => (
                    <BFVirtualizedTableFooter
                      key={column.identifier}
                      column={column}
                      index={index}
                      sort={props.sort}
                      onSort={props.onSort}
                      params={props.params}
                      tableIdentifier={tableIdentifier}
                      persistCustomizations={props.persistCustomizations}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

BFVirtualizedTable.defaultProps = {
  persistCustomizations: true,
} as Partial<BFVirtualizedTableProps>;
export default BFVirtualizedTable;

interface RowProps {
  rowOverlay?: (
    position: BFVirtualizedTablePosition,
    node: any,
    index: number,
    params?: any
  ) => React.ReactNode;
  onRowClick?: (
    node: any,
    index: number,
    ev: MouseEvent<HTMLDivElement>
  ) => void;
  onRowDoubleClick?: (
    node: any,
    index: number,
    ev: MouseEvent<HTMLDivElement>,
    params: any
  ) => void;
  rowClass?: (
    node: any,
    index: number,
    params?: any,
    position?: BFVirtualizedTablePosition
  ) => string;
  subRowRender?: (
    position: BFVirtualizedTablePosition,
    node: any,
    index: number,
    params?: any
  ) => React.ReactNode;
  size: number | [number, number];
  params: any;
  hover: boolean;
  rowIndex: number;
  render: (ref: RefObject<HTMLDivElement>) => React.ReactNode;
  data: any;
  setFocusedRow: (index: number) => void;
  focusedRow: boolean;
  identifierSelector?: string;
  position: BFVirtualizedTablePosition;
  selected: boolean;

  onRowDrop?: (
    ev: React.DragEvent<HTMLDivElement>,
    node: any,
    params: any
  ) => void;
}
const BFVirtualizedTableRow = (props: RowProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [dragged, setDragged] = useState(false);

  // props.rootRef?.current.contains(document.activeElement)
  const overlay = props.rowOverlay?.(
    props.position,
    props.data,
    props.rowIndex,
    props.params
  );

  const clickable = props.onRowClick || props.onRowDoubleClick;

  const subrow = props.subRowRender?.(
    props.position,
    props.data,
    props.rowIndex,
    props.params
  );
  return (
    <div
      key={props.data[props.identifierSelector || "_id"]}
      ref={ref}
      onFocus={() => {
        if (!props.focusedRow) {
          props.setFocusedRow(props.rowIndex);
        }
      }}
      onDragOver={(ev) => {
        if (props.onRowDrop) {
          ev.preventDefault();
          setDragged(true);
        }
      }}
      onDragLeave={() => {
        if (dragged) {
          setDragged(false);
        }
      }}
      onDragExit={() => {
        if (dragged) {
          setDragged(false);
        }
      }}
      onDragEnd={(ev) => {
        if (dragged) {
          setDragged(false);
        }
      }}
      // onDragExit={}
      onDrop={(ev) => {
        if (props.onRowDrop) {
          ev.preventDefault();
          props.onRowDrop(ev, props.data, props.params);
          setDragged(false);
        }
      }}
      className={classNames(
        {
          "table-row": true,
          clickable,
          hovered: props.params.hovered,
          selected: props.selected,
          dragged: dragged,
        },
        props.rowClass?.(
          props.data,
          props.rowIndex,
          props.params,
          props.position
        )
      )}
      onMouseEnter={() => {
        if (props.hover) {
          props.params.setHoverIndex(props.rowIndex);
        }
      }}
      onMouseLeave={() => {
        if (props.hover) {
          props.params.setHoverIndex(null);
        }
      }}
      onDoubleClick={(ev: MouseEvent<HTMLDivElement>) => {
        if (props.onRowDoubleClick) {
          const ignoreRowDoubleClickParent = (ev.target as HTMLElement).closest(
            ".ignore-row-doubleclick"
          );
          if (!ignoreRowDoubleClickParent) {
            props.onRowDoubleClick(
              props.data,
              props.rowIndex,
              ev,
              props.params
            );
          }
        }
      }}
      onClick={
        props.onRowClick
          ? (ev: MouseEvent<HTMLDivElement>) => {
              const ignoreRowClickParent = (ev.target as HTMLElement).closest(
                ".ignore-row-click"
              );
              if (!ignoreRowClickParent) {
                props.onRowClick(props.data, props.rowIndex, ev);
              }
            }
          : undefined
      }
    >
      {overlay && <div className={`row-overlay`}>{overlay}</div>}
      <div
        className={`row-columns`}
        style={{
          height: Array.isArray(props.size) ? props.size[0] : props.size,
        }}
      >
        {props.render(ref)}
      </div>
      {subrow && (
        <div
          className={`sub-row`}
          style={{
            height: Array.isArray(props.size) ? props.size[1] : props.size,
          }}
        >
          {subrow}
        </div>
      )}
      {dragged && <div className={`drag-overlay`} />}
    </div>
  );
};
