import AssetService from "@/services/AssetService";
import ServiceUtils from "@/services/ServiceUtils";
import _ from "lodash";
import i18n from "../../../../../../i18n";
import { AssetTypes } from "../../../../../../model/AssetTypes";
import DSService from "../../../../../../modules/document-store/DSService";
import { store } from "../../../../../../redux/store";
import CDNService from "../../../../../../services/CDNService";
import SubmitService from "../../../../../../services/SubmitService";
import { isDefined } from "../../../../../../utils/Helpers";
import ObjectIdService from "../../../../../../utils/ObjectIdUtils";
import { ProjectScheduleGroup } from "../project-budget/APProjectBudgetInterfaces";
import {
  APConstructionDiaryDocumentationEntry,
  APConstructionDiaryForm,
  APProjectConstructionDiary,
  APProjectConstructionDiaryDocumentation,
  APProjectConstructionDiaryStatus,
  APProjectConstructionDiaryTemperatureUnit,
  APProjectConstructionDiaryType,
  ConstructionDiaryDocumentationType,
} from "./APConstructionDiariesInterfaces";

export const getConstructionDiaryTemperatureUnit = (
  unit: APProjectConstructionDiaryTemperatureUnit
) => {
  switch (unit) {
    case "celsius":
      return i18n.t("apTemplate:ConstructionDiary.Celsius", "°C");
    case "fahrenheit":
      return i18n.t("apTemplate:ConstructionDiary.Fahrenheit", "°F");
    case "kelvin":
      return i18n.t("apTemplate:ConstructionDiary.Kelvin", "F");
    default:
      return unit;
  }
};
export const getConstructionDiaryTypeLabel = (
  type: APProjectConstructionDiaryType
) => {
  switch (type) {
    case "daily":
      return i18n.t("apTemplate:ConstructionDiary.Daily", "Täglicher Bericht");
    case "weekly":
      return i18n.t("apTemplate:ConstructionDiary.Weekly", "Wochenbericht");
    default:
      return type;
  }
};
export const getConstructionDiaryStatusLabel = (
  status: APProjectConstructionDiaryStatus
) => {
  switch (status) {
    case "draft":
      return i18n.t("apTemplate:ConstructionDiary.Draft", "Entwurf");
    case "complete":
      return i18n.t("apTemplate:ConstructionDiary.Complete", "Geschlossen");
    default:
      return status;
  }
};
export const getConstructionDiaryDocumentationTypeLabel = (
  type: ConstructionDiaryDocumentationType
) => {
  switch (type) {
    case "observations":
      return i18n.t(
        "apTemplate:ConstructionDiary.Observations",
        "Beobachtungen"
      );
    case "defects":
      return i18n.t("apTemplate:ConstructionDiary.Defects", "Mangel");
    case "workProgress":
      return i18n.t(
        "apTemplate:ConstructionDiary.WorkProgress",
        "Arbeitsfortschritt"
      );
    default:
      return type;
  }
};

export const getGroupParentString = (
  parentId: string,
  groups: ProjectScheduleGroup[]
) => {
  let labels = [];
  let pId = parentId;
  while (pId) {
    const parent = groups.find((g) => g.id === pId);
    labels.push(parent.name);
    pId = parent.parentId;
  }
  return labels.reverse().join(" > ");
};
export const getSelectOptionsForBudgetProjectScheduleGroups = (
  groups: ProjectScheduleGroup[]
) => {
  const allParents = _.uniq(
    groups
      .filter((group) => isDefined(group.parentId))
      .map((group) => group.parentId)
  );
  return groups
    .filter((group) => !allParents.includes(group.id))
    .map((group) => {
      const parentString = getGroupParentString(group.parentId, groups);

      const displayValue = parentString
        ? parentString + " > " + group.name
        : group.name;
      return {
        label: group.name,
        value: group.id,
        displayValue: displayValue,
        group:
          parentString ||
          i18n.t(
            "apTemplate.ConstructionDiary.BudgetGroupNotSorted",
            "Ohne Kategorie"
          ),
      };
    });
};

export const submitConstructionDiary = async (
  form: APConstructionDiaryForm,
  projectId: string,
  constructionDiary?: APProjectConstructionDiary
) => {
  let createdId = null;
  return ServiceUtils.toastError(async () => {
    try {
      const state = store.getState();
      const userId = state.global.user._id;

      let updateData: Partial<APProjectConstructionDiary> = {
        _id: constructionDiary?._id,
        data: {
          author: constructionDiary?.data.author || userId,
          diaryType: form.diaryType,
          entryDate: form.entryDate,
          status: form.status,
          projectId: constructionDiary?.data.projectId || projectId,
          weather: form.weather,
          entries:
            constructionDiary?.data.entries.map((entry) => ({
              ...entry,
              status:
                [...form.defects, ...form.observations, ...form.workProgress]
                  .filter((e) => e._id)
                  .find((e) => e._id === entry._id)?.status || "archived",
            })) || [],
        },
      };

      if (!updateData._id) {
        // update empty object so that attachement upload is possible
        const createEmptyObj = (await SubmitService.submitDataAsync({
          type: "asset",
          assetType: AssetTypes.ActivityRelated.ConstructionDiary,
          data: updateData,
          ignorePropChecks: true,
          ignoreSubmitValidation: true,
        })) as APProjectConstructionDiary;
        createdId = createEmptyObj._id;
        updateData = {
          _id: createEmptyObj._id,
          data: createEmptyObj.data,
        };
      }

      const types: {
        type: "defects" | "observations" | "workProgress";
        data: APConstructionDiaryDocumentationEntry[];
      }[] = [
        {
          type: "defects",
          data: form.defects,
        },
        {
          type: "observations",
          data: form.observations,
        },
        {
          type: "workProgress",
          data: form.workProgress,
        },
      ];

      for (const { type, data } of types) {
        const dataToUse = data.filter((e) => e.status === "active");
        for (const entry of dataToUse) {
          let dataToUpdate: APProjectConstructionDiaryDocumentation =
            updateData.data.entries.find((t) => t._id === entry._id);

          if (!dataToUpdate) {
            dataToUpdate = {
              _id: ObjectIdService.new(),
              documentationType: type,
              note: entry.note,
              status: "active",
              group: entry.group,
              imageCdnLinks: [],
              progress:
                type === "workProgress" ? (entry as any).progress : undefined,
            };

            updateData.data.entries.push(dataToUpdate);
          }
          dataToUpdate.note = entry.note;

          dataToUpdate.imageCdnLinks = dataToUpdate.imageCdnLinks.filter(
            (cdnId) =>
              entry.images.find((e) => e.type === "cdn" && e.cdnLink === cdnId)
          );

          if (type === "workProgress") {
            (dataToUpdate as any).progress = (entry as any).progress;
          }

          for (const image of entry.images) {
            if (image.type === "file") {
              const imgUpload = await CDNService.uploadAttachment(
                updateData._id,
                {
                  file: image.file,
                  name: image.file.name,
                  note: image.note,
                },
                AssetTypes.ActivityRelated.ConstructionDiary,
                "data.images",
                undefined,
                undefined,
                true
              );

              dataToUpdate.imageCdnLinks.push((imgUpload as any).entryId);
            } else {
              const cdnData = constructionDiary?.data.images.find(
                (e) => e.linkToCdn === image.cdnLink
              );
              if (cdnData && cdnData.note !== image.note) {
                await DSService.updateDocument(
                  {
                    asset: constructionDiary,
                    assetType: AssetTypes.ActivityRelated.ConstructionDiary,
                    documentsFieldPath: "data.images",
                    type: "",
                  },
                  cdnData as any,
                  {
                    ...cdnData,
                    status: "active",
                    note: image.note,
                  }
                );
              }
            }
          }
        }
      }

      const result = await SubmitService.submitDataAsync({
        type: "asset",
        assetType: AssetTypes.ActivityRelated.ConstructionDiary,
        data: updateData,
        ignorePropChecks: true,
        ignoreSubmitValidation: true,
      });

      return result as APProjectConstructionDiary;
    } catch (err) {
      // try to delete the created asset, if it was a creation process
      if (!constructionDiary && createdId) {
        await AssetService.deleteAsset({
          assetId: createdId,
          assetType: AssetTypes.ActivityRelated.ConstructionDiary,
          preventToast: true,
        });
      }
      throw err;
    }
  });
};

export const getInitialValuesForConstructionDiary = (
  constructionDiary?: APProjectConstructionDiary
) => {
  const output: APConstructionDiaryForm = {
    defects: [],
    diaryType: constructionDiary?.data?.diaryType || "weekly",
    entryDate: constructionDiary?.data?.entryDate || new Date(),
    observations: [],
    status: constructionDiary?.data?.status || "draft",
    workProgress: [],
    notes: constructionDiary?.data?.notes || "",
    weather: constructionDiary?.data?.weather || {
      temperature: {
        unit: "celsius",
        value: null,
      },
      rain: null,
      wind: null,
    },
  };

  if (constructionDiary?.data?.entries) {
    constructionDiary.data.entries.forEach((entry) => {
      if (entry.documentationType === "observations") {
        output.observations.push({
          _id: entry._id,
          group: entry.group,
          note: entry.note,
          status: entry.status,
          images: entry.imageCdnLinks.map((link) => ({
            type: "cdn",
            cdnLink: link,
            note: constructionDiary.data.images?.find(
              (e) => e.linkToCdn === link
            )?.note,
          })),
        });
      } else if (entry.documentationType === "defects") {
        output.defects.push({
          _id: entry._id,
          group: entry.group,
          note: entry.note,
          status: entry.status,
          images: entry.imageCdnLinks.map((link) => ({
            type: "cdn",
            cdnLink: link,
            note: constructionDiary.data.images?.find(
              (e) => e.linkToCdn === link
            )?.note,
          })),
        });
      } else if (entry.documentationType === "workProgress") {
        output.workProgress.push({
          _id: entry._id,
          group: entry.group,
          note: entry.note,
          status: entry.status,
          progress: entry.progress,
          images: entry.imageCdnLinks.map((link) => ({
            type: "cdn",
            cdnLink: link,
            note: constructionDiary.data.images?.find(
              (e) => e.linkToCdn === link
            )?.note,
          })),
        });
      }
    });
  }

  return output;
};
