import classNames from "classnames";
import moment from "moment";
import { useEffect } from "react";
import ChartComponent from "../../../../../../../../configurable/components/ChartComponent/ChartComponent";
import i18n from "../../../../../../../../i18n";
import BFPlaceholder from "../../../../../../../../modules/abstract-ui/general/Placeholder/BFPlaceholder";
import { useStatisticQuery } from "../../../../../../../../redux/hooks";
import DataBus from "../../../../../../../../services/DataBus";
import NumberUtils from "../../../../../../../../utils/ NumberUtils";
import { DataBusSubKeys } from "../../../../../../../../utils/Constants";
import StringUtils from "../../../../../../../../utils/StringUtils";
import { CBStatisticImmoVacancyRate } from "../../../interfaces/CBStatisticQueries";
import "./CBRentalStatusChart.scss";
import { getCssVariable } from "@/apps/utils/getCssVariable";

interface CBRentalStatusChartProps {
  objectIds: string[];
  month: moment.Moment;
  title?: string;
  referenceId?: string;
  className?: string;
  showPlannedRent?: boolean;
  yearly: boolean;
}

export const cbRentalStatusChartOption = (
  data: {
    rentPlanned: number;
    rented: number;
    rentedDiff: number;
    vacant: number;
  },
  forPdf?: boolean
) => ({
  tooltip: {
    trigger: "axis",
    valueFormatter: (value) => StringUtils.formatCurrency(value),
    axisPointer: {
      // Use axis to trigger tooltip
      type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
    },
  },
  animation: forPdf ? false : true,
  legend: { show: false },
  grid: {
    top: "0%",
    left: "3%",
    right: "3%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "value",
    axisLabel: {
      formatter: (value: number) => StringUtils.formatCurrency(value),
      textStyle: {
        color: getCssVariable("--chart-axis-label-primary"),
      },
    },
  },
  yAxis: {
    show: false,
    type: "category",
    axisLabel: {
      textStyle: {
        color: getCssVariable("--chart-axis-label-primary"),
      },
    },
  },

  label: {
    formatter: i18n.t("cb:RentalStatusChart.label", "Mieteinnahmen SOLL/IST"),
  },
  series: [
    {
      name: i18n.t(
        "cb:RentalStatusChart.rentalIncomePlan",
        "Mieteinnahmen SOLL"
      ),
      type: "bar",
      barCategoryGap: 0,
      barGap: 0,

      stack: "plan",
      barWidth: "25%",
      emphasis: {
        focus: "series",
      },
      label: {
        show: true,
        formatter: (data) => {
          return `{title|${
            data.seriesName
          }}\n{value|${StringUtils.formatCurrency(data.value)}}`;
        },
        rich: {
          title: {
            align: "center",
          },
          value: {
            fontWeight: "bold",
            align: "center",
            fontSize: 16,
          },
        },
      },
      data: [
        {
          value: data?.rentPlanned,
          itemStyle: {
            barWidth: "20%",
            color: getCssVariable("--chart-color-focus"),
          },
        },
      ],
    },
    {
      name: i18n.t("cb:RentalStatusChart.rentalIncomeIs", "Mieteinnahmen IST"),
      type: "bar",
      barCategoryGap: 0,
      barGap: 0,
      stack: "is",
      label: {
        show: true,
        formatter: (data) => {
          return `{title|${
            data.seriesName
          }}\n{value|${StringUtils.formatCurrency(data.value)}}`;
        },
        rich: {
          title: {
            align: "center",
          },
          value: {
            fontWeight: "bold",
            align: "center",
            fontSize: 16,
          },
        },
      },
      emphasis: {
        focus: "series",
      },
      data: [
        {
          value: data?.rented,
          itemStyle: {
            color: getCssVariable("--chart-color-positive"),
          },
        },
      ],
    },
    {
      name: i18n.t("cb:RentalStatusChart.vacancy", "Leerstand"),
      type: "bar",
      barCategoryGap: 0,
      barGap: 0,
      stack: "is",
      label: {
        show: true,
        formatter: (data) => {
          return `{title|${
            data.seriesName
          }}\n{value|${StringUtils.formatCurrency(data.value)}}`;
        },
        rich: {
          title: {
            align: "center",
          },
          value: {
            fontWeight: "bold",
            align: "center",
            fontSize: 16,
          },
        },
      },
      emphasis: {
        focus: "series",
      },
      data: [
        {
          value: data?.vacant,
          itemStyle: {
            color: getCssVariable("--chart-color-negative"),
          },
        },
      ],
    },
    data?.rentedDiff > 0
      ? {
          name: i18n.t(
            "cb:RentalStatusChart.lessThanPlanned",
            "Mieteinnahmen weniger als geplant"
          ),
          type: "bar",
          stack: "is",
          barCategoryGap: 0,
          barGap: 0,
          label: {
            show: true,
            formatter: (data) => {
              return `{title|${
                data.seriesName
              }}\n{value|${StringUtils.formatCurrency(data.value)}}`;
            },
            rich: {
              title: {
                align: "center",
                color: getCssVariable("--text-color-strong"),
              },
              value: {
                fontWeight: "bold",
                align: "center",
                fontSize: 16,
                color: getCssVariable("--text-color-strong"),
              },
            },
          },
          emphasis: {
            focus: "series",
          },
          data: [
            {
              value: data?.rentedDiff,
              itemStyle: {
                color: getCssVariable("--chart-color-secondary"),
              },
            },
          ],
        }
      : undefined,
    data?.rentedDiff < 0
      ? {
          name: i18n.t(
            "cb:RentalStatusChart.moreThanPlanned",
            "Mieteinahmen mehr als geplant"
          ),
          type: "bar",
          stack: "plan",
          barCategoryGap: 0,
          barGap: 0,
          label: {
            show: true,
            formatter: (data) => {
              return `{title|${
                data.seriesName
              }}\n{value|${StringUtils.formatCurrency(data.value)}}`;
            },
            rich: {
              title: {
                align: "center",
              },
              value: {
                fontWeight: "bold",
                align: "center",
                fontSize: 16,
              },
            },
          },
          emphasis: {
            focus: "series",
          },
          data: [
            {
              value: data?.rentedDiff * -1,
              itemStyle: {
                color: getCssVariable("--chart-color-positive"),
              },
            },
          ],
        }
      : undefined,
  ],
});
const CBRentalStatusChart = (props: CBRentalStatusChartProps) => {
  const queryData = useStatisticQuery<CBStatisticImmoVacancyRate>(
    "IMMO_VACANCY_RATE",
    1,
    {
      fromDate: moment(props.month).startOf("month").utc(true).toISOString(),
      toDate: moment(props.month).endOf("month").utc(true).toISOString(),
      objectIds: props.objectIds,
    }
  );

  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          queryData.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });

  const data = queryData?.data
    ? {
        rentPlanned:
          (queryData?.data?.[0]?.kpi.vacancyRentNet.totalNonVacantPlanned +
            queryData?.data?.[0]?.kpi.vacancyRentNet.totalVacant) *
          (props.yearly ? 12 : 1),
        rented:
          queryData?.data?.[0]?.kpi.vacancyRentNet.totalNonVacant *
          (props.yearly ? 12 : 1),
        rentedDiff: NumberUtils.normalizeDecimal(
          (queryData?.data?.[0]?.kpi.vacancyRentNet.totalNonVacantPlanned -
            queryData?.data?.[0]?.kpi.vacancyRentNet.totalNonVacant) *
            (props.yearly ? 12 : 1)
        ),
        vacant:
          queryData?.data?.[0]?.kpi.vacancyRentNet.totalVacant *
          (props.yearly ? 12 : 1),
      }
    : null;

  return (
    <div
      className={classNames(`cb-rental-status-chart ${props.className || ""}`)}
    >
      <BFPlaceholder minHeight={200} loading={queryData.loading} width={"100%"}>
        <div className="chart-wrapper">
          <ChartComponent
            renderer="canvas"
            options={cbRentalStatusChartOption(data)}
            identifier={`cb-portfolio-dashboard-rental-status-all`}
          />
        </div>
      </BFPlaceholder>
    </div>
  );
};

export default CBRentalStatusChart;
