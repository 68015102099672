import { ObjectKind } from "@/apps/tatar/objectsApp/types/objectKind.interface";
import AssetLoader from "@/components/AssetLoader/AssetLoader";
import ModalManager from "@/components/ModalComponent/ModalManager";
import {
  PDFDocumentRect,
  PDFEditRect,
} from "@/components/PDFViewer/PDFViewerHooks";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { RentalAgreement, RentalUnit } from "../../../TenantsInterfaces";
import ObjectStackingPlan from "../../stacking-plan/ObjectStackingPlan";
import {
  RentalUnitTableEntry,
  RentalUnitTableHeader,
} from "../CBRentalAgreementHelper";
import CBRentalAgreementRentChange from "../CBRentalAgreementRentChange";
// import "./CBAgreementRentalUnits.scss";

export type RentalUnitType = {
  rentalUnits: string[];
};
interface CBAgreementRentalUnitsProps {
  marks: PDFEditRect[] | PDFDocumentRect[];
  data: RentalUnitType;
  objectId: string;
  hideStackingplan?: boolean;
  rentalAgreement?: RentalAgreement;
  editable?: boolean;
  kind: ObjectKind;
}
const CBAgreementRentalUnits = (props: CBAgreementRentalUnitsProps) => {
  return (
    <BFPDFMarkerSection
      marginBottom={20}
      readonly
      title={
        <>
          <span className={`padding-right-6`}>
            {i18n.t(
              "cb:RentalAgreement.Form.Fields.rentalUnits.title",
              "Mieteinheiten"
            )}
          </span>
          {props.rentalAgreement && props.editable && (
            <BFButton
              noPadding
              inline
              appearance="link"
              onClick={() => {
                ModalManager.show({
                  size: "fluid",
                  noPadding: true,
                  content: (state, setState, close) => {
                    return (
                      <CBRentalAgreementRentChange
                        onClose={close}
                        kind={props.kind}
                        rentalAgreementId={props.rentalAgreement._id}
                      />
                    );
                  },
                });
              }}
              size="sm"
            >
              {i18n.t("Global.Buttons.Customize", "Anpassen")}
            </BFButton>
          )}
        </>
      }
      value={props.marks}
      viewHint={i18n.t(
        "cb:RentalAgreement.Form.Fields.rentalUnits.viewHint",
        "Informationen zu vermieteten Einheiten"
      )}
      identifier="rentalUnits"
      pdfViewerIdentifier={
        props.rentalAgreement ? undefined : "rental-agreement-pdf"
      }
      cdnData={
        props.rentalAgreement
          ? {
              asset: props.rentalAgreement,
              assetField: "data.attachments",
              assetType: AssetTypes.Rental.RentalAgreement,
            }
          : undefined
      }
    >
      <>
        {!props.hideStackingplan && (
          <div className={`stacking-plan`} style={{ height: 200 }}>
            <ObjectStackingPlan
              objectId={props.objectId}
              markedUnits={props.data.rentalUnits || []}
            />
          </div>
        )}
        <div className={`units`}>
          <RentalUnitTableHeader />
          {(props.data.rentalUnits || []).map((rentalUnit) => (
            <AssetLoader
              assetType={AssetTypes.Rental.RentalUnit}
              id={rentalUnit}
              render={(rentalUnit: RentalUnit) => (
                <RentalUnitTableEntry rentalUnit={rentalUnit} asLink />
              )}
            />
          ))}
        </div>
      </>
    </BFPDFMarkerSection>
  );
};

export default CBAgreementRentalUnits;
