import { useEffect, useState } from "react";
import AssetLoader from "../../../../../components/AssetLoader/AssetLoader";
import { TaskAsset } from "../../../../../model/general-assets/TaskAsset";
import { RAInvoice } from "../../RAInterfaces";

import ArrayUtils from "../../../../../utils/ArrayUtils";
import "./RAInvoiceProcessHistory.scss";
import RAInvoiceProcessHistoryEntry from "./RAInvoiceProcessHistoryEntry";
import {
  CurrentProcessTypeFormatter,
  PastProcessTypeFormatter,
  ProcessEntryType,
} from "./RAInvoiceProcessHistoryUtils";

// | FutureProcessHistoryEntry;

const RAInvoiceProcessHistory = (props: {
  invoice: RAInvoice;
  maxHistoryHeight?: number;
}) => {
  return props.invoice?.intern?.invoiceTaskId ? (
    <AssetLoader
      assetType="task"
      id={props.invoice?.intern.invoiceTaskId}
      // renderError={() => (
      //   <RAInvoiceProcessHistoryContent invoice={props.invoice} />
      //   // <div>error</div>
      // )}
      render={(task) => {
        return (
          <RAInvoiceProcessHistoryContent
            maxHistoryHeight={props.maxHistoryHeight}
            invoice={props.invoice}
            task={task}
          />
        );
      }}
    />
  ) : (
    <RAInvoiceProcessHistoryContent
      maxHistoryHeight={props.maxHistoryHeight}
      invoice={props.invoice}
    />
  );
};

export const RAInvoiceProcessHistoryById = (props: {
  invoiceId: string;
  maxHistoryHeight?: number;
}) => {
  return (
    <AssetLoader
      assetType="invoice"
      id={props.invoiceId}
      render={(invoice) => (
        <RAInvoiceProcessHistory
          invoice={invoice}
          maxHistoryHeight={props.maxHistoryHeight}
        />
      )}
    />
  );
};

export default RAInvoiceProcessHistory;

export const RAInvoiceProcessHistoryContent = (props: {
  invoice: RAInvoice;
  task?: TaskAsset;
  maxHistoryHeight?: number;
}) => {
  const [processEntries, setProcessEntries] = useState<ProcessEntryType[]>([]);

  useEffect(() => {
    setProcessEntries([
      ...CurrentProcessTypeFormatter(props.invoice, props.task),
      ...(ArrayUtils.reverse(props.invoice?.data.history)
        // .sort((a, b) => Number(new Date(b.date)) - Number(new Date(a.date)))
        .map((e) => PastProcessTypeFormatter(e, props.invoice))
        .filter((e) => e !== null) || []),
    ]);
  }, [props.invoice, props.task]);

  //   <AssignmentLabel
  //   users={currentEntry.users.map((e) =>
  //     AssetUtils.getUser(e, props.invoice)
  //   )}
  //   teams={currentEntry.teams}
  // />
  return (
    <div
      className="ra-invoice-process-history"
      style={{ maxHeight: props.maxHistoryHeight }}
    >
      {processEntries.map((entry, index) => {
        return (
          <RAInvoiceProcessHistoryEntry
            key={index}
            entry={entry}
            invoice={props.invoice}
            task={props.task}
          />
        );
      })}
    </div>
  );
};
// const StatusToText = ()
