import { Content, DynamicContent } from "pdfmake/interfaces";
import {
  BlockConfig,
  BlockType,
  ColumnConfig,
  Config,
  TextConfig,
} from "./types/pdfConfigBlockTypes";
import {
  ObjectRiskManagementChart,
  ObjectDamageClaimsFields,
  ObjectTableActivityFilter,
  ObjectStackingPlanTableFields,
  ObjectTenantListFields,
  UpcomingVacancyFields,
  VacancyChart,
  ObjectMaintenanceContractsFields,
  ObjectLoanChart,
  ObjectLoanTableFields,
  ObjectLoanTableFilter,
} from "./types/pdfConfigObjectBlockTypes";
import { PDFDocumentDescriptionDynamicContent } from "./types/pdfConfigNodeTypes";
import { BaseConfig } from "./types/pdfConfigUtilTypes";

export const FontSize: Record<TextConfig["size"], number> = {
  sm: 8,
  md: 16,
  lg: 32,
} as const;

const PAGE_WIDTH = 595;
const PAGE_MARGIN = 40;

export const USABLE_PAGE_WIDTH = PAGE_WIDTH - 2 * PAGE_MARGIN;

let globalId = 0;

export const parseDynamicContent =
  (node: PDFDocumentDescriptionDynamicContent): DynamicContent =>
  (currentPage, pageCount) => {
    return {
      columnGap: 10,
      columns: [
        // throws error `object is not extensible` without the deep copy
        ...structuredClone(
          node.content.map((node) => {
            if (node.enablePageCount) {
              return {
                ...node,
                text: `${currentPage}/${pageCount}`,
              };
            }

            return node;
          })
        ),
      ],
      margin: node.margin,
    } as Content;
  };

export const generateId = () => {
  return ++globalId;
};

const generateDefaultProps = (): Omit<BaseConfig<BlockType, {}>, "type"> => {
  return {
    id: ++globalId,
    isTemplate: false,
    isEmpty: true,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  };
};

export const generateDefault = (type: BlockType): BlockConfig => {
  switch (type) {
    case "text":
      return {
        ...generateDefaultProps(),
        type,
        content: "",
        color: "#000",
        size: "md",
        bold: false,
        italics: false,
        background: "#fff",
        alignment: "left",
        lineHeight: 1,
        underline: false,
      };
    case "link":
      return {
        ...generateDefaultProps(),
        type,
        content: "",
        link: "",
        color: "#000",
        size: "md",
        bold: false,
        italics: false,
        background: "#fff",
        alignment: "left",
        lineHeight: 1,
        underline: false,
      };
    case "image":
      return {
        ...generateDefaultProps(),
        type,
        content: "",
        width: 0,
        height: 0,
      };
    case "line":
      return {
        ...generateDefaultProps(),
        type,
      };

    case "header": {
      return {
        ...generateDefaultProps(),
        isEmpty: false,
        type: "header",
        content: [],
        marginRight: 40,
        marginLeft: 40,
        marginTop: 10,
        marginBottom: 10,
      };
    }
    case "footer": {
      return {
        ...generateDefaultProps(),
        isEmpty: false,
        type: "footer",
        content: [],
        marginRight: 40,
        marginLeft: 40,
        marginTop: 10,
        marginBottom: 10,
      };
    }
    case "column-group": {
      return {
        ...generateDefaultProps(),
        type: "column-group",
        content: [generateDefault("column") as ColumnConfig],
        isEmpty: false,
      };
    }
    case "column": {
      return {
        ...generateDefaultProps(),
        type: "column",
        content: [],
        isEmpty: false,
        width: "*",
      };
    }
    case "break": {
      return {
        ...generateDefaultProps(),
        type: "break",
        isEmpty: true,
        pageOrientation: "portrait",
      };
    }
    case "page": {
      return {
        ...generateDefaultProps(),
        type: "page",
        pageOrientation: "portrait",
        isEmpty: true,
        marginLeft: 40,
        marginRight: 40,
        marginBottom: 40,
        marginTop: 40,
      };
    }
    case "object-details": {
      return {
        ...generateDefaultProps(),
        type: "object-details",
        assetId: "",
        viewType: "list",
      };
    }
    case "object-gallery": {
      return {
        ...generateDefaultProps(),
        type: "object-gallery",
        assetId: "",
        columns: 1,
        images: [],
      };
    }
    case "object-stacking-plan": {
      return {
        ...generateDefaultProps(),
        type: "object-stacking-plan",
        assetId: "",
        width: USABLE_PAGE_WIDTH,
        marginLeft: 0,
        marginRight: 0,
      };
    }
    case "vacancy-chart": {
      return {
        ...generateDefaultProps(),
        type: "vacancy-chart",
        assetId: "",
        chartType: VacancyChart.RENT_NET,
        width: USABLE_PAGE_WIDTH,
        marginLeft: 0,
        marginRight: 0,
      };
    }
    case "upcoming-vacancy-table": {
      return {
        ...generateDefaultProps(),
        type: "upcoming-vacancy-table",
        assetId: "",
        month: 6,
        fields: Object.values(UpcomingVacancyFields),
        fontSize: 16,
        changeOrientation: true,
        columnsInRow: 10,
        marginTop: 20,
        rowsInTable: -1,
        insertBreak: true,
      };
    }
    case "rental-unit-distribution-chart": {
      return {
        ...generateDefaultProps(),
        type: "rental-unit-distribution-chart",
        assetId: "",
        chartType: VacancyChart.RENT_NET,
        width: USABLE_PAGE_WIDTH,
        marginLeft: 0,
        marginRight: 0,
      };
    }
    case "object-stacking-plan-table": {
      return {
        ...generateDefaultProps(),
        type: "object-stacking-plan-table",
        assetId: "",
        fields: Object.values(ObjectStackingPlanTableFields),
        changeOrientation: true,
        fontSize: 16,
        columnsInRow: 10,
        marginTop: 20,
        rowsInTable: -1,
        insertBreak: true,
      };
    }
    case "object-tenant-list": {
      return {
        ...generateDefaultProps(),
        type: "object-tenant-list",
        assetId: "",
        fields: Object.values(ObjectTenantListFields),
        changeOrientation: true,
        fontSize: 16,
        columnsInRow: 10,
        marginTop: 20,
        rowsInTable: -1,
        insertBreak: true,
      };
    }
    case "object-vacancy-activity": {
      return {
        ...generateDefaultProps(),
        type: "object-vacancy-activity",
        assetId: "",
        fields: [],
        changeOrientation: true,
        fontSize: 16,
        columnsInRow: 10,
        marginTop: 20,
        rowsInTable: -1,
        insertBreak: true,
      };
    }
    case "object-damage-claims-table": {
      return {
        ...generateDefaultProps(),
        type: "object-damage-claims-table",
        assetId: "",
        fields: Object.values(ObjectDamageClaimsFields),
        changeOrientation: true,
        fontSize: 16,
        filter: ObjectTableActivityFilter.ACTIVE,
        columnsInRow: 10,
        marginTop: 20,
        rowsInTable: -1,
        insertBreak: true,
      };
    }
    case "object-rental-income-chart": {
      return {
        ...generateDefaultProps(),
        type: "object-rental-income-chart",
        assetId: "",
        months: 1,
        width: USABLE_PAGE_WIDTH,
        marginLeft: 0,
        marginRight: 0,
      };
    }
    case "object-risk-management": {
      return {
        ...generateDefaultProps(),
        type: "object-risk-management",
        assetId: "",
        chartType: ObjectRiskManagementChart.PLANNED,
        width: USABLE_PAGE_WIDTH,
        marginLeft: 0,
        marginRight: 0,
      };
    }
    case "object-maintenance-contracts-table": {
      return {
        ...generateDefaultProps(),
        type: "object-maintenance-contracts-table",
        assetId: "",
        fields: Object.values(ObjectMaintenanceContractsFields),
        changeOrientation: true,
        fontSize: 16,
        filter: ObjectTableActivityFilter.ACTIVE,
        columnsInRow: 10,
        marginTop: 20,
        marginBottom: 0,
        rowsInTable: -1,
        insertBreak: true,
      };
    }
    case "object-loan-chart": {
      return {
        ...generateDefaultProps(),
        type: "object-loan-chart",
        assetId: "",
        chartType: ObjectLoanChart.CURRENT,
        width: USABLE_PAGE_WIDTH,
        marginLeft: 0,
        marginRight: 0,
      };
    }
    case "date": {
      return {
        ...generateDefaultProps(),
        type: "date",
        format: "DD/MM/YYYY",
        alignment: "left",
      };
    }
    case "page-count": {
      return {
        ...generateDefaultProps(),
        type: "page-count",
        alignment: "left",
      };
    }
    case "object-loan-table": {
      return {
        ...generateDefaultProps(),
        type: "object-loan-table",
        columnsInRow: 10,
        assetId: "",
        filter: ObjectLoanTableFilter.ONGOING,
        fields: Object.values(ObjectLoanTableFields),
        changeOrientation: true,
        fontSize: 16,
        rowsInTable: -1,
        insertBreak: true,
      };
    }
    default:
      return null;
  }
};

export const prepareForTemplateSaving = (
  config: Config
): Omit<BlockConfig, "id">[] => {
  return config.map((block) => {
    if ("content" in block && Array.isArray(block.content)) {
      const { id: _, content, ...blockData } = block;
      return {
        ...blockData,
        content: prepareForTemplateSaving(content),
      };
    }

    switch (block.type) {
      case "text":
      case "link":
      case "image":
      case "line":
      case "break":
      case "page":
      case "date": {
        const { id: _, ...blockData } = block;
        return blockData;
      }
      case "object-details":
      case "object-stacking-plan":
      case "vacancy-chart":
      case "upcoming-vacancy-table":
      case "rental-unit-distribution-chart":
      case "object-stacking-plan-table":
      case "object-tenant-list":
      case "object-rental-income-chart":
      case "object-loan-chart":
      case "object-loan-table":
      case "object-damage-claims-table":
      case "object-maintenance-contracts-table":
      case "object-risk-management":
      case "object-vacancy-activity": {
        const { id: _, ...blockData } = block;
        return {
          ...blockData,
          assetId: "",
        };
      }
      case "object-gallery": {
        const { id: _, ...blockData } = block;
        return {
          ...blockData,
          assetId: "",
          images: [],
        };
      }
      default:
        const { id: _, ...blockData } = block;
        return {
          ...blockData,
        };
    }
  });
};
