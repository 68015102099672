import ListComponent from "@/configurable/components/ListComponent/ListComponent";
import { AssetTypes } from "@/model/AssetTypes";
import { MatchQuery } from "@/services/DataService";
import classNames from "classnames";
import UserReplacementEntry from "./UserReplacementEntry";
// import "./UserReplacementsForMe.scss";

interface UserReplacementsListProps {
  matchQuery: MatchQuery;
  emptyText: string;
  identifier: string;
}
const UserReplacementsList = (props: UserReplacementsListProps) => {
  return (
    <div className={classNames(`user-replacements-for-me`)}>
      <ListComponent
        assetType={AssetTypes.UserDependend.Replacement}
        additionalMatchQuery={props.matchQuery}
        asPost
        identifier={props.identifier}
        emptyText={props.emptyText}
        cleanupOnUnmount
        render={(entry) => (
          <div className={`__card margin-10 padding-10`} key={entry._id}>
            <UserReplacementEntry replacement={entry} />
          </div>
        )}
      />
    </div>
  );
};

export default UserReplacementsList;
