import { withPDFBlock } from "../../PDFBlock";
import { useState } from "react";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";

import { PDFBlockFooter } from "../../PDFBlockFooter";
import { ObjectDetailsConfig } from "@/components/PDFConstructor/services/types/pdfConfigObjectBlockTypes";
import i18n from "@/i18n";
import BFSelect from "@/modules/abstract-ui/forms/select/BFSelect";
import { useBlockChanges } from "../../PDFBlockHooks";
import { OBJECT_DETAILS_VIEW } from "./PDFObjectInputConstants";
import { PDFCommonInput } from "../../Common/PDFCommonInput";

const PDFObjectDetailsBlock = withPDFBlock<ObjectDetailsConfig>(
  ({ value, parentId }) => {
    const [{ asset }, { deleteBlock, saveBlock }] = usePDFConstructor();
    const [form, setForm] = useState({
      ...value,
      assetId: asset._id,
    });

    useBlockChanges(value, form);

    return (
      <div>
        <BFSelect
          data={OBJECT_DETAILS_VIEW.map((option) => ({
            ...option,
            label: option.label(),
          }))}
          value={form.viewType}
          onSelect={(value) =>
            setForm((form) => ({
              ...form,
              viewType: value,
            }))
          }
          cleanable={false}
        />

        <PDFCommonInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock(value, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () =>
    i18n.t(
      "Component.PDFConstructor.Block.Inputs.ObjectDetails",
      "Objektdetails"
    )
);

export { PDFObjectDetailsBlock };
