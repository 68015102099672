import classNames from "classnames";
import { Field } from "react-final-form";
import AssetLoader from "../../../../components/AssetLoader/AssetLoader";
import FormStruct from "../../../../components/Form/FormStruct/FormStruct";
import ModalManager from "../../../../components/ModalComponent/ModalManager";
import i18n from "../../../../i18n";
import { AssetTypes } from "../../../../model/AssetTypes";
import {
  Signature,
  UserConfigAsset,
} from "../../../../model/db/UserConfigAsset";
import { useTypedSelector } from "../../../../redux/hooks";
import CacheService from "../../../../services/CacheService";
import DataBusDefaults from "../../../../services/DataBusDefaults";
import SubmitService from "../../../../services/SubmitService";
import { hasValue } from "../../../../utils/Helpers";
import StringUtils from "../../../../utils/StringUtils";
import BFInput from "../../../abstract-ui/forms/input/BFInput";
import BFSelect from "../../../abstract-ui/forms/select/BFSelect";
import BFButton from "../../../abstract-ui/general/Button/BFButton";
import TextEditor from "../../../ckeditor/TextEditor";
import "./UserSignatureComponent.scss";

interface UserSignatureComponentProps {}
const UserSignatureComponent = (props: UserSignatureComponentProps) => {
  const userId = useTypedSelector((state) => state.global.user._id);
  const hasUserConfigAsset = useTypedSelector((state) =>
    state.global.user.permissions
      .reduce((prev, current) => {
        if (!current.ASSET) {
          return prev;
        }
        return [...prev, ...current.ASSET.map((e) => e.AssetID)];
      }, [])
      ?.includes(AssetTypes.UserDependend.UserConfig)
  );

  if (!hasUserConfigAsset) {
    return null;
  }
  return (
    <AssetLoader
      assetType={AssetTypes.UserDependend.UserConfig}
      query={{
        type: "op",
        name: "data.user",
        op: "eq",
        value: userId,
      }}
      render={(userConfig: UserConfigAsset, selector, reload) => {
        const signatures = userConfig?.data?.signatures || [];
        const defaultSignature = userConfig?.data?.defaultSignature || -1;

        const setDefaultSignature = async (defaultSignature: string | null) => {
          const asset = {
            _id: userConfig?._id,
            data: {
              defaultSignature,
            },
          };
          const data = await SubmitService.submitDataAsync({
            type: "asset",
            assetType: AssetTypes.UserDependend.UserConfig,
            data: asset,
            pushToCache: true,
            ignorePropChecks: true,
            ignoreSubmitValidation: true,
          });
          if (!userConfig) {
            reload();
          } else {
            CacheService.updateDataInCaches(selector, data);
          }
        };
        const onDelete = (index: number) => {
          ModalManager.confirm({
            title: i18n.t(
              "UserProfile.Profile.UserSignature.deleteSignature",
              "Signatur löschen"
            ),
            message: i18n.t(
              "UserProfile.Profile.UserSignature.deleteSignatureText",
              "Möchten Sie die Signatur wirklich löschen?"
            ),
            onConfirm: async () => {
              try {
                const data = await SubmitService.submitDataAsync({
                  type: "asset",
                  assetType: AssetTypes.UserDependend.UserConfig,
                  data: {
                    _id: userConfig._id,
                    data: {
                      ...userConfig.data,
                      signatures: signatures.filter((_, i) => i !== index),
                      defaultSignature:
                        index <= defaultSignature
                          ? defaultSignature - 1
                          : defaultSignature,
                    },
                  },
                  pushToCache: true,
                  ignorePropChecks: true,
                  ignoreSubmitValidation: true,
                });
                if (!userConfig) {
                  reload();
                } else {
                  CacheService.updateDataInCaches(selector, data);
                }
                DataBusDefaults.toast({
                  type: "success",
                  text: i18n.t(
                    "UserProfile.Profile.UserSignature.successDeleteSignature",
                    "Signatur wurde erfolgreich gelöscht"
                  ),
                });
              } catch (err) {
                DataBusDefaults.toast({
                  type: "error",
                  text: i18n.t(
                    "UserProfile.Profile.UserSignature.errorDeleteSignature",
                    "Signatur konnte nicht gelöscht werden"
                  ),
                });
              }
            },
            confirmButtonText: i18n.t(
              "UserProfile.Profile.UserSignature.deleteSignatureConfirm",
              "Signatur löschen"
            ),
          });
        };
        const openSignatureForm = (signature?: Signature, index?: number) => {
          ModalManager.show({
            backdrop: "static",
            noPadding: true,
            size: "lg",
            content: (states, setStates, closeModal) => (
              <SignatureFormModal
                signature={signature}
                onClose={closeModal}
                onError={(error) => {
                  DataBusDefaults.toast({
                    type: "error",
                    text: i18n.t(
                      "UserProfile.Profile.UserSignature.saveSignatureError",
                      "Fehler beim Speichern der Signatur"
                    ),
                  });
                }}
                onSuccess={async (value) => {
                  const asset = {
                    _id: userConfig?._id,
                    data: {
                      user: userId,
                      ...(userConfig?.data || {}),
                      signatures: !signature
                        ? [
                            ...(userConfig?.data?.signatures || []),
                            {
                              ...value,
                            },
                          ]
                        : userConfig.data.signatures.map((s, i) => {
                            if (i === index) {
                              return value;
                            }
                            return s;
                          }),
                    },
                  };
                  const data = await SubmitService.submitDataAsync({
                    type: "asset",
                    assetType: AssetTypes.UserDependend.UserConfig,
                    data: asset,
                    pushToCache: true,
                    ignorePropChecks: true,
                    ignoreSubmitValidation: true,
                  });
                  if (!userConfig) {
                    reload();
                  } else {
                    CacheService.updateDataInCaches(selector, data);
                  }
                  DataBusDefaults.toast({
                    type: "success",
                    text: i18n.t(
                      "UserProfile.Profile.UserSignature.saveSignatureSuccess",
                      "Signatur erfolgreich gespeichert"
                    ),
                  });
                }}
              />
            ),
          });
        };
        return (
          <div className={classNames(`user-signature-component`)}>
            <div className={`user-signature-component__header `}>
              <div className={`title __h1`}>
                {i18n.t(
                  "UserProfile.Profile.UserSignature.Title",
                  "E-Mail Signaturen"
                )}
              </div>
              <BFButton appearance="link" onClick={() => openSignatureForm()}>
                {i18n.t(
                  "UserProfile.Profile.UserSignature.Add",
                  "Neue Signatur hinzufügen"
                )}
              </BFButton>
            </div>
            {signatures.length > 0 && (
              <div className={`user-signature-component__default-selection`}>
                <BFSelect
                  label={i18n.t(
                    "UserProfile.Profile.UserSignature.DefaultSignature",
                    "Standard Signatur"
                  )}
                  value={
                    hasValue(userConfig?.data?.defaultSignature)
                      ? userConfig?.data?.defaultSignature
                      : -1
                  }
                  onChange={setDefaultSignature}
                  labelPosition={"left"}
                  cleanable={false}
                  data={[
                    {
                      label: i18n.t(
                        "UserProfile.Profile.UserSignature.NoDefaultSignature",
                        "Keine Standard Signatur"
                      ),
                      value: -1,
                    },
                    ...signatures.map((s, i) => ({
                      label: s.title,
                      value: i,
                    })),
                  ]}
                />
              </div>
            )}
            <div className={`user-signature-component__content`}>
              {signatures.length === 0 && (
                <div className={`user-signature-component__content__empty`}>
                  {i18n.t(
                    "UserProfile.Profile.UserSignature.Empty",
                    "Keine Signatur vorhanden"
                  )}
                </div>
              )}
              {signatures.map((signature, index) => {
                return (
                  <SignatureEntry
                    signature={signature}
                    key={index}
                    onDelete={() => onDelete(index)}
                    onEdit={() => {
                      openSignatureForm(signature, index);
                    }}
                  />
                );
              })}
            </div>

            <div className={`add-action-container`}></div>
          </div>
        );
      }}
    />
  );
};

export default UserSignatureComponent;

const SignatureEntry = (props: {
  signature: Signature;
  onEdit: () => void;
  onDelete: () => void;
}) => {
  return (
    <div className={`signature-entry`}>
      <div className={`signature-entry__header`}>
        <div className={`signature-entry__header__title`}>
          {props.signature.title}
        </div>
        <div className={`signature-entry__header__actions`}>
          <BFButton appearance="link" onClick={props.onEdit}>
            {i18n.t("Global.Buttons.edit")}
          </BFButton>
          <BFButton appearance="link" onClick={props.onDelete}>
            {i18n.t("Global.Buttons.delete")}
          </BFButton>
        </div>
      </div>
      <div className={`signature-entry__content`}>
        <div
          className={`signature-entry__content__text`}
          dangerouslySetInnerHTML={{
            __html: StringUtils.sanitizeHtml(props.signature.signature),
          }}
        />
      </div>
    </div>
  );
};

const SignatureFormModal = (props: {
  signature?: Signature;
  onClose: () => void;
  onSuccess: (data: Signature) => Promise<any>;
  onError?: (error: any) => void;
}) => {
  return (
    <FormStruct
      className={"user-signature-form"}
      initialValues={{
        title: props.signature?.title || "",
        signature: props.signature?.signature || "",
      }}
      title={
        props.signature
          ? i18n.t(
              "UserProfile.Profile.UserSignature.Form.TitleEdit",
              "Signatur bearbeiten"
            )
          : i18n.t(
              "UserProfile.Profile.UserSignature.Form.TitleCreate",
              "Signatur erstellen"
            )
      }
      description={
        props.signature ? (
          <div className={`user-signature-form__description`}>
            {i18n.t(
              "UserProfile.Profile.UserSignature.Form.DescriptionEdit",
              "Bearbeiten Sie die ausgewählte Signatur. Beim Versenden von E-Mails in Ihrem Namen, kann diese Signatur ausgewählt werden."
            )}
          </div>
        ) : (
          <div className={`user-signature-form__description`}>
            {i18n.t(
              "UserProfile.Profile.UserSignature.Form.DescriptionCreate",
              "Erstellen Sie eine neue Signatur. Beim Versenden von E-Mails in Ihrem Namen, kann diese Signatur ausgewählt werden"
            )}
          </div>
        )
      }
      submitText={
        props.signature
          ? i18n.t("Global.Buttons.save")
          : i18n.t("Global.Buttons.create")
      }
      onAbort={props.onClose}
      onSubmit={async (values) => {
        try {
          await props.onSuccess(values);
          props.onClose();
        } catch (err) {
          props.onError?.(err);
        }
      }}
      validate={(values) => {
        const errors: any = {};
        if (!values.title) {
          errors.title = i18n.t(
            "UserProfile.Profile.UserSignature.Form.Error.title",
            "Bitte geben Sie einen Namen ein"
          );
        }
        if (!values.signature) {
          errors.signature = i18n.t(
            "UserProfile.Profile.UserSignature.Form.Error.Signature",
            "Bitte geben Sie eine Signatur ein"
          );
        }
        return errors;
      }}
      render={({ form }) => (
        <>
          <div className={`__field`}>
            <Field name="title">
              {({ input, meta }) => (
                <BFInput
                  {...input}
                  label={`${i18n.t(
                    "UserProfile.Profile.UserSignature.Form.Label.title",
                    "Name"
                  )}*`}
                  validation={
                    meta.error && meta.touched
                      ? {
                          level: "error",
                          message: meta.error,
                        }
                      : undefined
                  }
                />
              )}
            </Field>
          </div>
          <div className={`__field`}>
            <Field name="signature">
              {({ input, meta }) => (
                <>
                  <TextEditor
                    placeholder={i18n.t(
                      "UserProfile.Profile.UserSignature.Form.Placeholder.Signature",
                      "Schreiben Sie Ihre Signatur hier..."
                    )}
                    value={input.value}
                    onChange={input.onChange}
                  />
                  {meta.error && meta.touched && (
                    <div className={`__error margin-top-10`}>{meta.error}</div>
                  )}
                </>
              )}
            </Field>
          </div>
        </>
      )}
    />
  );
};
