import AssetLoader from "@/components/AssetLoader/AssetLoader";
import ModalManager from "@/components/ModalComponent/ModalManager";
import i18n from "@/i18n";
import { AssetTypes } from "@/model/AssetTypes";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import DocumentStoreManaged from "@/modules/document-store/DocumentStoreManaged";
import MQ from "@/utils/MatchQueryUtils";
import classNames from "classnames";
import { APActivity, APRentReduction } from "../../../ActivityInterfaces";
import APRentReductionDetails from "./APRentReductionDetails";
import APRentReductionForm from "./APRentReductionForm";
import "./APRentReductionView.scss";

interface APRentReductionProps {
  activity: APActivity;
}
const APRentReductionView = (props: APRentReductionProps) => {
  const openForm = (
    asset?: APRentReduction,
    onDeleted?: () => void,
    onSuccess?: (asset: APRentReduction) => void
  ) => {
    ModalManager.show({
      size: "sm",
      noPadding: true,
      content: (state, setState, closeModal) => (
        <APRentReductionForm
          activity={props.activity}
          onClose={closeModal}
          asset={asset}
          onDeleted={onDeleted}
          onSuccess={onSuccess}
        />
      ),
    });
  };
  return (
    <div className={classNames(`ap-rent-reduction`)}>
      <AssetLoader
        assetType={AssetTypes.ActivityRelated.RentReduction}
        ignoreDelay
        query={MQ.eq("data.activityId", props.activity._id)}
        render={(rentReduction: APRentReduction, selector, reload) =>
          rentReduction ? (
            // second appleloader, otherwise cache is not properly reloaded because of generated cache id by matchquery
            <AssetLoader
              assetType={AssetTypes.ActivityRelated.RentReduction}
              ignoreDelay
              id={rentReduction._id}
              render={(rentReduction: APRentReduction) => (
                <div className={`__card rent-reduction-card`}>
                  <div className={`card-content`}>
                    <div className={`actions`}>
                      <BFButton
                        appearance="link"
                        noPadding
                        onClick={() => {
                          openForm(rentReduction, () => {
                            reload();
                          });
                        }}
                      >
                        {i18n.t("Global.Buttons.edit")}
                      </BFButton>
                    </div>
                    <APRentReductionDetails rentReduction={rentReduction} />
                  </div>
                  <div className={`attachments`}>
                    <DocumentStoreManaged
                      // hideUploadData={true}
                      appearance="clean"
                      assetParams={{
                        asset: rentReduction,
                        type: rentReduction.data.type,
                        assetType: AssetTypes.ActivityRelated.RentReduction,
                        documentsFieldPath: "data.attachments",
                      }}
                    />
                  </div>
                </div>
              )}
            />
          ) : (
            <div className={`no-rent-reduction`}>
              <div className={`description`}>
                {i18n.t(
                  "apTemplate:RentReduction.NoReduction",
                  "Bisher wurde keine Mietminderung erfasst."
                )}
              </div>
              <div>
                <BFButton
                  className={`add-reduction`}
                  onClick={() => {
                    openForm(undefined, undefined, () => {
                      reload();
                    });
                  }}
                >
                  {i18n.t(
                    "apTemplate:RentReduction.AddRentReduction",
                    "Mietminderung hinzufügen"
                  )}
                </BFButton>
              </div>
            </div>
          )
        }
      />
    </div>
  );
};

export default APRentReductionView;
