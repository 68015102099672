import ObjectKindStruct from "@/redux/actions/struct/implemented/ObjectKindStruct";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import ChartComponent from "../../../../../../../../configurable/components/ChartComponent/ChartComponent";
import i18n from "../../../../../../../../i18n";
import BFPlaceholder from "../../../../../../../../modules/abstract-ui/general/Placeholder/BFPlaceholder";
import { useStatisticQuery } from "../../../../../../../../redux/hooks";
import DataBus from "../../../../../../../../services/DataBus";
import LanguageService from "../../../../../../../../services/LanguageService";
import { DataBusSubKeys } from "../../../../../../../../utils/Constants";
import StringUtils from "../../../../../../../../utils/StringUtils";
import { getConfigRentalUnitTypeGroup } from "../../../../tenants/CBTenantsConst";
import { CBStatisticImmoVacancyDistribution } from "../../../interfaces/CBStatisticQueries";
import "./CBVacancyByUnitChart.scss";
import { getCssVariable } from "@/apps/utils/getCssVariable";

type ChartValue = {
  id: string;
  name: string;
  area: number;
  count: number;
  rent: number;
  rentNet: number;
  parent?: string;
  color?: string;
  operatingCostGross: number;
  operatingCostNet: number;
};
interface CBVacancyByUnitChartProps {
  objectIds: string[];
  date: moment.Moment;
  selector: "count" | "area" | "rent" | "rentNet";
  referenceId?: string;
  className?: string;
}

export const calcRelevantDataForVacancyByUnitChart = (
  queryData: CBStatisticImmoVacancyDistribution
) => {
  const relevantUnits = queryData
    ?.map((e) => ObjectKindStruct.getUnitTypeBy(e._id))
    .filter((e) => e);
  const relevantGroups = _.uniq(relevantUnits.map((e) => e.group)).map(
    (id) => ({
      id: id,
      ...(getConfigRentalUnitTypeGroup(id) || {}),
    })
  );

  const allData: ChartValue[] = [];

  if (relevantUnits.length > 0) {
    allData.push({
      id: "all",
      color: getCssVariable("--chart-color-negative"),
      name: i18n.t("cb:Vacancy.all", "Leerstand Gesamt"),
      parent: null,
      ...queryData.reduce(
        (acc, cur) => ({
          area: acc.area + cur.area,
          operatingCostGross: acc.operatingCostGross + cur.operatingCostGross,
          operatingCostNet: acc.operatingCostNet + cur.operatingCostNet,
          rent: acc.rent + cur.rentGross,
          rentNet: acc.rentNet + cur.rentNet,
          count: acc.count + (cur.count || 0),
        }),
        {
          area: 0,
          operatingCostGross: 0,
          operatingCostNet: 0,
          rent: 0,
          rentNet: 0,
          count: 0,
        }
      ),
    });

    relevantGroups.forEach((group) => {
      allData.push({
        id: group.id,
        name: group.label,
        color: group.color,
        parent: "all",
        ...queryData
          .filter(
            (e) => ObjectKindStruct.getUnitTypeBy(e._id)?.group === group.id
          )
          .reduce(
            (acc, cur) => ({
              area: acc.area + cur.area,
              operatingCostGross:
                acc.operatingCostGross + cur.operatingCostGross,
              operatingCostNet: acc.operatingCostNet + cur.operatingCostNet,
              rent: acc.rent + cur.rentGross,
              rentNet: acc.rentNet + cur.rentNet,
              count: acc.count + (cur.count || 0),
            }),
            {
              area: 0,
              operatingCostGross: 0,
              operatingCostNet: 0,
              rent: 0,
              rentNet: 0,
              count: 0,
            }
          ),
      });

      relevantUnits
        .filter((e) => e.group === group.id)
        .forEach((unit) => {
          const el = queryData.find((e) => e._id === unit.id);
          if (el) {
            allData.push({
              id: unit.id,
              name: LanguageService.translateLabel(unit.displayName),
              parent: group.id,
              operatingCostGross: el.operatingCostGross,
              operatingCostNet: el.operatingCostNet,
              area: el.area,
              rent: el.rentGross,
              rentNet: el.rentNet,
              count: el.count || 0,
            });
          }
        });
    });

    return allData;
  } else {
    return null;
  }
};
const CBVacancyByUnitChart = (props: CBVacancyByUnitChartProps) => {
  const queryData = useStatisticQuery<CBStatisticImmoVacancyDistribution>(
    "IMMO_VACANCY_RATE_DETAIL",
    2,
    {
      date: props.date.startOf("day").utc(true).toISOString(),
      objectIds: props.objectIds,
    }
  );
  const [data, setData] = useState<ChartValue[]>(null);
  useEffect(() => {
    const subId =
      props.referenceId &&
      DataBus.subscribe(DataBusSubKeys.ASSET_CACHED, (cache: any) => {
        if (cache.selector === props.referenceId) {
          queryData.reload();
        }
      });

    return () => {
      if (subId) {
        DataBus.unsubscribe(subId);
      }
    };
  });

  useEffect(() => {
    if (!queryData.data) {
      if (data) {
        setData(null);
      }
    } else {
      setData(calcRelevantDataForVacancyByUnitChart(queryData.data));
    }
  }, [queryData.data]);

  return (
    <div
      className={classNames(
        `cb-vacancy-by-unit-chart ${props.className || ""}`
      )}
    >
      <BFPlaceholder
        minHeight={350}
        loading={queryData.loading || !data}
        width={"100%"}
      >
        <div className="chart-wrapper">
          <ChartComponent
            renderer="canvas"
            options={cbVacancyByUnitChartOptions(data, props.selector)}
            identifier={`cb-portfolio-vacancy-by-unit-all`}
          />
        </div>
      </BFPlaceholder>
    </div>
  );
};

export default CBVacancyByUnitChart;

export const cbVacancyByUnitChartOptions = (
  data: ChartValue[],
  selector: "count" | "area" | "rent" | "rentNet",
  forPdf?: boolean
) => {
  return {
    animation: forPdf ? false : true,
    series: {
      type: "sankey",
      layout: "none",
      draggable: false,
      emphasis: {
        focus: "adjacency",
      },
      data:
        data?.map((e) => ({
          name: e.id,
          itemStyle: {
            color: e.color,
          },
          valueDisplay:
            selector === "area"
              ? `${StringUtils.formatNumber(
                  e[selector]
                )} ${StringUtils.getAreaUnit()}`
              : selector === "rent" || selector === "rentNet"
              ? StringUtils.formatCurrency(e[selector])
              : e[selector],
          displayName: e.name,
        })) || [],
      links:
        data
          ?.filter((e) => e.id !== "all")
          .map((e) => ({
            source: e.parent,
            target: e.id,
            value: e[selector],
          })) || [],

      label: {
        formatter: (entry) => {
          return `{title|${entry.data.displayName}}\n{value|${entry.data.valueDisplay}}`;
        },

        rich: {
          title: {
            color: getCssVariable("--text-color-secondary"),
            align: "left",
            padding: [3, 0, 2, 0],
          },
          value: {
            color: getCssVariable("--text-color-strong"),
            fontSize: 14,
            align: "left",
          },
        },
      },
    },
  };
};
