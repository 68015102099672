import FormFieldValues from "@/components/Form/Fields/FormFieldValues";
import { FV } from "@/components/Form/Validation/FormValidators";
import i18n from "@/i18n";
import BFFormSection from "@/modules/abstract-ui/data/form-section/BFFormSection";
import BFDatefield from "@/modules/abstract-ui/forms/datefield/BFDatefield";
import BFPDFMarkerSection from "@/modules/abstract-ui/forms/pdf-marker/BFPDFMarkerSection";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import { isDefined } from "@/utils/Helpers";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import CBRentalAgreementPaymentPositions from "./CBRentalAgreementPaymentPositions";
// import "./CBRentalAgreementGraduatedRentForm.scss";

interface CBRentalAgreementGraduatedRentFormProps {
  objectId: string;
}
const CBRentalAgreementGraduatedRentForm = (
  props: CBRentalAgreementGraduatedRentFormProps
) => {
  return (
    <Field name="graduatedRent" allowNull defaultValue={null}>
      {(graduatedRent) => {
        if (graduatedRent.input.value === null) {
          return null;
        }
        return (
          <div className={`option-form`}>
            <Field
              name="marks.graduatedRent"
              validate={FV.compose(FV.required(), FV.min(1))}
            >
              {({ input, meta }) => (
                <BFPDFMarkerSection
                  marginBottom={20}
                  {...FV.getValidation(meta)}
                  title={
                    <>
                      <span className={`margin-right-6`}>
                        {i18n.t(
                          "cb:RentalAgreement.Form.SectionTitle.graduatedRent.title",
                          "Staffelmiete"
                        )}
                      </span>
                      <BFButton
                        inline
                        onClick={() => graduatedRent.input.onChange(null)}
                        appearance="link"
                        size="xs"
                        noPadding
                        className={`margin-left-10`}
                      >
                        {i18n.t("Global.Buttons.remove")}
                      </BFButton>
                    </>
                  }
                  {...input}
                  editHint={i18n.t(
                    "cb:RentalAgreement.Form.Fields.graduatedRent.editHint",
                    "Markieren Sie die Stellen, wo Sie die Informationen zur Staffelmiete finden."
                  )}
                  viewHint={i18n.t(
                    "cb:RentalAgreement.Form.Fields.graduatedRent.viewHint",
                    "Informationen zur Staffelmeite"
                  )}
                  identifier="graduatedRent"
                  pdfViewerIdentifier="rental-agreement-pdf"
                  value={input.value}
                  onChange={input.onChange}
                >
                  <>
                    <FormFieldValues
                      names={["moveIn", "agreementExpiration", "moveOut"]}
                    >
                      {([moveIn, agreementExpiration, moveOut]) => (
                        <FieldArray name="graduatedRent">
                          {({ fields }) => (
                            <div>
                              {fields.map((name, index) => {
                                const dateBefore =
                                  index === 0
                                    ? moveIn
                                    : graduatedRent.input.value[index - 1]
                                        .fromDate;

                                return (
                                  <div>
                                    <BFFormSection
                                      className="margin-bottom-20"
                                      title={
                                        <Field
                                          name={`${name}.fromDate`}
                                          validate={FV.compose(
                                            FV.required(),
                                            FV.dateAfter(dateBefore),
                                            agreementExpiration
                                              ? FV.dateBefore(
                                                  agreementExpiration
                                                )
                                              : undefined,
                                            moveOut
                                              ? FV.dateBefore(moveOut)
                                              : undefined
                                          )}
                                        >
                                          {({ input, meta }) => (
                                            <BFDatefield
                                              preventNull
                                              label={`${i18n.t(
                                                "cb:RentalAgreement.Form.Fields.fromDate",
                                                "Ab"
                                              )}`}
                                              validatorStyle="behind"
                                              labelPosition="left"
                                              {...FV.getValidation(meta)}
                                              {...input}
                                            />
                                          )}
                                        </Field>
                                      }
                                      initialOpen
                                    >
                                      <div>
                                        <CBRentalAgreementPaymentPositions
                                          objectId={props.objectId}
                                          fieldName={`${name}.paymentPositions`}
                                        />
                                        <div
                                          className={`__flex __justify-end margin-top-20`}
                                        >
                                          <BFButton
                                            noPadding
                                            appearance="link"
                                            onClick={() => fields.remove(index)}
                                          >
                                            {i18n.t("Global.Buttons.remove")}
                                          </BFButton>
                                        </div>
                                      </div>
                                    </BFFormSection>
                                  </div>
                                );
                              })}

                              <div className={`__flex __justify-center`}>
                                <BFButton
                                  noPadding
                                  onClick={() =>
                                    fields.push({
                                      fromDate: null,
                                      paymentPositions:
                                        fields.value[fields.value.length - 1]
                                          ?.paymentPositions,
                                    })
                                  }
                                  appearance="link"
                                  size="xs"
                                >
                                  {i18n.t(
                                    "cb:RentalAgreement.Form.Buttons.addGraduatedRent",
                                    "Weitere Staffelmiete hinzufügen"
                                  )}
                                </BFButton>
                              </div>
                            </div>
                          )}
                        </FieldArray>
                      )}
                    </FormFieldValues>
                  </>
                </BFPDFMarkerSection>
              )}
            </Field>
          </div>
        );
      }}
    </Field>
  );
};

export default CBRentalAgreementGraduatedRentForm;

export const CBRentalAgreementGraduatedRentFormButton = () => {
  return (
    <FormFieldValues names={["paymentPositions"]}>
      {([paymentPositions]) => (
        <Field name="graduatedRent" allowNull defaultValue={null}>
          {({ input, meta }) => {
            if (!isDefined(input.value)) {
              return (
                <BFButton
                  type="button"
                  appearance="outline"
                  size="xs"
                  onClick={() =>
                    input.onChange([
                      {
                        fromDate: null,
                        paymentPositions: paymentPositions,
                      },
                    ])
                  }
                  text={i18n.t(
                    "cb:RentalAgreement.Form.Buttons.addGraduatedRent",
                    "Staffelmiete hinzufügen"
                  )}
                />
              );
            } else {
              return null;
            }
          }}
        </Field>
      )}
    </FormFieldValues>
  );
};
