import "./PDFTemplatesSaver.scss";

import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import { useState } from "react";
import BFButton from "@/modules/abstract-ui/general/Button/BFButton";
import i18n from "@/i18n";
import Toast from "@/modules/abstract-ui/notification/Toast";

type PDFTemplatesSaverProps = {
  defaultContent?: string;
  onSave: (content: string) => void | Promise<void>;
};

const PDFTemplatesSaver: React.FC<PDFTemplatesSaverProps> = ({
  defaultContent = "",
  onSave,
}) => {
  const [content, setContent] = useState(defaultContent);

  return (
    <div className="pdf-constructor--template-saver">
      <h2 style={{ marginBottom: "0.5rem" }}>
        {i18n.t(
          "Component.PDFConstructor.Template.SaveTemplate",
          "Vorlage speichern"
        )}
      </h2>

      <BFInput
        value={content}
        onChange={(value) => setContent(value.toString())}
        placeholder={i18n.t(
          "Component.PDFConstructor.Template.TemplateName",
          "Vorlagenname"
        )}
      />

      <BFButton
        type="button"
        onClick={async () => {
          try {
            await onSave(content);
          } catch {
            Toast.error(
              i18n.t(
                "Component.PDFConstructor.Error.TemplateSavingError",
                "Vorlage konnte nicht gespeichert werden"
              )
            );
          }
        }}
      >
        {i18n.t("Component.PDFConstructor.Template.Save", "Speichern")}
      </BFButton>
    </div>
  );
};

export { PDFTemplatesSaver };
