import { BlockType, Config } from "../services/types/pdfConfigBlockTypes";
import "./PDFBlockList.scss";
import {
  BasePDFBlockElementProps,
  DraggablePDFBlock,
} from "../common/PDFBlockTypes";

type PDFInputElementProps = BasePDFBlockElementProps & DraggablePDFBlock;

type PDFBlockListProps = {
  config: Config;
  parentId: number;

  elements: Partial<Record<BlockType, React.FC<PDFInputElementProps>>>;
};

const PDFBlockList: React.FC<PDFBlockListProps> = ({
  config,
  parentId,
  elements,
}) => {
  if (config.filter((config) => elements[config.type]).length === 0) {
    return null;
  }

  return (
    <div className="pdf-constructor--block-list">
      {config.map((configElement, index) => {
        if (typeof configElement === "string") {
          return configElement;
        }

        const Element = elements[configElement.type];

        if (!Element) {
          return null;
        }

        return (
          <Element
            key={configElement.id}
            id={configElement.id}
            parentId={parentId}
            index={index}
            value={configElement}
          />
        );
      })}
    </div>
  );
};

export { PDFBlockList };
