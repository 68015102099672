import { useEffect, useState } from "react";

import { TextConfig } from "../../../services/types/pdfConfigBlockTypes";
import { withPDFBlock } from "../../PDFBlock";
import { PDFBlockFooter } from "../../PDFBlockFooter";
import { useDebouncedEffect } from "@/utils/Hooks";
import i18n from "@/i18n";

import BFInput from "@/modules/abstract-ui/forms/input/BFInput";
import { PDFTextarea } from "./PDFTextarea";
import { usePDFConstructor } from "@/components/PDFConstructor/context/PDFConstructorContext";
import { useBlockChanges } from "../../PDFBlockHooks";
import { PDFCommonInput } from "../../Common/PDFCommonInput";

const PDFTextInput = withPDFBlock<TextConfig>(
  ({ value, parentId }) => {
    const [form, setForm] = useState(value);
    const [_, { editBlock, deleteBlock, saveBlock }] = usePDFConstructor();

    const setFormValue = <K extends keyof typeof form>(
      key: K,
      value: typeof form[K]
    ) => {
      // color picker sets a new color whenever it's opened which causes unwanted rerenders
      if (form[key] === value) {
        return;
      }

      setForm((form) => ({
        ...form,
        [key]: value,
      }));
    };

    useBlockChanges(value, form);

    return (
      <div>
        <PDFTextarea form={form} setFormValue={setFormValue} />

        <BFInput
          label={i18n.t(
            "Component.PDFConstructor.Block.LineHeight",
            "Linieneingabe"
          )}
          value={form.lineHeight}
          placeholder={i18n.t(
            "Component.PDFConstructor.Block.LineHeight",
            "Linieneingabe"
          )}
          type="number"
          onChange={(value) => {
            setFormValue("lineHeight", Number(value));
          }}
          min={1}
          step={0.1}
        />

        <PDFCommonInput
          value={form}
          onChange={(key, value) =>
            setForm((form) => ({
              ...form,
              [key]: value,
            }))
          }
        />

        <PDFBlockFooter
          isEmpty={value.isEmpty}
          onSave={() => saveBlock(value, parentId)}
          onDelete={() => deleteBlock(value)}
        />
      </div>
    );
  },
  () => i18n.t("Component.PDFConstructor.Block.Inputs.TextInput", "Texteingabe")
);

export { PDFTextInput };
